import gql from 'graphql-tag';
import client from '../../graphql/client';
import { applicationBasicFragment } from '../application/queries';

/* all applications fetch */
const fetchAllApplicationsGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query allApplications(
      $candidateName: String
      $tags: [Int]
      $projects: [Int]
      $offset: Int
      $limit: Int
    ) {
      allApplicationsInProjects(
        candidateName: $candidateName
        tags: $tags
        projects: $projects
        offset: $offset
        limit: $limit
      ) {
        count
        applications {
          id
          ...BasicFields
          fromSite
          projects {
            id
            name
          }
          job {
            id
            title
          }
          tags {
            id
            name
            special_type
          }
        }
      }
    }
    ${applicationBasicFragment}
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const fetchAllTagsGraphql = () => {
  const query = gql`
    query tagFilters {
      projectFilters {
        id
        name
      }
    }
  `;

  return client.query({
    query,
  });
};

const fetchProjectsGraphql = () => {
  const query = gql`
    query projectsFilterList {
      projects(sortField: "", sortDirection: "desc", limit: null) {
        project {
          id
          name
        }
      }
    }
  `;

  return client.query({
    query,
  });
};

export { fetchAllApplicationsGraphql, fetchProjectsGraphql, fetchAllTagsGraphql };
