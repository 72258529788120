import { Map } from 'immutable';
import createAboutUsFromAPIResponse from '../aboutUs/helpers';
import createCompanyTeamMemberFromAPIResponse from '../companyTeamMembers/helpers';
import createPostFromAPIResponse from '../profilePost/helpers';
import createSelectionProcessFromAPIResponse from '../selectionProcess/helpers';
import { ProfileActions, ProfileState } from '../types';
import { calculatePreviewType, getProfileSnapshot } from './helpers';
import {
  CREATE_PROFILE_SNAPSHOT,
  PROFILE_FETCH_CANCELLED,
  PROFILE_FETCH_DENIED,
  PROFILE_FETCH_DONE,
  PROFILE_FETCH_ERROR,
  PROFILE_FETCH_START,
  PROFILE_ORDER_CANCELLED,
  PROFILE_ORDER_DONE,
  PROFILE_ORDER_ERROR,
  PROFILE_ORDER_START,
  PUBLISH_PROFILE_CANCELLED,
  PUBLISH_PROFILE_DONE,
  PUBLISH_PROFILE_ERROR,
  PUBLISH_PROFILE_START,
  SET_PROFILE_LANGUAGE,
  SET_PROFILE_PREVIEW_TYPE,
  TOGGLE_PREVIEW_MODAL,
  TOGGLE_SUBSECTION_EDITING_IN_PROGRESS,
  UNHIDE_PROFILE_CANCELLED,
  UNHIDE_PROFILE_DONE,
  UNHIDE_PROFILE_ERROR,
  UNHIDE_PROFILE_START
} from './types';

const initState: ProfileState = Map<string, any>({
  id: null,
  profileType: 'free',
  profilePreviewType: 'free',
  active: false,
  published: false,
  hidden: false,
  orderedProfileTypes: [],
  orderedProfileTypesNew: [],
  pib: '',
  maticniBroj: '',
  profileBadges: [],
  profileFetching: true,
  profileFetchError: null,
  accessDenied: false,

  profileOrderInProgress: false,
  profileOrderError: null,
  profileOrderDone: false,

  publishProfileInProgress: false,
  publishProfileError: null,
  publishProfileDone: false,

  unhideProfileInProgress: false,
  unhideProfileError: null,
  unhideProfileDone: false,

  profileSnapshot: '',
  showPreviewModal: false,
  subsectionEditingInProgress: false,
});

const reducer = (state = initState, action: ProfileActions) => {
  switch (action.type) {
    case CREATE_PROFILE_SNAPSHOT:
      return state.set(
        'profileSnapshot',
        getProfileSnapshot({
          myCompanySection: {
            companyName: state.get('companyName'),
            companyLogo: state.get('companyLogo'),
            slogan: state.get('slogan'),
            backgroundImage: state.get('backgroundImage'),
          },
          aboutUs: state.get('aboutUs'),
          companyBenefits: state.get('companyBenefits'),
          companyTeamMembers: state.get('companyTeamMembers'),
          companyBrands: state.get('companyBrands'),
          selectionProcess: state.get('selectionProcess'),
          profilePosts: state.get('profilePosts'),
          contactSection: {
            contactInfo: state.get('contactInfo'),
            socialNetworks: state.get('socialNetworks'),
            website: state.get('website'),
            employeeCountOption: state.get('employeeCountOption'),
          },
          companyAddresses: state.get('companyAddresses'),
        })
      );
    case PROFILE_FETCH_START:
      return state
        .set('profileFetching', true)
        .set('profileFetchError', null)
        .set('accessDenied', false);

    case PROFILE_FETCH_ERROR:
      return state.set('profileFetching', false).set('profileFetchError', action.payload);
    case PROFILE_FETCH_CANCELLED:
      return state.set('profileFetching', false);

    case PROFILE_FETCH_DENIED:
      return state.set('profileFetching', false).set('accessDenied', true);

    case PROFILE_FETCH_DONE:
      return state
        .set('id', action.payload.id)
        .set('profileFetching', false)
        .set('pib', action.payload.pib)
        .set('maticniBroj', action.payload.maticniBroj)
        .set('active', action.payload.active)
        .set('gracePeriodActive', action.payload.gracePeriodActive)
        .set('published', action.payload.published)
        .set('hidden', action.payload.hidden)
        .set('language', action.payload.language)
        .set('profileBadges', action.payload.profileBadges)
        .set('orderedProfileTypes', action.payload.orderedProfileTypesNew)
        .set('profileType', action.payload.profileType)
        .set('profilePreviewType', calculatePreviewType(action.payload))
        .set('companyName', action.payload.companyName)
        .set('companyLogo', action.payload.companyLogo)
        .set('slogan', action.payload.profileHeader.slogan)
        .set('backgroundImage', action.payload.profileHeader.backgroundImage)
        .set('aboutUs', createAboutUsFromAPIResponse(action.payload.aboutUs))
        .set('website', action.payload.website)
        .set('employeeCountOption', action.payload.employeeCountOption)
        .set('contactInfo', action.payload.companyContactInfo)
        .set('socialNetworks', action.payload.socialNetworks)
        .set(
          'selectionProcess',
          createSelectionProcessFromAPIResponse(action.payload.selectionProcess)
        )
        .set(
          'profilePosts',
          action.payload.profilePosts.map((profilePost) => createPostFromAPIResponse(profilePost))
        )
        .set(
          'companyBrands',
          action.payload.companyBrands?.map((companyBrand) => ({
            ...companyBrand,
            type: 'existing',
          })) || []
        )
        .set(
          'companyTeamMembers',
          action.payload.companyTeamMembers?.map((companyTeamMember) =>
            createCompanyTeamMemberFromAPIResponse(companyTeamMember)
          )
        )
        .set(
          'companyBenefits',
          action.payload.companyBenefits?.map(
            (companyBenefit) =>
              ({
                ...companyBenefit,
                benefitTypeId: companyBenefit?.benefitType?.id || 0,
              } || [])
          )
        )
        .set(
          'companyAddresses',
          action.payload.companyAddresses.length === 0
            ? [
                {
                  address: '',
                },
              ]
            : action.payload.companyAddresses
        );
    case SET_PROFILE_PREVIEW_TYPE:
      return state.set('profilePreviewType', action.payload);
    case SET_PROFILE_LANGUAGE:
      return state.set('language', action.payload);
    case PROFILE_ORDER_START:
      return state
        .set('profileOrderInProgress', true)
        .set('profileOrderDone', false)
        .set('profileOrderError', null);
    case PROFILE_ORDER_CANCELLED:
      return state.set('profileOrderInProgress', false).set('profileOrderDone', false);
    case PROFILE_ORDER_ERROR:
      return state
        .set('profileOrderInProgress', false)
        .set('profileOrderDone', false)
        .set('profileOrderError', action.payload);
    case PROFILE_ORDER_DONE:
      return state
        .set('profileOrderInProgress', false)
        .set('orderedProfileTypes', [...state.get('orderedProfileTypes'), action.payload])
        .set('profileOrderDone', true)
        .set('profileOrderError', null);
    case PUBLISH_PROFILE_START:
      return state
        .set('publishProfileInProgress', true)
        .set('publishProfileDone', false)
        .set('publishProfileError', null);
    case PUBLISH_PROFILE_CANCELLED:
      return state.set('publishProfileInProgress', false).set('publishProfileDone', false);
    case PUBLISH_PROFILE_ERROR:
      return state
        .set('publishProfileInProgress', false)
        .set('publishProfileDone', false)
        .set('publishProfileError', action.payload);
    case PUBLISH_PROFILE_DONE:
      return state
        .set('publishProfileInProgress', false)
        .set('publishProfileDone', true)
        .set('published', true)
        .set('active', true)
        .set('publishProfileError', null);
    case UNHIDE_PROFILE_START:
      return state
        .set('unhideProfileInProgress', true)
        .set('unhideProfileDone', false)
        .set('unhideProfileError', null);
    case UNHIDE_PROFILE_CANCELLED:
      return state.set('unhideProfileInProgress', false).set('unhideProfileDone', false);
    case UNHIDE_PROFILE_ERROR:
      return state
        .set('unhideProfileInProgress', false)
        .set('unhideProfileDone', false)
        .set('unhideProfileError', action.payload);
    case UNHIDE_PROFILE_DONE:
      return state
        .set('unhideProfileInProgress', false)
        .set('unhideProfileDone', true)
        .set('hidden', false)
        .set('unhideProfileError', null);

    case TOGGLE_PREVIEW_MODAL: {
      return state.set('showPreviewModal', action.payload);
    }

    case TOGGLE_SUBSECTION_EDITING_IN_PROGRESS: {
      return state.set('subsectionEditingInProgress', action.payload);
    }

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
};

export { initState, reducer };
