import {
  all, takeLatest, put, spawn, call, cancelled,
} from 'redux-saga/effects';
import Nprogress from 'nprogress';
import { message } from 'antd';
import translate from 'languageProvider/inline';
import actions from './actions';
import zipApplicationsRequest from './requests';

/**
 * Zip applications generator function
 * @param {*} action
 */
function* zipApplicationsGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    yield call(zipApplicationsRequest, action.payload);
    yield put({
      type: actions.ZIP_APPLICATIONS_DONE,
      payload: {
        applicationIds: action.payload.applicationIds,
      },
    });
    yield call(() => {
      Nprogress.done();
    });
    yield call(() => {
      message.success(translate('zip.message.success'));
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.ZIP_APPLICATIONS_ERROR });
    yield call(() => {
      message.success(translate('zip.message.error'));
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.ZIP_APPLICATIONS_CANCELLED,
      });
    }
  }
}

/* zip application filters sada */
export function* zipApplications() {
  yield takeLatest([actions.ZIP_APPLICATIONS_START], zipApplicationsGenerator);
}

export default function* rootSaga() {
  yield all([spawn(zipApplications)]);
}
