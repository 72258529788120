import { getCurrentLanguage } from 'containers/LanguageSwitcher/config';
import { language } from 'helpers/utility';
import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  isActivated: false,
  language: getCurrentLanguage(language.get() || 'serbian'),
});

const languageSwitcherReducer = function (state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return state.set('isActivated', !state.get('isActivated'));
    case actions.CHANGE_LANGUAGE:
      language.set(action.language.languageId);
      return state.set('language', action.language);
    default:
      return state;
  }
};

export default languageSwitcherReducer;
