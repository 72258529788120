import { all, spawn } from 'redux-saga/effects';
import adDiscountListSagas from './adDiscountList/saga';
import allApplicationsSagas from './allApplications/saga';
import applicationSagas from './application/saga';
import authSagas from './auth/saga';
import bannerSagas from './banner/saga';
import companySagas from './company/saga';
import continueIncompleteJobSaga from './continueIncompleteJob/saga';
import { rootSaga as cvDatabaseSagas } from './cvDatabase';
import createApplicationSagas from './editApplication/saga';
import emailTemplateEditorSagas from './emailTemplateEditor/saga';
import emailTemplatesSagas from './emailTemplates/saga';
import faqSagas from './faq/saga';
import { rootSaga as featureFlagsSagas } from './featureFlags';
import feedbackSagas from './feedback/saga';
import forwardApplicationsSagas from './forwardApplications/saga';
import invoicesSagas from './invoice/saga';
import jobSagas from './job/saga';
import jobReceiptSagas from './jobReceipt/saga';
import languageSagas from './languageSwitcher/saga';
import listsSagas from './lists/saga';
import newProfileSagas from './newProfile/saga';
import notificationSagas from './notification/saga';
import packagesSagas from './packages/saga';
import pricelistSagas from './pricelist/saga';
import profileSagas from './profile/saga';
import projectSagas from './project/saga';
import projectsSagas from './projects/saga';
import recruitRankSagas from './recruitRank/saga';
import shareSagas from './share/saga';
import spreadsheetSagas from './spreadsheet/saga';
import teamSagas from './team/saga';
import themeSagas from './themeSwitcher/saga';
import tourSaga from './tour/saga';
import upsaleSagas from './upsale/saga';
import userSagas from './user/saga';
import zipSagas from './zip/saga';

export default function* rootSaga() {
  yield all([
    spawn(authSagas),
    spawn(jobSagas),
    spawn(emailTemplatesSagas),
    spawn(userSagas),
    spawn(applicationSagas),
    spawn(emailTemplateEditorSagas),
    spawn(forwardApplicationsSagas),
    spawn(allApplicationsSagas),
    spawn(shareSagas),
    spawn(teamSagas),
    spawn(zipSagas),
    spawn(spreadsheetSagas),
    spawn(profileSagas),
    spawn(companySagas),
    spawn(invoicesSagas),
    spawn(packagesSagas),
    spawn(listsSagas),
    spawn(createApplicationSagas),
    spawn(feedbackSagas),
    spawn(bannerSagas),
    spawn(themeSagas),
    spawn(languageSagas),
    spawn(notificationSagas),
    spawn(jobReceiptSagas),
    spawn(pricelistSagas),
    spawn(recruitRankSagas),
    spawn(adDiscountListSagas),
    spawn(tourSaga),
    spawn(projectsSagas),
    spawn(projectSagas),
    spawn(newProfileSagas),
    spawn(cvDatabaseSagas),
    spawn(featureFlagsSagas),
    spawn(faqSagas),
    spawn(upsaleSagas),
    spawn(continueIncompleteJobSaga),
  ]);
}
