import { Map, fromJS } from 'immutable';
import actions from './actions';

const initState = new Map({ cities: null, regions: null });

export default function listReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_LISTS_DONE: {
      let newState = state;
      Object.keys(action.payload).forEach((key) => {
        newState = newState.set(key, fromJS(action.payload[key]));
      });
      return newState;
    }
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
