import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  expanded: false,
});

export default function siderReducer(state = initState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return state.set('expanded', false);
    case actions.TOGGLE_SIDEBAR:
      return state.set('expanded', !state.get('expanded'));
    case actions.SIDEBAR_EXPAND:
      return state.set('expanded', !action.expanded);
    default:
      return state;
  }
}
