import axios from 'axios';
import config from 'config';
import { getToken } from 'helpers/utility';

export const uploadPhotoRequest = (file: any) => {
  const formData = new FormData();
  formData.append('image', file);
  return axios({
    method: 'post',
    url: `${config.apiUrl}/imageUpload`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};
