import { Map } from 'immutable';
import ImmutableMapInterface from '../ImmutableMapInterface.type';
import profileNavigationActions from './actions';

interface ProfileNavigatioState extends Map<string, any>{
  activeSection: string;
}

export type ProfileNavigatioMapState = ImmutableMapInterface<ProfileNavigatioState, string, any>;

const initState: ProfileNavigatioMapState = Map<string, any>({
  activeSection: '1',
});

type ProfileNavigationActions = ChangeSectionAction;

interface ChangeSectionAction {
  type: typeof profileNavigationActions.CHANGE_SECTION;
  payload: string;
}

const profileNavigationReducer = (state = initState, action: ProfileNavigationActions) => {
  switch (action.type) {
    case profileNavigationActions.CHANGE_SECTION:
      return state.set('activeSection', action.payload);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
};

export default profileNavigationReducer;
