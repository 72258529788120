import { fromJS, Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  recruitRank: new Map(),
  loading: false,
  denied: false,
});

export default function recruitRankReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_RECRUIT_RANK_START:
      return state.set('loading', true).set('denied', false);

    case actions.FETCH_RECRUIT_RANK_DONE:
      return state.set('recruitRank', new Map(fromJS(action.payload))).set('loading', false);

    case actions.FETCH_RECRUIT_RANK_DENIED:
      return state.set('denied', true);

    case actions.FETCH_RECRUIT_RANK_CANCELLED:
    case actions.FETCH_RECRUIT_RANK_ERROR:
      return state.set('loading', false);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
