const spreadsheetActions = {
  SPREADSHEET_GENERATE_START: 'SPREADSHEET_GENERATE_START',
  SPREADSHEET_GENERATE_DONE: 'SPREADSHEET_GENERATE_DONE',
  SPREADSHEET_GENERATE_ERROR: 'SPREADSHEET_GENERATE_ERROR',
  SPREADSHEET_GENERATE_CANCELLED: 'SPREADSHEET_GENERATE_CANCELLED',

  generateSpreadsheet: payload => ({
    type: spreadsheetActions.SPREADSHEET_GENERATE_START,
    payload,
  }),
};

export default spreadsheetActions;
