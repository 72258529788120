const teamActions = {
  FETCH_TEAM_START: 'FETCH_TEAM_START',
  FETCH_TEAM_DONE: 'FETCH_TEAM_DONE',
  FETCH_TEAM_ERROR: 'FETCH_TEAM_ERROR',
  FETCH_TEAM_CANCELLED: 'FETCH_TEAM_CANCELLED',

  MUTATE_REVOKE_START: 'MUTATE_REVOKE_START',
  MUTATE_REVOKE_DONE: 'MUTATE_REVOKE_DONE',
  MUTATE_REVOKE_ERROR: 'MUTATE_REVOKE_ERROR',
  MUTATE_REVOKE_CANCELLED: 'MUTATE_REVOKE_CANCELLED',

  MUTATE_GRANT_START: 'MUTATE_GRANT_START',
  MUTATE_GRANT_DONE: 'MUTATE_GRANT_DONE',
  MUTATE_GRANT_ERROR: 'MUTATE_GRANT_ERROR',
  MUTATE_GRANT_CANCELLED: 'MUTATE_GRANT_CANCELLED',

  MUTATE_EMPLOYER_ACCESS_START: 'MUTATE_EMPLOYER_ACCESS_START',
  MUTATE_EMPLOYER_ACCESS_DONE: 'MUTATE_EMPLOYER_ACCESS_DONE',
  MUTATE_EMPLOYER_ACCESS_ERROR: 'MUTATE_EMPLOYER_ACCESS_ERROR',
  MUTATE_EMPLOYER_ACCESS_CANCELLED: 'MUTATE_EMPLOYER_ACCESS_CANCELLED',

  ITEM_MODIFY: 'ITEM_MODIFY',
  ITEM_RESET: 'ITEM_RESET',

  TOGGLE_MODAL: 'TOGGLE_INVITE_MODAL',

  REDEEM_ACCESS_START: 'REDEEM_ACCESS_START',
  REDEEM_ACCESS_DONE: 'REDEEM_ACCESS_DONE',
  REDEEM_ACCESS_ERROR: 'REDEEM_ACCESS_ERROR',
  REDEEM_ACCESS_CANCELLED: 'REDEEM_ACCESS_CANCELLED',

  redeemAccess: payload => ({
    type: teamActions.REDEEM_ACCESS_START,
    payload,
  }),

  resetItem: () => ({
    type: teamActions.ITEM_RESET,
  }),

  modifyItem: payload => ({
    type: teamActions.ITEM_MODIFY,
    payload,
  }),

  toggleModal: payload => ({
    type: teamActions.TOGGLE_MODAL,
    payload,
  }),

  fetchTeam: () => ({
    type: teamActions.FETCH_TEAM_START,
  }),

  revokeAccess: payload => ({
    type: teamActions.MUTATE_REVOKE_START,
    payload,
  }),

  addAccess: payload => ({
    type: teamActions.MUTATE_GRANT_START,
    payload,
  }),

  mutateEmployerAccess: payload => ({
    type: teamActions.MUTATE_EMPLOYER_ACCESS_START,
    payload,
  }),
};

export default teamActions;
