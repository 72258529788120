import {
  all, takeLatest, put, spawn, call, cancelled,
} from 'redux-saga/effects';
import Nprogress from 'nprogress';
import { message } from 'antd';
import translate from 'languageProvider/inline';
import actions from './actions';
import {
  createEmailTemplateRequest,
  deleteEmailTemplateRequest,
  updateEmailTemplateRequest,
} from './requests';

/* create templates generator */
function* createTemplatesGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    if (action.payload) {
      const response = yield call(createEmailTemplateRequest, action.payload);
      yield put({
        type: actions.EMAIL_TEMPLATE_CREATE_DONE,
        payload: response.data,
      });
    }
  } catch (e) {
    yield put({ type: actions.EMAIL_TEMPLATE_CREATE_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.EMAIL_TEMPLATE_CREATE_CANCELLED,
      });
    }
  }
}

/* delete templates generator */
function* deleteTemplatesGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    if (action.payload) {
      yield call(deleteEmailTemplateRequest, action.payload);
      yield put({
        type: actions.EMAIL_TEMPLATE_DELETE_DONE,
      });
    }
  } catch (e) {
    yield put({ type: actions.EMAIL_TEMPLATE_DELETE_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.EMAIL_TEMPLATE_DELETE_CANCELLED,
      });
    }
  }
}

/* update templates generator */
function* updateTemplatesGenerator(action) {
  try {
    if (action.payload) {
      yield call(updateEmailTemplateRequest, action.payload);
      yield put({
        type: actions.EMAIL_TEMPLATE_UPDATE_DONE,
      });
    }
  } catch (e) {
    yield put({ type: actions.EMAIL_TEMPLATE_UPDATE_ERROR });
  } finally {
    yield call(() => {
      message.success(translate('templates.saved'));
    });
    if (yield cancelled()) {
      yield put({
        type: actions.EMAIL_TEMPLATE_UPDATE_CANCELLED,
      });
    }
  }
}

/* create email templates saga */
export function* createTemplate() {
  yield takeLatest(actions.EMAIL_TEMPLATE_CREATE_START, createTemplatesGenerator);
}

/* update email templates saga */
export function* deleteTemplate() {
  yield takeLatest(actions.EMAIL_TEMPLATE_DELETE_START, deleteTemplatesGenerator);
}

/* delete email templates saga */
export function* updateTemplate() {
  yield takeLatest(actions.EMAIL_TEMPLATE_UPDATE_START, updateTemplatesGenerator);
}

export default function* rootSaga() {
  yield all([spawn(createTemplate), spawn(deleteTemplate), spawn(updateTemplate)]);
}
