import client from 'graphql/client';
import gql from 'graphql-tag';

const mutateUserLocaleGraphql = payload => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const mutation = gql`
    mutation changeUserLocale($locale: EmployerLocale!) {
      changeUserLocale(locale: $locale)
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

export default mutateUserLocaleGraphql;
