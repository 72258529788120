import { fromJS, List, Map } from 'immutable';
import languageActions from 'redux/languageSwitcher/actions';
import actions from './actions';

const initState = new Map({
  loaded: false,
  notifications: new List([]),
  loading: false,
  popupOpened: false,
  showIndicator: false,
});

export default function notificationReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_NOTIFICATION_POPUP:
      return state.withMutations(newState => {
        return newState.set('popupOpened', action.payload).set('showIndicator', false);
      });
    case actions.FETCH_NOTIFICATIONS_START:
      return state.set('loading', true);

    case actions.FETCH_NOTIFICATIONS_ERROR:
    case actions.FETCH_NOTIFICATIONS_DONE:
      return state.withMutations(newState => {
        const responseNotificationList = new List(fromJS(action.payload.items));
        let notificationList = responseNotificationList;
        try {
          if (window.sessionStorage) {
            const readNotifications = JSON.parse(
              window.sessionStorage.getItem('readNotifications')
            );
            if (readNotifications) {
              notificationList = responseNotificationList.map(notification => {
                if (readNotifications[notification.get('id')]) {
                  return notification.set('state', 'read');
                }
                return notification;
              });
            }
          }
        } catch (e) {
          log(e);
        }
        return newState
          .set('loaded', true)
          .set('loading', false)
          .set('showIndicator', action.payload.indicator)
          .set('notifications', notificationList);
      });

    case languageActions.CHANGE_LANGUAGE:
      return state.withMutations(newState => {
        return newState
          .set('loaded', false)
          .set('loading', true)
          .set('showIndicator', false)
          .set('notifications', new List([]));
      });

    case actions.MUTATE_MARK_NOTIFICATION_AS_READ_START:
      return state.setIn(
        [
          'notifications',
          state.get('notifications').findIndex(item => item.get('id') === action.payload.id),
          'state',
        ],
        'read'
      );

    case actions.LOGOUT: {
      return initState;
    }
    default:
      return state;
  }
}
