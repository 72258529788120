import { all, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import jobActions from 'redux/job/actions';
import { ReduxAction } from 'types/redux.types';
import projectsActions from './actions';

function* afterAccessChangeGenerator(action: ReduxAction) {
  if (action.payload.promiseResolver) {
    yield action.payload.promiseResolver();
  }
}

function* afterJobDeactivatedGenerator(action: ReduxAction) {
  if (action.callback) {
    yield action.callback();
  }
}

function* afterAccessChange() {
  yield takeLatest(projectsActions.UPDATE_PROJECTS_LIST, afterAccessChangeGenerator);
}

function* afterJobDeactivated() {
  yield takeEvery(jobActions.MUTATE_JOB_DEACTIVATE_START, afterJobDeactivatedGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(afterAccessChange),
    spawn(afterJobDeactivated)
  ]);
}
