import gql from 'graphql-tag';
import client from 'graphql/client';
import accessFragment from '../job/queries';

/* fetch teams */
const fetchTeamGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query fetchTeam {
      team {
        invitedMembers {
          id
          allowedContent
          allowedSection
          email
          validUntil
          issuedDate
          contentDescription
          redeemed
        }
        teamMembers {
          id
          access {
            id
            allowed
            contentType
            lastChange {
              timeOfChange
              employer {
                id
                name
                email
              }
            }
          }
          employer {
            id
            email
            name
            initials
            currentUser
          }
        }
      }
    }
  `;
  return client.query({
    query,
    variables,
    ...params,
  });
};

/* revoke access */
const mutateRevokeAccessGraphql = (variables) => {
  const params = {};

  const mutation = gql`
    mutation revokeProjectAccess($id: Int!) {
      revokeProjectAccess(id: $id) {
        id
        access {
          ...AccessFragment
        }
      }
    }
    ${accessFragment}
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

/* grant access */
const mutateGrantAccessGraphql = (variables) => {
  const params = {};

  const mutation = gql`
    mutation addProjectAccess($email: String!, $allowedContent: Int, $allowedSection: String) {
      addProjectAccess(
        email: $email
        allowedContent: $allowedContent
        allowedSection: $allowedSection
      ) {
        id
        access {
          ...AccessFragment
        }
      }
    }
    ${accessFragment}
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const mutateEmployerAccessGraphql = (variables) => {
  const params = {};
  const mutation = gql`
    mutation modifyEmployerAccess(
      $employerId: Int!
      $admin: Boolean!
      $job: Boolean!
      $profile: Boolean!
      $invoice: Boolean!
    ) {
      modifyEmployerAccess(
        employerId: $employerId
        admin: $admin
        job: $job
        profile: $profile
        invoice: $invoice
      ) {
        id
        access {
          id
          allowed
          contentType
          lastChange {
            timeOfChange
            employer {
              id
              name
              email
            }
          }
        }
        employer {
          id
          email
          name
          initials
        }
      }
    }
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const mutateRedeemAccessGraphql = (token) => {
  const mutation = gql`
    mutation redeemAccess($token: String!) {
      redeemAccess(token: $token) {
        id
        allowedContent
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      token,
    },
  });
};

export {
  mutateRevokeAccessGraphql,
  fetchTeamGraphql,
  mutateGrantAccessGraphql,
  mutateEmployerAccessGraphql,
  mutateRedeemAccessGraphql,
};
