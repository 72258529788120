import { all, takeLatest, spawn, call } from 'redux-saga/effects';
import actions from 'redux/themeSwitcher/actions';
import mutateColorSchemeGraphql from './graphql';

function* changeThemeGenerator(action) {
  try {
    yield call(mutateColorSchemeGraphql, {
      variables: {
        colorScheme: action.theme === 'atsFancy' ? 'light' : 'dark',
      },
    });
  } catch (e) {
    log(e);
  }
}

function* changeTheme() {
  yield takeLatest(actions.CHANGE_THEME, changeThemeGenerator);
}

export default function* rootSaga() {
  yield all([spawn(changeTheme)]);
}
