const actions = {
  TOGGLE_GENERIC_OVERLAY: 'TOGGLE_GENERIC_OVERLAY',

  toggleOverlay: payload => ({
    type: actions.TOGGLE_GENERIC_OVERLAY,
    payload,
  }),
};

export default actions;
