import {
  all, put, call, spawn, takeEvery, cancelled, select,
} from 'redux-saga/effects';
import Nprogress from 'nprogress';
import { notification } from 'antd';
import applicationActions from 'redux/application/actions';
import actions from 'redux/forwardApplications/actions';
import forwardApplication from 'redux/forwardApplications/requests';
import translate from 'languageProvider/inline';
import cacheActions from 'redux/cacheControl/actions';

/* applications forwarding generator */
function* forwardApplicationsGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(forwardApplication, action.payload);
    yield put({
      type: actions.FORWARD_EMAIL_DONE,
      payload: response.data,
    });
    log(action.payload);

    // refresh application if its in currrent view
    const applicationsStore = state => state.Application;
    const applicationsState = yield select(applicationsStore);
    if (
      action.payload.applicationIds.filter(item => item === applicationsState.get('application').id)
        .length
    ) {
      yield put({
        type: applicationActions.APPLICATION_REFRESH,
        payload: {
          variables: {
            applicationId: applicationsState.get('application').id,
          },
          recache: true,
        },
      });
    }

    // mark applications as invalid in graphql store
    yield put({
      type: cacheActions.INVALIDATE_APPLICATIONS,
      payload: action.payload.applicationIds.map(item => item),
    });

    yield call(() => {
      notification.success({
        message: translate(response.data.userMessage),
      });
    });
  } catch (e) {
    log(e);
    const messages = [];
    yield put({ type: actions.FORWARD_EMAIL_ERROR });
    if (e.response && e.response.data.errors) {
      Object.keys(e.response.data.errors).forEach((itemKey) => {
        const { message } = e.response.data.errors[itemKey];
        messages.push(translate(message));
      });
    }
    if (messages.length) {
      notification.error({
        message: messages.join('<br />'),
      });
    } else {
      notification.error({
        message: translate('error.userError'),
        description: translate('error.tryAgain'),
      });
    }
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FORWARD_EMAIL_CANCELLED,
      });
    }
    yield call(() => {
      Nprogress.done();
    });
  }
}

/* applications forwarding saga */
function* forwardApplications() {
  yield takeEvery([actions.FORWARD_EMAIL_START], forwardApplicationsGenerator);
}

export default function* rootSaga() {
  yield all([spawn(forwardApplications)]);
}
