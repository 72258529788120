import gql from 'graphql-tag';
import client from 'graphql/client';

const createApplicationGraphql = (variables: any) => {
  const mutation = gql`
    mutation($application: ApplicationInput) {
      mutateApplication(application: $application) {
        id
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export default createApplicationGraphql;
