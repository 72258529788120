const actions = {
  TOGGLE_BANNER: 'TOGGLE_BANNER',

  FETCH_BANNER_START: 'FETCH_BANNER_START',
  FETCH_BANNER_DONE: 'FETCH_BANNER_DONE',
  FETCH_BANNER_ERROR: 'FETCH_BANNER_ERROR',
  FETCH_BANNER_CANCELLED: 'FETCH_BANNER_CANCELLED',

  fetchBanner: () => ({
    type: actions.FETCH_BANNER_START,
  }),

  toggleBanner: payload => ({
    type: actions.TOGGLE_BANNER,
    payload,
  }),
};
export default actions;
