import {
  all, takeLatest, put, spawn, call, cancelled,
} from 'redux-saga/effects';
import actions from './actions';
import fetchListsGraphql from './graphql';

function* fetchListsGenerator() {
  try {
    const response = yield call(fetchListsGraphql);
    yield put({
      type: actions.FETCH_LISTS_DONE,
      payload: response.data.lists,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_LISTS_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_LISTS_CANCELLED,
      });
    }
  }
}

export function* fetchLists() {
  yield takeLatest(actions.FETCH_LISTS_START, fetchListsGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchLists)]);
}
