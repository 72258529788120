const upsaleActions = {
  TOGGLE_UPSALE_MODAL: 'TOGGLE_UPSALE_MODAL',

  FETCH_AVAILABLE_JOBS_START: 'FETCH_AVAILABLE_JOBS_START',
  FETCH_AVAILABLE_JOBS_DONE: 'FETCH_AVAILABLE_JOBS_DONE',
  FETCH_AVAILABLE_JOBS_ERROR: 'FETCH_AVAILABLE_JOBS_ERROR',
  FETCH_AVAILABLE_JOBS_CANCELLED: 'FETCH_AVAILABLE_JOBS_CANCELLED',

  toggleUpsaleModal: (payload: any) => ({
    type: upsaleActions.TOGGLE_UPSALE_MODAL,
    payload,
  }),

  fetchAvailableJobs: () => ({
    type: upsaleActions.FETCH_AVAILABLE_JOBS_START,
  }),
};

export default upsaleActions;
