import gql from 'graphql-tag';
import client from 'graphql/client';

const BillingFragment = gql`
  fragment BillingFragment on Employer {
    companyName
    typeId
    typeValue
    PIB
    maticniBroj
    contactPhone
    ime
    prezime
    address
    postalCode
    cityId
    cityName
    countryId
    websiteCompanyName
    loyalty
    loyaltyStatus
  }
`;

const fetchCurrentEmployerGraphql = (payload) => {
  const query = gql`
    query {
      user {
        id
        username
        initials
        email
        local
        eligibleForExtension
        ...BillingFragment
        name
        photo
        cid
        uid
        companyId
        locale
        photo
        tawkHash
        logo(default: true) {
          id
          filename
          isDefault
        }
      }
    }
    ${BillingFragment}
  `;
  return client.query({
    query,
    fetchPolicy: 'network-only',
  });
};

/* fetch employer data */
const fetchEmployerGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query employer($id: Int) {
      employer(id: $id) {
        id
        username
        initials
        email
        local
        eligibleForExtension
        ...BillingFragment
        logo(default: true) {
          id
          filename
          isDefault
        }
      }
    }
    ${BillingFragment}
  `;
  return client.query({
    query,
    variables,
    ...params,
  });
};

/* mutate employer data */
const mutateEmployerGraphql = (variables) => {
  const params = {};

  const mutation = gql`
    mutation modifyBillingInformation(
      $typeId: Int
      $typeValue: String
      $maticniBroj: String
      $contactPhone: String
      $ime: String
      $prezime: String
    ) {
      modifyBillingInformation(
        typeId: $typeId
        typeValue: $typeValue
        maticniBroj: $maticniBroj
        contactPhone: $contactPhone
        ime: $ime
        prezime: $prezime
      ) {
        id
        username
        initials
        id
        email
        ...BillingFragment
      }
    }
    ${BillingFragment}
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const mutateEmployerBasicGraphql = (variables) => {
  const mutation = gql`
    mutation employer($ime: String, $prezime: String, $photo: String) {
      mutateEmployer(ime: $ime, prezime: $prezime, photo: $photo) {
        id
        ime
        prezime
      }
    }
  `;
  return client.mutate({
    mutation,
    variables,
  });
};

export {
  fetchEmployerGraphql,
  fetchCurrentEmployerGraphql,
  mutateEmployerGraphql,
  mutateEmployerBasicGraphql,
};
