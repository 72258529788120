const recruitRankActions = {
  FETCH_RECRUIT_RANK_START: 'FETCH_RECRUIT_RANK_START',
  FETCH_RECRUIT_RANK_DONE: 'FETCH_RECRUIT_RANK_DONE',
  FETCH_RECRUIT_RANK_ERROR: 'FETCH_RECRUIT_RANK_ERROR',
  FETCH_RECRUIT_RANK_CANCELLED: 'FETCH_RECRUIT_RANK_CANCELLED',
  FETCH_RECRUIT_RANK_DENIED: 'FETCH_RECRUIT_RANK_DENIED',

  fetchRecruitRank: () => ({
    type: recruitRankActions.FETCH_RECRUIT_RANK_START,
  }),
};

export default recruitRankActions;
