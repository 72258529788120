// import antdEn from 'antd/lib/locale-provider/en_US';
// import appLocaleData from 'react-intl/locale-data/sr';
import rsMessages from '../locales/sr_RS.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const RsLang = {
  messages: {
    ...rsMessages,
  },
  // antd: antdEn,
  locale: 'sr-RS',
  // data: appLocaleData,
};
export default RsLang;
