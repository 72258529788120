import AdDiscountList from './adDiscountList/reducer';
import AllApplications from './allApplications/reducer';
import Application from './application/reducer';
import Auth from './auth/reducer';
import Banner from './banner/reducer';
import Breadcrumb from './breadcrumb/reducer';
import CacheControl from './cacheControl/reducer';
import Company from './company/reducer';
import ContinueIncompleteJob from './continueIncompleteJob/reducer';
import CVDatabase from './cvDatabase';
import EditApplication from './editApplication/reducer';
import EmailTemplateEditor from './emailTemplateEditor/reducer';
import EmailTemplates from './emailTemplates/reducer';
import Faq from './faq/reducer';
import FeatureFlags from './featureFlags';
import Feedback from './feedback/reducer';
import ForwardApplications from './forwardApplications/reducer';
import Invoices from './invoice/reducer';
import Job from './job/reducer';
import JobReceipt from './jobReceipt/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import Lists from './lists/reducer';
import Maintenance from './maintenance/reducer';
import Navigation from './navigation/reducer';
import profileNavigationReducer from './newProfile/navigation/reducer';
import NewProfile from './newProfile/reducer';
import Notifications from './notification/reducer';
import Overlay from './overlay/reducer';
import Packages from './packages/reducer';
import Pricelist from './pricelist/reducer';
import Profile from './profile/reducer';
import Project from './project/reducer';
import Projects from './projects/reducer';
import RecruitRank from './recruitRank/reducer';
import Renew from './renew/reducer';
import Share from './share/reducer';
import Sider from './sider/reducer';
import Team from './team/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Tour from './tour/reducer';
import Upsale from './upsale/reducer';
import User from './user/reducer';
import Zip from './zip/reducer';

const reducers = {
  Auth,
  Job,
  Navigation,
  Sider,
  LanguageSwitcher,
  User,
  Application,
  Breadcrumb,
  ThemeSwitcher,
  EmailTemplates,
  EmailTemplateEditor,
  ForwardApplications,
  AllApplications,
  CacheControl,
  Share,
  Team,
  Zip,
  Profile,
  Company,
  Invoices,
  Packages,
  Banner,
  EditApplication,
  Lists,
  Overlay,
  Feedback,
  Notifications,
  JobReceipt,
  Pricelist,
  RecruitRank,
  AdDiscountList,
  Tour,
  Renew,
  Maintenance,
  Projects,
  Project,
  NewProfile,
  CVDatabase,
  FeatureFlags,
  Faq,
  Upsale,
  profileNavigationReducer,
  ContinueIncompleteJob,
};

export default reducers;
