import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  team: null,
  loading: true,
  modalVisible: false,
  accessItem: null,
  inviteLoading: true,
});

export default function teamReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TEAM_START:
      return state.set('loading', true);
    case actions.FETCH_TEAM_DONE:
      return state.set('team', action.payload).set('loading', false);
    case actions.FETCH_TEAM_ERROR:
      return state.set('loading', false);

    case actions.ITEM_MODIFY:
      return state.set('accessItem', action.payload);

    case actions.TOGGLE_MODAL:
      return state.set('modalVisible', action.payload);

    case actions.MUTATE_EMPLOYER_ACCESS_DONE:
      const teamMembers = state.get('team').teamMembers;
      teamMembers[teamMembers.findIndex((member) => member.id === action.payload.id)] =
        action.payload;
      return state.set('team', {
        ...state.get('team'),
        teamMembers
      });

    case actions.REDEEM_ACCESS_DONE:
    case actions.REDEEM_ACCESS_ERROR:
    case actions.REDEEM_ACCESS_CANCELLED:
      return state.set('inviteLoading', false);

    case actions.MUTATE_REVOKE_START: {
      if (state.get('team')) {
        const { invitedMembers } = state.get('team');
        return state.set('team', {
          ...state.get('team'),
          invitedMembers: invitedMembers.filter((member) => member.id !== action.payload.id),
        });
      }
      return state;
    }
    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
