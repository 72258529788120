const actions = {
  TOGGLE_FORWARD_APPLICATION_MODAL: 'TOGGLE_FORWARD_APPLICATION_MODAL',
  SET_FORWARD_EMAIL: 'SET_FORWARD_EMAIL',
  FORWARD_EMAIL_START: 'FORWARD_EMAIL_START',
  FORWARD_EMAIL_DONE: 'FORWARD_EMAIL_DONE',
  FORWARD_EMAIL_ERROR: 'FORWARD_EMAIL_ERROR',
  FORWARD_EMAIL_CANCELLED: 'FORWARD_EMAIL_CANCELLED',

  toggleForwardApplicationModal: state => ({
    type: actions.TOGGLE_FORWARD_APPLICATION_MODAL,
    payload: state,
  }),

  setForwardEmail: email => ({
    type: actions.SET_FORWARD_EMAIL,
    payload: email,
  }),

  forwardEmails: payload => ({
    type: actions.FORWARD_EMAIL_START,
    payload,
  }),
};

export default actions;
