import { all, takeLatest, put, spawn, call, cancelled } from 'redux-saga/effects';
import actions from './actions';
import fetchRecruitRankGraphql from './graphql';

function* fetchRecruitRankGenerator() {
  try {
    const response = yield call(fetchRecruitRankGraphql);
    yield put({
      type: actions.FETCH_RECRUIT_RANK_DONE,
      payload: response.data.recruitRank,
    });
  } catch (e) {
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.FETCH_RECRUIT_RANK_DENIED });
    }
    yield put({ type: actions.FETCH_RECRUIT_RANK_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_RECRUIT_RANK_CANCELLED,
      });
    }
  }
}

export function* fetchRecruitRank() {
  yield takeLatest(actions.FETCH_RECRUIT_RANK_START, fetchRecruitRankGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchRecruitRank)]);
}
