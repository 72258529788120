import config from 'config';
import FormData from 'form-data';
import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';

const verifyCheckpointRequest = ({ section, contentId }) => {
  const formData = new FormData();
  formData.append('section', section);
  formData.append('contentId', contentId);
  formData.append('option', 'project');
  return axios({
    method: 'post',
    url: `${config.apiUrl}/checkOnboarding`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const finishOnboardingRequest = ({ section }) => {
  const formData = new FormData();
  formData.append('section', section);
  return axios({
    method: 'post',
    url: `${config.apiUrl}/finishOnboarding`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

export default verifyCheckpointRequest;
export { finishOnboardingRequest };

