import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  invoices: [],
  loading: false,
  denied: false,
});

export default function invoicesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_INVOICES_START:
      return state.set('loading', true).set('denied', false);

    case actions.FETCH_INVOICES_DONE:
      return state.set('invoices', action.payload).set('loading', false);

    case actions.FETCH_INVOICES_DENIED:
      return state.set('denied', true);

    case actions.FETCH_INVOICES_CANCELLED:
    case actions.FETCH_INVOICES_ERROR:
      return state.set('loading', false);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
