const invoiceActions = {
  FETCH_INVOICES_START: 'FETCH_INVOICES_START',
  FETCH_INVOICES_DONE: 'FETCH_INVOICES_DONE',
  FETCH_INVOICES_ERROR: 'FETCH_INVOICES_ERROR',
  FETCH_INVOICES_CANCELLED: 'FETCH_INVOICES_CANCELLED',
  FETCH_INVOICES_DENIED: 'FETCH_INVOICES_DENIED',

  fetchInvoices: () => ({
    type: invoiceActions.FETCH_INVOICES_START,
  }),
};

export default invoiceActions;
