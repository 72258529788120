import { Listener } from '../listenersStore';

const commentListeners: Listener = {
  'refresh/comments': (dispatch: any, action: any) => {
    dispatch({
      type: 'APPLICATION_COMMENTS_FETCH_START',
      payload: action.payload,
    });
  },
};

export default commentListeners;
