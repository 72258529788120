import Maintenance from 'components/maintenance/Maintenance';
import { ConnectedRouter } from 'connected-react-router';
import Validate from 'containers/Page/validate';
import links from 'helpers/links';
import { isDemo } from 'helpers/utility';
import AppLocale from 'languageProvider';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { StyledRouterWrapper } from './AtsAppStyle';
import error404 from './components/errors';
import Loader from './components/loader';
import config from './config';
import SignIn from './containers/Page/signin';

/* eslint-disable */
if (Route.propTypes && Route.propTypes.path) {
  Route.propTypes.path = PropTypes.oneOfType([PropTypes.array, PropTypes.string]);
}
/* eslint-enable */

const withErrorHandler = (fn) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        window.location.reload();
      });
  });
};

const Loadable = ({ loader, loading }) => {
  const Component = React.lazy(() => withErrorHandler(loader));
  const LoadableComponent = ({ ...props }) => {
    return (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    );
  };
  return LoadableComponent;
};

const LoadableAts = Loadable({
  loader: () => import('./containers/Ats/Ats'),
  loading: Loader,
});

const LoadableInvite = Loadable({
  loader: () => import('./containers/Invite'),
  loading: Loader,
});

const LoadableActivateJob = Loadable({
  loader: () => import('./containers/ActivateJob'),
  loading: Loader,
})

const RestrictedRoute = ({
  component: Component,
  isLoggedIn,
  baseUrl,
  location: { pathname, search },
  ...rest
}) => {
  if (isLoggedIn) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  if (!isLoggedIn && isDemo()) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: `${baseUrl}/prijava-poslodavca`,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
  window.sessionStorage.setItem('redirectTo', `${pathname}${search}`);
  window.location = links.oauth().authorize;
  return null;
};

RestrictedRoute.propTypes = {
  component: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  baseUrl: PropTypes.string,
  location: PropTypes.object,
};

const PublicRoutes = ({ history, isLoggedIn, locale, maintenance }) => {
  const loggedIn = !!isLoggedIn;
  const currentAppLocale = AppLocale[locale];
  return (
    <HelmetProvider>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <React.Fragment>
          <FormattedMessage id="head.title.default">
            {(title) => <Helmet title={title.toString()} />}
          </FormattedMessage>
          {maintenance && <Maintenance />}
          {!maintenance && (
            <ConnectedRouter history={history}>
              <StyledRouterWrapper>
                <Switch>
                  <Route exact path={`${config.baseUrl}/prijava-poslodavca`} component={SignIn} />
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Redirect
                        to={{
                          pathname: links.jobList.url,
                        }}
                      />
                    )}
                  />
                  <RestrictedRoute
                    path={`${config.baseUrl}/(ats-prijave|ats-sve-prijave|podesavanja|naplata|fakture|stanje-zakupljenog-paketa|pregled-narucenog-oglasa|formular-za-placeni-oglas|ponovno-aktiviranje-oglasa|transaction|formular-za-placeni-oglas-kreiraj|online-intervju|baza-kandidata|moj-profil|izvestaj-lojalnosti|klub-lojalnosti)`} // eslint-disable-line
                    component={LoadableAts}
                    isLoggedIn={loggedIn}
                    baseUrl={config.baseUrl}
                  />
                  <Route path="/poslodavci/validate" component={Validate} />
                  <Route
                    path={links.invite().verify}
                    component={LoadableInvite}
                    isLoggedIn={loggedIn}
                    baseUrl={config.baseUrl}
                  />
                  <Route
                    path={links.activateJob().pattern}
                    component={LoadableActivateJob}
                    isLoggedIn={loggedIn}
                    baseUrl={config.baseUrl}
                  />
                  <Route component={error404} />
                </Switch>
              </StyledRouterWrapper>
            </ConnectedRouter>
          )}
        </React.Fragment>
      </IntlProvider>
    </HelmetProvider>
  );
};

PublicRoutes.propTypes = {
  history: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  baseUrl: PropTypes.string,
  locale: PropTypes.string,
  maintenance: PropTypes.bool,
};

export default connect((state) => ({
  isLoggedIn: state.Auth.get('loggedIn'),
  locale: state.LanguageSwitcher.get('language').locale,
  maintenance: state.Maintenance.get('maintenance'),
}))(PublicRoutes);
