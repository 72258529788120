import {
  all, takeLatest, put, spawn, call, cancelled,
} from 'redux-saga/effects';
import actions from './actions';
import fetchCompanySellerGraphql from './graphql';

function* fetchCompanySellerGenerator(action) {
  try {
    const response = yield call(fetchCompanySellerGraphql, action.payload);
    yield put({
      type: actions.FETCH_COMPANY_SELLER_DONE,
      payload: response.data.companySeller,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_COMPANY_SELLER_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_COMPANY_SELLER_CANCELLED,
      });
    }
  }
}

export function* fetchCompanySeller() {
  yield takeLatest(
    [actions.FETCH_COMPANY_SELLER_START],
    fetchCompanySellerGenerator,
  );
}

export default function* rootSaga() {
  yield all([spawn(fetchCompanySeller)]);
}
