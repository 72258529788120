import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  adDiscountList: [],
  loading: false,
  denied: false,
});

export default function adDiscountListReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_AD_DISCOUNT_LIST_START:
      return state.set('loading', true).set('denied', false);

    case actions.FETCH_AD_DISCOUNT_LIST_DONE:
      return state.set('adDiscountList', action.payload).set('loading', false);

    case actions.FETCH_AD_DISCOUNT_LIST_DENIED:
      return state.set('denied', true);

    case actions.FETCH_AD_DISCOUNT_LIST_CANCELLED:
    case actions.FETCH_AD_DISCOUNT_LIST_ERROR:
      return state.set('loading', false);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
