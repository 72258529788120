import { Map } from 'immutable';
import { CompanyBenefit, CompanyBenefitsPayload, CompanyBenefitType } from 'types/profile.types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

/* SYNC COMPANY BENEFITS ACTION TYPES */
export const COMPANY_BENEFITS_SYNC_START = 'COMPANY_BENEFITS_SYNC_START';
export const COMPANY_BENEFITS_SYNC_DONE = 'COMPANY_BENEFITS_SYNC_DONE';
export const COMPANY_BENEFITS_SYNC_CANCELLED = 'COMPANY_BENEFITS_SYNC_CANCELLED';
export const COMPANY_BENEFITS_SYNC_ERROR = 'COMPANY_BENEFITS_SYNC_ERROR';

/* SYNC COMPANY BENEFIT TYPES ACTION TYPES */
export const COMPANY_BENEFIT_TYPES_FETCH_START = 'COMPANY_BENEFIT_TYPES_FETCH_START';
export const COMPANY_BENEFIT_TYPES_FETCH_DONE = 'COMPANY_BENEFIT_TYPES_FETCH_DONE';
export const COMPANY_BENEFIT_TYPES_FETCH_CANCELLED = 'COMPANY_BENEFIT_TYPES_FETCH_CANCELLED';
export const COMPANY_BENEFIT_TYPES_FETCH_ERROR = 'COMPANY_BENEFIT_TYPES_FETCH_ERROR';

export const COMPANY_BENEFIT_STATE_TOGGLE_BENEFIT = 'COMPANY_BENEFIT_STATE_TOGGLE_BENEFIT';
export const COMPANY_BENEFIT_STATE_UPDATE_BENEFIT = 'COMPANY_BENEFIT_STATE_UPDATE_BENEFIT';
export const COMPANY_BENEFIT_STATE_UPDATE_BENEFIT_ORDER =
  'COMPANY_BENEFIT_STATE_UPDATE_BENEFIT_ORDER';

export interface CompanyBenefitsStateMap extends Map<string, any> {
  companyBenefits: CompanyBenefit[];
  companyBenefitTypes: CompanyBenefitType[];
  benefitsSyncing: boolean;
  benefitsSyncingError: string | null;
  benefitsSyncingSuccess: string | null;
  benefitTypesFetching: boolean;
  benefitTypesFetchingError: string | null;
}

export type CompanyBenefitsState = ImmutableMapInterface<CompanyBenefitsState, string, any>;

export interface APIResponseCompanyBenefit {
  id: number;
  description: string | null;
  benefitType: CompanyBenefitType;
}

export interface CompanyProfileBenefitsMutation {
  profileId: number | null;
  companyBenefits: {
    description?: string | null;
    hidden?: boolean;
    order?: number;
    benefitTypeId?: number;
  }[];
}

export interface CompanyBenefitTypeFetchDoneAction {
  type: typeof COMPANY_BENEFIT_TYPES_FETCH_DONE;
  payload: CompanyBenefitType[];
}

export interface CompanyBenefitsSyncDoneAction {
  type: typeof COMPANY_BENEFITS_SYNC_DONE;
  payload: { companyBenefits: CompanyBenefit[]; successMessage: string | null };
}

export interface CompanyBenefitStateUpdateAction {
  type: typeof COMPANY_BENEFIT_STATE_UPDATE_BENEFIT;
  payload: CompanyBenefit;
}

export interface CompanyBenefitToggleBenefitAction {
  type: typeof COMPANY_BENEFIT_STATE_TOGGLE_BENEFIT;
  payload: CompanyBenefitType;
}

export interface CompanyBenefitAPIError {
  type: typeof COMPANY_BENEFIT_TYPES_FETCH_ERROR | typeof COMPANY_BENEFITS_SYNC_ERROR;
  payload: string;
}

export interface CompanyBenefitsSyncAction {
  type: typeof COMPANY_BENEFITS_SYNC_START;
  payload: CompanyBenefitsPayload;
}

export interface CompanyBenefitNoPayloadAction {
  type:
    | typeof COMPANY_BENEFITS_SYNC_CANCELLED
    | typeof COMPANY_BENEFIT_TYPES_FETCH_START
    | typeof COMPANY_BENEFIT_TYPES_FETCH_CANCELLED;
}

export interface CompanyBenefitUpdateBenefitOrder {
  type: typeof COMPANY_BENEFIT_STATE_UPDATE_BENEFIT_ORDER;
  payload: CompanyBenefit[];
}

export type CompanyBenefitActions =
  | CompanyBenefitTypeFetchDoneAction
  | CompanyBenefitsSyncDoneAction
  | CompanyBenefitAPIError
  | CompanyBenefitStateUpdateAction
  | CompanyBenefitToggleBenefitAction
  | CompanyBenefitsSyncAction
  | CompanyBenefitNoPayloadAction;
