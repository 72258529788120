import { List, Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  visible: false,
  durationInDays: '15',
  loading: true,
  advertPositions: new List([]),
  calculatedPrice: undefined,
});

export default function pricelistReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_PRICELIST_MODAL:
      return state.set('visible', action.payload);

    case actions.SET_LOADING:
    case actions.MUTATE_CREATE_INCOMPLETE_JOB_START:
      return state.set('loading', true);

    case actions.MUTATE_CREATE_INCOMPLETE_JOB_ERROR:
    case actions.MUTATE_CREATE_INCOMPLETE_JOB_CANCELLED:
      return state.set('loading', false);

    case actions.CHANGE_DURATION:
      return state.set('durationInDays', action.payload);

    case actions.FETCH_PRICELIST_DONE:
      return state.withMutations((newState) => {
        return newState.set('pricelist', action.payload).set('loading', false);
      });

    case actions.FETCH_ADVERT_POSITIONS_DONE:
      return state.set('advertPositions', new List(action.payload));

    case actions.FETCH_PRICE_CALCULATION_DONE:
      return state.set('calculatedPrice', action.payload);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
