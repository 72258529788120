import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  breadcrumbs: [],
});

export default function breadcrumbReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_BREADCRUMB:
      return state.set('breadcrumbs', action.payload);
    default:
      return state;
  }
}
