import { Input as AntInput } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {
  InputGroupWrapper,
  InputSearchWrapper,
  InputSimpleWrapper,
  InputWrapper,
  TextAreaWrapper,
  TextAreaSimpleWrapper,
} from './styles/input.style';

const { Search, TextArea: Textarea, Group } = AntInput;

const StyledInput = InputWrapper(AntInput);

const InputGroup = InputGroupWrapper(Group);

const InputSearch = InputSearchWrapper(Search);

const TextArea = TextAreaWrapper(Textarea);

const InputSimple = InputSimpleWrapper(AntInput);

const TextAreaSimple = TextAreaSimpleWrapper(Textarea);

class Input extends React.Component {
  static propTypes = {
    onEnter: PropTypes.func,
  };

  handleKeyPress = (event) => {
    const { onEnter } = this.props;
    const { key } = event;

    if (onEnter && key === 'Enter') {
      onEnter(event);
      event.stopPropagation();
    }
  };

  render() {
    const { onEnter, ...props } = this.props;
    return <StyledInput {...props} onKeyPress={this.handleKeyPress} />;
  }
}

export default Input;
export { InputSearch, InputGroup, TextArea, InputSimple, TextAreaSimple };
