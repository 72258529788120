import { gql, OperationVariables } from 'apollo-boost';
import client from 'graphql/client';

interface Payload {
  variables: object;
  recache: Boolean;
}

const faqFetchGraphql = (payload: Payload | undefined) => {
  const params: OperationVariables = {};

  if (payload && payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query faq {
      faq {
        id
        title
        link
        excerpt
        content
        category {
          id
          name
          description
        }
      }
    }
  `;

  return client.query({
    query,
    ...params,
  });
};

export default faqFetchGraphql;
