import { omit } from 'lodash';
import moment from 'moment';

const requestGenerator = (data) => {
  const request = {};

  request.name = data.values.name;
  request.email = data.values.email || '';
  request.phone = data.values.phone || '';
  request.applicationTime = `${moment().format('Y-MM-DD H:m')}`;
  request.tags = [];
  request.preselectionApplicationAnswer = [];
  request.attachments = [];
  request.projectId = data.project.get('id');

  if (data.values.cityRegionValues) {
    const { cityRegionValues } = data.values;
    Object.keys(cityRegionValues).forEach((key) => {
      const item = cityRegionValues[key];
      if (item.indexOf('city') !== -1) {
        // this is city
        const cityId = item.substr(4, item.length);
        request.tags.push({
          specialType: 'city',
          cityId,
        });
      }
      if (item.indexOf('region') !== -1) {
        // this is region
        const regionId = item.substr(6, item.length);
        request.tags.push({
          specialType: 'region',
          regionId,
        });
      }
    });
  }

  if (data.values.tags) {
    data.values.tags.forEach((tag) => {
      let tagCopy = { ...tag };
      // keeping BC
      if (tagCopy.special_type) {
        tagCopy.specialType = tagCopy.special_type;
        tagCopy = omit(tagCopy, 'special_type');
      }
      // need to omit id if null
      request.tags.push(tagCopy.id ? tagCopy : omit(tagCopy, 'id'));
    });
  }

  if (data.values.question) {
    const { question } = data.values;
    Object.keys(question).forEach((key) => {
      const item = question[key];
      const answer = {};
      if (key.indexOf('a') !== -1) {
        answer.questionId = key.substr(1, key.length);
        answer.answerId = item.answer_id;
        answer.comment = item.comment || '';
        request.preselectionApplicationAnswer.push(answer);
      }
    });
  }

  data.files.forEach((file) => {
    request.attachments.push({
      path: file.get('key'),
      type: file.get('fileType') || 'user',
      filename: file.get('filename'),
    });
  });
  return request;
};

export default requestGenerator;
