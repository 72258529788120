import gql from 'graphql-tag';
import client from 'graphql/client';
import {
  applicationBasicFragment,
  commentsFragment,
  queryFetchApplication,
  queryFetchApplications,
  tagFragment,
} from './queries';

const mutateMarkAsUnreadGeneratorGraphql = (variables) => {
  const params = {};

  const mutation = gql`
    mutation markAsUnread($id: Int!) {
      markApplicationAsUnread(id: $id) {
        id
        ...BasicFields
      }
    }
    ${applicationBasicFragment}
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

/* application rating mutator */
const mutateApplicationRatingGraphql = (variables) => {
  const params = {};

  const mutation = gql`
    mutation applicationRating($id: [Int], $rating: Int) {
      applicationRating(id: $id, rating: $rating) {
        id
        ...BasicFields
        job {
          id
        }
      }
    }
    ${applicationBasicFragment}
  `;

  return client.mutate({
    mutation,
    /* well..... for some reason apollo knowns what to update without
     * the code below. But i'll leave it here for now
     */
    // update: (cache, data) => {
    //   cache.data.data[`Application:${data.data.applicationRating.id}`].rating
    // = data.data.applicationRating.rating; // eslint-disable-line
    // },
    variables,
    ...params,
  });
};

/* application selectionLevel mutator */
const mutateApplicationSelectionLevelGraphql = (variables) => {
  const params = {};

  const mutation = gql`
    mutation applicationSelectionLevel($id: [Int!], $selectionLevel: SelectionLevel!) {
      changeApplicationSelectionLevel(id: $id, selectionLevel: $selectionLevel) {
        id
        ...BasicFields
        job {
          id
        }
      }
    }
    ${applicationBasicFragment}
  `;

  return client.mutate({
    mutation,
    /* well..... for some reason apollo knowns what to update without
     * the code below. But i'll leave it here for now
     */
    // update: (cache, data) => {
    //   cache.data.data[`Application:${data.data.applicationRating.id}`].rating
    // = data.data.applicationRating.rating; // eslint-disable-line
    // },
    variables,
    ...params,
  });
};

/* application list fetch */
const fetchApplicationsGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  return client.query({
    query: queryFetchApplications,
    variables,
    ...params,
  });
};

/* single application fetch */
const fetchApplicationGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  return client.query({
    query: queryFetchApplication,
    variables,
    ...params,
  });
};

/**
 * modify application tags
 */
const mutateApplicationAddTagGraphql = (variables) => {
  const params = {};
  const mutation = gql`
    mutation addNewApplicationTag($id: Int, $name: String, $tagId: Int) {
      addNewApplicationTag(id: $id, name: $name, tagId: $tagId) {
        id
        tags {
          ...TagFields
        }
      }
    }
    ${tagFragment}
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};
const mutateApplicationRemoveTagGraphql = (variables) => {
  const params = {};
  const mutation = gql`
    mutation removeApplicationTag($id: Int, $tagId: Int) {
      removeApplicationTag(id: $id, tagId: $tagId) {
        id
        tags {
          ...TagFields
        }
      }
    }
    ${tagFragment}
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

/* application comment add mutator */
const mutateApplicationAddComment = (variables) => {
  const params = {};

  const mutation = gql`
    mutation addApplicationComment(
      $applicationId: Int
      $comment: String
      $draftRaw: String
      $jobId: Int
      $projectId: Int
    ) {
      addApplicationComment(
        applicationId: $applicationId
        comment: $comment
        draftRaw: $draftRaw
        jobId: $jobId
        projectId: $projectId
      ) {
        id
        comments(includePreviousComments: true, option: "project") {
          ...CommentsFields
        }
      }
    }
    ${commentsFragment}
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

/* application comment edit mutator */
const mutateApplicationEditComment = (variables) => {
  const params = {};

  const mutation = gql`
    mutation editApplicationComment(
      $id: Int
      $comment: String
      $draftRaw: String
      $jobId: Int
      $projectId: Int
    ) {
      editApplicationComment(
        id: $id
        comment: $comment
        draftRaw: $draftRaw
        jobId: $jobId
        projectId: $projectId
      ) {
        id
        comments(includePreviousComments: true, option: "project") {
          ...CommentsFields
        }
      }
    }
    ${commentsFragment}
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

/* application comment remove mutator */
const mutateApplicationRemoveComment = (variables) => {
  const params = {};

  const mutation = gql`
    mutation removeApplicationComment($id: Int) {
      removeApplicationComment(id: $id) {
        id
        comments(includePreviousComments: true, option: "project") {
          ...CommentsFields
        }
      }
    }
    ${commentsFragment}
  `;
  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const applicationHideGraphql = (variables) => {
  const mutation = gql`
    mutation hideApplication($applicationId: Int!, $state: Boolean!) {
      hideApplication(applicationId: $applicationId, state: $state) {
        id
        hidden
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export {
  fetchApplicationGraphql,
  fetchApplicationsGraphql,
  mutateApplicationRatingGraphql,
  mutateApplicationSelectionLevelGraphql,
  mutateApplicationAddTagGraphql,
  mutateApplicationRemoveTagGraphql,
  mutateApplicationAddComment,
  mutateApplicationEditComment,
  mutateApplicationRemoveComment,
  mutateMarkAsUnreadGeneratorGraphql,
  applicationHideGraphql,
};
