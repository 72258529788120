const renewActions = {
  TOGGLE_RENEW_MODAL: 'TOGGLE_RENEW_MODAL',

  SET_JOB: 'SET_JOB',

  setJob: (payload: any) => ({
    type: renewActions.SET_JOB,
    payload,
  }),

  toggleRenewModal: (state: boolean) => ({
    type: renewActions.TOGGLE_RENEW_MODAL,
    payload: {
      state,
    },
  }),
};

export default renewActions;
