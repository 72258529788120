const palette = {};

const primaries = [
  '#fff', // 14: white
  '#333', // 15: text color
  '#57c86f', // 16: green activity color
  '#ed650f', // 17: orange IS color
  '#519CCE', // 18: IS blue
  'rgba(0, 0, 0, 0.2)', // 19: transparent shadow
  'rgba(0, 0, 0, 0.63)', // 20: grey text
  '#c3c3c3', // 21: grey icons
  '#f1f3f6', // 22: panel list separator
  '#022238ba', // 23: bluish text
  '#00A823', // 24: dark green
  '#999', // 25: dark grey
  '#ffc100', // 26: yellow color used for stars in light theme
  '#022238', // 27: dark blue
  '#2d3446', // 28: dark blue too
  '#f7fafc', // 29: light grey
  '#e3e3e3', // 30: darker grey
  '#ebebeb', // 31: moar grey
  '#ccc', // 32: classic grey
  'rgba(0, 0, 0, 0.3)', // 33: transparent background
  '#9ca6bc', // 34,
  '#532757', // 35
  '#d9d9d9', // 36
  'transparent', // 37
  'rgba(0, 0, 0, 0.4)', // 38
  '#f0f8fc', // 39
  'rgba(241, 243, 244, 0.89)', // 40
  'rgba(241, 243, 244, 0.24)', // 41
  '#fbfdff', // 42
  '#fbfbfb', // 43
  '#f2f0f0', // 44
  '#40a9ff', // 45
  '#f0f0f0', // 46
  '#6e6c6c', // 47
  '#aaa', // 48
  '#8d2a96', // 49
  '#d1844e', // 50
  'rgba(220, 220, 220, 0.25)', // 51
  'rgba(0, 0, 0, 0.65)', // 52
  '#fafafa', // 53
  '#f1f0f0', // 54
  '#575656', // 55
  '#dddddd', // 56
  '#e6f7ff', // 57
  '#b48988', // 58
  '#fff1f0', // 59
  '#ccefff', // 60
  '#585858', // 61
  '#e8e8e8', // 62
  'rgba(91, 58, 95, 0.477)', // 63
  'rgba(45, 52, 70, 0.47)', // 64
  '#fcfcfc', // 65
  '#f07575', // 66
  '#b69292', // 67
  '#91d5ff', // 68
  'linear-gradient(0deg, rgba(91, 58, 95, 0.11) 0%, rgba(134, 34, 195, 0.14) 100%)', // 69
  'linear-gradient(0deg,rgb(48, 48, 48) 0%,rgb(168, 168, 168) 100%)', // 70
  '#f1f1f1', // 71
  '#00a8ff', // 72
  '#00a8ff33', // 73
  '#6f91a2', // 74
  '#6f91a226', // 75
  '#db120e', // 76
  '#fff9f4', // 77
  '#622727', // 78
  'rgba(0, 0, 0, 0.12)', // 79
  '#2a4451', // 80
  '#1890ff', // 81
  '#f8f2f8', // 82 light HR Lab purple
  '#4e317e', // 83 Dark HR Lab purple - standout-text light theme
  '#fee715 ', // 84 Yellow - standout-text dark theme
  '#72588c', // 85 - purple standout loyalty report
  '#363636', // 86 - section background dar
  'rgba(51, 51, 51, 0.4)', // 87 - border color light - loyalty report
  'rgba(255, 255, 255, 0.4)', // 88 - border color dark - loyalty report
  '#27397D', // 89 - poslovi blue
];

palette.dark = [
  '#000', // 0: black
  '#212121', // 1
  '#303030', // 2
  '#424242', // 3
  '#fff', // 4
  'rgba(255, 255, 255, 0.7)', // 5
  'rgba(255, 255, 255, 0.5)', // 6
  'rgba(255, 255, 255, 0.3)', // 7
];

palette.green = [
  '#E6F8E9', // 0: green lightest
  '#99E4A9', // 1: green lighter
  '#4DCF68', // 2: green light
  '#00BB27', // 3: green
  '#00A823', // 4: green dark
  '#007017', // 5: green darker
  '#00380C', // 6: green darkest
];

palette.red = [
  '#F8E9E9', // 0: red lightest
  '#E5A6A7', // 1: red lighter
  '#D16366', // 2: red light
  '#BD2024', // 3: red
  '#AA1D20', // 4: red dark
  '#711316', // 5: red darker
  '#390A0B', // 6: red darkest
];

palette.orange = [
  '#FDEFE7', // 0: orange-lightest
  '#F7C1A0', // 1: orange-lighter
  '#F19258', // 2: orange-light
  '#EB6311', // 3: orange
  '#D4590F', // 4: orange-dark
  '#8D3B0A', // 5: orange-darker
  '#471E05', // 6: orange-darkest
];

palette.grey = [
  '#f9f9f9', // 0: grey-lightest
  '#e5e5e5', // 1: grey-lighter
  '#d1d1d1', // 2: grey-light
  '#bebebe', // 3: grey
  '#979797', // 4: grey-dark
  '#6f6f6f', // 5: grey-darker
  '#494949', // 6: grey-darkest
];

palette.blue = [
  '#E6F1F8', // 0: blue-lightest
  '#9BC6E3', // 1: blue-lighter
  '#519CCE', // 2: blue-light
  '#0671B9', // 3: blue
  '#0566A7', // 4: blue-dark
  '#04446F', // 5: blue-darker
  '#022238', // 6: blue-darkest
];

const shadowColor = [
  'rgba(0, 0, 0, 0.11)', // 0: transparent shadow
  'rgba(28, 31, 43, 0.26)', // 1: hover/focus shadow
  '#ccc', // 2: big action button
  'rgba(0, 0, 0, 0.2)', // 3
  '', // 4
  'rgba(218, 218, 218, 0.49)', // 5: input fields shadow
  'rgb(69, 69, 69)', // 6: input fields border
  'rgba(44, 44, 44, 0.49)', // 7: input fields shadow
  '#756969', // 8: pricelist highlight light
  '#121111', // 9: pricelist highlight dark
  'rgba(28, 31, 43, 0.16)', // 10
];

const shadowSize = [
  '1px 1px 1px', // 0: small
  '2px 2px 2px', // 1: medium
  '2px 2px 8px', // 2: highlight
  '2px 2px 8px', // 3: highlight more
  '1px 1px 5px', // 4: big action button
  '2px 2px 2px', // 5:
  '0px 2px 5px', // 6
  'none', // 7
  '2px 2px 21px', // 8
  '14px 2px 26px', // 9
  '0px 0px 21px', // 10: input fields shadow
  '0px 0px 1px', // 11: input fields shadow border
  '2px 2px 9px', // 12: circle image shadow
  '0 4px 9px', // 13
  '0 2px 0', // 14
];

const rebrand = [
  '#0D3082', // 0: primary blue
  '#E1146B', // 1: secondary fushia
  '#FFD752', // 2: yellow accent
  '#EFB2AD', // 3: nude accent
  '#8A55AF', // 4: purple accent
];

const rebrandBlue = [
  '#EFF4FD', // 0: blue-50
  '#E0EAFE', // 1: blue-100
  '#D0DDFA', // 2: blue-150
  '#B1C7F7', // 3: blue-200
  '#92AEEC', // 4: blue-300
  '#7296E8', // 5: blue-400
  '#537BD5', // 6: blue-500
  '#2A5ED0', // 7: blue-600
  '#1449BE', // 8: blue-700
  '#0F3A9F', // 9: blue-800
  '#0D3082', // 10: blue-900
  '#031A6A', // 11: blue-950
];

const rebrandNude = [
  '#FFECEB', // 0: nude-100
  '#FFE3E1', // 1: nude-200
  '#FECDC9', // 2: nude-300
  '#EFB2AD', // 3: nude-400
  '#DE9790', // 4: nude-500
  '#CD7A74', // 5: nude-600
];

const rebrandError = [
  '#FEF4F1', // 0
  '#FBDAD3', // 1
  '#FAC8BD', // 2
  '#F8B6A7', // 3
  '#F4917C', // 4
  '#F16D50', // 5
  '#ED4824', // 6
  '#BE3A1D', // 7
  '#8E2B16', // 8
  '#5F1D0E', // 9
];

const rebrandPurple = [
'#EBE7F9', // 0: purple-100
'#DCD3F3', // 1: purple-200
'#C9B7EB', // 2: purple-300
'#B799E1', // 3: purple-400
'#A980D6', // 4: purple-500
'#8A55AF', // 5: purple-600
'#6F448D', // 6: purple-700
'#52356B', // 7: purple-800
];

const rebrandFushia = [
'#FCEDF0', // 0: fushia-50
'#F7C9D5', // 1: fushia-100
'#F2A5BC', // 2: fushia-200
'#EE81A4', // 3: fushia-300
'#E95D8F', // 4: fushia-400
'#E5397C', // 5: fushia-500
'#E1146B', // 6: fushia-600
'#B80C59', // 7: fushia-700
'#8E0545', // 8: fushia-800
'#610130', // 9: fushia-900
];

export {
  palette,
  primaries,
  shadowColor,
  shadowSize,
  rebrand,
  rebrandBlue,
  rebrandNude,
  rebrandError,
  rebrandPurple,
  rebrandFushia
};
