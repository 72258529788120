import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import { fetchCompanyCountriesGraphql, fetchCompanyEmployeeCountOptionsGraphql } from './graphql';
import updateContactSectionRequest from './requests';
import {
  ContactSectionUpdateActionPayload,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START,
  PROFILE_CONTACT_SECTION_UPDATE_CANCELLED,
  PROFILE_CONTACT_SECTION_UPDATE_DONE,
  PROFILE_CONTACT_SECTION_UPDATE_ERROR,
  PROFILE_CONTACT_SECTION_UPDATE_START
} from './types';

const Nprogress = require('nprogress');

function* updateContactSectionGenerator(action: ContactSectionUpdateActionPayload): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    yield call(updateContactSectionRequest, {
      profileId: action.payload.profileId,
      website: storeState.get('website'),
      employeeCountOption: storeState.get('employeeCountOption'),
      contactInfo: storeState.get('contactInfo'),
      socialNetworks: storeState.get('socialNetworks'),
      companyAddresses: storeState.get('companyAddresses'),
    });
    yield put({
      type: PROFILE_CONTACT_SECTION_UPDATE_DONE,
      payload: { successMessage: translate('profile.form.saved') },
    });
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    log(e);
    yield put({
      type: PROFILE_CONTACT_SECTION_UPDATE_ERROR,
      payload: translate('error.tryAgainMessage'),
    });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_CONTACT_SECTION_UPDATE_CANCELLED,
      });
    }
  }
}

function* fetchEmployeeCountOptionsGenerator(): any {
  try {
    const response = yield call(fetchCompanyEmployeeCountOptionsGraphql);
    yield put({
      type: PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE,
      payload: response.data.companyProfileCompanyEmployeeCountOptions,
    });
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED,
      });
    }
  }
}

function* fetchCountriesGenerator(): any {
  try {
    const response = yield call(fetchCompanyCountriesGraphql);
    yield put({
      type: PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE,
      payload: response.data.companyProfileCountries,
    });
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED,
      });
    }
  }
}

function* fetchEmployeeCountOptions() {
  yield takeLatest(
    PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START,
    fetchEmployeeCountOptionsGenerator
  );
}

function* fetchCountries() {
  yield takeLatest(PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START, fetchCountriesGenerator);
}

function* updateContactSection() {
  yield takeLatest(PROFILE_CONTACT_SECTION_UPDATE_START, updateContactSectionGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchEmployeeCountOptions), spawn(fetchCountries), spawn(updateContactSection)]);
}
