import { Listener } from '../listenersStore';

const ratingListeners: Listener = {
  'refresh/rating': (dispatch: any, action: any) => {
    dispatch({
      type: 'APPLICATION_RATING_FETCH_START',
      payload: action.payload,
    });
  },
};

export default ratingListeners;
