// Transition
export function transition(timing = 0.3) {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}

// Border Radius
export function borderRadius(radius = '0') {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

// Box Shadow
export function boxShadow(shadow = 'none') {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `;
}

export function animateBackground(args) {
  return `
  background: linear-gradient(${args});
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 90% 0%;
    }
    50% {
      background-position: 11% 100%;
    }
    100% {
      background-position: 90% 0%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 90% 0%;
    }
    50% {
      background-position: 11% 100%;
    }
    100% {
      background-position: 90% 0%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 90% 0%;
    }
    50% {
      background-position: 11% 100%;
    }
    100% {
      background-position: 90% 0%;
    }
  }`;
}
