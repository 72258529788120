import {
  all, takeLatest, put, spawn, call, cancelled,
} from 'redux-saga/effects';
import Nprogress from 'nprogress';
import { message } from 'antd';
import translate from 'languageProvider/inline';
import FileSaver from 'file-saver';
import 'blob-polyfill';
import contentDisposition from 'content-disposition';
import actions from './actions';
import spreadsheetRequests from './requests';

/**
 * Generate spreadsheet
 * @param {*} action
 */
function* generateSpreadsheetGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    message.info(translate('application.action.excel.loading'));
    const response = yield call(spreadsheetRequests, action.payload);
    const { filename } = contentDisposition.parse(
      response.headers['content-disposition'],
    ).parameters;

    FileSaver.saveAs(new Blob([response.data], { type: response.data.type }), filename);
    yield put({
      type: actions.SPREADSHEET_GENERATE_DONE,
      payload: {
        applicationIds: action.payload.applicationIds,
      },
    });
    yield call(() => {
      Nprogress.done();
    });
    yield call(() => {
      message.success(translate('zip.message.success'));
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.SPREADSHEET_GENERATE_ERROR });
    yield call(() => {
      message.success(translate('zip.message.error'));
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.SPREADSHEET_GENERATE_CANCELLED,
      });
    }
  }
}

/* zip application filters sada */
export function* generateSpreadsheet() {
  yield takeLatest([actions.SPREADSHEET_GENERATE_START], generateSpreadsheetGenerator);
}

export default function* rootSaga() {
  yield all([spawn(generateSpreadsheet)]);
}
