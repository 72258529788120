import config from 'config';
import storeObject from 'redux/storeDeclaration';
import io, { Socket } from 'socket.io-client';
import listeners from './listeners';

interface ConnectionProps {
  userId: number | string;
  companyId: number;
}

let socket: undefined | typeof Socket = undefined;

const debug = false;

type RoomManagerType = {
  rooms: Array<string>;
  join: (roomName: string) => void;
  leave: (roomName: string) => void;
  list: () => Array<string>;
  rejoin: () => void;
  isIn: (roomName: string) => boolean;
};

export const roomManager: RoomManagerType = {
  rooms: [],
  join: (roomName: string) => {
    roomManager.rooms.push(roomName);
    roomManager.rooms = [...new Set(roomManager.list())];
    if (debug) {
      log(`joining: ${roomName}`);
    }
    roomManager.rejoin();
  },
  leave: (roomName: string) => {
    roomManager.rooms = roomManager.rooms.filter((room: string) => room !== roomName);
    if (socket) {
      if (debug) {
        log(`leaving: ${roomName}`);
        log(`current rooms: ${roomManager.list()}`);
      }
      socket.emit('leaveRoom', { rooms: [roomName] });
    }
  },
  list: () => {
    return roomManager.rooms;
  },
  isIn: (roomName: string) => {
    return roomManager.rooms.includes(roomName);
  },
  rejoin: () => {
    if (socket) {
      if (debug) {
        log(`current rooms: ${roomManager.list()}`);
      }
      socket.emit('joinRoom', { rooms: roomManager.list() });
    }
  },
};

roomManager.rooms = ['HRLab'];

const connectToServer = ({ userId, companyId }: ConnectionProps) => {
  if (!!socket) {
    socket.emit(
      'connectToServer',
      {
        userId: typeof userId === 'string' ? parseInt(userId) : userId,
        groupId: typeof companyId === 'string' ? parseInt(companyId) : companyId,
      },
      (response: any) => {
        if (socket) {
          roomManager.rejoin();
        }
      }
    );
  }
};

export const socketConnect = ({ userId, companyId }: ConnectionProps) => {
  if (!socket || !socket.id) {
    socket = io(config.socketHrlabUrl);
    if (socket) {
      connectToServer({ userId, companyId });
      socket.on('reconnect', () => {
        connectToServer({ userId, companyId });
      });

      socket.on('update', (data: any) => {
        const listener = listeners.find(data.action);
        const { dispatch }: any = storeObject.getStore();
        listener?.forEach((item) => {
          item(dispatch, {
            payload: data.payload,
          });
        });
      });
    }
  }
};

const anyWindow = window as any;
anyWindow.socketRooms = roomManager.list;
