const profileActions = {
  PROFILE_FETCH_START: 'PROFILE_FETCH_START',
  PROFILE_FETCH_DONE: 'PROFILE_FETCH_DONE',
  PROFILE_FETCH_ERROR: 'PROFILE_FETCH_ERROR',
  PROFILE_FETCH_CANCELLED: 'PROFILE_FETCH_CANCELLED',
  PROFILE_FETCH_DENIED: 'PROFILE_FETCH_DENIED',

  PROFILE_UPDATE_START: 'PROFILE_UPDATE_START',
  PROFILE_UPDATE_DONE: 'PROFILE_UPDATE_DONE',
  PROFILE_UPDATE_ERROR: 'PROFILE_UPDATE_ERROR',
  PROFILE_UPDATE_CANCELLED: 'PROFILE_UPDATE_CANCELLED',

  PROFILE_COUNTRY_NAMES_FETCH_START: 'PROFILE_COUNTRY_NAMES_FETCH_START',
  PROFILE_COUNTRY_NAMES_FETCH_DONE: 'PROFILE_COUNTRY_NAMES_FETCH_DONE',
  PROFILE_COUNTRY_NAMES_FETCH_ERROR: 'PROFILE_COUNTRY_NAMES_FETCH_ERROR',
  PROFILE_COUNTRY_NAMES_FETCH_CANCELLED: 'PROFILE_COUNTRY_NAMES_FETCH_CANCELLED',

  PROFILE_EMPLOYEE_COUNT_OPTIONS_START: 'PROFILE_EMPLOYEE_COUNT_OPTIONS_START',
  PROFILE_EMPLOYEE_COUNT_OPTIONS_DONE: 'PROFILE_EMPLOYEE_COUNT_OPTIONS_DONE',
  PROFILE_EMPLOYEE_COUNT_OPTIONS_ERROR: 'PROFILE_EMPLOYEE_COUNT_OPTIONS_ERROR',
  PROFILE_EMPLOYEE_COUNT_OPTIONS_CANCELLED: 'PROFILE_EMPLOYEE_COUNT_OPTIONS_CANCELLED',

  PROFILE_LOGO_UPLOAD_START: 'PROFILE_LOGO_UPLOAD_START',
  PROFILE_LOGO_UPLOAD_DONE: 'PROFILE_LOGO_UPLOAD_DONE',
  PROFILE_LOGO_UPLOAD_ERROR: 'PROFILE_LOGO_UPLOAD_ERROR',
  PROFILE_LOGO_UPLOAD_CANCELLED: 'PROFILE_LOGO_UPLOAD_CANCELlED',

  fetchProfile: () => ({
    type: profileActions.PROFILE_FETCH_START,
  }),

  fetchCountryNames: () => ({
    type: profileActions.PROFILE_COUNTRY_NAMES_FETCH_START,
  }),

  fetchEmployeeCountOptions: () => ({
    type: profileActions.PROFILE_EMPLOYEE_COUNT_OPTIONS_START,
  }),

  updateProfile: payload => ({
    type: profileActions.PROFILE_UPDATE_START,
    payload,
  }),

  uploadProfileImage: payload => ({
    type: profileActions.PROFILE_LOGO_UPLOAD_START,
    payload,
  }),
};

export default profileActions;
