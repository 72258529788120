import application from 'demo/mocks/application';

const filters = (jobIdString) => {
  const jobId = parseInt(jobIdString, 10);
  return {
    preselection: [
      { name: 'DoesNotMeetRequirements', count: 0, results: [] },
      { name: 'DoesMeetRequirements', count: 1, results: [] },
    ],
    selectionLevel: [
      {
        name: 'Candidate',
        count: application.filter(
          item => item.selectionLevel === 'Candidate' && item.job.id === jobId,
        ).length,
        results: [],
      },
      {
        name: 'Qualification',
        count: application.filter(
          item => item.selectionLevel === 'Qualification' && item.job.id === jobId,
        ).length,
        results: [],
      },
      {
        name: 'Interview',
        count: application.filter(
          item => item.selectionLevel === 'Interview' && item.job.id === jobId,
        ).length,
        results: [],
      },
      {
        name: 'NarrowedRound',
        count: application.filter(
          item => item.selectionLevel === 'NarrowedRound' && item.job.id === jobId,
        ).length,
        results: [],
      },
      {
        name: 'Hired',
        count: application.filter(item => item.selectionLevel === 'Hired' && item.job.id === jobId)
          .length,
        results: [],
      },
    ],
    rating: [
      {
        name: 'VeryBad',
        count: application.filter(item => item.rating === 2 && item.job.id === jobId).length,
        results: [],
      },
      {
        name: 'Bad',
        count: application.filter(item => item.rating === 4 && item.job.id === jobId).length,
        results: [],
      },
      {
        name: 'Ok',
        count: application.filter(item => item.rating === 6 && item.job.id === jobId).length,
        results: [],
      },
      {
        name: 'Good',
        count: application.filter(item => item.rating === 8 && item.job.id === jobId).length,
        results: [],
      },
      {
        name: 'VeryGood',
        count: application.filter(item => item.rating === 10 && item.job.id === jobId).length,
        results: [],
      },
    ],
    rejection: [
      { name: 'Rejected', count: application.filter(item => item.rejected).length, results: [] },
      {
        name: 'NotRejected',
        count: application.filter(item => !item.rejected).length,
        results: [],
      },
    ],
    tags: [{ name: 'city', count: 0, results: [] }, { name: 'tag', count: 0, results: [] }],
  };
};

export default filters;
