import config from 'config';
import FormData from 'form-data';
import gql from 'graphql-tag';
import client from 'graphql/client';
import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';
import { CompanyTeamMember } from 'types/profile.types';

const companyTeamMemberCreate = (employee: CompanyTeamMember) => {
  const formData = new FormData();
  formData.append('name', employee.name);
  formData.append('workPosition', employee.workPosition);
  formData.append('workplaceDescription', employee.workplaceDescription);
  formData.append('videoUrl', employee.videoUrl);
  if (employee.picture?.file) {
    formData.append('picture', employee.picture?.file);
  }
  return axios({
    method: 'post',
    url: `${config.apiUrl}/company-profile/company-team-member`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const companyTeamMemberUpdate = (employee: CompanyTeamMember) => {
  const variables = { ...employee };
  if (employee.picture) {
    variables.picture = employee.picture.file as any;
  }

  const mutation = gql`
    mutation companyProfileTeamMemberMutation(
      $profileId: Int!
      $id: Int
      $name: String
      $videoUrl: String
      $workplaceDescription: String
      $workPosition: String
      $picture: CompanyProfileUpload
    ) {
      companyProfileTeamMemberMutation(
        id: $profileId
        teamMember: {
          id: $id
          name: $name
          picture: $picture
          videoUrl: $videoUrl
          workplaceDescription: $workplaceDescription
          workPosition: $workPosition
        }
      ) {
        id
        name
        picture {
          id
          path
        }
        videoUrl
        workplaceDescription
        workPosition
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

const companyTeamMemberDelete = (variables: CompanyTeamMember) => {
  log(variables);
  const mutation = gql`
    mutation companyProfileDeteleTeamMember($profileId: Int!, $id: Int!) {
      companyProfileDeteleTeamMember(id: $profileId, teamMemberId: $id) {
        id
        name
        picture {
          id
          path
        }
        videoUrl
        workplaceDescription
        workPosition
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export { companyTeamMemberCreate, companyTeamMemberUpdate, companyTeamMemberDelete };
