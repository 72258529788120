import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  modalVisible: false,
  forwardEmail: null,
  sending: false,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_FORWARD_APPLICATION_MODAL:
      return state.set('modalVisible', action.payload);
    case actions.SET_FORWARD_EMAIL:
      return state.set('forwardEmail', action.payload);
    case actions.FORWARD_EMAIL_START:
      return state.set('sending', true);
    case actions.FORWARD_EMAIL_CANCELLED:
    case actions.FORWARD_EMAIL_ERROR:
      return state.set('sending', false);
    case actions.FORWARD_EMAIL_DONE:
      return state.set('sending', false).set('modalVisible', false);
    default:
      return state;
  }
}
