const allApplicationsActions = {
  ALL_APPLICATIONS_FETCH_START: 'ALL_APPLICATIONS_FETCH_START',
  ALL_APPLICATIONS_FETCH_DONE: 'ALL_APPLICATIONS_FETCH_DONE',
  ALL_APPLICATIONS_FETCH_ERROR: 'ALL_APPLICATIONS_FETCH_ERROR',
  ALL_APPLICATIONS_FETCH_CANCELLED: 'ALL_APPLICATIONS_FETCH_CANCELLED',

  ALL_APPLICATIONS_COUNT: 'ALL_APPLICATIONS_COUNT',

  ALL_APPLICATIONS_SET_FILTER: 'ALL_APPLICATIONS_SET_FILTER',
  ALL_APPLICATIONS_RESET_FILTER: 'ALL_APPLICATIONS_RESET_FILTER',

  ALL_APPLICATIONS_FILTERS_FETCH_START: 'ALL_APPLICATIONS_FILTERS_FETCH_START',
  ALL_APPLICATIONS_FILTERS_FETCH_DONE: 'ALL_APPLICATIONS_FILTERS_FETCH_DONE',
  ALL_APPLICATIONS_FILTERS_FETCH_ERROR: 'ALL_APPLICATIONS_FILTERS_FETCH_ERROR',
  ALL_APPLICATIONS_FILTERS_FETCH_CANCELLED: 'ALL_APPLICATIONS_FILTERS_FETCH_CANCELLED',

  fetchAllApplications: payload => ({
    type: allApplicationsActions.ALL_APPLICATIONS_FETCH_START,
    payload,
  }),

  fetchFilters: () => ({
    type: allApplicationsActions.ALL_APPLICATIONS_FILTERS_FETCH_START,
  }),

  resetFilter: () => ({
    type: allApplicationsActions.ALL_APPLICATIONS_RESET_FILTER,
  }),

  setFilter: ({ field, value }) => ({
    type: allApplicationsActions.ALL_APPLICATIONS_SET_FILTER,
    payload: {
      field,
      value,
    },
  }),
};
export default allApplicationsActions;
