import { IncompleteJobToContinue } from 'containers/ContinueJobNotification/ContinueJobNotification';
import { Map } from 'immutable';
import { ReduxAction } from 'types/redux.types';
import actions from './actions';

const initState = Map({
  continuableJobs: [],
});

export default function continuableJobsReducer(state = initState, action: ReduxAction) {
  switch (action.type) {
    case actions.FETCH_CONTINUABLE_JOBS_DONE:
      if (action.payload.length > 0) {
        const hiddenContinuableJobs = JSON.parse(
          localStorage.getItem('hiddenContinuableJobs') || '[]'
        );

        const sortedContinuableJobs = action.payload
          .filter(
            (incompleteJob: IncompleteJobToContinue) =>
              incompleteJob.completionPercentage > 30 &&
              !hiddenContinuableJobs.includes(incompleteJob.uid)
          )
          .sort((a: IncompleteJobToContinue, b: IncompleteJobToContinue) => {
            return b.completionPercentage - a.completionPercentage;
          })
          .slice(0, 2);

        return state.set('continuableJobs', sortedContinuableJobs);
      }
      return state;

    case actions.HIDE_CONTINUABLE_JOB:
      const uid = action.payload;
      const hiddenContinuableJobs = JSON.parse(localStorage.getItem('hiddenContinuableJobs') || '[]');
      localStorage.setItem('hiddenContinuableJobs', JSON.stringify([...hiddenContinuableJobs, uid]));
      return state;

    default:
      return state;
  }
}
