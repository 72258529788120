import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import { ReduxAction } from 'types/redux.types';
import faqActions from './actions';
import faqFetchGraphql from './graphql';

function* fetchFaqGenerator(action: ReduxAction): any {
  try {
    const response = yield call(faqFetchGraphql, action.payload);
    yield put({
      type: faqActions.FETCH_FAQ_DONE,
      payload: response.data.faq,
    });
  } catch (e) {
    log(e);
    yield put({ type: faqActions.FETCH_FAQ_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: faqActions.FETCH_FAQ_CANCELLED,
      });
    }
  }
}

function* fetchFaq() {
  yield takeLatest(faqActions.FETCH_FAQ_START, fetchFaqGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchFaq)]);
}
