import gql from 'graphql-tag';

const commentsFragment = gql`
  fragment CommentsFields on Comment {
    id
    content
    draftRaw
    createdDateTime
    canEdit
    employer {
      id
      username
      name
      initials
      email
      photoThumbnail
    }
    application {
      id
    }
    projectId
    projectTitle
  }
`;

const tagFragment = gql`
  fragment TagFields on Tag {
    id
    name
    special_type
  }
`;

const eventsFragment = gql`
  fragment EventsFields on ApplicationEvent {
    id
    eventType
    dateTime
    eventData
    employer {
      id
      username
      initials
      name
      email
      photoThumbnail
    }
  }
`;

const applicationBasicFragment = gql`
  fragment BasicFields on Application {
    name
    photo
    rating
    selectionLevel
    rejected
    preselectionPassed
    preselectionPointCount
    phone
    email
    employerView
    consentExpiring
    consentExpiringDate
    consent
    hidden
  }
`;

const queryFetchApplication = gql`
  query fetchApplication($applicationId: Int!, $employerViewedUpdate: Boolean, $projectId: Int) {
    application(id: $applicationId, employerViewedUpdate: $employerViewedUpdate) {
      id
      email
      phone
      coverLetter
      fromSite
      canDelete
      comment
      ...BasicFields
      comments(includePreviousComments: true, option: "project") {
        ...CommentsFields
      }
      applications(option: "project") {
        id
        applicationTime
        projects {
          id
          name
        }
      }
      attachments {
        id
        type
        ext
      }
      job {
        id
      }
      applicationCVSnapshot {
        id
      }
      events(projectId: $projectId) {
        ...EventsFields
      }
      tags {
        ...TagFields
      }
      preselectionApplication {
        id
        preselectionQuestion {
          id
          required
          question
          passingPoints
          genericQuestion
          genericQuestionType
          preselectionQuestionAnswers {
            id
            answer
            order
            points
          }
        }
        candidateAnswer {
          id
          answerId
          comment
        }
      }
      isWithDynamicProfile
    }
  }
  ${commentsFragment}
  ${applicationBasicFragment}
  ${eventsFragment}
  ${tagFragment}
`;

const queryFetchApplications = gql`
  query fetchApplications($id: Int) {
    job(id: $id) {
      applications {
        id
        name
        email
        rating
        selectionLevel
      }
    }
  }
`;

export {
  queryFetchApplication,
  queryFetchApplications,
  applicationBasicFragment,
  tagFragment,
  commentsFragment,
};

