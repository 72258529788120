const actions = {
  TOGGLE_TEMPLATE_EDITOR_MODAL: 'TOGGLE_TEMPLATE_EDITOR_MODAL',
  EMAIL_TEMPLATE_CREATE_START: 'EMAIL_TEMPLATE_CREATE_START',
  EMAIL_TEMPLATE_CREATE_DONE: 'EMAIL_TEMPLATE_CREATE_DONE',
  EMAIL_TEMPLATE_CREATE_ERROR: 'EMAIL_TEMPLATE_CREATE_ERROR',
  EMAIL_TEMPLATE_CREATE_CANCELLED: 'EMAIL_TEMPLATE_CREATE_CANCELLED',

  EMAIL_TEMPLATE_UPDATE_START: 'EMAIL_TEMPLATE_UPDATE_START',
  EMAIL_TEMPLATE_UPDATE_DONE: 'EMAIL_TEMPLATE_UPDATE_DONE',
  EMAIL_TEMPLATE_UPDATE_ERROR: 'EMAIL_TEMPLATE_UPDATE_ERROR',
  EMAIL_TEMPLATE_UPDATE_CANCELLED: 'EMAIL_TEMPLATE_UPDATE_CANCELLED',

  EMAIL_TEMPLATE_DELETE_START: 'EMAIL_TEMPLATE_DELETE_START',
  EMAIL_TEMPLATE_DELETE_DONE: 'EMAIL_TEMPLATE_DELETE_DONE',
  EMAIL_TEMPLATE_DELETE_ERROR: 'EMAIL_TEMPLATE_DELETE_ERROR',
  EMAIL_TEMPLATE_DELETE_CANCELLED: 'EMAIL_TEMPLATE_DELETE_CANCELLED',

  toggleModal: state => ({
    type: actions.TOGGLE_TEMPLATE_EDITOR_MODAL,
    payload: state,
  }),

  createTemplate: payload => ({
    type: actions.EMAIL_TEMPLATE_CREATE_START,
    payload,
  }),

  updateTemplate: payload => ({
    type: actions.EMAIL_TEMPLATE_UPDATE_START,
    payload,
  }),

  deleteTemplate: payload => ({
    type: actions.EMAIL_TEMPLATE_DELETE_START,
    payload,
  }),
};

export default actions;
