import { application } from '.';
import jobs from './job';

const projects = [
  {
    id: '1',
    name: jobs[1].title,
    jobs: [jobs[1]],
    applicationCount: application.filter((item) => item.job.id === 1).length,
    applications: application.filter((item) => item.job.id === 1),
    access: jobs[1].access,
    customSelectionLabels: [],
  },
  {
    id: '2',
    name: jobs[2].title,
    jobs: [jobs[2]],
    applicationCount: application.filter((item) => item.job.id === 2).length,
    applications: application.filter((item) => item.job.id === 2),
    access: jobs[2].access,
    customSelectionLabels: [],
  },
];

export default projects;
