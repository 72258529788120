import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchCompanySeller = (payload) => {
  const params = {};

  if (payload && payload.recache) {
    params.fetchPolicy = 'network-only';
  }
  const queryFetchCompanySeller = gql`
    query companySeller {
      companySeller {
        id
        firstName
        lastName
        phoneNumber
        imagePath
        email
      }
    }
  `;
  return client.query({
    query: queryFetchCompanySeller,
    ...params,
  });
};

export default fetchCompanySeller;
