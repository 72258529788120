import { Map } from 'immutable';
import { ProfileFile, ProfilePost, ProfileVideo } from 'types/profile.types';
import { ProfileActions, ProfileState } from '../types';
import {
  ProfilePostState,
  PROFILE_POST_ACTIVE_POST_ADD_FILE,
  PROFILE_POST_ACTIVE_POST_ADD_VIDEO,
  PROFILE_POST_ACTIVE_POST_DELETE_FILE,
  PROFILE_POST_ACTIVE_POST_DELETE_VIDEO,
  PROFILE_POST_ACTIVE_POST_SET_ACTIVE_POST,
  PROFILE_POST_ACTIVE_POST_UPDATE_FIELD,
  PROFILE_POST_ACTIVE_POST_UPDATE_FILE,
  PROFILE_POST_ACTIVE_POST_UPDATE_VIDEO, PROFILE_POST_DELETE_CANCELLED,
  PROFILE_POST_DELETE_DONE,
  PROFILE_POST_DELETE_ERROR,
  PROFILE_POST_DELETE_START,
  PROFILE_POST_RESET_MESSAGES,
  PROFILE_POST_STATE_ADD_POST,
  PROFILE_POST_STATE_DELETE_POST,
  PROFILE_POST_STATE_UPDATE_POST,
  PROFILE_POST_UPDATE_CANCELLED,
  PROFILE_POST_UPDATE_DONE,
  PROFILE_POST_UPDATE_ERROR,
  PROFILE_POST_UPDATE_START
} from './types';

const initState: ProfilePostState = Map<string, any>({
  profilePosts: [],
  activePost: null,
  postSubmitting: false,
  postSubmitError: null,
  postSubmitSuccess: null,
  postDeleting: false,
  postDeleteError: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    /* Blog Posts */

    case PROFILE_POST_UPDATE_START:
      return state
        .set('postSubmitting', true)
        .set('postSubmitError', null)
        .set('postSubmitSuccess', null);
    case PROFILE_POST_UPDATE_ERROR:
      return state.set('postSubmitting', false).set('postSubmitError', action.payload);
    case PROFILE_POST_UPDATE_CANCELLED:
      return state.set('postSubmitting', false);
    case PROFILE_POST_UPDATE_DONE:
      return state
        .set('postSubmitting', false)
        .set('profilePosts', action.payload.posts)
        .set('postSubmitSuccess', action.payload.successMessage);

    case PROFILE_POST_DELETE_START:
      return state.set('postDeleting', true).set('postDeleteError', null);
    case PROFILE_POST_DELETE_ERROR:
      return state.set('postDeleting', false).set('postDeleteError', action.payload);
    case PROFILE_POST_DELETE_CANCELLED:
      return state.set('postDeleting', false);

    case PROFILE_POST_DELETE_DONE:
      return state.set('postDeleting', false).set('profilePosts', action.payload.posts);

    case PROFILE_POST_STATE_ADD_POST:
      return state
        .set('profilePosts', [action.payload, ...state.get('profilePosts')])
        .set('activePost', action.payload);

    case PROFILE_POST_STATE_UPDATE_POST: {
      return state.set(
        'profilePosts',
        Object.assign([], state.get('profilePosts'), {
          [state
            .get('profilePosts')
            .findIndex(
              (v: ProfilePost) =>
                v.id === action.payload.id && v.postType === action.payload.postType
            )]: action.payload,
        })
      );
    }

    case PROFILE_POST_STATE_DELETE_POST:
      return state.set(
        'profilePosts',
        state.get('profilePosts').filter((v: ProfilePost) => {
          if (v.id === action.payload.id && v.postType === action.payload.postType) {
            return false;
          }
          return true;
        })
      );

    case PROFILE_POST_ACTIVE_POST_SET_ACTIVE_POST:
      return state.set('activePost', action.payload);

    case PROFILE_POST_ACTIVE_POST_UPDATE_FIELD:
      return state.set('activePost', {
        ...state.get('activePost'),
        [action.payload.field]: action.payload.value,
      });

    case PROFILE_POST_ACTIVE_POST_ADD_VIDEO: {
      const activePost = state.get('activePost');
      return state.set('activePost', {
        ...activePost,
        videoUrls: [null, ...activePost.videoUrls],
      });
    }

    case PROFILE_POST_ACTIVE_POST_UPDATE_VIDEO: {
      const activePost = state.get('activePost');
      const videoIndex = activePost.videoUrls.findIndex(
        (v: ProfileVideo, index: number) => index === action.payload.index
      );
      if (videoIndex < 0) {
        return state;
      }
      return state.set('activePost', {
        ...activePost,
        videoUrls: Object.assign([], activePost.videoUrls, {
          [videoIndex]: action.payload.link,
        }),
      });
    }

    case PROFILE_POST_ACTIVE_POST_DELETE_VIDEO: {
      const activePost = state.get('activePost');
      return state.set('activePost', {
        ...activePost,
        videoUrls: activePost
          .videoUrls.filter((v: any, index: number) => index !== action.payload.index),
      });
    }

    case PROFILE_POST_ACTIVE_POST_ADD_FILE: {
      const activePost = state.get('activePost');
      return state.set('activePost', {
        ...activePost,
        files: [action.payload, ...activePost.files],
      });
    }

    case PROFILE_POST_ACTIVE_POST_UPDATE_FILE: {
      const activePost = state.get('activePost');
      const fileIndex = activePost.files.findIndex(
        (v: ProfileFile) => v.type === action.payload.type && v.id === action.payload.id
      );
      if (fileIndex < 0) {
        return state;
      }
      return state.set('activePost', {
        ...activePost,
        files: Object.assign([], activePost.files, {
          [fileIndex]: action.payload,
        }),
      });
    }

    case PROFILE_POST_ACTIVE_POST_DELETE_FILE: {
      const activePost = state.get('activePost');
      return state.set('activePost', {
        ...activePost,
        files: activePost.files.filter(
          (v: ProfileFile) => v.type !== action.payload.type || v.id !== action.payload.id
        ),
      });
    }

    case PROFILE_POST_RESET_MESSAGES:
      return state.set('postSubmitError', null).set('postSubmitSuccess', null);

    default:
      return state;
  }
};

export default initState;

export { initState, reducer };
