const actions = {
  SIDEBAR_EXPAND: 'SIDEBAR_EXPAND',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  expandSidebar: expanded => ({
    type: actions.SIDEBAR_EXPAND,
    expanded,
  }),

  toggleSidebar: () => ({
    type: actions.TOGGLE_SIDEBAR,
  }),
};

export default actions;
