import EMPLOYER_TYPE from 'components/jobSubmission/employerTypes';
import { push } from 'connected-react-router';
import links from 'helpers/links';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import fetchPricelistGraphql, {
  fetchAdvertPositionsGraphql,
  fetchPriceCalculationGraphQl,
  mutateCreateIncompleteJob,
} from './graphql';

function* fetchAdvertPositionsGenerator() {
  try {
    const response = yield call(fetchAdvertPositionsGraphql);
    yield put({
      type: actions.FETCH_ADVERT_POSITIONS_DONE,
      payload: response.data.lists.advertPositions,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_ADVERT_POSITIONS_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_ADVERT_POSITIONS_CANCELLED,
      });
    }
  }
}

function* createIncompleteJobGenerator(action) {
  try {
    const response = yield call(mutateCreateIncompleteJob, {
      variables: {
        ...action.payload,
      },
    });
    yield put({
      type: actions.MUTATE_CREATE_INCOMPLETE_JOB_DONE,
      payload: response.data.createIncompleteJob,
    });

    yield put(push(links.jobSubmission(response.data.createIncompleteJob).url));
    yield put({
      type: actions.TOGGLE_PRICELIST_MODAL,
      payload: false,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_CREATE_INCOMPLETE_JOB_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_CREATE_INCOMPLETE_JOB_CANCELLED,
      });
    }
  }
}

function* fetchPricelistGenerator(action) {
  try {
    if (!action || (action && action.payload)) {
      yield put({
        type: actions.SET_LOADING,
        payload: true,
      });

      const userState = yield select((state) => state.User.get('user'));

      const employerType =
        userState.typeId &&
        [EMPLOYER_TYPE.FOREIGN, EMPLOYER_TYPE.FOREIGN_INDIVIDUAL].includes(userState.typeId)
          ? 'foreign'
          : 'domestic';

      const currency = employerType === 'foreign' ? 'EUR' : 'RSD';

      const response = yield call(fetchPricelistGraphql, {
        variables: {
          currency,
        },
      });
      yield put({
        type: actions.FETCH_PRICELIST_DONE,
        payload: response.data.adPriceList,
      });
    }
  } catch (e) {
    log(e);
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.FETCH_PRICELIST_ERROR });
    }
    yield put({ type: actions.FETCH_PRICELIST_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_PRICELIST_CANCELLED,
      });
    }
  }
}

function* fetchPriceCalculationGenerator(action) {
  try {
    const response = yield call(fetchPriceCalculationGraphQl, action.payload);
    yield put({
      type: actions.FETCH_PRICE_CALCULATION_DONE,
      payload: response.data.adPriceCalculation,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_PRICE_CALCULATION_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_PRICE_CALCULATION_CANCELLED,
      });
    }
  }
}

export function* fetchPricelist() {
  yield takeLatest(
    [actions.FETCH_PRICELIST_START, actions.TOGGLE_PRICELIST_MODAL],
    fetchPricelistGenerator
  );
}

export function* createIncompleteJob() {
  yield takeLatest(actions.MUTATE_CREATE_INCOMPLETE_JOB_START, createIncompleteJobGenerator);
}

function* fetchAdvertPositions() {
  yield takeLatest(actions.FETCH_ADVERT_POSITIONS_START, fetchAdvertPositionsGenerator);
}

function* fetchPriceCalculation() {
  yield takeLatest(actions.FETCH_PRICE_CALCULATION_START, fetchPriceCalculationGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchPricelist),
    spawn(createIncompleteJob),
    spawn(fetchAdvertPositions),
    spawn(fetchPriceCalculation),
  ]);
}
