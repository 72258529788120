import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchListsGraphql = () => {
  const params = {};

  const query = gql`
    query lists {
      lists {
        cities {
          id
          name
        }
        regions {
          id
          name
        }
      }
    }
  `;

  return client.query({
    query,
    ...params,
  });
};

export default fetchListsGraphql;
