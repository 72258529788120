import IntlMessages from 'components/utility/IntlMessages';
import config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import authAction from 'redux/auth/actions';
import langActions from 'redux/languageSwitcher/actions';
import { SignInStyleWrapper } from './signin.style';

const { login, credentialsChange } = authAction;

const { changeLanguage } = langActions;

class SignIn extends React.Component {
  static propTypes = {
    credentialsChange: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    login: PropTypes.func,
    baseUrl: PropTypes.string,
    lastPageBeforeLogout: PropTypes.string,
    changeLanguage: PropTypes.func,
    locale: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  state = {
    redirectToReferrer: false,
  };

  credentials = {
    username: '',
    password: '',
  };

  constructor(props) {
    super(props);
    this.props.credentialsChange(this.credentials);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.isLoggedIn) {
      const homepage = `${nextProps.baseUrl}/ats-prijave`;
      if (nextProps.lastPageBeforeLogout) {
        nextProps.history.replace(nextProps.lastPageBeforeLogout);
      } else if (nextProps.location.state) {
        const { pathname } = nextProps.location.state.from;
        nextProps.history.replace(pathname);
      } else {
        nextProps.history.replace(homepage);
      }
    }
    return nextProps;
  }

  handleChange = (e) => {
    this.credentials[e.target.name] = e.target.value;
    this.props.credentialsChange(this.credentials);
  };

  handleLogin = () => {
    this.props.login(this.credentials);
  };

  onKeyDownHandle = (event) => {
    if (event.key === 'Enter') {
      this.props.login(this.credentials);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleLandChange = (lang) => {
    this.props.changeLanguage(lang);
  };

  render() {
    const from = { pathname: this.props.baseUrl };
    const { redirectToReferrer } = this.state;
    const { locale } = this.props;
    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <SignInStyleWrapper className="isoSignInPage">
        <FormattedMessage id="page.signInButton">
          {(title) => (
            <Helmet>
              <title>{title.toString()} | HR Lab</title>
            </Helmet>
          )}
        </FormattedMessage>
        {locale === 'en' ? (
          <button
            onClick={() => this.handleLandChange('serbian')}
            className="absolute pin-t pin-r m-8 px-3 py-2 text-white no-underline bg-black rounded"
          >
            sr
          </button>
        ) : (
          <button
            onClick={() => this.handleLandChange('english')}
            className="absolute pin-t pin-r m-8 px-3 py-2 text-white no-underline bg-black rounded"
          >
            en
          </button>
        )}
        <div className="background-photo" />
        <div className="bg-white p-8 max-w-sm mx-auto sm:rounded-large sm:my-8 border border-solid border-grey-light shadow">
          <div className="flex flex-col md:flex-row items-start sm:items-center justify-between">
            <img height="54" style={{ height: '54px' }} src="/images/hrlab-logo.svg" alt="" />
          </div>
          <div>
            <p className="text-2xl mb-1">
              <IntlMessages id="page.signInButton" />
            </p>

            <form action="/login" method="post">
              <label className="label" htmlFor="username">
                <FormattedMessage id="signin.username.placeholder" />{' '}
                <span className="require">*</span>
              </label>
              <input
                type="text"
                className="input"
                id="username"
                name="_username"
                value="demo"
                readOnly
              />

              <label className="label" htmlFor="password">
                <FormattedMessage id="signin.password.placeholder" />{' '}
                <span className="require">*</span>
              </label>
              <input
                type="password"
                className="input mb-2"
                id="password"
                name="_password"
                value="demo"
                readOnly
              />

              <button
                type="button"
                className="button "
                id="login-button"
                onClick={this.handleLogin}
              >
                <IntlMessages id="page.signInButton" />
              </button>
            </form>
          </div>
        </div>
      </SignInStyleWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.Auth.get('loggedIn') !== false,
  baseUrl: config.baseUrl,
  lastPageBeforeLogout: state.Navigation.get('lastPageBeforeLogout'),
  locale: state.LanguageSwitcher.get('language').locale,
});

export default connect(
  mapStateToProps,
  { login, credentialsChange, changeLanguage },
)(SignIn);
