import { language } from '../../config.js';
import serbianLang from '../../image/flag/serbia.svg';
import englishLang from '../../image/flag/uk.svg';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      icon: englishLang,
    },
    {
      languageId: 'serbian',
      locale: 'sr',
      text: 'Serbian',
      icon: serbianLang,
    },
  ],
};

export function getLanguageByLocale(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((languageOption) => {
    if (languageOption.locale === lang) {
      selecetedLanguage = languageOption;
    }
  });
  return selecetedLanguage;
}

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((languageOption) => {
    if (languageOption.languageId === lang) {
      selecetedLanguage = languageOption;
    }
  });
  return selecetedLanguage;
}
export default config;
