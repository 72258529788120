import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import createSelectionProcessFromAPIResponse from './helpers';
import selectionProcessUpdateRequest from './requests';
import {
  PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED,
  PROFILE_SELECTION_PROCESS_UPDATE_DONE,
  PROFILE_SELECTION_PROCESS_UPDATE_ERROR,
  PROFILE_SELECTION_PROCESS_UPDATE_START,
  SelectionProcessApiRequestAction
} from './types';

const Nprogress = require('nprogress');

function* profilePostUpdateGenerator(action: SelectionProcessApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(selectionProcessUpdateRequest, action.payload);
    yield put({
      type: PROFILE_SELECTION_PROCESS_UPDATE_DONE,
      payload: {
        selectionProcess: createSelectionProcessFromAPIResponse(
          response.data.companyProfileSelectionProcessMutation
        ),
        successMessage: translate('profile.form.saved'),
      },
    });
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    log(e);
    yield put({
      type: PROFILE_SELECTION_PROCESS_UPDATE_ERROR,
      payload: translate('error.tryAgainMessage'),
    });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED,
      });
    }
  }
}

function* selectionProcessUpdate() {
  yield takeLatest(PROFILE_SELECTION_PROCESS_UPDATE_START, profilePostUpdateGenerator);
}

export default function* rootSaga() {
  yield all([spawn(selectionProcessUpdate)]);
}
