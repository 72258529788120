const ADTYPE = {
  MINI: 6,
  ECONOMY: 1,
  STANDARD: 2,
  BRAND: 5,
  PREMIUM: 3,
  GRAPHQL: {
    6: 'MINI',
    1: 'ECONOMY',
    2: 'CLASSIC',
    5: 'DESIGNED',
    3: 'HOT',
  },
};

export default ADTYPE;
