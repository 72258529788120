import { notification } from 'antd';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import applicationActions from 'redux/application/actions';
import { ReduxAction } from 'types/redux.types';
import createApplicationActions from '../editApplication/actions';
import projectActions from './actions';
import fetchProjectGraphql, { mutateCustomSelectionLabelsGraphql } from './graphql';
import fetchApplicationFiltersRequest from './requests';

function* fetchProjectGenerator(action: ReduxAction): any {
  const projectState = yield select((state: any) => state.Project);
  try {
    yield call(() => {
      Nprogress.start();
    });

    const activeSorts = projectState
      .get('sort')
      .filter((sortDirection: any) => sortDirection !== null);

    const sort = {
      sortField: Object.keys(activeSorts.toJS())[0],
      sortDirection: activeSorts.toJS()[Object.keys(activeSorts.toJS())[0]],
    };

    const payload = {
      ...action.payload,
      variables: {
        ...action.payload.variables,
        ...projectState.get('selectedFilters').toJS(),
        ...sort,
      },
    };

    const response = yield call(fetchProjectGraphql, payload);
    yield put({
      type: projectActions.FETCH_PROJECT_DONE,
      payload: response.data.project,
    });
  } catch (e: any) {
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: projectActions.FETCH_PROJECT_DENIED });
    } else {
      notification.error({
        message: translate('error.userError'),
        description: translate('error.tryAgain'),
        duration: 0,
      });
    }
    yield put({
      type: projectActions.FETCH_PROJECT_ERROR,
      payload: e.message,
    });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: projectActions.FETCH_PROJECT_CANCELLED,
      });
    }
  }
}

/* fetchApplicationFiltersGenerator */
function* fetchApplicationFiltersGenerator(action: ReduxAction): any {
  try {
    let id = null;

    if (action.type === projectActions.APPLICATION_FILTER_FETCH) {
      id = action.payload;
    }

    if (!id) {
      const projectState = yield select((state: any) => state.Project);
      id = projectState.get('currentProject').get('id');
    }

    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(fetchApplicationFiltersRequest, id);
    yield put({
      type: projectActions.APPLICATION_FILTER_FETCHING_DONE,
      payload: response.data,
    });
  } catch (e) {
    log(e);
    yield put({ type: projectActions.APPLICATION_FILTER_FETCHING_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: projectActions.APPLICATION_FILTER_FETCHING_CANCELLED,
      });
    }
  }
}

function* setApplicationToSelectionGenerator(): any {
  const projectState = yield select((state: any) => state.Project);
  const selectCount = Object.keys(projectState.toJS().selectedApplications).length;
  yield put({
    type: projectActions.TOGGLE_BULK_ACTIONS,
    payload: {
      state: !!selectCount,
    },
  });
}

function* mutateCustomSelectionLabelsGenerator(action: ReduxAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const response = yield call(mutateCustomSelectionLabelsGraphql, action.payload);
    yield put({
      type: projectActions.SAVE_CUSTOM_SELECTION_LABELS_DONE,
      payload: response.data.projectCustomSelectionLabels,
    });
  } catch (e) {
    log(e);
    yield put({ type: projectActions.SAVE_CUSTOM_SELECTION_LABELS_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: projectActions.SAVE_CUSTOM_SELECTION_LABELS_CANCELLED,
      });
    }
  }
}

function* fetchProject() {
  yield takeLatest(
    [
      projectActions.FETCH_PROJECT_START,
      projectActions.SET_FILTER,
      projectActions.RESET_FILTER,
      projectActions.SET_SORT,
    ],
    fetchProjectGenerator
  );
}

export function* setApplicationToSelection() {
  yield takeLatest(
    [projectActions.MODIFY_APPLICATION_SELECTION, projectActions.RESET_APPLICATION_SELECTION],
    setApplicationToSelectionGenerator
  );
}

/**
 * fetch application filters
 */
export function* fetchApplicationFilters() {
  yield takeLatest(
    [
      projectActions.APPLICATION_FILTER_FETCH,
      createApplicationActions.CREATE_APPLICATION_DONE,
      applicationActions.APPLICATION_DELETE_DONE,
      applicationActions.APPLICATION_TAGS_FETCH_DONE,
      applicationActions.APPLICATION_EVENTS_FETCH_DONE,
      applicationActions.APPLICATION_RATING_FETCH_DONE,
    ],
    fetchApplicationFiltersGenerator
  );
}

function* mutateCustomSelectionLabels() {
  yield takeLatest(
    projectActions.SAVE_CUSTOM_SELECTION_LABELS_START,
    mutateCustomSelectionLabelsGenerator
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchProject),
    spawn(fetchApplicationFilters),
    spawn(setApplicationToSelection),
    spawn(mutateCustomSelectionLabels),
  ]);
}
