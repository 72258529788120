import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  applications: undefined,
  applicationCount: 0,
  loading: true,
  filters: new Map({
    candidateName: '',
    tags: [],
    projects: [],
    limit: 50,
    offset: 0,
  }),
  availableFilters: new Map({}),
});

export default function allApplicationsReducer(state = initState, action) {
  switch (action.type) {
    case actions.ALL_APPLICATIONS_FETCH_START:
      return state.set('loading', true);

    case actions.ALL_APPLICATIONS_FILTERS_FETCH_DONE: {
      const { project: projects } = action.payload[0].data.projects;
      const { projectFilters: tags } = action.payload[1].data;
      return state
        .setIn(['availableFilters', 'projects'], projects)
        .setIn(['availableFilters', 'tags'], tags);
    }

    case actions.ALL_APPLICATIONS_RESET_FILTER:
      return state.set('filters', initState.get('filters')).set('loading', true);

    case actions.ALL_APPLICATIONS_SET_FILTER:
      return state
        .setIn(['filters', action.payload.field], action.payload.value)
        .set('loading', true);

    case actions.ALL_APPLICATIONS_FETCH_DONE:
      return state
        .set('applications', action.payload.applications)
        .set('applicationCount', action.payload.count)
        .set('loading', false);
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
