import { Map, fromJS } from 'immutable';
import actions from './actions';

const initState = new Map({
  seller: null,
});

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_COMPANY_SELLER_DONE:
      return state.set('seller', fromJS(action.payload));

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
