import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';
import config from 'config';

const fetchBannerRequest = () => axios({
  method: 'get',
  url: `${config.apiUrl}/announcement`,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export default fetchBannerRequest;
