import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { ProfilePost } from 'types/profile.types';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import createPostFromAPIResponse from './helpers';
import {
  profilePostDelete as profilePostDeleteRequest,
  profilePostUpdate as profilePostUpdateRequest
} from './requests';
import {
  PostApiRequestAction, PROFILE_POST_DELETE_CANCELLED,
  PROFILE_POST_DELETE_DONE,
  PROFILE_POST_DELETE_ERROR,
  PROFILE_POST_DELETE_START,
  PROFILE_POST_UPDATE_CANCELLED,
  PROFILE_POST_UPDATE_DONE,
  PROFILE_POST_UPDATE_ERROR,
  PROFILE_POST_UPDATE_START
} from './types';

const Nprogress = require('nprogress');

function* profilePostUpdateGenerator(action: PostApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    const profilePosts = storeState.get('profilePosts');
    const response = yield call(profilePostUpdateRequest, action.payload);
    const postIndex = profilePosts.findIndex(
      (v: ProfilePost) => v.id === action.payload.id && v.postType === action.payload.postType
    );
    if (postIndex < 0) {
      const updatedPost = createPostFromAPIResponse(
        response.data.companyProfileProfilePostMutation
      );
      yield put({
        type: PROFILE_POST_UPDATE_DONE,
        payload: {
          posts: [updatedPost, ...profilePosts],
          successMessage: translate('profile.form.saved'),
        },
      });
      yield put({
        type: CREATE_PROFILE_SNAPSHOT
      });
    } else {
      const updatedPost = createPostFromAPIResponse(
        response.data.companyProfileProfilePostMutation
      );
      yield put({
        type: PROFILE_POST_UPDATE_DONE,
        payload: {
          posts: Object.assign([], profilePosts, {
            [postIndex]: updatedPost,
          }),
          successMessage: translate('profile.form.saved'),
        },
      });
      message.success(translate('profile.form.saved'));
    }
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_POST_UPDATE_ERROR, payload: translate('error.tryAgainMessage') });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_POST_UPDATE_CANCELLED,
      });
    }
  }
}

function* profilePostDeleteGenerator(action: PostApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    const profilePosts = storeState.get('profilePosts');
    yield call(profilePostDeleteRequest, action.payload);
    const postIndex = profilePosts.findIndex(
      (v: ProfilePost) => v.id === action.payload.id && v.postType === action.payload.postType
    );
    if (postIndex < 0) {
      yield put({ type: PROFILE_POST_DELETE_ERROR, payload: 'Undefined post index' });
      message.error(translate('error.tryAgainMessage'));
    } else {
      yield put({
        type: PROFILE_POST_DELETE_DONE,
        payload: {
          post: action.payload,
          posts: profilePosts.filter((v: ProfilePost) => {
            if (v.id === action.payload.id && v.postType === action.payload.postType) {
              return false;
            }
            return true;
          }),
        },
      });
      yield put({
        type: CREATE_PROFILE_SNAPSHOT
      });
      message.success(translate('profile.form.saved'));
    }
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_POST_DELETE_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_POST_DELETE_CANCELLED,
      });
    }
  }
}

function* profilePostUpdate() {
  yield takeLatest(PROFILE_POST_UPDATE_START, profilePostUpdateGenerator);
}

function* profilePostDelete() {
  yield takeLatest(PROFILE_POST_DELETE_START, profilePostDeleteGenerator);
}

export default function* rootSaga() {
  yield all([spawn(profilePostUpdate), spawn(profilePostDelete)]);
}
