import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import createAboutUsFromAPIResponse from './helpers';
import aboutUsUpdateRequest from './requests';
import {
  AboutUsApiRequestAction,
  PROFILE_ABOUT_US_UPDATE_CANCELLED,
  PROFILE_ABOUT_US_UPDATE_DONE,
  PROFILE_ABOUT_US_UPDATE_ERROR,
  PROFILE_ABOUT_US_UPDATE_START
} from './types';

const Nprogress = require('nprogress');

function* profilePostUpdateGenerator(action: AboutUsApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(aboutUsUpdateRequest, action.payload);
    yield put({
      type: PROFILE_ABOUT_US_UPDATE_DONE,
      payload: {
        aboutUs: createAboutUsFromAPIResponse(response.data.companyProfileAboutUsMutation),
        successMessage: translate('profile.form.saved'),
      },
    });
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_ABOUT_US_UPDATE_ERROR, payload: translate('error.tryAgainMessage') });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_ABOUT_US_UPDATE_CANCELLED,
      });
    }
  }
}

function* aboutUsUpdate() {
  yield takeLatest(PROFILE_ABOUT_US_UPDATE_START, profilePostUpdateGenerator);
}

export default function* rootSaga() {
  yield all([spawn(aboutUsUpdate)]);
}
