const packageActions = {
  FETCH_PACKAGES_START: 'FETCH_PACKAGES_START',
  FETCH_PACKAGES_DONE: 'FETCH_PACKAGES_DONE',
  FETCH_PACKAGES_ERROR: 'FETCH_PACKAGES_ERROR',
  FETCH_PACKAGES_CANCELLED: 'FETCH_PACKAGES_CANCELLED',
  FETCH_PACKAGES_DENIED: 'FETCH_PACKAGES_DENIED',

  fetchPackages: (payload = {}) => ({
    type: packageActions.FETCH_PACKAGES_START,
    payload,
  }),
};

export default packageActions;
