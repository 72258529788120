import { message } from 'antd';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import { all, call, cancelled, put, spawn, takeEvery } from 'redux-saga/effects';
import actions from 'redux/feedback/actions';
import { isFeedbackGivenRequest, sendFeedbackRequest } from './requests';

function* sendFeedbackGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const { payload: data } = action;
    data.feedback_url = window.location.href;

    yield call(sendFeedbackRequest, data);
    yield put({
      type: actions.SEND_FEEDBACK_DONE,
    });
    yield call(() => {
      Nprogress.done();
    });
    yield call(() => {
      message.success(translate('feedback.handle.success'));
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.SEND_FEEDBACK_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.SEND_FEEDBACK_CANCELLED,
      });
    }
  }
}

function* checkShowFeedbackGenerator() {
  try {
    yield call(() => {
      Nprogress.start();
    });
    yield call(isFeedbackGivenRequest);

    /** no exception given, so feedback already given */
    yield put({
      type: actions.CHECK_SHOW_FEEDBACK_DENIED,
    });
  } catch (e) {
    /**
     * throws not found when no feedback given
     * so trigger showing here
     */
    const {
      response: { status },
    } = e;
    if (status === 404) {
      yield put({ type: actions.CHECK_SHOW_FEEDBACK_ALLOWED });
    }
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

function* sendFeedback() {
  yield takeEvery([actions.SEND_FEEDBACK_START], sendFeedbackGenerator);
}

function* checkShowFeedback() {
  yield takeEvery([actions.CHECK_SHOW_FEEDBACK], checkShowFeedbackGenerator);
}

export default function* rootSaga() {
  yield all([spawn(sendFeedback), spawn(checkShowFeedback)]);
}
