const zipActions = {
  TOGGLE_ZIP_MODAL_VISIBLE: 'TOGGLE_ZIP_MODAL_VISIBLE',

  SET_ZIP_RECEPIENTS: 'SET_ZIP_RECEPIENTS',

  ZIP_APPLICATIONS_START: 'ZIP_APPLICATIONS_START',
  ZIP_APPLICATIONS_DONE: 'ZIP_APPLICATIONS_DONE',
  ZIP_APPLICATIONS_ERROR: 'ZIP_APPLICATIONS_ERROR',
  ZIP_APPLICATIONS_CANCELLED: 'ZIP_APPLICATIONS_CANCELLED',

  submit: payload => ({
    type: zipActions.ZIP_APPLICATIONS_START,
    payload,
  }),

  setRecepients: applications => ({
    type: zipActions.SET_ZIP_RECEPIENTS,
    payload: applications,
  }),

  toggleZipModal: state => ({
    type: zipActions.TOGGLE_ZIP_MODAL_VISIBLE,
    payload: state,
  }),
};

export default zipActions;
