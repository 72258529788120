import { Map } from 'immutable';
import { CompanyTeamMember } from 'types/profile.types';
import { ProfileActions, ProfileState } from '../types';
import {
  CompanyTeamMemberState,
  PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_SET_ACTIVE_TEAM_MEMBER,
  PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_UPDATE_FIELD,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_START,
  PROFILE_COMPANY_TEAM_MEMBER_RESET_MESSAGES,
  PROFILE_COMPANY_TEAM_MEMBER_STATE_ADD_TEAM_MEMBER,
  PROFILE_COMPANY_TEAM_MEMBER_STATE_DELETE_TEAM_MEMBER,
  PROFILE_COMPANY_TEAM_MEMBER_STATE_UPDATE_TEAM_MEMBER,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START,
} from './types';

const initState: CompanyTeamMemberState = Map<string, any>({
  companyTeamMembers: [],
  activeTeamMember: null,
  teamMemberSubmitting: false,
  teamMemberSubmitError: null,
  teamMemberSubmitSuccess: null,
  teamMemberDeleting: false,
  teamMemberDeleteError: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    /* Blog Posts */
    case PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START:
      return state
        .set('teamMemberSubmitting', true)
        .set('teamMemberSubmitError', null)
        .set('teamMemberSubmitSuccess', null);
    case PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR:
      return state.set('teamMemberSubmitting', false).set('teamMemberSubmitError', action.payload);
    case PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED:
      return state.set('teamMemberSubmitting', false);
    case PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE:
      return state
        .set('teamMemberSubmitting', false)
        .set('companyTeamMembers', action.payload.teamMembers)
        .set('teamMemberSubmitSuccess', action.payload.successMessage);

    case PROFILE_COMPANY_TEAM_MEMBER_DELETE_START:
      return state.set('teamMemberDeleting', true).set('teamMemberDeleteError', null);
    case PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR:
      return state.set('teamMemberDeleting', false).set('teamMemberDeleteError', action.payload);
    case PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED:
      return state.set('teamMemberDeleting', false);

    case PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE:
      return state
        .set('teamMemberDeleting', false)
        .set('companyTeamMembers', action.payload.teamMembers);

    case PROFILE_COMPANY_TEAM_MEMBER_STATE_ADD_TEAM_MEMBER:
      return state
        .set('companyTeamMembers', [action.payload, ...state.get('companyTeamMembers')])
        .set('activeTeamMember', action.payload);

    case PROFILE_COMPANY_TEAM_MEMBER_STATE_UPDATE_TEAM_MEMBER: {
      return state.set(
        'companyTeamMembers',
        Object.assign([], state.get('companyTeamMembers'), {
          [state
            .get('companyTeamMembers')
            .findIndex(
              (v: CompanyTeamMember) => v.id === action.payload.id && v.type === action.payload.type
            )]: action.payload,
        })
      );
    }

    case PROFILE_COMPANY_TEAM_MEMBER_STATE_DELETE_TEAM_MEMBER:
      return state.set(
        'companyTeamMembers',
        state.get('companyTeamMembers').filter((v: CompanyTeamMember) => {
          if (v.id === action.payload.id && v.type === action.payload.type) {
            return false;
          }
          return true;
        })
      );

    case PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_SET_ACTIVE_TEAM_MEMBER:
      return state.set('activeTeamMember', action.payload);

    case PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_UPDATE_FIELD:
      return state.set('activeTeamMember', {
        ...state.get('activeTeamMember'),
        [action.payload.field]: action.payload.value,
      });

    case PROFILE_COMPANY_TEAM_MEMBER_RESET_MESSAGES:
      return state.set('teamMemberSubmitError', null).set('teamMemberSubmitSuccess', null);

    default:
      return state;
  }
};

export default initState;

export { initState, reducer };
