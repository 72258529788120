const packages = [
  {
    id: 1734,
    activationDate: '2019-06-03T00:00:00+02:00',
    expirationDate: '2020-06-03T00:00:00+02:00',
    balance: 3,
    initialBalance: 5,
    jobPackageType: 'Klasični oglas do 15 dana',
  },
];

export default packages;
