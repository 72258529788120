import 'loaders.css/loaders.min.css';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loaders';
import AnimatedLoaderStyled from './animatedLoader.style';

class AnimatedLoader extends React.Component {
  static propTypes = {
    scale: PropTypes.string,
  };

  static defaultProps = {
    scale: 1,
  };

  render() {
    const { scale, ...props } = this.props;
    return (
      <AnimatedLoaderStyled
        className="animated-loader"
        style={{
          padding: props.padding || '40px',
        }}
      >
        <Loader {...props} style={{ transform: `scale(${scale})` }} />
      </AnimatedLoaderStyled>
    );
  }
}

export default AnimatedLoader;
