import { message } from 'antd';
import { push, replace } from 'connected-react-router';
import links from 'helpers/links';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import {
  all,
  call,
  cancelled,
  delay,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  fetchTeamGraphql,
  mutateEmployerAccessGraphql,
  mutateGrantAccessGraphql,
  mutateRedeemAccessGraphql,
  mutateRevokeAccessGraphql,
} from 'redux/team/graphql';
import actions from './actions';
import checkAccesRequest from './requests';

/**
 * Fetch team
 * @param {*} action
 */
function* fetchTeamGenerator() {
  try {
    const response = yield call(fetchTeamGraphql, {
      recache: true,
    });
    yield put({
      type: actions.FETCH_TEAM_DONE,
      payload: response.data.team,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_TEAM_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_TEAM_CANCELLED,
      });
    }
  }
}

function* mutateRevokeGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(mutateRevokeAccessGraphql, {
      id: action.payload.id,
    });
    yield put({
      type: actions.MUTATE_REVOKE_DONE,
      payload: response.data.revokeProjectAccess,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_REVOKE_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_REVOKE_CANCELLED,
      });
    }
  }
}

function* mutateGrantGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(mutateGrantAccessGraphql, action.payload);
    yield put({
      type: actions.MUTATE_GRANT_DONE,
      payload: response.data.addProjectAccess,
    });

    message.success(translate('sharePopup.success'));
  } catch (e) {
    log(e);

    if (e.message === 'GraphQL error: duplicate.entry') {
      message.error(translate('sharePopup.emailInput.duplicateEntry'));
    }

    yield put({ type: actions.MUTATE_GRANT_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_GRANT_CANCELLED,
      });
    }
  }
}

function* mutateEmployerAccessGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(mutateEmployerAccessGraphql, action.payload);
    yield put({
      type: actions.MUTATE_EMPLOYER_ACCESS_DONE,
      payload: response.data.modifyEmployerAccess,
    });
    yield call(() => {
      message.success(translate('team.message.success'));
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_EMPLOYER_ACCESS_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_EMPLOYER_ACCESS_CANCELLED,
      });
    }
  }
}

/* redeem access generator */
export function* redeemAccessGenerator(action) {
  try {
    let userExists = false;
    try {
      const userExistsResponse = yield call(checkAccesRequest, action.payload);
      userExists = userExistsResponse.status === 200;
    } catch (e) {
      log(e);
    }

    /**
     * Special case!
     * we check if the user exists and then redirect where neccessary
     */

    const store = yield select((state) => state.Auth);
    const isLoggedIn = store.get('loggedIn');

    if (!isLoggedIn) {
      const {
        location: { pathname, search },
      } = window;
      window.sessionStorage.setItem('redirectTo', `${pathname}${search}`);
      window.location.href = userExists
        ? links.oauth().authorize
        : links.oauth({
            redirectUri: window.btoa(window.location.href),
          }).register;
      return;
    }

    const response = yield call(mutateRedeemAccessGraphql, action.payload);
    yield put({
      type: actions.REDEEM_ACCESS_DONE,
      payload: response.data,
    });
    message.success(translate('invite.success'));
    yield put(push(links.project(response.data.redeemAccess.allowedContent).url));
  } catch (e) {
    const { message: errorMessage } = e;
    let payload = false;
    if (errorMessage.indexOf('Unable to find access') !== -1) {
      payload = 'invite.missing_token';
    }
    if (errorMessage.indexOf('access_denied') !== -1) {
      payload = 'invite.access_denied';
    }
    yield put({ type: actions.REDEEM_ACCESS_ERROR, payload });
  } finally {
    if (yield cancelled()) {
      yield put({ type: actions.REDEEM_ACCESS_CANCELLED });
    }
  }
}

export function* redeemAccessErrorMessageGenerator(action) {
  if (action.payload) {
    message.error(translate(action.payload));
    yield delay(3000);
    yield put(replace(links.jobList.url));
  }
}

/* fetch Team saga */
export function* fetchTeam() {
  yield takeEvery([actions.FETCH_TEAM_START], fetchTeamGenerator);
}

/* remove access */
export function* mutateRevoke() {
  yield takeEvery([actions.MUTATE_REVOKE_START], mutateRevokeGenerator);
}

/* grant access */
export function* mutateGrant() {
  yield takeEvery([actions.MUTATE_GRANT_START], mutateGrantGenerator);
}

/* change employer access */
export function* mutateEmployerAccess() {
  yield takeEvery([actions.MUTATE_EMPLOYER_ACCESS_START], mutateEmployerAccessGenerator);
}

/* redeem access */
export function* redeemAccess() {
  yield takeEvery(actions.REDEEM_ACCESS_START, redeemAccessGenerator);
}

function* redeemError() {
  yield takeLatest(actions.REDEEM_ACCESS_ERROR, redeemAccessErrorMessageGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchTeam),
    spawn(mutateRevoke),
    spawn(mutateGrant),
    spawn(mutateEmployerAccess),
    spawn(redeemAccess),
    spawn(redeemError),
  ]);
}
