import { all, takeLatest, put, spawn, call, cancelled } from 'redux-saga/effects';
import actions from './actions';
import fetchAdDiscountListGraphql from './graphql';

function* fetchAdDiscountListGenerator() {
  try {
    const response = yield call(fetchAdDiscountListGraphql);
    yield put({
      type: actions.FETCH_AD_DISCOUNT_LIST_DONE,
      payload: response.data.adDiscountList,
    });
  } catch (e) {
    console.log(e);
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.FETCH_AD_DISCOUNT_LIST_DENIED });
    }
    yield put({ type: actions.FETCH_AD_DISCOUNT_LIST_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_AD_DISCOUNT_LIST_CANCELLED,
      });
    }
  }
}

export function* fetchAdDiscountList() {
  yield takeLatest(actions.FETCH_AD_DISCOUNT_LIST_START, fetchAdDiscountListGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchAdDiscountList)]);
}
