import { CompanyTeamMember } from 'types/profile.types';

const createCompanyTeamMemberFromAPIResponse = (
  apiResponse: CompanyTeamMember
): CompanyTeamMember => ({
  ...apiResponse,
  type: 'existing',
});

export default createCompanyTeamMemberFromAPIResponse;
