type Dispatcher = (dispatch: any, action: any) => void;

export type Listener = {
  [key: string]: Dispatcher;
};

export type ListenerList = {
  [key: string]: Listener;
};

type ListenersStore = (listenersList: ListenerList) => Listeners;

type Listeners = {
  find: (action: string) => Dispatcher[] | null;
  list: () => ListenerList;
};

const listenersStore: ListenersStore = (listenersList: ListenerList) => {
  return {
    find: (action: string) => {
      const matches: Dispatcher[] = [];
      Object.keys(listenersList).forEach((key) => {
        const listener: Listener = listenersList[key];
        if (listener[action]) {
          matches.push(listener[action]);
        }
      });
      if (matches.length) {
        return matches;
      }
      return null;
    },
    list: () => listenersList,
  };
};

export default listenersStore;
