/* eslint-disable */
import { Map } from 'immutable';
import { debounce } from 'lodash';

export function clearToken() {
  // localStorage.removeItem('id_token');
  cookie.erase('token');
  cookie.erase('refresh_token');
}

const cookie = {
  set: function (name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
    return cookie;
  },
  get: function (name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  erase: function (name) {
    var date = new Date();
    date.setTime(0);
    date.toUTCString();
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
  },
};

export { cookie };

export function getToken() {
  try {
    // const idToken = localStorage.getItem('id_token');
    const idToken = cookie.get('token');
    const refreshToken = cookie.get('refresh_token');
    return new Map({ idToken, refreshToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export const theme = {
  set: (theme) => {
    if (window.localStorage) {
      const storage = window.localStorage;
      storage.setItem('selectedTheme', theme);
    }
  },
  get: () => {
    if (window.localStorage) {
      const storage = window.localStorage;
      return storage.getItem('selectedTheme');
    }
    return null;
  },
};

export const language = {
  set: (lang) => {
    if (window.localStorage) {
      const storage = window.localStorage;
      storage.setItem('language', lang);
    }
  },
  get: () => {
    if (window.localStorage) {
      const storage = window.localStorage;
      return storage.getItem('language');
    }
    return null;
  },
};

export const declination = (count, string1, string2, string3) => {
  const num = typeof count === 'number' ? count.toString() : count;
  const lastnum = parseInt(num.substring(num.length - 1, num.length));
  const lasttwonum = parseInt(num.substring(num.length - 2, num.length));
  let link_title = '';

  if (lasttwonum <= 20 && lasttwonum >= 11) {
    link_title = string1;
  } else if (lastnum === 1) {
    if (lasttwonum === 11) link_title = string1;
    else link_title = string3;
  } else if (lastnum === 2 || lastnum === 3 || lastnum === 4) {
    link_title = string2;
  } else link_title = string1;

  return link_title;
};

export const LogSetup = () => {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof window.log === 'undefined') {
      window.log = console.log.bind(window.console);
    }
  } else {
    window.log = (data) => {};
  }

  if (process.env.NODE_ENV !== 'production') {
    window.dump = (object) => {
      return <pre>{JSON.stringify(object, null, 2)}</pre>;
    };
  } else {
    window.dump = (object) => {};
  }
};

export const lastAccessTime = {
  get: () => {
    if (window.sessionStorage) {
      if (window.sessionStorage.getItem('lastAccess')) {
        /** same session just return it */
        return new Date(window.sessionStorage.getItem('lastAccess'));
      }
      return null;
    }
    return new Date();
  },
  set: (time) => {
    if (window.sessionStorage && !window.sessionStorage.getItem('lastAccess')) {
      window.sessionStorage.setItem('lastAccess', new Date(time));
    }
  },
};

export const isDemo = () => {
  return !!process.env.REACT_APP_DEMO;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

(function (window) {
  if (process.env.NODE_ENV !== 'production') {
    window.enableLogging = () => {
      window.localStorage.removeItem('disable-redux-logger');
      console.log('Redux logger enabled');
    };
    window.disableLogging = () => {
      window.localStorage.setItem('disable-redux-logger', true);
      console.log('Redux logger disabled');
    };
  }
})(window);

export const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }
  return true;
};

(function (window) {
  window.getDevicePixelRatio = function () {
    var ratio = 1;
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (
      window.screen.systemXDPI !== undefined &&
      window.screen.logicalXDPI !== undefined &&
      window.screen.systemXDPI > window.screen.logicalXDPI
    ) {
      // Only allow for values > 1
      ratio = window.screen.systemXDPI / window.screen.logicalXDPI;
    } else if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    }
    return ratio;
  };
})(window);

export const asyncDebounce = (func, wait) => {
  const debounced = debounce(async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }

  return returnFunc;
};

export const findKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
