import gql from 'graphql-tag';

const profileFragment = gql`
  fragment ProfileFragment on CompanyProfile {
    id
    companyId
    companyName
    website
    pib
    language
    active
    gracePeriodActive
    published
    hidden
    maticniBroj
    profileType
    orderedProfileTypesNew {
      id
      profileType
      profileId
      site
    }
    employeeCountOption {
      id
      labelSr
      labelEn
    }
    companyLogo {
      id
      name
      path
    }
    profileHeader {
      slogan
      backgroundImage {
        id
        name
        path
      }
    }
    aboutUs {
      aboutUsHtml
      videoUrls
      files {
        id
        name
        path
        description
      }
    }
    companyBenefits {
      id
      description
      hidden
      order
      benefitType {
        id
        titleSr
        titleEn
        inputExampleSr
        inputExampleEn
        iconClass
      }
    }
    companyTeamMembers {
      id
      name
      picture {
        id
        name
        path
        description
      }
      workPosition
      workplaceDescription
      videoUrl
    }
    profileBadges {
      id
      badgeType {
        id
        name
        icon {
          id
          name
          path
          description
        }
      }
    }
    selectionProcess {
      selectionProcessHtml
      selectionType
      videoUrls
      selectionDuration
      selectionPhases
      files {
        id
        name
        path
        description
      }
    }
    companyBrands {
      id
      title
      description
      logo {
        id
        name
        path
        description
      }
    }
    companyContactInfo {
      phone
      email
      address
      cityName
      countryId
      countryName
    }
    companyAddresses {
      id
      address
    }
    socialNetworks {
      facebook
      linkedIn
      instagram
      twitter
      youtube
    }
    profilePosts {
      id
      title
      postHtml
      author
      videoUrls
      files {
        id
        name
        path
        description
      }
    }
  }
`;

const fetchProfileGraphqlQuery = gql`
  query profile($id: Int) {
    companyProfile(id: $id) {
      ...ProfileFragment
    }
  }
  ${profileFragment}
`;

export { fetchProfileGraphqlQuery };

export default fetchProfileGraphqlQuery;
