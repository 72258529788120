import { Component } from 'react';
// import Nprogress from 'nprogress';
// import 'nprogress/nprogress.css';

// Nprogress.configure({ showSpinner: false });

export default class Loader extends Component {
  // constructor(props) {
  //   super(props);
  //   this.Nprogress = Nprogress;
  // }

  componentDidMount() {
    // this.Nprogress.start();
  }

  componentWillUnmount() {
    // this.Nprogress.done();
  }

  render() {
    return null;
  }
}
