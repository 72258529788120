import { fromJS, Map } from 'immutable';
import teamActions from '../team/actions';
import actions from './actions';

const initState = new Map({
  sharePopupVisible: false,
  submitting: false,
  inputRef: undefined,
  editingProject: null,
});

export default function shareReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_VISIBLE:
      return state.set('sharePopupVisible', action.payload);

    case teamActions.MUTATE_GRANT_START:
      return state.set('submitting', true);

    case teamActions.MUTATE_REVOKE_DONE:
    case teamActions.MUTATE_GRANT_DONE: {
      if (state.get('editingProject')) {
        return state
          .set('submitting', false)
          .setIn(['editingProject', 'access'], fromJS(action.payload.access));
      }
      return state;
    }

    case teamActions.MUTATE_GRANT_ERROR:
      return state.set('submitting', false);

    case actions.SET_EDITING_PROJECT:
      return state.set('editingProject', fromJS(action.payload));

    case actions.STORE_REF:
      return state.set('inputRef', action.payload);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
