import { all, takeLatest, spawn, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import actions from 'redux/languageSwitcher/actions';
import mutateUserLocaleGraphql from './graphql';

function* changeUserLocaleGenerator(action) {
  try {
    yield call(mutateUserLocaleGraphql, {
      variables: {
        locale: action.language.locale.toUpperCase(),
      },
    });
    yield call(() => {
      Sentry.configureScope(scope => {
        scope.setTag('locale', action.language.locale);
      });
    });
  } catch (e) {
    log(e);
  }
}

function* changeUserLocale() {
  yield takeLatest(actions.CHANGE_LANGUAGE, changeUserLocaleGenerator);
}

export default function* rootSaga() {
  yield all([spawn(changeUserLocale)]);
}
