let firstLoad = true;

const pageView = (action) => {
  if (firstLoad) {
    firstLoad = false;
    return null;
  }
  return {
    hitType: 'pageview',
    page: action.payload,
  };
};

const loginSuccessEvent = () => ({
  hitType: 'event',
  eventAction: 'login',
  eventCategory: 'User auth',
});

const reduxTrackEvent = (action) => {
  const event = {
    hitType: 'event',
  };
  event.eventCategory = action.payload.category;
  event.eventAction = action.payload.action;
  event.eventLabel = action.payload.label;
  event.eventValue = action.payload.value;
  return event;
};

const eventMap = {
  REDUX_TRACK_EVENT: reduxTrackEvent,
  LOGIN_SUCCESS: loginSuccessEvent,
  '@@router/LOCATION_CHANGE': pageView,
};

export default eventMap;
