import styled from 'styled-components';
import { switchProp } from 'styled-tools';

const Center = styled.div`
  display: block;
  text-align: center;
  * {
    vertical-align: ${switchProp('vertical', {
    true: 'middle',
    false: 'top',
  })};
  }
`;

export default Center;
