import { Map } from 'immutable';
import { ProfileFile, SelectionProcess } from 'types/profile.types';
import { VideoUpdate } from '../aboutUs/types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

export const PROFILE_SELECTION_PROCESS_UPDATE_START = 'PROFILE_SELECTION_PROCESS_UPDATE_START';
export const PROFILE_SELECTION_PROCESS_UPDATE_DONE = 'PROFILE_SELECTION_PROCESS_UPDATE_DONE';
export const PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED =
  'PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED';
export const PROFILE_SELECTION_PROCESS_UPDATE_ERROR = 'PROFILE_SELECTION_PROCESS_UPDATE_ERROR';

export const PROFILE_SELECTION_PROCESS_UPDATE_FIELD = 'PROFILE_SELECTION_PROCESS_UPDATE_FIELD';

export const PROFILE_SELECTION_PROCESS_ADD_VIDEO = 'PROFILE_SELECTION_PROCESS_ADD_VIDEO';
export const PROFILE_SELECTION_PROCESS_UPDATE_VIDEO = 'PROFILE_SELECTION_PROCESS_UPDATE_VIDEO';
export const PROFILE_SELECTION_PROCESS_DELETE_VIDEO = 'PROFILE_SELECTION_PROCESS_DELETE_VIDEO';

export const PROFILE_SELECTION_PROCESS_ADD_FILE = 'PROFILE_SELECTION_PROCESS_ADD_FILE';
export const PROFILE_SELECTION_PROCESS_UPDATE_FILE = 'PROFILE_SELECTION_PROCESS_UPDATE_FILE';
export const PROFILE_SELECTION_PROCESS_DELETE_FILE = 'PROFILE_SELECTION_PROCESS_DELETE_FILE';

export const PROFILE_SELECTION_PROCESS_TOGGLE_SELECTION_TYPE =
  'PROFILE_SELECTION_PROCESS_TOGGLE_SELECTION_TYPE';

export interface ProfileSelectionProcessStateMap extends Map<string, any> {
  selectionProcess: SelectionProcess | null;
  selectionProcessSubmitting: boolean;
  selectionProcessSubmitError: string | null;
  selectionProcessSubmitSuccess: string | null;
}

export interface SelectionProcessFieldUpdateProps {
  field: keyof SelectionProcess;
  value: any;
}

export interface SelectionProcessApiRequestDoneAction {
  type: typeof PROFILE_SELECTION_PROCESS_UPDATE_DONE;
  payload: { selectionProcess: SelectionProcess; successMessage: string | null };
}

export interface SelectionProcessApiRequestAction {
  type: typeof PROFILE_SELECTION_PROCESS_UPDATE_START;
  payload: SelectionProcess;
}

export interface SelectionProcessApiRequestErrorAction {
  type: typeof PROFILE_SELECTION_PROCESS_UPDATE_ERROR;
  payload: string;
}

export type ProfileSelectionProcessState = ImmutableMapInterface<
  ProfileSelectionProcessStateMap,
  string,
  any
>;

export interface SelectionProcessFileUpdateAction {
  type:
    | typeof PROFILE_SELECTION_PROCESS_ADD_FILE
    | typeof PROFILE_SELECTION_PROCESS_UPDATE_FILE
    | typeof PROFILE_SELECTION_PROCESS_DELETE_FILE;
  payload: ProfileFile;
}

export interface SelectionProcessVideoAddAction {
  type: typeof PROFILE_SELECTION_PROCESS_ADD_VIDEO;
}

export interface VideoDelete {
  index: number;
}

export interface SelectionProcessVideoDeleteAction {
  type: typeof PROFILE_SELECTION_PROCESS_DELETE_VIDEO;
  payload: VideoDelete;
}

export interface SelectionProcessVideoUpdateAction {
  type: typeof PROFILE_SELECTION_PROCESS_UPDATE_VIDEO;
  payload: VideoUpdate;
}

export interface SelectionProcessFieldUpdateAction {
  type: typeof PROFILE_SELECTION_PROCESS_UPDATE_FIELD;
  payload: SelectionProcessFieldUpdateProps;
}

export interface SelectionProcessNoPayloadAction {
  type: typeof PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED;
}

export interface SelectionProcessToggleSelectionTypeAction {
  type: typeof PROFILE_SELECTION_PROCESS_TOGGLE_SELECTION_TYPE;
  payload: 'interview' | 'test';
}
