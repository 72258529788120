import { Listener } from '../listenersStore';

const eventsListeners: Listener = {
  'refresh/events': (dispatch: any, action: any) => {
    dispatch({
      type: 'APPLICATION_EVENTS_FETCH_START',
      payload: action.payload,
    });
  },
};

export default eventsListeners;
