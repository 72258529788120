import { Map } from 'immutable';
import {
  CompanyContactInfo,
  CompanyProfileCountry,
  EmployeeCountOption,
  ProfileSocialNetworks
} from 'types/profile.types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

export const PROFILE_CONTACT_SECTION_UPDATE_START = 'PROFILE_CONTACT_SECTION_UPDATE_START';
export const PROFILE_CONTACT_SECTION_UPDATE_DONE = 'PROFILE_CONTACT_SECTION_UPDATE_DONE';
export const PROFILE_CONTACT_SECTION_UPDATE_CANCELLED = 'PROFILE_CONTACT_SECTION_UPDATE_CANCELLED';
export const PROFILE_CONTACT_SECTION_UPDATE_ERROR = 'PROFILE_CONTACT_SECTION_UPDATE_ERROR';

export const PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START =
  'PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START';
export const PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE =
  'PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE';
export const PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED =
  'PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED';
export const PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR =
  'PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR';

export const PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START =
  'PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START';
export const PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE =
  'PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE';
export const PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED =
  'PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED';
export const PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR =
  'PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR';

export const PROFILE_CONTACT_SECTION_STATE_UPDATE_WEBSITE =
  'PROFILE_CONTACT_SECTION_STATE_UPDATE_WEBSITE';
export const PROFILE_CONTACT_SECTION_STATE_UPDATE_EMPLOYEE_COUNT_OPTION =
  'PROFILE_CONTACT_SECTION_STATE_UPDATE_EMPLOYEE_COUNT_OPTION';

export const PROFILE_CONTACT_SECTION_STATE_UPDATE_CONTACT_INFO_FIELD =
  'PROFILE_CONTACT_SECTION_STATE_UPDATE_CONTACT_INFO_FIELD';
export const PROFILE_CONTACT_SECTION_STATE_UPDATE_SOCIAL_NETWORKS_FIELD =
  'PROFILE_CONTACT_SECTION_STATE_UPDATE_SOCIAL_NETWORKS_FIELD';

export const PROFILE_CONTACT_SECTION_STATE_UPDATE_COMPANY_ADDRESS =
  'PROFILE_CONTACT_SECTION_STATE_UPDATE_COMPANY_ADDRESS';

export const PROFILE_CONTACT_SECTION_STATE_ADD_COMPANY_ADDRESS =
  'PROFILE_CONTACT_SECTION_STATE_ADD_COMPANY_ADDRESS';

export const PROFILE_CONTACT_SECTION_STATE_REMOVE_COMPANY_ADDRESS =
  'PROFILE_CONTACT_SECTION_STATE_REMOVE_COMPANY_ADDRESS';

export interface ContactSectionSubmitProps {
  profileId: number | null;
  website: string;
  employeeCountOption: EmployeeCountOption | null;
  contactInfo: CompanyContactInfo | null;
  socialNetworks: ProfileSocialNetworks | null;
  companyAddresses: Array<CompanyAddress>;
}

export interface CompanyAddress {
  id: string;
  address: string;
}

/* STATE INTERFACES */
export interface ContactSectionStateMap extends Map<string, any> {
  countries: CompanyProfileCountry[];
  employeeCountOptions: EmployeeCountOption[];

  website: string;
  employeeCountOption: EmployeeCountOption | null;
  contactInfo: CompanyContactInfo | null;
  companyAddresses: Array<CompanyAddress>;
  socialNetworks: ProfileSocialNetworks | null;

  contactSectionUpdating: boolean;
  contactSectionUpdateError: string | null;
  contactSectionUpdateSuccess: string | null;

  countriesFetching: boolean;
  countriesFetchError: string | null;

  employeeCountOptionsFetching: boolean;
  employeeCountOptionsFetchError: string | null;
}
export type ContactSectionState = ImmutableMapInterface<ContactSectionStateMap, string, any>;

/* STATE INTERFACES */
export interface ContactSectionUpdateWebsiteAction {
  type: typeof PROFILE_CONTACT_SECTION_STATE_UPDATE_WEBSITE;
  payload: string;
}

export interface ContactSectionUpdateEmployeeCountAction {
  type: typeof PROFILE_CONTACT_SECTION_STATE_UPDATE_EMPLOYEE_COUNT_OPTION;
  payload: EmployeeCountOption | null;
}

/* Update Contact Info */
export interface ContactSectionUpdateContactInfoFieldProps {
  field: keyof CompanyContactInfo;
  value: string | number;
}

export interface ContactSectionUpdateContactInfoFieldAction {
  type: typeof PROFILE_CONTACT_SECTION_STATE_UPDATE_CONTACT_INFO_FIELD;
  payload: ContactSectionUpdateContactInfoFieldProps;
}

/* Update social networks */
export interface ContactSectionUpdateSocialNetworksFieldProps {
  field: keyof ProfileSocialNetworks;
  value: string | number;
}

export interface ContactSectionRemoveCompanyAddressPayload {
  companyAddress: CompanyAddress;
  index: number;
}

export interface ContactSectionUpdateCompanyAddressPayload {
  companyAddress: CompanyAddress;
  value: string;
  index: number;
}

export interface ContactSectionUpdateCompanyAddressProps {
  type: typeof PROFILE_CONTACT_SECTION_STATE_UPDATE_COMPANY_ADDRESS;
  payload: ContactSectionUpdateCompanyAddressPayload;
}

export interface ContactSectionAddCompanyAddress {
  type: typeof PROFILE_CONTACT_SECTION_STATE_ADD_COMPANY_ADDRESS;
}

export interface ContactSectionUpdateSocialNetworksFieldAction {
  type: typeof PROFILE_CONTACT_SECTION_STATE_UPDATE_SOCIAL_NETWORKS_FIELD;
  payload: ContactSectionUpdateSocialNetworksFieldProps;
}

export interface ContactSectionUpdateActionPayload {
  type: typeof PROFILE_CONTACT_SECTION_UPDATE_START;
  payload: {
    profileId: number | null;
  };
}

/* API INTERFACES */
export interface ContactSectionNoPayloadAction {
  type:
    | typeof PROFILE_CONTACT_SECTION_UPDATE_DONE
    | typeof PROFILE_CONTACT_SECTION_UPDATE_CANCELLED
    | typeof PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START
    | typeof PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED
    | typeof PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START
    | typeof PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED;
  payload?: { successMessage: string | null };
}

export interface ContactSectionApiErrorAction {
  type:
    | typeof PROFILE_CONTACT_SECTION_UPDATE_ERROR
    | typeof PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR
    | typeof PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR;
  payload: string;
}

export interface ContactSectionFetchCountriesDoneAction {
  type: typeof PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE;
  payload: CompanyProfileCountry[];
}

export interface ContactSectionFetchEmployeeCountOptionsDoneAction {
  type: typeof PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE;
  payload: CompanyProfileCountry[];
}

export interface ContactSectionRemoveCompanyAddressAction {
  type: typeof PROFILE_CONTACT_SECTION_STATE_REMOVE_COMPANY_ADDRESS;
  payload: ContactSectionRemoveCompanyAddressPayload;
}
