import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { AttachmentPayload, ProfileFile, ProfilePost } from 'types/profile.types';

const profilePostUpdate = (payload: ProfilePost) => {
  const variables = { ...payload };

  if (variables.files?.length) {
    variables.attachments = variables.files
      .filter((file) => file.file && file.type === 'new')
      .map(
        (file: any): AttachmentPayload => ({
          attachment: file.file,
          description: file.description || '',
        })
      );

    variables.files = variables.files
      .filter((file) => file.type !== 'new')
      .map((file: any): ProfileFile => omit(file, '__typename', 'type') as any);
  }

  const mutation = gql`
    mutation companyProfileProfilePostMutation(
      $profileId: Int!
      $attachments: [CompanyProfileAttachmentInput!]
      $author: String
      $files: [CompanyProfileFileInput]
      $id: Int
      $postHtml: String
      $title: String
      $videoUrls: [String]
    ) {
      companyProfileProfilePostMutation(
        id: $profileId
        post: {
          attachments: $attachments
          author: $author
          files: $files
          id: $id
          postHtml: $postHtml
          title: $title
          videoUrls: $videoUrls
        }
      ) {
        author
        files {
          id
          description
          path
        }
        id
        postHtml
        title
        videoUrls
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

const profilePostDelete = (variables: ProfilePost) => {
  const mutation = gql`
    mutation companyProfileDeleteProfilePost($profileId: Int!, $id: Int!) {
      companyProfileDeleteProfilePost(id: $profileId, postId: $id) {
        author
        files {
          id
          path
          description
        }
        id
        postHtml
        title
        videoUrls
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export { profilePostUpdate, profilePostDelete };
