import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';
import FormData from 'form-data';
import config from 'config';

const sendFeedbackRequest = (data) => {
  const formData = new FormData();
  formData.append('feedback_option', data.feedback_option);
  formData.append('feedback_text', data.feedback_text);
  formData.append('feedback_url', data.feedback_url);
  return axios({
    method: 'post',
    url: `${config.apiUrl}/feedback`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const isFeedbackGivenRequest = () => axios({
  method: 'get',
  url: `${config.apiUrl}/is-feedback-given`,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export { sendFeedbackRequest, isFeedbackGivenRequest };
