const jobActions = {
  /* job list actions */
  FETCH_JOBS: 'FETCH_JOBS',
  DONE_FETCHING: 'DONE_FETCHING',
  ERROR_FETCHING: 'ERROR_FETCHING',
  JOBS_LOADING_DONE: 'JOBS_LOADING_DONE',
  CANCEL_FETCH: 'CANCEL_FETCH',
  FETCH_CANCELLED: 'FETCH_CANCELLED',

  FETCH_JOBS_COUNT_START: 'FETCH_JOBS_COUNT_START',
  FETCH_JOBS_COUNT_DONE: 'FETCH_JOBS_COUNT_DONE',
  FETCH_JOBS_COUNT_ERROR: 'FETCH_JOBS_COUNT_ERROR',
  FETCH_JOBS_COUNT_CANCELLED: 'FETCH_JOBS_COUNT_CANCELLED',

  /* single job actions */
  JOB_FETCH: 'JOB_FETCH',
  JOB_FETCHING: 'JOB_FETCHING',
  JOB_FETCHING_DONE: 'JOB_FETCHING_DONE',
  JOB_FETCHING_ERROR: 'JOB_FETCHING_ERROR',
  JOB_FETCHING_DENIED: 'JOB_FETCHING_DENIED',
  JOB_LOADING_DONE: 'JOB_LOADING_DONE',
  JOB_FETCH_CANCEL: 'JOB_FETCH_CANCEL',
  JOB_FETCH_CANCELLED: 'JOB_FETCH_CANCELLED',
  JOB_CLEAR: 'JOB_CLEAR',

  END_SELECTION_SELECT_JOB: 'END_SELECTION_SELECT_JOB',
  END_SELECTION_MUTATION_START: 'END_SELECTION_MUTATION_START',
  END_SELECTION_MUTATION_DONE: 'END_SELECTION_MUTATION_DONE',
  END_SELECTION_MUTATION_ERROR: 'END_SELECTION_MUTATION_ERROR',
  END_SELECTION_MUTATION_CANCELLED: 'END_SELECTION_MUTATION_CANCELLED',

  FETCH_ORDERED_AD_DATA_START: 'FETCH_ORDERED_AD_DATA_START',
  FETCH_ORDERED_AD_DATA_DONE: 'FETCH_ORDERED_AD_DATA_DONE',
  FETCH_ORDERED_AD_DATA_ERROR: 'FETCH_ORDERED_AD_DATA_ERROR',
  FETCH_ORDERED_AD_DATA_CANCELLED: 'FETCH_ORDERED_AD_DATA_CANCELLED',

  MUTATE_JOB_DEACTIVATE_START: 'MUTATE_JOB_DEACTIVATE_START',
  MUTATE_JOB_DEACTIVATE_DONE: 'MUTATE_JOB_DEACTIVATE_DONE',
  MUTATE_JOB_DEACTIVATE_ERROR: 'MUTATE_JOB_DEACTIVATE_ERROR',
  MUTATE_JOB_DEACTIVATE_CANCELLED: 'MUTATE_JOB_DEACTIVATE_CANCELLED',

  fetchOrderedAdData: (payload) => ({
    type: jobActions.FETCH_ORDERED_AD_DATA_START,
    payload,
  }),

  clearApplicationFilters: () => ({
    type: jobActions.APPLICATION_FILTER_CLEAR,
  }),

  fetchApplicationFilters: (jobId) => ({
    type: jobActions.APPLICATION_FILTER_FETCH,
    payload: jobId,
  }),  

  clearCurrentJob: () => ({
    type: jobActions.JOB_CLEAR,
  }),

  fetchJobs: (variables, recache) => ({
    type: jobActions.FETCH_JOBS,
    payload: {
      variables,
      recache,
    },
  }),

  fetchJobsCount: (variables, recache) => ({
    type: jobActions.FETCH_JOBS_COUNT_START,
    payload: {
      variables,
      recache,
    },
  }),

  fetchJob: (variables, recache) => ({
    type: jobActions.JOB_FETCH,
    payload: {
      variables,
      recache,
    },
  }),

  endSelection: (job, result) => ({
    type: jobActions.END_SELECTION_MUTATION_START,
    payload: {
      job,
      result,
    },
  }),

  endSelectionSelectJob: (payload) => ({
    type: jobActions.END_SELECTION_SELECT_JOB,
    payload,
  }),


  deactivateJob: (payload, callback) => ({
    type: jobActions.MUTATE_JOB_DEACTIVATE_START,
    payload,
    callback
  })
};
export default jobActions;
