import styled from 'styled-components';
import { palette } from 'styled-theme';

const StyledAlert = component => styled(component)`
  &.ant-alert-error {
    background: ${palette('background', 70)};
  }
  &.ant-alert-info {
    background-color: ${palette('background', 75)};
    border: ${palette('borderStyle', 7)};
    .ant-alert-message {
      color: ${palette('foreground', 0)};
    }
  }
`;

export default StyledAlert;
