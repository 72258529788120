import { Map } from 'immutable';
import { CompanyBrand } from 'types/profile.types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

/* UPDATE BRAND ACTION TYPES */
export const COMPANY_BRAND_UPDATE_START = 'COMPANY_BRAND_UPDATE_START';
export const COMPANY_BRAND_UPDATE_DONE = 'COMPANY_BRAND_UPDATE_DONE';
export const COMPANY_BRAND_UPDATE_CANCELLED = 'COMPANY_BRAND_UPDATE_CANCELLED';
export const COMPANY_BRAND_UPDATE_ERROR = 'COMPANY_BRAND_UPDATE_ERROR';

/* DELETE BRAND ACTION TYPES */
export const COMPANY_BRAND_DELETE_START = 'COMPANY_BRAND_DELETE_START';
export const COMPANY_BRAND_DELETE_DONE = 'COMPANY_BRAND_DELETE_DONE';
export const COMPANY_BRAND_DELETE_CANCELLED = 'COMPANY_BRAND_DELETE_CANCELLED';
export const COMPANY_BRAND_DELETE_ERROR = 'COMPANY_BRAND_DELETE_ERROR';

/* BRAND STATE ACTION TYPES */
export const COMPANY_BRAND_STATE_ADD_BRAND = 'COMPANY_BRAND_STATE_ADD_BRAND';
export const COMPANY_BRAND_STATE_UPDATE_BRAND = 'COMPANY_BRAND_STATE_UPDATE_BRAND';
export const COMPANY_BRAND_STATE_DELETE_BRAND = 'COMPANY_BRAND_STATE_DELETE_BRAND';

/* ACTIVE BRAND ACTION TYPES */
export const COMPANY_BRAND_ACTIVE_BRAND_SET_ACTIVE_BRAND =
  'COMPANY_BRAND_ACTIVE_BRAND_SET_ACTIVE_BRAND';
export const COMPANY_BRAND_ACTIVE_BRAND_UPDATE_FIELD = 'COMPANY_BRAND_ACTIVE_BRAND_UPDATE_FIELD';

export const COMPANY_BRANDS_RESET_MESSAGES = 'COMPANY_BRANDS_RESET_MESSAGES';

export interface CompanyBrandStateMap extends Map<string, any> {
  companyBrands: CompanyBrand[];
  activeBrand: CompanyBrand | null;
  brandSubmitting: boolean;
  brandSubmitError: string | null;
  brandSubmitSuccess: string | null;
  brandDeleting: boolean;
  brandDeleteError: string | null;
}

export interface ActiveCompanyBrandFieldUpdateProps {
  field: string;
  value: any;
}

export type CompanyBrandState = ImmutableMapInterface<CompanyBrandStateMap, string, any>;

export interface CompanyBrandApiRequestDoneAction {
  type:
    | typeof COMPANY_BRAND_UPDATE_DONE
    | typeof COMPANY_BRAND_DELETE_DONE;
  payload: {
    brand: CompanyBrand;
    brands: CompanyBrand[];
    successMessage: string | null;
  };
}

export interface CompanyBrandApiRequestAction {
  type:
    | typeof COMPANY_BRAND_UPDATE_START
    | typeof COMPANY_BRAND_DELETE_START;
  payload: CompanyBrand;
}

export interface CompanyBrandApiRequestErrorAction {
  type:
    | typeof COMPANY_BRAND_UPDATE_ERROR
    | typeof COMPANY_BRAND_DELETE_ERROR;
  payload: string;
}

export interface CompanyBrandStateUpdateAction {
  type:
    | typeof COMPANY_BRAND_STATE_ADD_BRAND
    | typeof COMPANY_BRAND_STATE_UPDATE_BRAND
    | typeof COMPANY_BRAND_STATE_DELETE_BRAND;
  payload: CompanyBrand;
}

export interface SetActiveCompanyBrandAction {
  type: typeof COMPANY_BRAND_ACTIVE_BRAND_SET_ACTIVE_BRAND;
  payload: CompanyBrand | null;
}

export interface ActiveCompanyBrandFieldUpdateAction {
  type: typeof COMPANY_BRAND_ACTIVE_BRAND_UPDATE_FIELD;
  payload: ActiveCompanyBrandFieldUpdateProps;
}

export interface CompanyBrandNoPayloadAction {
  type:
    | typeof COMPANY_BRAND_UPDATE_CANCELLED
    | typeof COMPANY_BRAND_DELETE_CANCELLED;
}

export interface CompanyBrandsResetMessages {
  type: typeof COMPANY_BRANDS_RESET_MESSAGES;
}

export type CompanyBrandActions =
  | CompanyBrandApiRequestAction
  | CompanyBrandApiRequestDoneAction
  | CompanyBrandApiRequestErrorAction
  | CompanyBrandStateUpdateAction
  | ActiveCompanyBrandFieldUpdateAction
  | SetActiveCompanyBrandAction
  | CompanyBrandNoPayloadAction
  | CompanyBrandsResetMessages;
