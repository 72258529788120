import client from 'graphql/client';
import gql from 'graphql-tag';

const mutateColorSchemeGraphql = payload => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const mutation = gql`
    mutation changeColorScheme($colorScheme: String) {
      changeColorScheme(colorScheme: $colorScheme)
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

export default mutateColorSchemeGraphql;
