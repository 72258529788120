import { Map } from 'immutable';
import {
  PROFILE_MY_COMPANY_SECTION_UPDATE_START,
  PROFILE_MY_COMPANY_SECTION_UPDATE_DONE,
  PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR,
  PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED,
  PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_FIELD,
  MyCompanySectionState,
  PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_LOGO,
  PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_BACKGROUND_IMAGE,
} from './types';
import { ProfileState, ProfileActions } from '../types';

const initState: MyCompanySectionState = Map<string, any>({
  companyName: '',
  companyLogo: null,
  backgroundImage: null,

  myCompanySectionUpdating: false,
  myCompanySectionUpdateError: null,
  myCompanySectionUpdateSuccess: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    case PROFILE_MY_COMPANY_SECTION_UPDATE_START:
      return state
        .set('myCompanySectionUpdating', true)
        .set('myCompanySectionUpdateError', null)
        .set('myCompanySectionUpdateSuccess', null);
    case PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR:
      return state
        .set('myCompanySectionUpdating', false)
        .set('myCompanySectionUpdateError', action.payload);
    case PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED:
      return state.set('myCompanySectionUpdating', false);
    case PROFILE_MY_COMPANY_SECTION_UPDATE_DONE:
      return state
        .set('myCompanySectionUpdating', false)
        .set('companyName', action.payload.companyName)
        .set('companyLogo', action.payload.companyLogo)
        .set('backgroundImage', action.payload.backgroundImage)
        .set('myCompanySectionUpdateSuccess', action.payload.successMessage);
    case PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_FIELD:
      return state.set(action.payload.field, action.payload.value);
    case PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_LOGO:
      return state.set('companyLogo', action.payload);
    case PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_BACKGROUND_IMAGE:
      return state.set('backgroundImage', action.payload);
    default:
      return state;
  }
};

export default initState;

export { initState, reducer };
