import { isDemo } from 'helpers/utility';
import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({ idToken: null, loggedIn: false, logoutInProgress: false });

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREDENTIALS_CHANGE:
      return state.set('credentials', action.payload);
    case actions.LOGIN_SUCCESS:
      return state.set('loggedIn', true);
    case actions.LOGOUT:
      if (action.payload && action.payload.redirect) {
        window.sessionStorage.setItem('redirectTo', action.payload.redirect);
      }
      return isDemo() ? initState : state.set('logoutInProgress', true);
    default:
      return state;
  }
}
