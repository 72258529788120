import MockAdapter from 'axios-mock-adapter';
import config from 'config';
import { application, banner, emailTemplates, filters, tags, token, user } from 'demo/mocks';
import { isDemo } from 'helpers/utility';

const mocker = axios => {
  // This sets the mock adapter on the default instance
  if (isDemo()) {
    const mock = new MockAdapter(axios);
    mock.onPost(/\/api\/login/).reply(200, token);

    mock.onGet(/\/api\/user/).reply(200, user);

    mock.onGet(`${config.apiUrl}/emailTemplates`).reply(200, emailTemplates);

    const filtersUrl = new RegExp('job/([0-9]+)/filters');
    mock.onGet(filtersUrl).reply(requestConfig => {
      const id = requestConfig.url.match(filtersUrl)[1];
      return [200, filters(id)];
    });

    const projecFiltersUrl = new RegExp('project/([0-9]+)/filters');
    mock.onGet(projecFiltersUrl).reply(requestConfig => {
      const id = requestConfig.url.match(projecFiltersUrl)[1];
      return [200, filters(id)];
    });

    const converterUrl = new RegExp(`${config.converterUrl}/snapshot/([0-9]+)`);
    mock.onGet(converterUrl).reply(() => [200, { FileUrl: '/1' }]);

    mock.onGet('/html/flowpaper_ui.html').passThrough();

    mock.onGet(`${config.apiUrl}/get_tags`).reply(
      () =>
        new Promise(resolve => {
          resolve([200, tags]);
        })
    );

    mock.onGet(`${config.apiUrl}/announcement`).reply(
      () =>
        new Promise(resolve => {
          resolve([200, banner]);
        })
    );

    mock.onPost(`${config.apiUrl}/all_applications`).reply(
      () =>
        new Promise(resolve => {
          resolve([
            200,
            application,
            {
              numfound: application.length,
            },
          ]);
        })
    );

    mock.onGet(`${config.apiUrl}/anonymous/job-submission-picker`).reply(
      () =>
        new Promise(resolve => {
          resolve([
            200,
            'new'
          ]);
        })
    );
  }
};

export default mocker;
