import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { CompanyBenefit } from 'types/profile.types';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import fetchCompanyBenefitTypesGraphql from './graphql';
import apiSyncCompanyBenefitsRequest from './requests';
import {
  COMPANY_BENEFITS_SYNC_CANCELLED,
  COMPANY_BENEFITS_SYNC_DONE,
  COMPANY_BENEFITS_SYNC_ERROR,
  COMPANY_BENEFITS_SYNC_START,
  COMPANY_BENEFIT_TYPES_FETCH_CANCELLED,
  COMPANY_BENEFIT_TYPES_FETCH_DONE,
  COMPANY_BENEFIT_TYPES_FETCH_ERROR,
  COMPANY_BENEFIT_TYPES_FETCH_START
} from './types';

const Nprogress = require('nprogress');

function* syncCompanyBenefitsGenerator({ payload }: any): any {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const storeState = yield select((s) => s.NewProfile);
    const companyBenefits = storeState.get('companyBenefits');
    const response = yield call(apiSyncCompanyBenefitsRequest, payload.profileId, companyBenefits);
    yield put({
      type: COMPANY_BENEFITS_SYNC_DONE,
      payload: {
        companyBenefits: response.data.companyProfileBenefitsMutation.map(
          (apiResBenefit: CompanyBenefit) => ({
            ...apiResBenefit,
            benefitTypeId: apiResBenefit?.benefitType?.id || null,
          })
        ),
        successMessage: translate('profile.form.saved'),
      },
    });
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    yield put({ type: COMPANY_BENEFITS_SYNC_ERROR, payload: translate('error.tryAgainMessage') });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: COMPANY_BENEFITS_SYNC_CANCELLED,
      });
    }
  }
}

function* fetchBenefiTypesGenerator(): any {
  try {
    const response = yield call(fetchCompanyBenefitTypesGraphql);
    yield put({
      type: COMPANY_BENEFIT_TYPES_FETCH_DONE,
      payload: response.data.companyProfileCompanyBenefitTypes,
    });
  } catch (e) {
    yield put({ type: COMPANY_BENEFIT_TYPES_FETCH_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    if (yield cancelled()) {
      yield put({
        type: COMPANY_BENEFIT_TYPES_FETCH_CANCELLED,
      });
    }
  }
}

function* fetchBenefitTypes() {
  yield takeLatest(COMPANY_BENEFIT_TYPES_FETCH_START, fetchBenefiTypesGenerator);
}

function* syncCompanyBenefits() {
  yield takeLatest(COMPANY_BENEFITS_SYNC_START, syncCompanyBenefitsGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchBenefitTypes), spawn(syncCompanyBenefits)]);
}
