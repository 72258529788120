import links from 'helpers/links';
import { Map } from 'immutable';
import { pathToRegexp } from 'path-to-regexp';
import actions from './actions';

const initState = new Map({
  lastPageBeforeLogout: null,
  settingsFloaterVisible: false,
  rightSidebarVisible: false,
  profileMaximized: false,
  rightSidebarSlidenOut: true,
  faqSidebarVisible: false,
  miniSidebar: false,
});

export default function navigationReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGOUT: {
      const lastPageBeforeLogout = (action.payload && action.payload.lastPageBeforeLogout) || null;
      return state.set('lastPageBeforeLogout', lastPageBeforeLogout);
    }
    case actions.TOGGLE_SETTINGS_FLOATER:
      return state.set('settingsFloaterVisible', action.payload);

    case actions.TOGGLE_MAXIMIZE:
      return state.set('profileMaximized', action.payload);

    case actions.TOGGLE_SIDEBAR_SLIDEOUT:
      return state.set('rightSidebarSlidenOut', action.payload);

    case actions.TOGGLE_MINI_SIDEBAR:
      return state.set('miniSidebar', action.payload);

    case '@@router/LOCATION_CHANGE': {
      let newState = state;
      // right sidebar
      if (action.payload.location.hash.indexOf('rightSidebar') === -1) {
        newState = newState.set('rightSidebarVisible', false);
      } else {
        newState = newState.set('rightSidebarVisible', true);
      }

      // faq
      if (action.payload.location.hash.indexOf('faq') === -1) {
        newState = newState.set('faqSidebarVisible', false);
      } else {
        newState = newState.set('faqSidebarVisible', true);
      }

      // settings
      if (action.payload.location.hash.indexOf('settings') === -1) {
        newState = newState.set('settingsFloaterVisible', false);
      }
      const match = pathToRegexp(links.application().pattern).exec(
        action.payload.location.pathname
      );
      if (!match || !match[2]) {
        newState = newState.set('profileMaximized', false);
      }

      // // compaany profile
      // const matchCompanyProfile = pathToRegexp(links.newProfile().url).exec(
      //   action.payload.location.pathname
      // );

      // newState = newState.set('miniSidebar', !!matchCompanyProfile);

      return newState;
    }
    default:
      return state;
  }
}
