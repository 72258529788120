import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';
import FormData from 'form-data';
import config from 'config';

const uploadDocumentRequest = (file) => {
  const formData = new FormData();
  formData.append('document', file);
  return axios({
    method: 'post',
    url: `${config.apiUrl}/document/upload`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const createApplicationRequest = data => axios({
  method: 'post',
  url: `${config.apiUrl}/applications`,
  data,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export { uploadDocumentRequest, createApplicationRequest };
