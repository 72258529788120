import { Map } from 'immutable';
import maintenanceActions from './actions';

const initState = Map({
  maintenance: false,
});

export default function maintenanceReducer(state = initState, action: any) {
  switch (action.type) {
    case maintenanceActions.TOGGLE_MAINTENANCE:
      return state.set('maintenance', action.payload);
    default:
      return state;
  }
}
