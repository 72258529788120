import config from 'config.js';
import { isDemo } from 'helpers/utility';
import storeObject from 'redux/storeDeclaration';

const { baseUrl, oauthUrl, posloviBaseUrl, poslonautBaseUrl, wwwHrlabUrl } = config;

const isEng = () => {
  const store = storeObject.getStore();
  const state = store.getState();
  const { LanguageSwitcher } = state;
  const { locale } = LanguageSwitcher.get('language');
  return locale === 'en';
};

const urlNormalizeString = (text) => {
  if (typeof text !== 'string') {
    return text;
  }
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/,+|-+|\s+|\/+/g, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
};

const links = {
  newJob: {
    url: isDemo() ? '#' : `${baseUrl}/ats-prijave/new`,
  },
  jobList: {
    url: `${baseUrl}/ats-prijave`,
    pattern: `${baseUrl}/ats-prijave`,
  },
  projects: () => ({
    url: `${baseUrl}/ats-prijave`,
    pattern: `${baseUrl}/ats-prijave`,
  }),
  statistics: (projectId) => ({
    url: `${baseUrl}/ats-prijave/statistike${projectId ? `/${projectId}` : ''}`,
    pattern: `${baseUrl}/ats-prijave/statistike`,
    patternProject: `${baseUrl}/ats-prijave/statistike/:jobId`,
  }),
  projectStatistics: (projectId) => ({
    url: `${baseUrl}/ats-prijave/pozicija/${projectId}/statistike`,
    pattern: `${baseUrl}/ats-prijave/pozicija/:projectId(\\d+)/statistike`,
  }),
  job: (jobId) => ({
    url: `${baseUrl}/ats-prijave/${jobId}`,
    pattern: `${baseUrl}/ats-prijave/:jobId`,
    redirectPattern: `${baseUrl}/ats-prijave/:jobId([0-9]+)/:applicationId([0-9]+)?`,
  }),
  project: (projectId) => ({
    url: `${baseUrl}/ats-prijave/pozicija/${projectId}`,
    pattern: `${baseUrl}/ats-prijave/pozicija/:projectId`,
  }),
  applications: {
    pattern: `${baseUrl}/ats-prijave/pozicija/:projectId([0-9]+)/:applicationId([0-9]+)?`,
  },
  application: (projectId, applicationId) => ({
    url: `${baseUrl}/ats-prijave/pozicija/${projectId}/${applicationId}`,
    pattern: `${baseUrl}/ats-prijave/pozicija/:projectId(\\d+)/:applicationId?`,
    singleApplicationPattern: `${baseUrl}/ats-prijave/pozicija/:projectId(\\d+)/:applicationId`,
  }),
  selection: (projectId) => ({
    url: `${baseUrl}/ats-prijave/selekcije/${projectId}`,
    pattern: `${baseUrl}/ats-prijave/selekcije/:projectId([0-9]+)`,
  }),
  teams: {
    url: `${baseUrl}/podesavanja/timski-rad`,
    pattern: `${baseUrl}/podesavanja/timski-rad`,
  },
  billing: {
    url: `${baseUrl}/fakture`,
    pattern: `${baseUrl}/fakture`,
  },
  billingSettings: {
    url: `${baseUrl}/naplata`,
    pattern: `${baseUrl}/naplata`,
  },
  allApplications: {
    url: `${baseUrl}/ats-sve-prijave`,
    pattern: `${baseUrl}/ats-sve-prijave`,
  },
  settings: {
    url: `${baseUrl}/podesavanja`,
    pattern: `${baseUrl}/podesavanja`,
  },
  profile: {
    url: `${baseUrl}/moj-stari-profil`,
    pattern: `${baseUrl}/moj-stari-profil`,
    promo: `${posloviBaseUrl}/poslodavci/moj-stari-profil?designed_force=1`,
    hrBrending: `${posloviBaseUrl}/poslodavci/moj-stari-profil?b_force=1`,
    editor: `${posloviBaseUrl}/poslodavci/moj-stari-profil`,
  },
  newProfile: () => ({
    url: `${baseUrl}/moj-profil`,
    pattern: `${baseUrl}/moj-profil`,
    promo: `${posloviBaseUrl}/poslodavci/moj-profil?designed_force=1`,
    hrBrending: `${posloviBaseUrl}/poslodavci/moj-profil?b_force=1`,
    editor: `${posloviBaseUrl}/poslodavci/moj-profil`,
  }),
  newProfileEditor: (profileId) => ({
    url: `${baseUrl}/moj-profil/${profileId}`,
    pattern: `${baseUrl}/moj-profil/:profileId([0-9]+)`,
  }),
  oauth: (params) => ({
    /* eslint-disable */
    authorize: `${oauthUrl}/authorize?response_type=code&client_id=ats&scope=identity ads:read ads:write applications:read applications:write email_templates:read email_templates:write employer_profile:read employer_profile:write password:write&redirect_uri=${
      /* eslint-enable */
      location.origin // eslint-disable-line
    }${baseUrl}/validate`, // eslint-disable-line
    logout: `${oauthUrl}/logout`,
    /* eslint-disable */
    requestLogin: `${oauthUrl}/authorize?response_type=code&client_id=ats&scope=identity ads:read ads:write applications:read applications:write email_templates:read email_templates:write employer_profile:read employer_profile:write password:write&redirect_uri=${
      /* eslint-enable */
      location.origin // eslint-disable-line
    }${baseUrl}/validate`, // eslint-disable-line,
    login: `${oauthUrl}/login`,
    changePassword: `${oauthUrl}/changePassword?redirectTo=${location.href}`, // eslint-disable-line
    register: `${oauthUrl}/register${
      params && params.redirectUri ? `?redirect_uri=${params.redirectUri}` : ''
    }`,
  }),
  jobPosting: (uid) => ({
    url: `${posloviBaseUrl}/poslodavci/formular-za-placeni-oglas${uid ? `/${uid}` : ''}`,
  }),
  poslonautJobPosting: () => ({
    url: `${poslonautBaseUrl}/naruci-oglas`,
  }),
  poslonautJobPreview: ({ jobTitle, jobId }) => ({
    url: `${poslonautBaseUrl}/posao/${urlNormalizeString(jobTitle)}/${jobId}`,
  }),
  invite: () => ({
    verify: `${baseUrl}/invite/verify`,
  }),
  packages: () => ({
    url: `${baseUrl}/stanje-zakupljenog-paketa`,
    pattern: `${baseUrl}/stanje-zakupljenog-paketa`,
  }),
  packagesCombo: () => ({
    url: `${baseUrl}/stanje-zakupljenog-paketa?form_ad_placement_type=multiple`,
    pattern: `${baseUrl}/stanje-zakupljenog-paketa?form_ad_placement_type=multiple`,
  }),
  jobOrderReceipt: (receiptId) => ({
    pattern: `${baseUrl}/pregled-narucenog-oglasa/:jobOrderId`,
    url: `${baseUrl}/pregled-narucenog-oglasa/${receiptId}`,
  }),
  posloviBaseUrl: () => ({
    url: posloviBaseUrl,
  }),
  fbPromo: () => ({
    url: `${wwwHrlabUrl}${isEng() ? '/en' : '/sr'}/promocija-oglasa-na-fejsbuku/`,
  }),
  eb: () => ({
    url: `${wwwHrlabUrl}${isEng() ? '/en' : '/sr'}/eb-studio`,
  }),
  tk: () => ({
    url: `${wwwHrlabUrl}${isEng() ? '/en' : '/sr'}/targetiranje-kandidata`,
  }),
  jobSubmission: (hash, step) => ({
    pattern: `${baseUrl}/formular-za-placeni-oglas/:hash?/:step?`,
    createPattern: `${baseUrl}/formular-za-placeni-oglas-kreiraj`,
    url: `${baseUrl}/formular-za-placeni-oglas${hash ? `/${hash}` : ''}${step ? `/${step}` : ''}`,
  }),
  jobRenew: (jobId) => ({
    pattern: `${baseUrl}/ponovno-aktiviranje-oglasa/:jobId([0-9]+)`,
    url: `${baseUrl}/ponovno-aktiviranje-oglasa/${jobId}`,
  }),
  payments: () => ({
    pattern: `${baseUrl}/transaction`,
    url: `${baseUrl}/transaction`,
  }),
  jobActivated: () => ({
    pattern: `${baseUrl}/ats-prijave/:jobId([0-9]+)/activated`,
  }),
  onlineInterviews: () => ({
    pattern: `${baseUrl}/online-intervju`,
    url: `${baseUrl}/online-intervju`,
  }),
  crm: (companyId) => ({
    url: `https://crm-poslovi.infostud.com/poslodavac/${companyId}`,
  }),
  blackFriday: () => ({
    url: 'https://www.hrlab.rs/sr/hr-blog/novosti/planetarno-popularni-crni-petak-stize-i-kod-nas',
  }),
  cvDatabase: () => ({
    pattern: `${baseUrl}/baza-kandidata`,
    url: `${baseUrl}/baza-kandidata`,
  }),
  helloworldizer: (jobLink) =>
    jobLink.replace('https://poslovi.infostud.com/', 'https://www.helloworld.rs/'),
  loyaltyReport: () => ({
    pattern: `${baseUrl}/izvestaj-lojalnosti`,
    url: `${baseUrl}/izvestaj-lojalnosti`,
  }),
  loyaltyClub: () => ({
    url: `${baseUrl}/klub-lojalnosti`,
    pattern: `${baseUrl}/klub-lojalnosti`,
  }),
  activateJob: () => ({
    pattern: `${baseUrl}/aktiviraj_oglas/:jobId([0-9]+)/:hash`,
  }),
  startujAdForm: () => ({
    url: 'https://startuj.com/postavite-oglas',
  }),
};

export default links;
