import { Map } from 'immutable';
import { ReduxAction } from 'types/redux.types';
import actions from './actions';

const initState = Map({
  visible: false,
  loadingAvailableJobs: false,
  availableJobs: undefined,
  cityList: [],
  countryList: [],
});

export default function upsaleReducer(state = initState, action: ReduxAction) {
  switch (action.type) {
    case actions.TOGGLE_UPSALE_MODAL:
      return state.set('visible', action.payload);
    case actions.FETCH_AVAILABLE_JOBS_START:
      return state.set('loadingAvailableJobs', true);
    case actions.FETCH_AVAILABLE_JOBS_ERROR:
    case actions.FETCH_AVAILABLE_JOBS_CANCELLED:
      return state.set('loadingAvailableJobs', false);
    case actions.FETCH_AVAILABLE_JOBS_DONE:
      return state.withMutations((newState) => {
        return newState
          .set('availableJobs', action.payload.availableJobsForUpsale)
          .set('cityList', action.payload.lists.cities)
          .set('countryList', action.payload.lists.countries)
          .set('loadingAvailableJobs', false);
      });

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
