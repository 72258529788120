import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchAdDiscountListGraphql = () => {
  const query = gql`
    query adDiscountList {
      adDiscountList {
        adType
        minimumAdCount
        discountPercentage
      }
    }
  `;

  return client.query({
    query,
  });
};

export default fetchAdDiscountListGraphql;
