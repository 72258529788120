import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';
import config from 'config';

const spreadsheetRequests = ({ applicationIds }) => axios({
  method: 'post',
  url: `${config.apiUrl}/applications/spreadsheet`,
  responseType: 'blob', // important
  data: {
    applicationIds,
  },
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export default spreadsheetRequests;
