import { ProfilePost } from 'types/profile.types';
import { APIResponseProfilePost } from './types';

const createPostFromAPIResponse = (apiResponse: APIResponseProfilePost): ProfilePost => ({
  ...apiResponse,
  postType: 'existing',
  videoUrls: apiResponse?.videoUrls || [],
  files: apiResponse.files.map((profileFile) => ({
    ...profileFile,
    type: 'existing',
  })),
});

export default createPostFromAPIResponse;
