const actions = {
  LOADING_USER: 'LOADING_USER',
  USER_LOADED: 'USER_LOADED',
  ERROR_LOADING_USER: 'ERROR_LOADING_USER',

  LOAD_USER_DATA_START: 'LOAD_USER_DATA_START',
  LOAD_USER_DATA_DONE: 'LOAD_USER_DATA_DONE',
  LOAD_USER_DATA_ERROR: 'LOAD_USER_DATA_ERROR',
  LOAD_USER_DATA_CANCELLED: 'LOAD_USER_DATA_CANCELLED',

  UPDATE_BILLING_START: 'UPDATE_BILLING_START',
  UPDATE_BILLING_DONE: 'UPDATE_BILLING_DONE',
  UPDATE_BILLING_ERROR: 'UPDATE_BILLING_ERROR',
  UPDATE_BILLING_CANCELLED: 'UPDATE_BILLING_CANCELLED',

  MUTATE_USER_START: 'MUTATE_USER_START',
  MUTATE_USER_DONE: 'MUTATE_USER_DONE',
  MUTATE_USER_ERROR: 'MUTATE_USER_ERROR',
  MUTATE_USER_CANCELLED: 'MUTATE_USER_CANCELLED',

  loadUser: (payload) => ({
    type: actions.LOAD_USER_DATA_START,
    payload,
  }),

  updateBillingInformation: (payload) => ({
    type: actions.UPDATE_BILLING_START,
    payload,
  }),

  mutateUser: (payload) => ({
    type: actions.MUTATE_USER_START,
    payload,
  }),
};
export default actions;
