import styled from 'styled-components';
import { palette } from 'styled-theme';

const StyledSteps = (ComponentName) => styled(ComponentName)`
  &.ant-steps-item:not(.ant-steps-item-active) {
    .ant-steps-item-icon {
      background: ${palette('background', 88)};
      > span {
        color: ${palette('foreground', 0)};
      }
    }
  }

  .ant-steps-item-icon {
    line-height: 2rem;
  }
`;

export default StyledSteps;
