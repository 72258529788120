const projectsActions = {
  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',

  UPDATE_PROJECTS_LIST: 'UPDATE_PROJECTS_LIST',

  setSearchParams: (payload: String) => ({
    type: projectsActions.SET_SEARCH_PARAMS,
    payload,
  }),
};

export default projectsActions;
