import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  profile: null,
  loading: true,
  countryNames: null,
  employeeCountOptions: null,
  submitting: false,
  denied: false,
});

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case actions.PROFILE_FETCH_START:
      return state.set('loading', true).set('denied', false);

    case actions.PROFILE_FETCH_ERROR:
    case actions.PROFILE_FETCH_CANCELLED:
      return state.set('loading', false);

    case actions.PROFILE_FETCH_DENIED:
      return state.set('denied', true);

    case actions.PROFILE_FETCH_DONE:
      return state.set('loading', false).set('profile', action.payload);

    case actions.PROFILE_COUNTRY_NAMES_FETCH_DONE:
      return state.set('countryNames', action.payload);

    case actions.PROFILE_EMPLOYEE_COUNT_OPTIONS_DONE:
      return state.set('employeeCountOptions', action.payload);

    case actions.PROFILE_UPDATE_START:
      return state.set('submitting', true);

    case actions.PROFILE_UPDATE_ERROR:
    case actions.PROFILE_UPDATE_CANCELLED:
      return state.set('submitting', false);

    case actions.PROFILE_UPDATE_DONE:
      return state.set('submitting', false).set('profile', action.payload);

    case actions.PROFILE_LOGO_UPLOAD_DONE:
      if (action.payload.length) {
        const profileData = action.payload[0].data;
        return state.set('profile', profileData);
      }
      return state;

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
