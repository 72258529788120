import { IncompleteJobToContinue } from "containers/ContinueJobNotification/ContinueJobNotification";

const actions = {
  FETCH_CONTINUABLE_JOBS_START: 'FETCH_CONTINUABLE_JOBS_START',
  FETCH_CONTINUABLE_JOBS_DONE: 'FETCH_CONTINUABLE_JOBS_DONE',
  HIDE_CONTINUABLE_JOB: 'HIDE_CONTINUABLE_JOB',

  checkForContinuableJobs: () => ({
    type: actions.FETCH_CONTINUABLE_JOBS_START,
  }),

  hideContinuableJob: (uid: IncompleteJobToContinue['uid']) => ({
    type: actions.HIDE_CONTINUABLE_JOB,
    payload: uid,
  }),
};

export default actions;