const actions = {
  TOGGLE_MODAL_CREATE_APPLICATION: 'TOGGLE_MODAL_CREATE_APPLICATION',
  SET_JOB_ID: 'SET_JOB_ID',

  UPLOAD_DOCUMENT_START: 'UPLOAD_DOCUMENT_START',
  UPLOAD_DOCUMENT_DONE: 'UPLOAD_DOCUMENT_DONE',
  UPLOAD_DOCUMENT_ERROR: 'UPLOAD_DOCUMENT_ERROR',
  UPLOAD_DOCUMENT_CANCELLED: 'UPLOAD_DOCUMENT_CANCELLED',

  CLEAR_UPLOADED_DOCUMENTS: 'CLEAR_UPLOADED_DOCUMENTS',
  REMOVE_UPLOADED_DOCUMENT: 'REMOVE_UPLOADED_DOCUMENT',
  CHANGE_DOCUMENT_TYPE: 'CHANGE_DOCUMENT_TYPE',

  CREATE_APPLICATION_START: 'CREATE_APPLICATION_START',
  CREATE_APPLICATION_DONE: 'CREATE_APPLICATION_DONE',
  CREATE_APPLICATION_ERROR: 'CREATE_APPLICATION_ERROR',
  CREATE_APPLICATION_CANCELLED: 'CREATE_APPLICATION_CANCELLED',

  LOAD_FOR_EDITING: 'LOAD_FOR_EDITING',

  submitApplication: (values, files, project) => ({
    type: actions.CREATE_APPLICATION_START,
    payload: {
      values,
      files,
      project,
    },
  }),

  clearUploadedDocuments: () => ({
    type: actions.CLEAR_UPLOADED_DOCUMENTS,
  }),

  changeDocumentType: (file, value) => ({
    type: actions.CHANGE_DOCUMENT_TYPE,
    payload: {
      file,
      value,
    },
  }),

  removeDocumentFromUploaded: payload => ({
    type: actions.REMOVE_UPLOADED_DOCUMENT,
    payload,
  }),

  uploadDocuments: payload => ({
    type: actions.UPLOAD_DOCUMENT_START,
    payload,
  }),

  toggleModal: payload => ({
    type: actions.TOGGLE_MODAL_CREATE_APPLICATION,
    payload,
  }),

  setJobId: payload => ({
    type: actions.SET_JOB_ID,
    payload,
  }),

  loadApplicationForEditing: payload => ({
    type: actions.LOAD_FOR_EDITING,
    payload
  })
};
export default actions;
