import { Map } from 'immutable';
import { ProfileFile, ProfileVideo } from 'types/profile.types';
import { ProfileActions, ProfileState } from '../types';
import {
  ProfileSelectionProcessState,
  PROFILE_SELECTION_PROCESS_ADD_FILE,
  PROFILE_SELECTION_PROCESS_ADD_VIDEO,
  PROFILE_SELECTION_PROCESS_DELETE_FILE,
  PROFILE_SELECTION_PROCESS_DELETE_VIDEO,
  PROFILE_SELECTION_PROCESS_TOGGLE_SELECTION_TYPE,
  PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED,
  PROFILE_SELECTION_PROCESS_UPDATE_DONE,
  PROFILE_SELECTION_PROCESS_UPDATE_ERROR,
  PROFILE_SELECTION_PROCESS_UPDATE_FIELD,
  PROFILE_SELECTION_PROCESS_UPDATE_FILE,
  PROFILE_SELECTION_PROCESS_UPDATE_START,
  PROFILE_SELECTION_PROCESS_UPDATE_VIDEO
} from './types';

const initState: ProfileSelectionProcessState = Map<string, any>({
  selectionProcess: null,
  selectionProcessSubmitting: false,
  selectionProcessSubmitError: null,
  selectionProcessSubmitSuccess: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    // API Call
    case PROFILE_SELECTION_PROCESS_UPDATE_START:
      return state
        .set('selectionProcessSubmitting', true)
        .set('selectionProcessSubmitError', null)
        .set('selectionProcessSubmitSuccess', null);
    case PROFILE_SELECTION_PROCESS_UPDATE_ERROR:
      return state
        .set('selectionProcessSubmitting', false)
        .set('selectionProcessSubmitError', action.payload);
    case PROFILE_SELECTION_PROCESS_UPDATE_CANCELLED:
      return state.set('selectionProcessSubmitting', false);
    case PROFILE_SELECTION_PROCESS_UPDATE_DONE:
      return state
        .set('selectionProcessSubmitting', false)
        .set('selectionProcess', action.payload.selectionProcess)
        .set('selectionProcessSubmitSuccess', action.payload.successMessage);

    case PROFILE_SELECTION_PROCESS_UPDATE_FIELD:
      return state.set('selectionProcess', {
        ...state.get('selectionProcess'),
        [action.payload.field]: action.payload.value,
      });

    case PROFILE_SELECTION_PROCESS_ADD_VIDEO: {
      const selectionProcess = state.get('selectionProcess');
      return state.set('selectionProcess', {
        ...selectionProcess,
        videoUrls: [null, ...(selectionProcess.videoUrls || [])],
      });
    }

    case PROFILE_SELECTION_PROCESS_UPDATE_VIDEO: {
      const selectionProcess = state.get('selectionProcess');
      const videoIndex = selectionProcess.videoUrls.findIndex(
        (v: ProfileVideo, index: number) => index === action.payload.index
      );
      if (videoIndex < 0) {
        return state;
      }
      return state.set('selectionProcess', {
        ...selectionProcess,
        videoUrls: Object.assign([], selectionProcess.videoUrls, {
          [videoIndex]: action.payload.link,
        }),
      });
    }

    case PROFILE_SELECTION_PROCESS_DELETE_VIDEO: {
      const selectionProcess = state.get('selectionProcess');
      return state.set('selectionProcess', {
        ...selectionProcess,
        videoUrls: selectionProcess.videoUrls.filter(
          (v: ProfileVideo, index: number) => index !== action.payload.index
        ),
      });
    }

    case PROFILE_SELECTION_PROCESS_ADD_FILE: {
      const selectionProcess = state.get('selectionProcess');
      return state.set('selectionProcess', {
        ...selectionProcess,
        files: [action.payload, ...selectionProcess.files],
      });
    }

    case PROFILE_SELECTION_PROCESS_UPDATE_FILE: {
      const selectionProcess = state.get('selectionProcess');
      const fileIndex = selectionProcess.files.findIndex(
        (v: ProfileFile) => v.type === action.payload.type && v.id === action.payload.id
      );
      if (fileIndex < 0) {
        return state;
      }
      return state.set('selectionProcess', {
        ...selectionProcess,
        files: Object.assign([], selectionProcess.files, {
          [fileIndex]: action.payload,
        }),
      });
    }

    case PROFILE_SELECTION_PROCESS_DELETE_FILE: {
      const selectionProcess = state.get('selectionProcess');
      return state.set('selectionProcess', {
        ...selectionProcess,
        files: selectionProcess.files.filter(
          (v: ProfileFile) => v.type !== action.payload.type || v.id !== action.payload.id
        ),
      });
    }

    case PROFILE_SELECTION_PROCESS_TOGGLE_SELECTION_TYPE: {
      const selectionProcess = state.get('selectionProcess');
      const selectionType = selectionProcess.selectionType || [];
      const index = selectionType.indexOf(action.payload);
      if (index === -1) {
        return state.set('selectionProcess', {
          ...selectionProcess,
          selectionType: [...selectionType, action.payload],
        });
      }
      return state.set('selectionProcess', {
        ...selectionProcess,
        selectionType: selectionType.filter((fval: string) => fval !== action.payload),
      });
    }

    default:
      return state;
  }
};

export { initState, reducer };
