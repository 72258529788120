import { AboutUs } from 'types/profile.types';

const createAboutUsFromAPIResponse = (apiResponse: AboutUs): AboutUs => ({
  ...apiResponse,
  videoUrls:
    apiResponse?.videoUrls || [],
  files: apiResponse.files.map((profileFile) => ({
    ...profileFile,
    type: 'existing',
  })),
});

export default createAboutUsFromAPIResponse;
