import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import gql from 'graphql-tag';
import { all, takeLatest, put, spawn, call } from 'redux-saga/effects';
import client from 'graphql/client';

export interface CVDatabaseFilterDictionaryItem {
  key: string;
  name: string;
}

export interface CVDatabaseFilter {
  key: string;
  dictionary: CVDatabaseFilterDictionaryItem[];
}

type SliceState = {
  filters: CVDatabaseFilter[] | null;
};

const initialState: SliceState = {
  filters: null,
};

const cvDatabaseSlice = createSlice({
  name: 'cvDatabase',
  initialState,
  reducers: {
    fetchFiltersStart: () => {},
    fetchFiltersFinished: (state, action: PayloadAction<CVDatabaseFilter[]>) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', (state) => {
      state = initialState;
    });
  },
});

// saga

const fetchFiltersGraphql = () => {
  const query = gql`
    query cvDatabaseFilters {
      cvDatabaseFilters {
        key
        dictionary {
          key
          name
        }
      }
    }
  `;

  return client.query({
    query,
  });
};

function* fetchFiltersGenerator() {
  // @ts-ignore
  const response = yield call(fetchFiltersGraphql);
  yield put(actions.fetchFiltersFinished(response.data.cvDatabaseFilters));
}

export function* fetchFilters() {
  yield takeLatest(actions.fetchFiltersStart.type, fetchFiltersGenerator);
}

export function* rootSaga() {
  yield all([spawn(fetchFilters)]);
}

// exports

const { actions, reducer } = cvDatabaseSlice;
export const { fetchFiltersStart, fetchFiltersFinished } = actions;
export default reducer;
