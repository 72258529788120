import { OperationVariables } from 'apollo-boost';
import gql from 'graphql-tag';
import client from 'graphql/client';
import { applicationBasicFragment, commentsFragment } from 'redux/application/queries';
import accessFragment from 'redux/job/queries';

interface Payload {
  variables: object;
  recache: Boolean;
}

const fetchProjectGraphql = (payload: Payload) => {
  const params: OperationVariables = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query fetchProject(
      $id: Int!
      $rating: [Rating]
      $selectionLevel: [SelectionLevel]
      $preselectionPassed: PreselectionPassed
      $rejected: Rejected
      $candidateName: String
      $sortField: String
      $sortDirection: String
      $tags: [Int]
      $email: String
    ) {
      project(id: $id) {
        id
        name
        timeCreated
        applicationCount
        customSelectionLabels {
          id
          projectId
          selectionLevel
          name
          hidden
        }
        accessType
        access {
          ...AccessFragment
        }
        applications(
          rating: $rating
          selectionLevel: $selectionLevel
          preselectionPassed: $preselectionPassed
          rejected: $rejected
          candidateName: $candidateName
          sortField: $sortField
          sortDirection: $sortDirection
          tags: $tags
          email: $email
        ) {
          id
          ...BasicFields
          order
          applicationTime
          fromSite
          comments {
            ...CommentsFields
          }
          events(detailed: false) {
            id
            eventType
          }
        }
        jobs {
          id
          title
          status
          online
          application_count
          url
          applicationDeliveryMethod
          receiptId
          accessType
          paymentId
          showHwModal
          primaryCategoryId
          siteVisibility
          cities {
            id
          }
          regions {
            id
          }
          preselection {
            id
            preselectionMaxScore
            preselectionQuestion {
              id
              question
              commentAllowed
              genericQuestion
              genericQuestionType
              preselectionQuestionAnswers {
                id
                answer
              }
            }
          }
          statistics {
            JobApplicationCount
            JobTotalViews
            JobViewsPoslovi {
              id
              views
              viewDate
            }
            JobViewsHW {
              id
              views
              viewDate
            }
            JobViewsStartuj {
              id
              views
              viewDate
            }
            JobApplicationsPoslovi {
              id
              applications
              applicationDate
            }
            JobApplicationsHW {
              id
              applications
              applicationDate
            }
            JobApplicationsStartuj {
              id
              applications
              applicationDate
            }
          }
        }
      }
    }
    ${applicationBasicFragment}
    ${accessFragment}
    ${commentsFragment}
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const mutateCustomSelectionLabelsGraphql = (payload: Payload) => {
  const params: OperationVariables = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const mutation = gql`
    mutation projectCustomSelectionLabels(
      $customSelectionLabel: [ProjectSelectionLabelInput]
      $projectId: Int
    ) {
      projectCustomSelectionLabels(
        customSelectionLabel: $customSelectionLabel
        projectId: $projectId
      ) {
        id
        customSelectionLabels {
          id
          projectId
          selectionLevel
          name
          hidden
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

export default fetchProjectGraphql;
export { mutateCustomSelectionLabelsGraphql };
