import gql from 'graphql-tag';
import client from 'graphql/client';
import { applicationBasicFragment, commentsFragment } from '../application/queries';
import accessFragment from './queries';

const fetchJobsCountGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query fetchJobs($sortField: String, $title: String, $sortDirection: String) {
      jobs(sortField: $sortField, title: $title, sortDirection: $sortDirection) {
        count
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

/* job list fetch */
const fetchJobsGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query fetchJobs(
      $sortField: String
      $title: String
      $sortDirection: String
      $offset: Int
      $limit: Int
    ) {
      jobs(
        sortField: $sortField
        title: $title
        sortDirection: $sortDirection
        offset: $offset
        limit: $limit
      ) {
        count
        job {
          id
          title
          companyDisplayName
          status
          online
          datePosted
          expireDate
          dateTimeCreated
          employmentType
          jobEmploymentType {
            category
            name
          }
          application_count
          applicationDeliveryMethod
          accessType
          accessAssignedDateTime
          selectionEnded
          untilFilled
          url
          unread
          siteVisibility
          location
          duration
          jobType
          access {
            ...AccessFragment
          }
        }
      }
    }
    ${accessFragment}
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const fetchJobWithProjects = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query jobProjects($id: Int) {
      jobProjects(id: $id) {
        projects {
          id
        }
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

/* single job fetch */
const fetchJobGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query fetchJob(
      $id: Int
      $rating: [Rating]
      $selectionLevel: [SelectionLevel]
      $preselectionPassed: PreselectionPassed
      $rejected: Rejected
      $candidateName: String
      $sortField: String
      $sortDirection: String
      $tags: [Int]
      $email: String
    ) {
      job(id: $id) {
        id
        title
        status
        online
        application_count
        url
        applicationDeliveryMethod
        receiptId
        accessType
        siteVisibility
        cities {
          id
        }
        regions {
          id
        }
        preselection {
          id
          preselectionMaxScore
          preselectionQuestion {
            id
            question
            commentAllowed
            genericQuestion
            genericQuestionType
            preselectionQuestionAnswers {
              id
              answer
            }
          }
        }
        customSelectionLabels {
          id
          jobId
          selectionLevel
          name
          hidden
        }
        access {
          ...AccessFragment
        }
        applications(
          rating: $rating
          selectionLevel: $selectionLevel
          preselectionPassed: $preselectionPassed
          rejected: $rejected
          candidateName: $candidateName
          sortField: $sortField
          sortDirection: $sortDirection
          tags: $tags
          email: $email
        ) {
          id
          ...BasicFields
          order
          comments {
            ...CommentsFields
          }
          events(detailed: false) {
            id
            eventType
          }
        }
        statistics {
          JobApplicationCount
          JobTotalViews
          JobViews {
            id
            views
            viewDate
          }
          JobApplications {
            id
            applications
            applicationDate
          }
        }
        projects {
          id
          name
        }
      }
    }
    ${applicationBasicFragment}
    ${accessFragment}
    ${commentsFragment}
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const mutateSelectionEndedGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const mutation = gql`
    mutation endSelection($id: Int, $notification: SelectionEndedNotification) {
      endSelection(id: $id, notification: $notification) {
        id
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const fetchOrderedAdData = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query orderedAdData($jobId: Int!) {
      orderedAdData(jobId: $jobId) {
        trusted
        paymentMethod
        socialPosting
        delayed
        duringWorkhours
        datePosted
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const mutateDeactivateJob = (variables) => {
  const mutation = gql`
    mutation deactivateJob($jobId: Int!) {
      deactivateJob(jobId: $jobId) {
        id
        status
        online
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export {
  fetchJobWithProjects,
  fetchJobGraphql,
  fetchJobsGraphql,
  fetchJobsCountGraphql,
  mutateSelectionEndedGraphql,
  fetchOrderedAdData,
  mutateDeactivateJob,
};

