import { Map } from 'immutable';
import { CompanyTeamMember } from 'types/profile.types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

/* UPDATE TEAM_MEMBER ACTION TYPES */
export const PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START = 'PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START';
export const PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE = 'PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE';
export const PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED =
  'PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED';
export const PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR = 'PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR';

/* DELETE TEAM_MEMBER ACTION TYPES */
export const PROFILE_COMPANY_TEAM_MEMBER_DELETE_START = 'PROFILE_COMPANY_TEAM_MEMBER_DELETE_START';
export const PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE = 'PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE';
export const PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED =
  'PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED';
export const PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR = 'PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR';

/* TEAM_MEMBER STATE ACTION TYPES */
export const PROFILE_COMPANY_TEAM_MEMBER_STATE_ADD_TEAM_MEMBER =
  'PROFILE_COMPANY_TEAM_MEMBER_STATE_ADD_TEAM_MEMBER';
export const PROFILE_COMPANY_TEAM_MEMBER_STATE_UPDATE_TEAM_MEMBER =
  'PROFILE_COMPANY_TEAM_MEMBER_STATE_UPDATE_TEAM_MEMBER';
export const PROFILE_COMPANY_TEAM_MEMBER_STATE_DELETE_TEAM_MEMBER =
  'PROFILE_COMPANY_TEAM_MEMBER_STATE_DELETE_TEAM_MEMBER';

/* ACTIVE TEAM_MEMBER ACTION TYPES */
export const PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_SET_ACTIVE_TEAM_MEMBER =
  'PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_SET_ACTIVE_TEAM_MEMBER';
export const PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_UPDATE_FIELD =
  'PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_UPDATE_FIELD';

export const PROFILE_COMPANY_TEAM_MEMBER_RESET_MESSAGES =
  'PROFILE_COMPANY_TEAM_MEMBER_RESET_MESSAGES';

export interface CompanyTeamMemberStateMap extends Map<string, any> {
  companyTeamMembers: CompanyTeamMember[];
  activeTeamMember: CompanyTeamMember | null;
  teamMemberSubmitting: boolean;
  teamMemberSubmitError: string | null;
  teamMemberSubmitSuccess: string | null;
  teamMemberDeleting: boolean;
  teamMemberDeleteError: string | null;
}

export interface ActiveCompanyTeamMemberFieldUpdateProps {
  field: keyof CompanyTeamMember;
  value: any;
}

export type CompanyTeamMemberState = ImmutableMapInterface<CompanyTeamMemberStateMap, string, any>;

export interface CompanyTeamMemberApiRequestDoneAction {
  type:
    | typeof PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE
    | typeof PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE;
  payload: {
    teamMember: CompanyTeamMember;
    teamMembers: CompanyTeamMember[];
    successMessage: string | null;
  };
}

export interface CompanyTeamMemberApiRequestAction {
  type:
    | typeof PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START
    | typeof PROFILE_COMPANY_TEAM_MEMBER_DELETE_START;
  payload: CompanyTeamMember;
}

export interface CompanyTeamMemberApiRequestErrorAction {
  type:
    | typeof PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR
    | typeof PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR;
  payload: string;
}

export interface CompanyTeamMemberStateUpdateAction {
  type:
    | typeof PROFILE_COMPANY_TEAM_MEMBER_STATE_ADD_TEAM_MEMBER
    | typeof PROFILE_COMPANY_TEAM_MEMBER_STATE_UPDATE_TEAM_MEMBER
    | typeof PROFILE_COMPANY_TEAM_MEMBER_STATE_DELETE_TEAM_MEMBER;
  payload: CompanyTeamMember;
}

export interface SetActiveCompanyTeamMemberAction {
  type: typeof PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_SET_ACTIVE_TEAM_MEMBER;
  payload: CompanyTeamMember | null;
}

export interface ActiveCompanyTeamMemberFieldUpdateAction {
  type: typeof PROFILE_COMPANY_TEAM_MEMBER_ACTIVE_TEAM_MEMBER_UPDATE_FIELD;
  payload: ActiveCompanyTeamMemberFieldUpdateProps;
}

export interface CompanyTeamMemberNoPayloadAction {
  type:
    | typeof PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED
    | typeof PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED;
}

export interface CompanyTeamMembersResetAlertMessages {
  type: typeof PROFILE_COMPANY_TEAM_MEMBER_RESET_MESSAGES;
}

export type CompanyTeamMemberActions =
  | CompanyTeamMemberApiRequestAction
  | CompanyTeamMemberApiRequestDoneAction
  | CompanyTeamMemberApiRequestErrorAction
  | CompanyTeamMemberStateUpdateAction
  | ActiveCompanyTeamMemberFieldUpdateAction
  | SetActiveCompanyTeamMemberAction
  | CompanyTeamMemberNoPayloadAction
  | CompanyTeamMembersResetAlertMessages;
