import {
  ProfileOrder,
  ProfilePublishPayload, ProfileTypes
} from 'containers/NewProfile/newProfile.types';
import { Map } from 'immutable';
import { Profile, ProfileBadge } from 'types/profile.types';
import { CompanyAddress } from '../contactSection/types';

import ImmutableMapInterface from '../ImmutableMapInterface.type';

export const PROFILE_FETCH_START = 'NEW_PROFILE_FETCH_START';
export const PROFILE_FETCH_DONE = 'NEW_PROFILE_FETCH_DONE';
export const PROFILE_FETCH_ERROR = 'NEW_PROFILE_FETCH_ERROR';
export const PROFILE_FETCH_CANCELLED = 'NEW_PROFILE_FETCH_CANCELLED';
export const PROFILE_FETCH_DENIED = 'NEW_PROFILE_FETCH_DENIED';

export const ATS_LOGOUT = 'LOGOUT';
export const SET_PROFILE_PREVIEW_TYPE = 'SET_PROFILE_PREVIEW_TYPE';
export const SET_PROFILE_LANGUAGE = 'SET_PROFILE_LANGUAGE';

export const PROFILE_ORDER_START = 'PROFILE_ORDER_START';
export const PROFILE_ORDER_DONE = 'PROFILE_ORDER_DONE';
export const PROFILE_ORDER_CANCELLED = 'PROFILE_ORDER_CANCELLED';
export const PROFILE_ORDER_ERROR = 'PROFILE_ORDER_ERROR';

export const PUBLISH_PROFILE_START = 'PUBLISH_PROFILE_START';
export const PUBLISH_PROFILE_DONE = 'PUBLISH_PROFILE_DONE';
export const PUBLISH_PROFILE_CANCELLED = 'PUBLISH_PROFILE_CANCELELD';
export const PUBLISH_PROFILE_ERROR = 'PUBLISH_PROFILE_ERROR';

export const UNHIDE_PROFILE_START = 'UNHIDE_PROFILE_START';
export const UNHIDE_PROFILE_DONE = 'UNHIDE_PROFILE_DONE';
export const UNHIDE_PROFILE_CANCELLED = 'UNHIDE_PROFILE_CANCELELD';
export const UNHIDE_PROFILE_ERROR = 'UNHIDE_PROFILE_ERROR';

export const CREATE_PROFILE_SNAPSHOT = 'CREATE_PROFILE_SNAPSHOT';

export const TOGGLE_PREVIEW_MODAL = 'TOGGLE_PREVIEW_MODAL';
export const TOGGLE_SUBSECTION_EDITING_IN_PROGRESS = 'TOGGLE_SUBSECTION_EDITING_IN_PROGRESS';

export interface ProfileOrderAction {
  type: typeof PROFILE_ORDER_START | typeof PROFILE_ORDER_DONE;
  payload: ProfileOrder;
}

export interface ProfilePublishAction {
  type: typeof PUBLISH_PROFILE_START;
  payload: ProfilePublishPayload;
}

export interface ProfileFetchNoPayloadAction {
  type:
    | typeof PROFILE_FETCH_START
    | typeof PROFILE_FETCH_CANCELLED
    | typeof PROFILE_FETCH_DENIED
    | typeof PROFILE_ORDER_CANCELLED
    | typeof PUBLISH_PROFILE_START
    | typeof PUBLISH_PROFILE_DONE
    | typeof PUBLISH_PROFILE_CANCELLED
    | typeof UNHIDE_PROFILE_START
    | typeof UNHIDE_PROFILE_DONE
    | typeof CREATE_PROFILE_SNAPSHOT
    | typeof UNHIDE_PROFILE_CANCELLED;
}
export interface ProfileFetchDoneAction {
  type: typeof PROFILE_FETCH_DONE;
  payload: Profile;
}
export interface ProfileFetchErrorAction {
  type:
    | typeof PROFILE_FETCH_ERROR
    | typeof PROFILE_ORDER_ERROR
    | typeof PUBLISH_PROFILE_ERROR
    | typeof UNHIDE_PROFILE_ERROR;
  payload: string;
}

export interface SetProfilePreviewTypeAction {
  type: typeof SET_PROFILE_PREVIEW_TYPE;
  payload: ProfileTypes;
}

export interface SetProfileLanguageAction {
  type: typeof SET_PROFILE_LANGUAGE;
  payload: 'sr' | 'en';
}

export interface LogoutAction {
  type: typeof ATS_LOGOUT;
}

export interface ProfileSnapshotObjMyCompanySection {
  companyName: any;
  companyLogo: any;
  slogan: any;
  backgroundImage: any;
}

export interface ProfileSnapshotObjContactSection {
  contactInfo: any;
  socialNetworks: any;
  website: any;
  employeeCountOption: any;
}

export interface ProfileSnapshotObj {
  myCompanySection: ProfileSnapshotObjMyCompanySection;
  aboutUs: any;
  companyBenefits: any;
  companyTeamMembers: any;
  companyBrands: any;
  selectionProcess: any;
  profilePosts: any;
  contactSection: ProfileSnapshotObjContactSection;
  companyAddresses: Array<CompanyAddress>;
}

export interface ProfileGeneralStateMap extends Map<string, any> {
  id: number | null;
  profileType: ProfileTypes;
  profilePreviewType: ProfileTypes;
  profileSnapshot: string;
  active: boolean;
  published: boolean;
  orderedProfileTypes: string[];
  hidden: boolean;
  language: 'sr' | 'en';
  pib: string;
  maticniBroj: string;
  profileBadges: ProfileBadge[];
  profileFetching: boolean;
  profileFetchError: null | string;
  accessDenied: boolean;

  profileOrderInProgress: boolean;
  profileOrderError: null | string;
  profileOrderDone: boolean;

  publishProfileInProgress: boolean;
  publishProfileError: null | string;
  publishProfileDone: boolean;

  unhideProfileInProgress: boolean;
  unhideProfileError: null | string;
  unhideProfileDone: boolean;
  showPreviewModal: boolean;
  subsectionEditingInProgress: boolean;
}

export type ProfileSite = 'poslovi' | 'hello_world';

export type ProfileGeneralState = ImmutableMapInterface<ProfileGeneralStateMap, string, any>;
