import { Map } from 'immutable';
import { CompanyBrand } from 'types/profile.types';
import { ProfileActions, ProfileState } from '../types';
import {
  CompanyBrandState,
  COMPANY_BRANDS_RESET_MESSAGES,
  COMPANY_BRAND_ACTIVE_BRAND_SET_ACTIVE_BRAND,
  COMPANY_BRAND_ACTIVE_BRAND_UPDATE_FIELD,
  COMPANY_BRAND_DELETE_CANCELLED,
  COMPANY_BRAND_DELETE_DONE,
  COMPANY_BRAND_DELETE_ERROR,
  COMPANY_BRAND_DELETE_START,
  COMPANY_BRAND_STATE_ADD_BRAND,
  COMPANY_BRAND_STATE_DELETE_BRAND,
  COMPANY_BRAND_STATE_UPDATE_BRAND,
  COMPANY_BRAND_UPDATE_CANCELLED,
  COMPANY_BRAND_UPDATE_DONE,
  COMPANY_BRAND_UPDATE_ERROR,
  COMPANY_BRAND_UPDATE_START,
} from './types';

const initState: CompanyBrandState = Map<string, any>({
  companyBrands: [],
  activeBrand: null,
  brandSubmitting: false,
  brandSubmitError: null,
  brandSubmitSuccess: null,
  brandDeleting: false,
  brandDeleteError: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    case COMPANY_BRAND_UPDATE_START:
      return state
        .set('brandSubmitting', true)
        .set('brandSubmitError', null)
        .set('brandSubmitSuccess', null);
    case COMPANY_BRAND_UPDATE_ERROR:
      return state.set('brandSubmitting', false).set('brandSubmitError', action.payload);
    case COMPANY_BRAND_UPDATE_CANCELLED:
      return state.set('brandSubmitting', false);
    case COMPANY_BRAND_UPDATE_DONE:
      return state
        .set('brandSubmitting', false)
        .set('companyBrands', action.payload.brands)
        .set('brandSubmitSuccess', action.payload.successMessage);

    case COMPANY_BRAND_DELETE_START:
      return state.set('brandDeleting', true).set('brandDeleteError', null);
    case COMPANY_BRAND_DELETE_ERROR:
      return state.set('brandDeleting', false).set('brandDeleteError', action.payload);
    case COMPANY_BRAND_DELETE_CANCELLED:
      return state.set('brandDeleting', false);

    case COMPANY_BRAND_DELETE_DONE:
      return state.set('brandDeleting', false).set('companyBrands', action.payload.brands);

    case COMPANY_BRAND_STATE_ADD_BRAND:
      return state
        .set('companyBrands', [...state.get('companyBrands'), action.payload])
        .set('activeBrand', action.payload);

    case COMPANY_BRAND_STATE_UPDATE_BRAND: {
      return state.set(
        'companyBrands',
        Object.assign([], state.get('companyBrands'), {
          [state
            .get('companyBrands')
            .findIndex(
              (v: CompanyBrand) => v.id === action.payload.id && v.type === action.payload.type
            )]: action.payload,
        })
      );
    }

    case COMPANY_BRAND_STATE_DELETE_BRAND:
      return state.set(
        'companyBrands',
        state.get('companyBrands').filter((v: CompanyBrand) => {
          if (v.id === action.payload.id && v.type === action.payload.type) {
            return false;
          }
          return true;
        })
      );

    case COMPANY_BRAND_ACTIVE_BRAND_SET_ACTIVE_BRAND:
      return state.set('activeBrand', action.payload);

    case COMPANY_BRAND_ACTIVE_BRAND_UPDATE_FIELD: {
      const activeBrand = state.get('activeBrand');
      const updatedBrand = {
        ...activeBrand,
        [action.payload.field]: action.payload.value,
      };
      return state.set('activeBrand', updatedBrand);
    }

    case COMPANY_BRANDS_RESET_MESSAGES:
      return state.set('brandSubmitError', null).set('brandSubmitSuccess', null);

    default:
      return state;
  }
};

export default initState;

export { initState, reducer };
