const actions = {
  FETCH_NOTIFICATIONS_START: 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_DONE: 'FETCH_NOTIFICATIONS_DONE',
  FETCH_NOTIFICATIONS_ERROR: 'FETCH_NOTIFICATIONS_ERROR',
  FETCH_NOTIFICATIONS_CANCELLED: 'FETCH_NOTIFICATIONS_CANCELLED',

  TOGGLE_NOTIFICATION_POPUP: 'TOGGLE_NOTIFICATION_POPUP',

  MUTATE_BUBBLE_EXPAND_TIME_START: 'MUTATE_BUBBLE_EXPAND_TIME_START',
  MUTATE_BUBBLE_EXPAND_TIME_DONE: 'MUTATE_BUBBLE_EXPAND_TIME_DONE',
  MUTATE_BUBBLE_EXPAND_TIME_ERROR: 'MUTATE_BUBBLE_EXPAND_TIME_ERROR',
  MUTATE_BUBBLE_EXPAND_TIME_CANCELLED: 'MUTATE_BUBBLE_EXPAND_TIME_CANCELLED',

  MUTATE_MARK_NOTIFICATION_AS_READ_START: 'MUTATE_MARK_NOTIFICATION_AS_READ_START',
  MUTATE_MARK_NOTIFICATION_AS_READ_DONE: 'MUTATE_MARK_NOTIFICATION_AS_READ_DONE',
  MUTATE_MARK_NOTIFICATION_AS_READ_ERROR: 'MUTATE_MARK_NOTIFICATION_AS_READ_ERROR',
  MUTATE_MARK_NOTIFICATION_AS_READ_CANCELLED: 'MUTATE_MARK_NOTIFICATION_AS_READ_CANCELLED',

  toggleNotificationPopup: payload => ({
    type: actions.TOGGLE_NOTIFICATION_POPUP,
    payload,
  }),

  fetchNotifications: payload => ({
    type: actions.FETCH_NOTIFICATIONS_START,
    payload,
  }),

  updateBubbleExpandTime: () => ({
    type: actions.MUTATE_BUBBLE_EXPAND_TIME_START,
  }),

  markNotificationAsRead: payload => ({
    type: actions.MUTATE_MARK_NOTIFICATION_AS_READ_START,
    payload,
  }),
};

export default actions;
