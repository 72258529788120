import styled from 'styled-components';
import { palette } from 'styled-theme';

const mobile = palette('mobile', 0);

const AtsDivider = (Component) => styled(({ large, ...props }) => <Component {...props} />)`
  /* background: ${(props) =>
    props.theme.name === 'atsDark' ? props.theme.palette.dark[3] : '#e8e8e8'}; */
  &.ant-divider-with-text-center:before,
  &.ant-divider-with-text-center:after,
  &.ant-divider-with-text-right:before,
  &.ant-divider-with-text-right:after,
  &.ant-divider-with-text-left:before,
  &.ant-divider-with-text-left:after {
    border-top: ${palette('borderStyle', 2)} !important;
    background: transparent;
  }
  > span {
    color: ${palette('foreground', 7)};
    font-weight: ${(props) => (props.large ? '700' : 'inherit')};
    font-size: ${(props) => (props.large ? '2rem' : 'inherit')};
    @media only screen and (max-width: ${mobile}) {
      font-weight: 400;
      font-size: 1rem;
    }
    > i {
      margin-right: 13px;
    }
    > span {
      color: ${palette('foreground', 7)};
      font-size: ${(props) => (props.large ? '2rem' : 'inherit')};
      @media only screen and (max-width: ${mobile}) {
        font-size: 1rem;
      }
    }
  }
`;

export default AtsDivider;
