import client from 'graphql/client';
import {
  fetchProfileGraphqlQuery,
  fetchCountryNamesQuery,
  fetchEmployeeCountOptionsQuery,
  mutateProfileGraphqQuery,
} from './queries';

const fetchProfileGraphql = () => client.query({
  query: fetchProfileGraphqlQuery,
});

const fetchCountryNamesGraphql = () => client.query({
  query: fetchCountryNamesQuery,
});

const fetchEmployeeCountOptionsGraphql = () => client.query({
  query: fetchEmployeeCountOptionsQuery,
});

const mutateProfileGraphql = ({
  variables: {
    employerProfile: { logoPath, ...rest },
  },
}) => client.mutate({
  mutation: mutateProfileGraphqQuery,
  variables: {
    employerProfile: {
      ...rest,
    },
  },
});

export {
  fetchProfileGraphql,
  mutateProfileGraphql,
  fetchCountryNamesGraphql,
  fetchEmployeeCountOptionsGraphql,
};
