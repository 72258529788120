import { Map } from 'immutable';
import jobActions from 'redux/job/actions';
import teamActions from 'redux/team/actions';
import { ReduxAction } from 'types/redux.types';
import projectsActions from './actions';

const initState = Map({
  projects: [],
  searchParams: Map({
    string: '',
    sortDirection: 'desc',
    sortField: '',
  }),
});

export default function projectReducer(state = initState, action: ReduxAction) {
  switch (action.type) {
    case projectsActions.SET_SEARCH_PARAMS:
      return state.set('searchParams', action.payload);

    case projectsActions.UPDATE_PROJECTS_LIST: {
      const newState = state.set('projects', action.payload.projects);
      return newState;
    }

    case jobActions.MUTATE_JOB_DEACTIVATE_START: {
      return state.set(
        'projects',
        state.get('projects').map((project: any) => {
          project.jobs = project.jobs.map((job: any) => {
            const newJob = job;
            if (action.payload.id === job.id) {
              newJob.status = 'archive';
              newJob.online = false;
            }
            return newJob;
          });
          return project;
        }) as any
      );
    }

    case teamActions.MUTATE_REVOKE_DONE:
    case teamActions.MUTATE_GRANT_DONE: {
      let newState = state;

      /** if on project list update there */
      if (state.get('projects')) {
        newState = newState.set(
          'projects',
          // @ts-ignore
          state.get('projects').map((project: any) => {
            const newproject = project;
            if (project.id === action.payload.id) {
              newproject.access = action.payload.access;
            }
            return newproject;
          })
        );
      }
      return newState;
    }

    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
