import { isDemo } from 'helpers/utility';

const externalConfig =
  typeof atsConfiguration !== 'undefined' ? atsConfiguration : {}; /* eslint-disable-line */
const {
  apiUrl,
  graphqlUrl,
  flexPaperUrl,
  converterUrl,
  flexPaperAssetsUrl,
  posloviBaseUrl,
  poslonautBaseUrl,
  oauthUrl,
  // sentryDsn,
  wwwHrlabUrl,
  socketHrlabUrl
} = externalConfig;

const defaultConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiUrl: apiUrl || process.env.REACT_APP_API_URL,
  graphqlUrl: graphqlUrl || process.env.REACT_APP_GRAPHQL_URL,
  flexPaperUrl: isDemo() ? '/' : flexPaperUrl || process.env.REACT_APP_FLEXPAPER_URL,
  converterUrl: converterUrl || process.env.REACT_APP_CONVERTER_URL,
  flexPaperAssetsUrl: flexPaperAssetsUrl || process.env.REACT_APP_FLEXPAPER_ASSETS_URL,
  posloviBaseUrl: posloviBaseUrl || process.env.REACT_APP_POSLOVI_BASE_URL,
  poslonautBaseUrl: poslonautBaseUrl || process.env.REACT_APP_POSLONAUT_BASE_URL,
  oauthUrl: oauthUrl || process.env.REACT_APP_OAUTH_URL,
  sentryDsn: "https://2e4187c892794300ac99b0bb2b82fb7f@logerr.infostud.cloud/2",
  wwwHrlabUrl: wwwHrlabUrl || process.env.REACT_APP_WWW_HRLAB_URL,
  socketHrlabUrl: socketHrlabUrl || process.env.REACT_APP_SOCKET_HRLAB_URL || 'https://socket.hrlab.rs',
};

export default defaultConfig;

const siteConfig = {
  siteName: 'Theseus ATS',
  siteIcon: 'ion-flash',
  footerText: `Theseus ATS © ${new Date().getFullYear()}`,
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'atsFancy',
};
const language = 'serbian';
export { siteConfig, language, themeConfig };

