import IntlMessages from 'components/utility/IntlMessages';
import links from 'helpers/links';
import { Map } from 'immutable';
import { pathToRegexp } from 'path-to-regexp';
import { ACTIONS } from 'react-joyride';
import storeObject from 'redux/storeDeclaration';
import actions from './actions';

const cancelSteps = () => [
  {
    disableBeacon: true,
    target: '.tour-help-button-step',
    content: <IntlMessages id="tour.help.step" />,
    spotlightClicks: false,
  },
];

const cancelTour = () => ({
  callback: ({ action }) => {
    if (action === ACTIONS.CLOSE || action === ACTIONS.NEXT) {
      storeObject.getStore().dispatch({
        type: actions.RUN_CANCEL_TOUR_CLOSE,
      });
    }
  },
  steps: cancelSteps(),
  run: false,
  continuous: true,
  stepIndex: 0,
  styles: {
    options: {
      primaryColor: '#532757',
    },
  },
  locale: {
    back: <IntlMessages id="tour.back" />,
    close: <IntlMessages id="tour.close" />,
    last: <IntlMessages id="tour.close" />,
    next: <IntlMessages id="tour.next" />,
    skip: <IntlMessages id="tour.skip" />,
  },
});

const initState = new Map({
  run: false,
  params: {},
  stepIndex: 0,
  sectionsParams: new Map({}),
  finished: new Map({}),
  sections: [],
});

export default function tourReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOUR_ADD_SECTION:
      return state.set('sections', [...state.get('sections'), action.payload]);

    case actions.TOUR_SET_SECTION_PARAMS:
      return state.setIn(['sectionsParams', action.payload.section], action.payload.params);

    case actions.VERIFY_CHECKPOINT_DONE:
      if (state.get('run')) {
        return state;
      }
      return state.withMutations((newState) => {
        return newState
          .set('params', state.getIn(['sectionsParams', action.payload.section]))
          .set('run', action.payload.state)
          .setIn(['finished', action.payload.section], !action.payload.state);
      });

    case actions.RUN_CANCEL_TOUR_CLOSE:
      return state.set('run', false);

    case actions.RUN_CANCEL_TOUR:
      return state.withMutations((newState) => {
        return newState
          .set('params', cancelTour())
          .set('stepIndex', 0)
          .set('run', action.payload.state === actions.RUN);
      });

    case actions.TOUR_RUN_SECTION:
      return state.withMutations((newState) => {
        return newState
          .set('params', state.getIn(['sectionsParams', action.payload.section]))
          .set('run', action.payload.state === actions.RUN)
          .setIn(['finished', action.payload.section], action.payload.state !== actions.RUN);
      });

    case actions.TOUR_SET_INDEX:
      return state.set('stepIndex', action.payload);

    case actions.TOUR_SET_PARAMS:
      return state.set('params', action.payload);

    case actions.TOUR_REMOVE_SECTION:
      return state.withMutations((newState) => {
        return newState
          .set(
            'sections',
            state.get('sections').filter((item) => item !== action.payload)
          )
          .set(
            'sectionsParams',
            state.get('sectionsParams').filter((item, key) => key !== action.payload)
          );
      });
    case '@@router/LOCATION_CHANGE': {
      const matchApplications = pathToRegexp(links.application().singleApplicationPattern).exec(
        action.payload.location.pathname
      );
      const matchProject = pathToRegexp(links.project().pattern).exec(
        action.payload.location.pathname
      );
      const matchSelection = pathToRegexp(links.selection().pattern).exec(
        action.payload.location.pathname
      );
      if (matchSelection || matchApplications || matchProject) {
        return state;
      }
      return initState;
    }
    case actions.TOUR_RESET:
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
