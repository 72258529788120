const cacheActions = {
  INVALIDATE_APPLICATIONS: 'INVALIDATE_APPLICATIONS',
  VALIDATE_APPLICATIONS: 'VALIDATE_APPLICATIONS',
  RESET_INVALID_STORE: 'RESET_INVALID_STORE',

  invalidateApplications: payload => ({
    type: cacheActions.INVALIDATE_APPLICATIONS,
    payload,
  }),

  validateApplications: payload => ({
    type: cacheActions.VALIDATE_APPLICATIONS,
    payload,
  }),

  resetInvalidStore: payload => ({
    type: cacheActions.RESET_INVALID_STORE,
    payload,
  }),
};

export default cacheActions;
