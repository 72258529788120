import IntlMessages from 'components/utility/IntlMessages';
import links from 'helpers/links';
import error404svg from 'image/404.svg';
import cloud from 'image/cloud.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const breakAside = palette('mobile', 0);

const Styled404 = styled.div`
  height: 100%;
  color: #fff;
  background: radial-gradient(69.25% 164.91% at 50% 50%, ${palette('rebrand', 1)} 0%, ${palette('rebrand', 0)} 100%);
  .center-vertical {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    text-align: center;
    display: block;
    transform: translate(-50%, -50%);
    max-width: 700px;
    width: 100%;
    @media only screen and (max-width: ${breakAside}) {
      padding: 20px;
    }
  }
  .title {
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 32px;
  }
  .description {
    font-size: 1.5rem;
    margin-bottom: 32px;
  }
  img {
    margin-bottom: 32px;
    @media only screen and (max-width: ${breakAside}) {
      display: none;
    }
  }
  .btn {
    text-transform: uppercase;
    border: 3px solid #fff;
    background: #fff;
    color: #42296a;
    padding: 10px 15px;
    border-radius: 3px;
    margin: 0px 10px 15px;
    transition-duration: 200ms;
    transition-property: all;
    font-weight: 500;
    line-height: 1rem;
    display: inline-block;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
    &.clear {
      color: #fff;
      background: transparent;
      &:hover {
        background: #fff;
        color: #42296a;
        transform: scale(1.1);
      }
    }
  }
  .cloud {
    background-image: url(${cloud});
    height: 222px;
    background-repeat: no-repeat;
    width: 343px;
    display: inline-block;
    transform: scale(0.8);
    @media only screen and (max-width: ${breakAside}) {
      display: none;
    }
    &.one {
      position: fixed;
      top: -10px;
      left: -155px;
    }
    &.two {
      position: fixed;
      top: 38px;
      left: 50px;
    }
    &.three {
      position: fixed;
      bottom: -6px;
      right: -150px;
    }
    &.four {
      position: fixed;
      bottom: 26px;
      right: 50px;
    }
  }
`;

class error404 extends React.Component {
  handleReload() {
    window.location.reload();
  }

  render() {
    return (
      <Styled404>
        <div className="cloud one" />
        <div className="cloud two" />
        <div className="cloud three" />
        <div className="cloud four" />
        <div className="center-vertical">
          <div className="title">
            <IntlMessages id="error.404.page" />
          </div>
          <div className="description">
            <IntlMessages id="error.404.notFound" />
          </div>
          <center>
            <img src={error404svg} alt="404" />
          </center>
          <center>
            <button className="btn" onClick={this.handleReload}>
              <IntlMessages id="error.404.refresh" />
            </button>
            <Link className="btn clear" to={links.jobList.url}>
              <IntlMessages id="error.404.goBack" />
            </Link>
          </center>
        </div>

        {/* <div className="c">
          <div className="_404">404</div>
          <hr />
          <div className="_1">
            <IntlMessages id="error.404.page" />
          </div>
          <div className="_2">
            <IntlMessages id="error.404.notFound" />
          </div>
          
        </div> */}
      </Styled404>
    );
  }
}

export default error404;
