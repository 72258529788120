/**
 * All of this needs to happen before apollo client gets imported/loaded
 * so that sentry can wrap window.fetch.
 * Keep this file simple and without unneeded imports
 * and import it at the start of entrypoint (index.js)
 */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from 'config';
import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router';

const { sentryDsn, apiUrl, graphqlUrl } = config;

if (!!sentryDsn && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: sentryDsn,
    allowUrls: [window.location.origin],
    environment: process.env.NODE_ENV === 'production' ? window.location.host : 'development',
    release: process.env.REACT_APP_CLIENT_VERSION || undefined,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          createBrowserHistory(),
          [{ path: '/poslodavci/ats-prijave/pozicija/:projectId(\\d+)/:applicationId?' }],
          matchPath
        ),
        tracingOrigins: [apiUrl, graphqlUrl]
      }),
    ],
    tracesSampleRate: 0, // disable tracing
    // beforeSend(event, hint) {
    //   log("i wanna send this");
    //   const error = hint.originalException;
    //   log(error);
    //   log(error instanceof Error);
    //   log(event);
    //   return event;
    // },
  });
}