import axios from 'helpers/axios';
import FormData from 'form-data';
import { getToken } from 'helpers/utility';
import config from 'config';

const uploadProfileLogoRequest = (file) => {
  const formData = new FormData();
  formData.append('image', file);
  return axios({
    method: 'post',
    url: `${config.apiUrl}/employer-profile/photo`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

export default uploadProfileLogoRequest;
