import { SelectionProcess } from 'types/profile.types';

const createSelectionProcessFromAPIResponse = (
  apiResponse: SelectionProcess
): SelectionProcess => ({
  ...apiResponse,
  videoUrls: apiResponse?.videoUrls || [],
  files: apiResponse.files.map((profileFile) => ({
    ...profileFile,
    type: 'existing',
  })),
});

export default createSelectionProcessFromAPIResponse;
