import { makeExecutableSchema } from '@graphql-tools/schema';
import { message, notification } from 'antd';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { SchemaLink } from 'apollo-link-schema';
import { createUploadLink } from 'apollo-upload-client';
import config from 'config';
import resolvers from 'demo/resolvers';
import introspectionSchemaResults from 'demo/schema.json';
import { buildClientSchema, printSchema } from 'graphql';
import TokenRefresher from 'helpers/tokenRefresher';
import { getToken, isDemo } from 'helpers/utility';
import translate from 'languageProvider/inline';
import actions from 'redux/auth/actions';
import maintenanceActions from 'redux/maintenance/actions';
import storeObject from 'redux/storeDeclaration';

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'CVDatabaseSearchResultExtendedDataItemValue':
        return `${object.__typename}:${object.id}-${object.label}:${object.secondaryId}-${object.secondaryLabel}:${object.dateTime}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const httpLink = createUploadLink({ uri: config.graphqlUrl });
const checkErrorLink = new ApolloLink(
  (operation, forward) => forward(operation).map((response) => response) // eslint-disable-line
);

const serverErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError && networkError.statusCode === 403) {
    if (
      networkError.result &&
      networkError.result.message &&
      networkError.result.message === 'This token has expired'
    ) {
      /* expired token, try with refresh token and repeat request */
      const tokenStorage = getToken();
      if (tokenStorage.get('idToken') && tokenStorage.get('refreshToken')) {
        return new Observable((observer) => {
          if (!TokenRefresher.getRefreshing()) {
            TokenRefresher.refresh();
          }

          TokenRefresher.subscribeTokenRefresh((token, error) => {
            if (token) {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  // Re-add old headers
                  ...headers,
                  // Switch out old access token for new one
                  Authorization: `Bearer ${token}` || null,
                },
              }));
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              // Retry last failed request
              forward(operation).subscribe(subscriber);
            } else {
              // No refresh or client token available, we force user to login
              observer.error(error);
              storeObject.getStore().dispatch(
                actions.logout({
                  remember: true,
                  requestLogin: true,
                })
              );
            }
          });
        });
      }
    }
    storeObject.getStore().dispatch(
      actions.logout({
        remember: true,
        requestLogin: true,
      })
    );
  }

  if (networkError && networkError.statusCode === 503) {
    storeObject.getStore().dispatch(maintenanceActions.toggleMaintenance(true));
    return null;
  }

  if (graphQLErrors && graphQLErrors.length) {
    const messages = [];
    graphQLErrors.forEach((error) => {
      const { message: errorMessage } = error;
      if (errorMessage.indexOf('showError: ') !== -1) {
        const items = JSON.parse(errorMessage.replace('showError: ', ''));
        items.forEach((item) => {
          messages.push(item);
        });
      }
    });

    if (messages.length) {
      messages.forEach((item) => {
        message.error(item);
      });
    }
  }
  if (
    networkError &&
    (networkError.toString() === 'TypeError: Failed to fetch' ||
      networkError.toString() === 'TypeError: Network request failed')
  ) {
    notification.error({
      message: translate('error.networkError'),
      description: translate('error.lostConnection'),
    });
  }

  return null;
});

const authLink = setContext((_, { headers }) => ({
  // return the headers to the context so httpLink can read them
  headers: {
    ...headers,
    locale: storeObject.getStore().getState().LanguageSwitcher.get('language').locale,
    Authorization: `Bearer ${getToken().get('idToken')}`,
    ClientVersion: process.env.REACT_APP_CLIENT_VERSION,
  },
  credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
}));

let link;

if (isDemo()) {
  const graphqlSchemaObj = buildClientSchema(introspectionSchemaResults.data);
  const typeDefs = printSchema(graphqlSchemaObj);

  const schema = makeExecutableSchema({
    typeDefs,
    resolvers,
    resolverValidationOptions: {
      requireResolversForResolveType: false,
    },
  });

  // // // Add mocks, modifies schema in place
  // addMockFunctionsToSchema({
  //   schema,
  //   mocks: {
  //     ViewDate: time => time,
  //     DayMonth: dayMonth => dayMonth,
  //   },
  //   preserveResolvers: true,
  // });

  link = new SchemaLink({ schema });
} else {
  link = authLink.concat(serverErrorLink.concat(checkErrorLink.concat(httpLink)));
}

const client = new ApolloClient({
  link,
  cache,
});

export default client;
