import config from 'config';
import { ProfileOrder, ProfilePublishPayload } from 'containers/NewProfile/newProfile.types';
import gql from 'graphql-tag';
import client from 'graphql/client';
import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';

const orderProfile = (variables: ProfileOrder) => {
  const mutation = gql`
    mutation companyProfileOrder($profileId: Int!, $profileType: String!) {
      companyProfileOrder(profileType: $profileType, id: $profileId)
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

const publishProfile = (variables: ProfilePublishPayload) => {
  const mutation = gql`
    mutation companyProfilePublish($profileId: Int!) {
      companyProfilePublish(id: $profileId)
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

const unhideProfile = () => {
  return axios({
    method: 'post',
    url: `${config.apiUrl}/company-profile/unhide`,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

export default orderProfile;
export { orderProfile, publishProfile, unhideProfile };
