import gql from 'graphql-tag';

const fetchEmployeeCountOptionsQuery = gql`
  query countryNames {
    companyProfileCompanyEmployeeCountOptions {
      id
      labelSr
      labelEn
    }
  }
`;

const fetchCountriesQuery = gql`
  query countryNames {
    companyProfileCountries {
      id
      srName
      enName
    }
  }
`;

export { fetchEmployeeCountOptionsQuery, fetchCountriesQuery };
