import FormData from 'form-data';
import axios from 'helpers/axios';
import config from '../../config';
import { getToken } from '../../helpers/utility';

const fetchEmailTemplatesRequest = () =>
  axios({
    method: 'get',
    url: `${config.apiUrl}/emailTemplates`,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });

const fetchLogoRequest = ({ projectId }) =>
  axios({
    method: 'get',
    url: `${config.apiUrl}/get_email_image_data_project/${projectId}`,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });

const uploadLogoRequest = (file) => {
  const formData = new FormData();
  formData.append('image', file);
  return axios({
    method: 'post',
    url: `${config.apiUrl}/imageUpload`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const deleteLogoRequest = ({ id, projectId }) =>
  axios({
    method: 'delete',
    url: `${config.apiUrl}/delete_image_project/${id}/${projectId}`,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });

const connectImageToProjectRequest = ({ projectId, fileUrl }) => {
  const formData = new FormData();
  formData.append('projectId', projectId);
  formData.append('fileUrl', fileUrl);

  return axios({
    method: 'post',
    url: `${config.apiUrl}/connect_image_to_project`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const sendEmailsRequest = (data) =>
  axios({
    method: 'post',
    url: `${config.apiUrl}/applications/respond`,
    data: {
      category: data.category,
      applicationIds: data.recepients.map((application) => application.id),
      senderName: data.senderName,
      senderEmail: data.senderEmail,
      body: data.body,
      subject: data.subject,
      logoPath: data.logoPath,
      projectId: data.projectId,
    },
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });

export {
  fetchEmailTemplatesRequest,
  sendEmailsRequest,
  fetchLogoRequest,
  uploadLogoRequest,
  connectImageToProjectRequest,
  deleteLogoRequest,
};
