import { lastAccessTime } from 'helpers/utility';
import { all, call, cancelled, put, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import languageActions from 'redux/languageSwitcher/actions';
import actions from 'redux/notification/actions';
import fetchNotificationsGraphql, { mutateBubbleExpandTimeGraphql, mutateMarkNotificationAsReadGraphql } from 'redux/notification/graphql';

function* fetchNotificationsGenerator() {
  try {
    const response = yield call(fetchNotificationsGraphql, {
      recache: true,
      variables: {
        date: lastAccessTime.get() ? lastAccessTime.get().toISOString() : null,
      },
    });
    yield put({
      type: actions.FETCH_NOTIFICATIONS_DONE,
      payload: response.data.notifications_new,
    });
    yield call(() => {
      lastAccessTime.set(response.data.notifications_new.lastAccess);
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_NOTIFICATIONS_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_NOTIFICATIONS_CANCELLED,
      });
    }
  }
}

function* mutateBubbleExpandTimeGenerator() {
  try {
    yield call(mutateBubbleExpandTimeGraphql, {
      recache: true,
      variables: {
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_BUBBLE_EXPAND_TIME_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_BUBBLE_EXPAND_TIME_ERROR,
      });
    }
  }
}

function* mutateMarkNotificationAsReadGenerator(action) {
  try {
    if (action.payload.type === 'text') {
      yield call(mutateMarkNotificationAsReadGraphql, {
        recache: true,
        variables: {
          uid: action.payload.id,
        },
      });
    }
    yield call(() => {
      try {
        if (window.sessionStorage) {
          const readNotifications =
            JSON.parse(window.sessionStorage.getItem('readNotifications')) || {};
          readNotifications[action.payload.id] = true;
          window.sessionStorage.setItem('readNotifications', JSON.stringify(readNotifications));
        }
      } catch (e) {
        log(e);
        window.sessionStorage.removeItem('readNotifications');
      }
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_BUBBLE_EXPAND_TIME_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_BUBBLE_EXPAND_TIME_ERROR,
      });
    }
  }
}

function* fetchNotifications() {
  yield takeLatest(
    [actions.FETCH_NOTIFICATIONS_START, languageActions.CHANGE_LANGUAGE],
    fetchNotificationsGenerator
  );
}

function* mutateBubbleExpandTime() {
  yield takeEvery(actions.MUTATE_BUBBLE_EXPAND_TIME_START, mutateBubbleExpandTimeGenerator);
}

function* mutateMarkNotificationAsRead() {
  yield takeLatest(
    actions.MUTATE_MARK_NOTIFICATION_AS_READ_START,
    mutateMarkNotificationAsReadGenerator
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchNotifications),
    spawn(mutateBubbleExpandTime),
    spawn(mutateMarkNotificationAsRead),
  ]);
}
