import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  invalidApplications: new Map({}),
});

export default function cacheReducer(state = initState, action) {
  switch (action.type) {
    case actions.INVALIDATE_APPLICATIONS: {
      let newState = state;
      Object.keys(action.payload).forEach((applicationId) => {
        newState = newState.setIn(['invalidApplications', action.payload[applicationId]], true);
      });
      return newState;
    }

    case actions.VALIDATE_APPLICATIONS: {
      let newState = state;
      Object.keys(action.payload).forEach((applicationId) => {
        newState = newState.removeIn(['invalidApplications', action.payload[applicationId]]);
      });
      return newState;
    }

    case actions.RESET_INVALID_STORE:
      return initState;

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
