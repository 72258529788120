import application from 'demo/mocks/application';

const jobs = {
  1: {
    id: 1,
    title: 'Demo oglas',
    companyDisplayName: 'Demo kompanija',
    status: 'live',
    online: true,
    application_count: application.filter(item => item.job.id === 1).length,
    datePosted: '2019-03-25T00:00:00+02:00',
    dateTimeCreated: '2019-03-25T00:00:00+02:00',
    expireDate: '2019-04-30T00:00:00+02:00',
    untilFilled: true,
    employmentType: 'FullTime',
    url: 'https://poslovi.infostud.com/',
    accessType: 'admin',
    customSelectionLabels: [],
    applications: application.filter(item => item.job.id === 1),
    location: 'Srbija, Beograd',
    preselection: null,
    unread: 2,
    hasOnlineInterview: true,
    access: [
      {
        id: '1',
        username: 'Demo',
        initials: 'DD',
        name: 'Demo Korisnik',
        email: 'demo@infostud.com',
        currentUser: true,
        accessType: 'admin',
      },
      {
        id: '2',
        username: 'demo@infostud.com',
        initials: 'DD',
        name: 'Demo Korisnik 2',
        email: 'demo@infostud.com',
        currentUser: false,
        accessType: 'admin',
      },
    ],
    statistics: {
      JobViews: [
        {
          id: 'b94d8d2668d7037faf39fb686cdccb36',
          views: 1485,
          viewDate: '08 Nov',
        },
        {
          id: '89a5ad7cb54ed87de45c4af30e7fa87a',
          views: 1034,
          viewDate: '09 Nov',
        },
        {
          id: '2fd9f653052bd46d519e80ad3435857f',
          views: 205,
          viewDate: '10 Nov',
        },
        {
          id: '9ceb8237087604b4658508c0f7d60c4a',
          views: 126,
          viewDate: '11 Nov',
        },
        {
          id: '1750ce7021fd8e77340124b131cb7490',
          views: 141,
          viewDate: '12 Nov',
        },
        {
          id: '726896503a697a7a686dfe4f3909f381',
          views: 150,
          viewDate: '13 Nov',
        },
        {
          id: '3ffe9dd358867d0efad5229c81919597',
          views: 89,
          viewDate: '14 Nov',
        },
        {
          id: 'f7328a85f8d9e07a6bec0f82f8b72acd',
          views: 79,
          viewDate: '15 Nov',
        },
        {
          id: 'db5a015cd46a225d599565a0406b68ff',
          views: 68,
          viewDate: '16 Nov',
        },
        {
          id: '2edc4d5cacba6ffaccc3543e8f3b3a9d',
          views: 40,
          viewDate: '17 Nov',
        },
        {
          id: 'bfa8e3bdba7daab8fbb596144ebf14dd',
          views: 77,
          viewDate: '18 Nov',
        },
        {
          id: '5c4f2cb2d6d759299cd1b86f67af3da6',
          views: 57,
          viewDate: '19 Nov',
        },
        {
          id: 'f44fa6e2fef7a43fe9f5ab5fe2a548a2',
          views: 74,
          viewDate: '20 Nov',
        },
        {
          id: '381b9267fe58d0882aa322b90b3ac375',
          views: 66,
          viewDate: '21 Nov',
        },
        {
          id: 'ef85835c7601246efd4e9ae3685d0d19',
          views: 93,
          viewDate: '22 Nov',
        },
      ],
      JobApplications: [
        {
          id: 'd2bc231e225c789c65a26f5ad6806d74',
          applications: 9,
          applicationDate: '08 Nov',
        },
        {
          id: 'f763e33321c92e50c4d5be9763bf0edc',
          applications: 4,
          applicationDate: '09 Nov',
        },
        {
          id: 'af76341595f4bc0c994b2baf2f0002f3',
          applications: 1,
          applicationDate: '10 Nov',
        },
        {
          id: 'c2d5e437297bc554ff20ec0f68e5e69e',
          applications: 1,
          applicationDate: '11 Nov',
        },
        {
          id: '57249105ce06033373173ca91f61af04',
          applications: 1,
          applicationDate: '13 Nov',
        },
        {
          id: 'ac81a3b91641bcde40a8b5085e2b0d30',
          applications: 3,
          applicationDate: '15 Nov',
        },
        {
          id: '62ac8a5ff16e393b61a195d81d9a791d',
          applications: 1,
          applicationDate: '16 Nov',
        },
        {
          id: 'd913f7acec7ea57bae50cc226071efff',
          applications: 1,
          applicationDate: '17 Nov',
        },
        {
          id: 'd163998150cea75269493a2882d88264',
          applications: 3,
          applicationDate: '20 Nov',
        },
        {
          id: '1a231eba6fd4fc04f643245c0fe0a6f5',
          applications: 1,
          applicationDate: '21 Nov',
        },
        {
          id: 'b5ba628b5113c4df5d5c63f7124e7c9f',
          applications: 2,
          applicationDate: '22 Nov',
        },
      ],
      JobRating: 4.44,
      JobSelectionRating: 4.5,
      JobApplicationCount: 27,
      JobTotalViews: 1,
    },
  },
  2: {
    id: 2,
    title: 'Demo oglas 2',
    companyDisplayName: 'Demo kompanija',
    status: 'live',
    online: true,
    application_count: application.filter(item => item.job.id === 2).length,
    datePosted: '2019-03-22T00:00:00+02:00',
    dateTimeCreated: '2019-03-22T00:00:00+02:00',
    expireDate: '2050-04-30T00:00:00+02:00',
    untilFilled: false,
    employmentType: 'FullTime',
    url: 'https://poslovi.infostud.com/',
    accessType: 'invited',
    customSelectionLabels: [],
    applications: application.filter(item => item.job.id === 2),
    accessAssignedDateTime: '2019-04-30T00:00:00+02:00',
    location: 'Novi Sad',
    preselection: null,
    unread: 0,
    hasOnlineInterview: false,
    cities: [],
    statistics: {
      JobViews: [
        {
          id: 'b94d8d2668d7037faf39fb686cdccb36',
          views: 1485,
          viewDate: '08 Nov',
        },
        {
          id: '89a5ad7cb54ed87de45c4af30e7fa87a',
          views: 1034,
          viewDate: '09 Nov',
        },
        {
          id: '2fd9f653052bd46d519e80ad3435857f',
          views: 205,
          viewDate: '10 Nov',
        },
        {
          id: '9ceb8237087604b4658508c0f7d60c4a',
          views: 126,
          viewDate: '11 Nov',
        },
        {
          id: '1750ce7021fd8e77340124b131cb7490',
          views: 141,
          viewDate: '12 Nov',
        },
        {
          id: '726896503a697a7a686dfe4f3909f381',
          views: 150,
          viewDate: '13 Nov',
        },
        {
          id: '3ffe9dd358867d0efad5229c81919597',
          views: 89,
          viewDate: '14 Nov',
        },
        {
          id: 'f7328a85f8d9e07a6bec0f82f8b72acd',
          views: 79,
          viewDate: '15 Nov',
        },
        {
          id: 'db5a015cd46a225d599565a0406b68ff',
          views: 68,
          viewDate: '16 Nov',
        },
        {
          id: '2edc4d5cacba6ffaccc3543e8f3b3a9d',
          views: 40,
          viewDate: '17 Nov',
        },
        {
          id: 'bfa8e3bdba7daab8fbb596144ebf14dd',
          views: 77,
          viewDate: '18 Nov',
        },
        {
          id: '5c4f2cb2d6d759299cd1b86f67af3da6',
          views: 57,
          viewDate: '19 Nov',
        },
        {
          id: 'f44fa6e2fef7a43fe9f5ab5fe2a548a2',
          views: 74,
          viewDate: '20 Nov',
        },
        {
          id: '381b9267fe58d0882aa322b90b3ac375',
          views: 66,
          viewDate: '21 Nov',
        },
        {
          id: 'ef85835c7601246efd4e9ae3685d0d19',
          views: 93,
          viewDate: '22 Nov',
        },
      ],
      JobApplications: [
        {
          id: 'd2bc231e225c789c65a26f5ad6806d74',
          applications: 9,
          applicationDate: '08 Nov',
        },
        {
          id: 'f763e33321c92e50c4d5be9763bf0edc',
          applications: 4,
          applicationDate: '09 Nov',
        },
        {
          id: 'af76341595f4bc0c994b2baf2f0002f3',
          applications: 1,
          applicationDate: '10 Nov',
        },
        {
          id: 'c2d5e437297bc554ff20ec0f68e5e69e',
          applications: 1,
          applicationDate: '11 Nov',
        },
        {
          id: '57249105ce06033373173ca91f61af04',
          applications: 1,
          applicationDate: '13 Nov',
        },
        {
          id: 'ac81a3b91641bcde40a8b5085e2b0d30',
          applications: 3,
          applicationDate: '15 Nov',
        },
        {
          id: '62ac8a5ff16e393b61a195d81d9a791d',
          applications: 1,
          applicationDate: '16 Nov',
        },
        {
          id: 'd913f7acec7ea57bae50cc226071efff',
          applications: 1,
          applicationDate: '17 Nov',
        },
        {
          id: 'd163998150cea75269493a2882d88264',
          applications: 3,
          applicationDate: '20 Nov',
        },
        {
          id: '1a231eba6fd4fc04f643245c0fe0a6f5',
          applications: 1,
          applicationDate: '21 Nov',
        },
        {
          id: 'b5ba628b5113c4df5d5c63f7124e7c9f',
          applications: 2,
          applicationDate: '22 Nov',
        },
      ],
      JobRating: 4.44,
      JobSelectionRating: 4.5,
      JobApplicationCount: 27,
      JobTotalViews: 1,
    },
  },
};

export default jobs;
