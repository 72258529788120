import { fromJS, Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  loaded: false,
  jobReceipt: new Map(),
  loading: false,
  denied: false,
});

export default function jobReceiptReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_JOB_RECEIPT_START:
      return state.set('loading', true);

    case actions.FETCH_JOB_RECEIPT_CANCELLED:
    case actions.FETCH_JOB_RECEIPT_ERROR:
      return state.withMutations(newState => {
        return newState.set('loaded', false).set('loading', false);
      });

    case actions.FETCH_JOB_RECEIPT_DENIED:
      return state.withMutations(newState => {
        return newState
          .set('loaded', true)
          .set('loading', false)
          .set('jobReceipt', new Map())
          .set('denied', true);
      });

    case actions.FETCH_JOB_RECEIPT_DONE:
      return state.withMutations(newState => {
        return newState
          .set('loaded', true)
          .set('loading', false)
          .set('jobReceipt', new Map(fromJS(action.payload)));
      });

    case actions.LOGOUT: {
      return initState;
    }
    default:
      return state;
  }
}
