import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import { ReduxAction } from 'types/redux.types';
import { fetchProfileGraphql } from './graphql';
import {
  orderProfile as orderProfileRequest,
  publishProfile as publishProfileRequest,
  unhideProfile as unhideProfileRequest
} from './requests';
import {
  CREATE_PROFILE_SNAPSHOT,
  ProfileOrderAction,
  ProfilePublishAction,
  PROFILE_FETCH_CANCELLED,
  PROFILE_FETCH_DENIED,
  PROFILE_FETCH_DONE,
  PROFILE_FETCH_ERROR,
  PROFILE_FETCH_START,
  PROFILE_ORDER_CANCELLED,
  PROFILE_ORDER_DONE,
  PROFILE_ORDER_ERROR,
  PROFILE_ORDER_START,
  PUBLISH_PROFILE_CANCELLED,
  PUBLISH_PROFILE_DONE,
  PUBLISH_PROFILE_ERROR,
  PUBLISH_PROFILE_START,
  UNHIDE_PROFILE_CANCELLED,
  UNHIDE_PROFILE_DONE,
  UNHIDE_PROFILE_ERROR,
  UNHIDE_PROFILE_START
} from './types';

function* fetchProfileGenerator(action: ReduxAction): any {
  try {
    const response = yield call(fetchProfileGraphql, action.payload);
    yield put({
      type: PROFILE_FETCH_DONE,
      payload: response.data.companyProfile,
    });
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
  } catch (e: any) {
    log(e);
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: PROFILE_FETCH_DENIED });
    }
    yield put({ type: PROFILE_FETCH_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    if (yield cancelled()) {
      yield put({
        type: PROFILE_FETCH_CANCELLED,
      });
    }
  }
}

function* orderProfileGenerator(action: ProfileOrderAction): any {
  try {
    yield call(orderProfileRequest, action.payload);
    yield put({
      type: PROFILE_ORDER_DONE,
      payload: action.payload,
    });
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_ORDER_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    if (yield cancelled()) {
      yield put({
        type: PROFILE_ORDER_CANCELLED,
      });
    }
  }
}

function* publishProfileGenerator(action: ProfilePublishAction): any {
  try {
    yield call(publishProfileRequest, action.payload);
    yield put({
      type: PUBLISH_PROFILE_DONE,
    });
    message.success(translate('profile.published'));
  } catch (e) {
    log(e);
    yield put({ type: PUBLISH_PROFILE_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    if (yield cancelled()) {
      yield put({
        type: PUBLISH_PROFILE_CANCELLED,
      });
    }
  }
}

function* unhideProfileGenerator(): any {
  try {
    yield call(unhideProfileRequest);
    yield put({
      type: UNHIDE_PROFILE_DONE,
    });
    message.success(translate('profile.reactivated'));
  } catch (e) {
    log(e);
    yield put({ type: UNHIDE_PROFILE_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    if (yield cancelled()) {
      yield put({
        type: UNHIDE_PROFILE_CANCELLED,
      });
    }
  }
}

function* fetchProfile() {
  yield takeLatest(PROFILE_FETCH_START, fetchProfileGenerator);
}

function* orderProfile() {
  yield takeLatest(PROFILE_ORDER_START, orderProfileGenerator);
}

function* publishProfile() {
  yield takeLatest(PUBLISH_PROFILE_START, publishProfileGenerator);
}

function* unhideProfile() {
  yield takeLatest(UNHIDE_PROFILE_START, unhideProfileGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchProfile),
    spawn(orderProfile),
    spawn(publishProfile),
    spawn(unhideProfile),
  ]);
}
