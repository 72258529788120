import { CompanyProfileListItem } from 'containers/NewProfile/newProfile.types';
import gql from 'graphql-tag';
import client from 'graphql/client';
import { fetchProfileGraphqlQuery } from './queries';
import { ProfileSite } from './types';

const fetchProfileGraphql = (variables: { id: number }) =>
  client.query({
    query: fetchProfileGraphqlQuery,
    variables,
    fetchPolicy: 'network-only',
  });

export interface ProfileListItemResponse {
  data: {
    companyProfiles: Array<CompanyProfileListItem>;
  };
}

export const fetchProfiles = (): Promise<ProfileListItemResponse> => {
  const query = gql`
    query {
      companyProfiles {
        id
        site
        companyName
        profileType
        published
        active
        link
        orderedProfileTypes {
          id
          profileType
          profileId
          site
        }
      }
    }
  `;

  return client.query({
    query,
    fetchPolicy: 'network-only',
  });
};

export const mutationCreateProfile = (variables: { site: ProfileSite }) => {
  const mutation = gql`
    mutation companyProfileCreate($site: ProfileSite!) {
      companyProfileCreate(site: $site) {
        id
      }
    }
  `;
  return client.mutate({
    mutation,
    variables,
  });
};

export const fetchPreviewLinks = () => {
  const query = gql`
    query companyProfilePreviewLinks {
      companyProfilePreviewLinks {
        link
        status
        id
      }
    }
  `;
  return client.query({
    query,
    fetchPolicy: 'network-only',
  });
};

export { fetchProfileGraphql };
export default fetchProfileGraphql;
