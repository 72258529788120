import { ProfileTypes } from 'containers/NewProfile/newProfile.types';
import { Profile } from 'types/profile.types';
import { ProfileSnapshotObj } from './types';

export const getProfileSnapshot = (snapshotObj: ProfileSnapshotObj) => JSON.stringify(snapshotObj);

export const calculatePreviewType = (profile: Profile): ProfileTypes => {
  const profileTypesWeight: Array<ProfileTypes> = ['free', 'start', 'premium'];
  const requestedProfileTypeWeight: number = profileTypesWeight.findIndex(
    (profileTypeWeight) => profileTypeWeight === profile.profileType
  );
  const orderedProfileWeight: number = Math.max(
    ...profile.orderedProfileTypesNew.map((orderedProfileType) =>
      profileTypesWeight.findIndex(
        (profileTypeWeight) => profileTypeWeight === orderedProfileType.profileType
      )
    )
  );

  const previewType: ProfileTypes =
    profileTypesWeight[Math.max(...[requestedProfileTypeWeight, orderedProfileWeight])];

  return profile.active || profile.gracePeriodActive
    ? profile.profileType
    : !!profile.orderedProfileTypesNew.length
    ? previewType
    : 'premium';
};

export default getProfileSnapshot;
