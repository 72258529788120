import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';
import config from 'config';

const deleteApplicationRequest = id => axios({
  method: 'delete',
  url: `${config.apiUrl}/application/${id}`,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export default deleteApplicationRequest;
