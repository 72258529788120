import gql from 'graphql-tag';
import client from 'graphql/client';

/* notifications fetch */
const fetchNotificationsGraphql = payload => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query fetchNotifications($date: String) {
      notifications_new(date: $date) {
        id
        lastAccess
        indicator
        items {
          id
          link
          type
          title
          content
          date
          state
        }
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const mutateBubbleExpandTimeGraphql = payload => {
  const params = {};
  const { variables } = payload;

  const mutation = gql`
    mutation updateBubbleExpandTime($date: ViewTime) {
      updateBubbleExpandTime(date: $date)
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const mutateMarkNotificationAsReadGraphql = payload => {
  const params = {};
  const { variables } = payload;

  const mutation = gql`
    mutation markNotificationAsRead($uid: String) {
      markNotificationAsRead(uid: $uid)
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

export default fetchNotificationsGraphql;
export { mutateBubbleExpandTimeGraphql, mutateMarkNotificationAsReadGraphql };

