import { message } from 'antd';
import translate from 'languageProvider/inline';
import { Action } from 'redux';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import updateMyCompanySectionRequest from './requests';
import {
  PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED,
  PROFILE_MY_COMPANY_SECTION_UPDATE_DONE,
  PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR,
  PROFILE_MY_COMPANY_SECTION_UPDATE_START
} from './types';

const Nprogress = require('nprogress');

interface CompanyUpdatePayload {
  payload: { id: number };
}

interface UpdateAction extends Action, CompanyUpdatePayload {
  type: typeof PROFILE_MY_COMPANY_SECTION_UPDATE_START;
}

function* updateMyCompanySectionGenerator({ payload }: UpdateAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    const response = yield call(updateMyCompanySectionRequest as any, {
      id: payload.id,
      companyName: storeState.get('companyName') || '',
      slogan: storeState.get('slogan') || '',
      language: storeState.get('language'),
      companyLogo: storeState.get('companyLogo'),
      backgroundImage: storeState.get('backgroundImage'),
    });
    yield put({
      type: PROFILE_MY_COMPANY_SECTION_UPDATE_DONE,
      payload: {
        ...response.data.companyProfileMyCompanySectionMutation,
        successMessage: translate('profile.form.saved'),
      },
    });
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    yield put({
      type: PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR,
      payload: translate('error.tryAgainMessage'),
    });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED,
      });
    }
  }
}

function* updateMyCompanySection() {
  yield takeLatest(PROFILE_MY_COMPANY_SECTION_UPDATE_START, updateMyCompanySectionGenerator);
}

export default function* rootSaga() {
  yield all([spawn(updateMyCompanySection)]);
}
