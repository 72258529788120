import {
  application,
  applicationTagMutation,
  employer,
  employerCompany,
  employerMock,
  employerProfile,
  jobs,
  lists,
  notification,
  packages,
  pricelist,
  projects,
  seller,
  team
} from 'demo/mocks';

const resolvers = {
  Query: {
    jobs: () => {
      const jobArray = [];
      Object.keys(jobs).forEach((key) => {
        jobArray[key] = jobs[key];
      });
      return { job: jobArray, count: jobArray.filter((item) => item).length };
    },
    job: (obj, { id }) => jobs[id],
    allApplications: () => ({
      applications: application,
      count: application.length,
    }),
    allApplicationsInProjects: () => ({
      applications: application,
      count: application.length,
    }),
    application: (obj, { id }) => application.find((item) => item.id === id),
    team: () => team,
    employerProfile: () => employerProfile,
    employer: () => employer,
    companySeller: () => seller,
    companyPackages: () => packages,
    notifications_new: () => notification,
    adPriceList: (obj, { currency }) => {
      return pricelist[currency].data.adPriceList;
    },
    lists: () => lists,
    employerCompany: () => employerCompany,
    projects: () => ({
      project: projects,
      count: projects.length,
    }),
    project: (obj, { id }) => projects.find((item) => parseInt(item.id, 10) === id),
    user: () => employerMock,
  },
  Mutation: {
    addNewApplicationTag: (config, variables) => {
      applicationTagMutation.tags.push({
        tagId: variables.idTag,
        name: variables.name,
      });
      return applicationTagMutation;
    },
    createIncompleteJob: () => {
      return null;
    },
  },
};

export default resolvers;
