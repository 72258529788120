const analyticsActions = {
  REDUX_TRACK_EVENT: 'REDUX_TRACK_EVENT',

  reduxTrackEvent: (category, action, label, value) => ({
    type: analyticsActions.REDUX_TRACK_EVENT,
    payload: {
      category,
      action,
      label,
      value,
    },
  }),
};

export default analyticsActions;
