import sortDirectionFlow from 'helpers/sortDirectionFlow';
import { fromJS, Map } from 'immutable';
import applicationActions from 'redux/application/actions';
import emailTemplatesActions from 'redux/emailTemplates/actions';
import spreatsheetActions from 'redux/spreadsheet/actions';
import teamActions from 'redux/team/actions';
import zipActions from 'redux/zip/actions';
import { ReduxAction } from 'types/redux.types';
import projectActions from './actions';

const sortOptions: Map<string, any> = Map({
  name: null,
  selectionLevel: null,
  rating: null,
  preselectionPassed: null,
  rejected: null,
  tags: null,
  suggested: null,
});

const initState = Map({
  currentProject: null,
  projectLoading: true,
  errorLoading: false,
  accessDenied: false,
  applicationFilters: {},
  applicationListExpanded: true,
  selectedFilters: Map({
    selectionLevel: [],
    rating: [],
    tags: [],
    candidateName: '',
    email: undefined,
  }),
  sort: Map({
    name: null,
    selectionLevel: null,
    rating: null,
    preselectionPassed: null,
    rejected: null,
    tags: null,
    suggested: 'desc',
  }),
  selectedApplications: Map({}),
  showBulkActions: false,
  hideApplicationList: false,
  showHidden: false,
  selectionLabelEditorVisible: false,
});

export default function projectReducer(state = initState, action: ReduxAction) {
  switch (action.type) {
    case projectActions.FETCH_PROJECT_START:
      return state.set('projectLoading', true).set('errorLoading', false);
    case projectActions.FETCH_PROJECT_DONE:
      return state
        .set('currentProject', fromJS(action.payload))
        .set('projectLoading', false)
        .set('errorLoading', false);

    case projectActions.FETCH_PROJECT_ERROR:
      return state.set('projectLoading', false).set('errorLoading', true);

    case projectActions.FETCH_PROJECT_DENIED:
      return state.set('accessDenied', true);

    case projectActions.APPLICATION_FILTER_FETCHING_DONE:
      return state.set('applicationFilters', action.payload);

    /**
     * cycle sort
     */
    case projectActions.SET_SORT: {
      const newState = sortOptions.set(
        action.payload.field,
        sortDirectionFlow(action.payload.currentDirection) as string
      );
      return state.set('sort', newState).set('projectLoading', true);
    }

    case projectActions.SET_FILTER:
      return state
        .setIn(['selectedFilters', action.payload.group], action.payload.filters)
        .set('projectLoading', true);

    case projectActions.RESET_FILTER:
      return state
        .set('selectedFilters', initState.get('selectedFilters'))
        .set('projectLoading', true);

    case projectActions.RESET_APPLICATION_SELECTION:
      return state.set('selectedApplications', Map({}));

    /**
     * toggle visiblity of bulk actions bar
     */
    case projectActions.TOGGLE_BULK_ACTIONS:
      return state.set('showBulkActions', action.payload.state);

    /**
     * modifiy application selection
     * bulk action bar is triggered from saga as a side effect
     */
    case projectActions.MODIFY_APPLICATION_SELECTION:
      if (action.payload.checked) {
        return state.setIn(
          ['selectedApplications', action.payload.application.get('id')],
          action.payload.application
        );
      }
      return state.removeIn(['selectedApplications', action.payload.application.get('id')]);

    case projectActions.SELECT_ALL_APPLICATIONS: {
      let selectedApplications = Map({});
      state.getIn(['currentProject', 'applications']).forEach((item: any) => {
        state.get('showHidden');
        /**
         * select only with consent and not hidden OOOOR select hidden if showHidden set
         */
        if (item.get('consent') && (!item.get('hidden') || state.get('showHidden'))) {
          selectedApplications = selectedApplications.set(item.get('id'), item);
        }
      });
      return state.set('selectedApplications', selectedApplications);
    }

    case '@@router/LOCATION_CHANGE': {
      const {
        payload: {
          location: { pathname },
        },
      } = action;
      const match = pathname.match('/ats-prijave/pozicija/([0-9]+)/?([0-9]+)?');
      if (match && match[1] && !match[2]) {
        return state.set('applicationListExpanded', true);
      }
      return state;
    }

    case teamActions.MUTATE_REVOKE_DONE:
    case teamActions.MUTATE_GRANT_DONE: {
      let newState = state;

      /** if viewing single job update there */
      if (state.get('currentProject')) {
        newState = newState.setIn(['currentProject', 'access'], fromJS(action.payload.access));
      }

      return newState;
    }

    /**
     * when we change selectionLevel it needs to be updated in applications for project
     */
    case applicationActions.APPLICATION_SELECTION_LEVEL_MUTATION_START:
    case applicationActions.APPLICATION_SELECTION_LEVEL_MUTATION_DONE: {
      let newState = state;
      Object.keys(action.payload.id).forEach((key) => {
        newState = newState.setIn(
          [
            'currentProject',
            'applications',
            state
              .getIn(['currentProject', 'applications'])
              .findIndex((el: any) => el.get('id') === action.payload.id[key]),
            'selectionLevel',
          ],
          action.payload.selectionLevel
        );
      });
      return newState;
    }

    case projectActions.UPDATE_REJECTED_APPLICATIONS: {
      let newState = state;
      Object.keys(action.payload.id).forEach((key) => {
        newState = newState.setIn(
          [
            'currentProject',
            'applications',
            state
              .getIn(['currentProject', 'applications'])
              .findIndex((el: any) => el.get('id') === action.payload.id[key]),
            'rejected',
          ],
          true
        );
      });
      return newState;
    }

    case projectActions.SHOW_APPLICATIONS_LIST:
      return state.set('hideApplicationList', false);

    case projectActions.HIDE_APPLICATIONS_LIST:
      return state.set('hideApplicationList', true);

    case applicationActions.APPLICATION_FETCH:
      return (
        state.getIn(['currentProject', 'applications'])
          ? state.setIn(
              [
                'currentProject',
                'applications',
                state
                  .getIn(['currentProject', 'applications'])
                  .findIndex((el: any) => el.get('id') === action.payload.variables.applicationId),
                'employerView',
              ],
              true
            )
          : state
      ).set('hideApplicationList', true);

    case projectActions.TOGGLE_SHOW_HIDDEN:
      return state.set('showHidden', action.payload);

    /**
     * application list collapse/expand
     */
    case projectActions.EXPAND_APPLICATIONS_LIST:
      return state.set('applicationListExpanded', true);
    case projectActions.COLLAPSE_APPLICATIONS_LIST:
      return state.set('applicationListExpanded', false);

    case applicationActions.APPLICATION_HIDE_START:
      return state.getIn(['currentProject', 'applications'])
        ? state.setIn(
            [
              'currentProject',
              'applications',
              state.getIn(['currentProject', 'applications']).findIndex((el: any) => {
                return el.get('id') === action.payload.applicationId;
              }),
              'hidden',
            ],
            action.payload.state
          )
        : state;

    /**
     * when we change rating it needs to be updated in applications for job
     */
    case applicationActions.APPLICATION_RATING_MUTATION_START:
    case applicationActions.APPLICATION_RATING_MUTATION_DONE: {
      let newState = state;
      /**
       * check that user is still looking at project
       */
      if (state.getIn(['currentProject', 'applications'])) {
        Object.keys(action.payload.id).forEach((key) => {
          newState = newState.setIn(
            [
              'currentProject',
              'applications',
              state
                .getIn(['currentProject', 'applications'])
                .findIndex((el: any) => el.get('id') === action.payload.id[key]),
              'rating',
            ],
            action.payload.rating
          );
        });
      }
      return newState;
    }

    case zipActions.ZIP_APPLICATIONS_DONE:
    case spreatsheetActions.SPREADSHEET_GENERATE_DONE: {
      let newState = state;
      action.payload.applicationIds.forEach((applicationId: string) => {
        newState = newState.getIn(['currentProject', 'applications'])
          ? newState.setIn(
              [
                'currentProject',
                'applications',
                newState
                  .getIn(['currentProject', 'applications'])
                  .findIndex((el: any) => el.get('id') === parseInt(applicationId, 10)),
                'employerView',
              ],
              true
            )
          : newState;
      });
      return newState;
    }

    case projectActions.TOGGLE_SELECTION_LABEL_EDITOR:
      return state.set('selectionLabelEditorVisible', action.payload);

    case projectActions.CHANGE_SELECTION_LABEL_TEXT: {
      const customSelectionLabels = state.getIn(['currentProject', 'customSelectionLabels']).toJS();
      const index = customSelectionLabels.findIndex(
        (item: any) => item.selectionLevel === action.payload.selectionLevel
      );
      const exists = index !== -1;
      if (exists) {
        return state.setIn(
          [
            'currentProject',
            'customSelectionLabels',
            state
              .getIn(['currentProject', 'customSelectionLabels'])
              .findIndex(
                (item: any) => item.get('selectionLevel') === action.payload.selectionLevel
              ),
            'name',
          ],
          action.payload.text
        );
      }

      customSelectionLabels.push({
        selectionLevel: action.payload.selectionLevel,
        name: action.payload.text,
      });

      return state.setIn(
        ['currentProject', 'customSelectionLabels'],
        fromJS(customSelectionLabels)
      );
    }

    case projectActions.CHANGE_SELECTION_LABEL_VISIBLITY: {
      const customSelectionLabels = state.getIn(['currentProject', 'customSelectionLabels']).toJS();
      const index = customSelectionLabels.findIndex(
        (item: any) => item.selectionLevel === action.payload.selectionLevel
      );
      const exists = index !== -1;
      if (exists) {
        return state.setIn(
          [
            'currentProject',
            'customSelectionLabels',
            state
              .getIn(['currentProject', 'customSelectionLabels'])
              .findIndex(
                (item: any) => item.get('selectionLevel') === action.payload.selectionLevel
              ),
            'hidden',
          ],
          action.payload.hidden
        );
      }

      customSelectionLabels.push({
        selectionLevel: action.payload.selectionLevel,
        hidden: action.payload.hidden,
      });

      return state.setIn(
        ['currentProject', 'customSelectionLabels'],
        fromJS(customSelectionLabels)
      );
    }

    case projectActions.SAVE_CUSTOM_SELECTION_LABELS_DONE: {
      /* prevent modification if user navigate away from this job */
      log(action.payload);
      const projectId = state.getIn(['currentProject', 'id']);
      if (projectId === action.payload.id) {
        return state.setIn(
          ['currentProject', 'customSelectionLabels'],
          fromJS(action.payload.customSelectionLabels)
        );
      }
      return state;
    }

    case emailTemplatesActions.EMAIL_SEND_START: {
      let newState = state;
      Object.keys(action.payload.recepients).forEach((key) => {
        const recepient = action.payload.recepients[key];
        const events = state.getIn([
          'currentProject',
          'applications',
          state
            .getIn(['currentProject', 'applications'])
            .findIndex((el: any) => el.get('id') === recepient.id),
          'events',
        ]);
        newState = newState.setIn(
          [
            'currentProject',
            'applications',
            state
              .getIn(['currentProject', 'applications'])
              .findIndex((el: any) => el.get('id') === recepient.id),
            'events',
          ],
          events.push(
            Map({
              eventType: `${action.payload.category}_sent`,
            })
          )
        );
      });
      return newState;
    }

    case applicationActions.APPLICATION_MARK_AS_UNREAD_START:
      return state.getIn(['currentProject', 'applications'])
        ? state.setIn(
            [
              'currentProject',
              'applications',
              state.getIn(['currentProject', 'applications']).findIndex((el: any) => {
                return el.get('id') === action.payload.id;
              }),
              'employerView',
            ],
            false
          )
        : state;

    case applicationActions.APPLICATION_COMMENT_EDIT_MUTATION_DONE:
    case applicationActions.APPLICATION_COMMENT_ADD_MUTATION_DONE:
      return state.getIn(['currentProject', 'applications'])
        ? state.setIn(
            [
              'currentProject',
              'applications',
              state.getIn(['currentProject', 'applications']).findIndex((el: any) => {
                return el.get('id') === action.payload.id;
              }),
              'comments',
            ],
            fromJS(action.payload.comments)
          )
        : state;

    case applicationActions.APPLICATION_RATING_FETCH_DONE:
      return state.getIn(['currentProject', 'applications'])
        ? state.setIn(
            [
              'currentProject',
              'applications',
              state.getIn(['currentProject', 'applications']).findIndex((el: any) => {
                return el.get('id') === action.payload.id;
              }),
              'rating',
            ],
            fromJS(action.payload.rating)
          )
        : state;

    case applicationActions.APPLICATION_EVENTS_FETCH_DONE:
      return state.getIn(['currentProject', 'applications'])
        ? state.setIn(
            [
              'currentProject',
              'applications',
              state.getIn(['currentProject', 'applications']).findIndex((el: any) => {
                return el.get('id') === action.payload.id;
              }),
              'events',
            ],
            fromJS(action.payload.events)
          )
        : state;
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
