import { fromJS, List, Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  modalVisible: false,
  editingJobId: null,
  uploadedFiles: List(),
  submitting: false,
  uploading: false,
  loadedApplication: null,
});

export default function editApplicationReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_MODAL_CREATE_APPLICATION:
      return state.set('modalVisible', action.payload);
    case actions.SET_JOB_ID:
      return state.set('editingJobId', action.payload);

    case actions.LOAD_FOR_EDITING:
      return state.set('loadedApplication', action.payload);

    case actions.UPLOAD_DOCUMENT_START: {
      const { payload } = action;
      let newState = state;
      Object.keys(payload).forEach((key) => {
        const { name } = payload[key];
        if (!newState.get('uploadedFiles').some((item) => item.get('filename') === name)) {
          newState = newState.update('uploadedFiles', (list) =>
            list.push(
              new Map({
                key: name,
                filename: name,
                loading: true,
              })
            )
          );
        }
      });
      return newState.set('uploading', true);
    }

    case actions.UPLOAD_DOCUMENT_DONE: {
      const { payload } = action;
      let newState = state;
      Object.keys(payload).forEach((key) => {
        const { data } = payload[key];
        newState = newState.update('uploadedFiles', (list) =>
          list.set(
            list.findIndex((item) => item.get('filename') === data.filename),
            fromJS(data)
          )
        );
      });
      return newState.set('uploading', false);
    }

    case actions.REMOVE_UPLOADED_DOCUMENT:
      return state.update('uploadedFiles', (list) =>
        list.filter((file) => file.get('key') !== action.payload.key)
      );

    case actions.CLEAR_UPLOADED_DOCUMENTS:
      return state.set('uploadedFiles', List());

    case actions.CHANGE_DOCUMENT_TYPE:
      return state.update('uploadedFiles', (list) =>
        list.map((file) => {
          if (file.get('key') === action.payload.file.key) {
            return file.set('fileType', action.payload.value);
          }
          return file;
        })
      );

    case actions.CREATE_APPLICATION_START:
      return state.set('submitting', true);

    case actions.CREATE_APPLICATION_DONE:
      return state.set('modalVisible', false).set('submitting', false);

    case actions.CREATE_APPLICATION_ERROR:
    case actions.CREATE_APPLICATION_CANCELLED:
      return state.set('submitting', false);

    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
