import { replace } from 'connected-react-router';
import links from 'helpers/links';
import Nprogress from 'nprogress';
import {
  all,
  call,
  cancelled,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import projectActions from 'redux/project/actions';
import cacheActions from '../cacheControl/actions';
import { default as actions, default as applicationActions } from './actions';
import {
  applicationHideGraphql,
  fetchApplicationGraphql,
  fetchApplicationsGraphql,
  mutateApplicationAddComment,
  mutateApplicationAddTagGraphql,
  mutateApplicationEditComment,
  mutateApplicationRatingGraphql,
  mutateApplicationRemoveComment,
  mutateApplicationRemoveTagGraphql,
  mutateApplicationSelectionLevelGraphql,
  mutateMarkAsUnreadGeneratorGraphql,
} from './graphql';
import deleteApplicationRequest from './requests';

function* fetchCommentsGenerator(action) {
  try {
    const response = yield call(fetchApplicationGraphql, {
      recache: true,
      variables: {
        applicationId: action.payload.applicationId,
      },
    });
    yield put({
      type: actions.APPLICATION_COMMENTS_FETCH_DONE,
      payload: response.data.application,
    });
  } catch (e) {
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_COMMENTS_FETCH_CANCELLED,
      });
    }
  }
}

function* fetchTagsGenerator(action) {
  try {
    const response = yield call(fetchApplicationGraphql, {
      recache: true,
      variables: {
        applicationId: action.payload.applicationId,
      },
    });
    yield put({
      type: actions.APPLICATION_TAGS_FETCH_DONE,
      payload: response.data.application,
    });
  } catch (e) {
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_TAGS_FETCH_CANCELLED,
      });
    }
  }
}

function* fetchEventsGenerator(action) {
  try {
    const response = yield call(fetchApplicationGraphql, {
      recache: true,
      variables: {
        applicationId: action.payload.applicationId,
      },
    });
    yield put({
      type: actions.APPLICATION_EVENTS_FETCH_DONE,
      payload: response.data.application,
    });
  } catch (e) {
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_EVENTS_FETCH_CANCELLED,
      });
    }
  }
}

function* fetchRatingGenerator(action) {
  try {
    const response = yield call(fetchApplicationGraphql, {
      recache: true,
      variables: {
        applicationId: action.payload.applicationId,
      },
    });
    yield put({
      type: actions.APPLICATION_RATING_FETCH_DONE,
      payload: response.data.application,
    });
  } catch (e) {
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_RATING_FETCH_CANCELLED,
      });
    }
  }
}

/* single application generator */
function* fetchApplicationGenerator(action) {
  try {
    // yield call(() => {
    //   Nprogress.start();
    // });

    /**
     * if applications is marked for recache add recache param
     */
    const CacheControl = (state) => state.CacheControl;
    const cacheState = yield select(CacheControl);

    const finalAction = action;
    if (cacheState.getIn(['invalidApplications', action.payload.variables.applicationId])) {
      finalAction.payload.recache = true;
    }

    const response = yield call(fetchApplicationGraphql, finalAction.payload);
    yield put({
      type: actions.APPLICATION_FETCHING_DONE,
      payload: response.data.application,
    });

    /**
     * When application is loaded validate it in cache
     */
    yield put({
      type: cacheActions.VALIDATE_APPLICATIONS,
      payload: [action.payload.variables.applicationId],
    });

    yield put({
      type: actions.APPLICATION_LOADING_DONE,
    });
  } catch (e) {
    if (e.message && e.message.indexOf('gdpr') !== -1) {
      yield put({ type: actions.APPLICATION_GDPRED });
    }
    yield put({ type: actions.APPLICATION_FETCHING_ERROR });
  } finally {
    // yield call(() => {
    //   Nprogress.done();
    // });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_FETCH_CANCELLED,
      });
    }
  }
}

/* single application rating mutation generator */
function* mutateApplicationRatingGenerator(action) {
  try {
    yield call(mutateApplicationRatingGraphql, action.payload);
    const projectId = yield select((state) => state.Project.getIn(['currentProject', 'id']));
    yield put({
      type: actions.APPLICATION_RATING_MUTATION_DONE,
      payload: action.payload,
    });
    yield put({
      type: projectActions.APPLICATION_FILTER_FETCH,
      payload: projectId,
    });

    const applicationsStore = (state) => state.Application;
    const applicationsState = yield select(applicationsStore);

    if (action.payload.id.filter((id) => id === applicationsState.get('application').id).length) {
      yield put({
        type: applicationActions.APPLICATION_REFRESH,
        payload: {
          variables: {
            applicationId: applicationsState.get('application').id,
            projectId,
          },
          recache: true,
        },
      });
    }
  } catch (e) {
    yield put({ type: actions.APPLICATION_RATING_MUTATION_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_RATING_MUTATION_CANCELLED,
      });
    }
  }
}

/* single application selectionLevel mutation generator */
function* mutateApplicationSelectionLevelGenerator(action) {
  try {
    yield call(mutateApplicationSelectionLevelGraphql, action.payload);
    const projectState = yield select((state) => state.Project);
    yield put({
      type: actions.APPLICATION_SELECTION_LEVEL_MUTATION_DONE,
      payload: action.payload,
    });
    if (projectState.getIn(['currentProject', 'id'])) {
      yield put({
        type: projectActions.APPLICATION_FILTER_FETCH,
        payload: projectState.getIn(['currentProject', 'id']),
      });
    }
  } catch (e) {
    log(e);
    yield put({ type: actions.APPLICATION_SELECTION_LEVEL_MUTATION_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_SELECTION_LEVEL_MUTATION_CANCELLED,
      });
    }
  }
}

/* application list generator */
function* fetchApplicationsGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const response = yield call(fetchApplicationsGraphql, action.payload);
    yield put({
      type: actions.APPLICATIONS_FETCHING_DONE,
      payload: response.data.job.applications,
    });
    yield put({
      type: actions.APPLICATIONS_LOADING_DONE,
    });
  } catch (e) {
    yield put({ type: actions.APPLICATIONS_FETCHING_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATIONS_FETCH_CANCELLED,
      });
    }
  }
}

function* mutateApplicationAddTagGenerator(action) {
  try {
    const response = yield call(mutateApplicationAddTagGraphql, action.payload);
    const projectState = yield select((state) => state.Project);
    yield put({
      type: actions.APPLICATION_TAG_MUTATION_ADD_DONE,
      payload: response.data.addNewApplicationTag,
    });
    const id = projectState.getIn(['currentProject', 'id']);
    yield put({
      type: projectActions.APPLICATION_FILTER_FETCH,
      payload: id,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.APPLICATION_TAG_MUTATION_ADD_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_TAG_MUTATION_ADD_CANCELLED,
      });
    }
  }
}

function* mutateApplicationRemoveTagGenerator(action) {
  try {
    const response = yield call(mutateApplicationRemoveTagGraphql, action.payload);
    const projectState = yield select((state) => state.Project);
    yield put({
      type: actions.APPLICATION_TAG_MUTATION_REMOVE_DONE,
      payload: response.data.removeApplicationTag,
    });
    const id = projectState.getIn(['currentProject', 'id']);
    yield put({
      type: projectActions.APPLICATION_FILTER_FETCH,
      payload: id,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.APPLICATION_TAG_MUTATION_REMOVE_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_TAG_MUTATION_REMOVE_CANCELLED,
      });
    }
  }
}

function* mutateAddApplicationCommentGenerator(action) {
  try {
    yield put({
      type: actions.APPLICATION_COMMENT_ADD_MUTATION_LOADING,
    });
    const response = yield call(mutateApplicationAddComment, action.payload);
    yield put({
      type: actions.APPLICATION_COMMENT_ADD_MUTATION_DONE,
      payload: response.data.addApplicationComment,
    });
  } catch (e) {
    yield put({ type: actions.APPLICATION_COMMENT_ADD_MUTATION_ERROR });
  } finally {
    yield put({
      type: actions.APPLICATION_COMMENT_EDITOR_TOGGLE,
      payload: false,
    });
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_COMMENT_ADD_MUTATION_CANCELLED,
      });
    }
  }
}

function* mutateRemoveApplicationCommentGenerator(action) {
  try {
    const response = yield call(mutateApplicationRemoveComment, action.payload);
    yield put({
      type: actions.APPLICATION_COMMENT_ADD_MUTATION_DONE,
      payload: response.data.removeApplicationComment,
    });
  } catch (e) {
    yield put({ type: actions.APPLICATION_COMMENT_REMOVE_MUTATION_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_COMMENT_REMOVE_MUTATION_CANCELLED,
      });
    }
  }
}

function* mutateEditApplicationCommentGenerator(action) {
  try {
    yield put({
      type: actions.APPLICATION_COMMENT_ADD_MUTATION_LOADING,
    });
    const response = yield call(mutateApplicationEditComment, action.payload);
    yield put({
      type: actions.APPLICATION_COMMENT_EDIT_MUTATION_DONE,
      payload: response.data.editApplicationComment,
    });
  } catch (e) {
    yield put({ type: actions.APPLICATION_COMMENT_EDIT_MUTATION_ERROR });
  } finally {
    yield put({
      type: actions.APPLICATION_COMMENT_EDITOR_TOGGLE,
      payload: false,
    });
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_COMMENT_EDIT_MUTATION_CANCELLED,
      });
    }
  }
}

function* deleteApplicationGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const { id, projectId, resolve } = action.payload;

    yield call(deleteApplicationRequest, id);
    yield put({
      type: actions.APPLICATION_DELETE_DONE,
    });
    yield call(resolve);
    yield put(replace(links.project(projectId).url));

    yield put({
      type: projectActions.FETCH_PROJECT_START,
      payload: {
        variables: {
          id: projectId,
        },
        recache: true,
      },
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.APPLICATION_DELETE_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_DELETE_CANCELLED,
      });
    }
  }
}

function* mutateMarkAsUnreadGenerator(action) {
  try {
    const response = yield call(mutateMarkAsUnreadGeneratorGraphql, action.payload);
    yield put({
      type: actions.APPLICATION_MARK_AS_UNREAD_DONE,
      payload: response.data.markApplicationAsUnread,
    });
  } catch (e) {
    yield put({ type: actions.APPLICATION_MARK_AS_UNREAD_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_MARK_AS_UNREAD_CANCELLED,
      });
    }
  }
}

function* applicationHideGenerator(action) {
  try {
    yield call(applicationHideGraphql, action.payload);
    yield put({
      type: actions.APPLICATION_HIDE_DONE,
      payload: action.payload,
    });
    if (action.payload.state) {
      yield put({
        type: projectActions.TOGGLE_SHOW_HIDDEN,
        payload: false,
      });
    }

    yield put({
      type: cacheActions.INVALIDATE_APPLICATIONS,
      payload: [action.payload.applicationId],
    });
  } catch (e) {
    yield put({ type: actions.APPLICATION_HIDE_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.APPLICATION_HIDE_CANCELLED,
      });
    }
  }
}

// function* fetchApplicationEffectsGenerator() {
//   yield put({
//     type: jobActions.HIDE_APPLICATIONS_LIST,
//   });
// }

export function* fetchApplicationEffects() {
  // yield takeLatest(actions.APPLICATION_FETCH, fetchApplicationEffectsGenerator);
}

/* single application saga */
export function* fetchApplication() {
  yield takeLatest(
    [actions.APPLICATION_FETCH, actions.APPLICATION_REFRESH],
    fetchApplicationGenerator
  );
}

/* single application rating mutation saga */
export function* mutateApplicationRating() {
  yield takeLatest(actions.APPLICATION_RATING_MUTATION_START, mutateApplicationRatingGenerator);
}

/* single application selection level mutation saga */
export function* mutateApplicationSelectionLevel() {
  yield takeLatest(
    actions.APPLICATION_SELECTION_LEVEL_MUTATION_START,
    mutateApplicationSelectionLevelGenerator
  );
}

/* application list saga */
export function* fetchApplications() {
  yield takeLatest(actions.APPLICATIONS_FETCH, fetchApplicationsGenerator);
}

/* tag mutation */
export function* mutateAddApplicationTag() {
  yield takeLatest(actions.APPLICATION_TAG_MUTATION_ADD_START, mutateApplicationAddTagGenerator);
}

export function* mutateRemoveApplicationTag() {
  yield takeLatest(
    actions.APPLICATION_TAG_MUTATION_REMOVE_START,
    mutateApplicationRemoveTagGenerator
  );
}

export function* mutateAddApplicationComment() {
  yield takeLatest(
    actions.APPLICATION_COMMENT_ADD_MUTATION_START,
    mutateAddApplicationCommentGenerator
  );
}

export function* mutateRemoveApplicationComment() {
  yield takeLatest(
    actions.APPLICATION_COMMENT_REMOVE_MUTATION_START,
    mutateRemoveApplicationCommentGenerator
  );
}

export function* mutateEditApplicationComment() {
  yield takeLatest(
    actions.APPLICATION_COMMENT_EDIT_MUTATION_START,
    mutateEditApplicationCommentGenerator
  );
}

export function* deleteApplication() {
  yield takeEvery(actions.APPLICATION_DELETE_START, deleteApplicationGenerator);
}

export function* mutateMarkAsUnread() {
  yield takeLatest(actions.APPLICATION_MARK_AS_UNREAD_START, mutateMarkAsUnreadGenerator);
}

export function* applicationHide() {
  yield takeEvery(actions.APPLICATION_HIDE_START, applicationHideGenerator);
}

function* fetchComments() {
  yield takeLatest(actions.APPLICATION_COMMENTS_FETCH_START, fetchCommentsGenerator);
}

function* fetchTags() {
  yield takeLatest(actions.APPLICATION_TAGS_FETCH_START, fetchTagsGenerator);
}

function* fetchEvents() {
  yield takeLatest(actions.APPLICATION_EVENTS_FETCH_START, fetchEventsGenerator);
}

function* fetchRating() {
  yield takeLatest(actions.APPLICATION_RATING_FETCH_START, fetchRatingGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchApplication),
    spawn(fetchApplicationEffects),
    spawn(fetchApplications),
    spawn(mutateApplicationRating),
    spawn(mutateApplicationSelectionLevel),
    spawn(mutateAddApplicationTag),
    spawn(mutateRemoveApplicationTag),
    spawn(mutateAddApplicationComment),
    spawn(mutateEditApplicationComment),
    spawn(mutateRemoveApplicationComment),
    spawn(deleteApplication),
    spawn(mutateMarkAsUnread),
    spawn(applicationHide),
    spawn(fetchComments),
    spawn(fetchTags),
    spawn(fetchEvents),
    spawn(fetchRating),
  ]);
}
