import storeObject from 'redux/storeDeclaration';
import { getCurrentLanguage } from 'containers/LanguageSwitcher/config';
import AppLocale from './index';

const translate = (id) => {
  const store = storeObject.getStore();
  const currentAppLocale = AppLocale[getCurrentLanguage(store.getState().LanguageSwitcher.get('language').languageId).locale];
  return currentAppLocale.messages[id] || id;
};

export default translate;
