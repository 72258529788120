import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchInvoicesGraphql = () => {
  const query = gql`
    query invoices {
      invoices {
        id
        proformaInvoiceId
        invoiceId
        items
        status
        createTime
        sum
        pdfUrl
      }
    }
  `;

  return client.query({
    query,
  });
};

export default fetchInvoicesGraphql;
