import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntDatePicker = ComponentName => styled(ComponentName)`
  background-color: ${palette('background', 46)};
  color: ${palette('foreground', 0)};
  border-color: ${palette('borderColor', 10)};
  input {
    color: ${palette('foreground', 0)};
  }
`;

export default AntDatePicker;
