import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({ visible: false, banner: null });

export default function bannerReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_BANNER_DONE:
      return state.set('banner', action.payload).set('visible', !!action.payload);
    case actions.TOGGLE_BANNER:
      return state.set('visible', action.payload);
    default:
      return state;
  }
}
