import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  visible: false,
});

export default function overlayReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_GENERIC_OVERLAY:
      return state.set('visible', action.payload);
    default:
      return state;
  }
}
