import styled from 'styled-components';
import signInBg from './signin.bg.png';

const SignInStyleWrapper = styled.div`
  overflow: hidden;
  button {
    border: none;
    cursor: pointer;
  }
  .background-photo {
    background: url(${signInBg}) no-repeat center center fixed;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    bottom: 0px;
    filter: hue-rotate(314deg);
  }

  .background-photo::before {
    content: ' ';
    background: rgba(0, 0, 0, 0.32);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
  }
  * {
    outline: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .link {
    color: #3490dc;
  }
  .link:hover {
    color: #2779bd;
  }
  .require {
    color: #e3342f;
    font-weight: 600;
  }
  label {
    display: block;
    color: #8795a1;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  input[type='checkbox'] + label,
  input[type='radio'] + label {
    display: block;
    color: #8795a1;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
  }
  .input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 1px;
    border-style: solid;
    border-color: #b8c2cc;
    border-radius: 9999px;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    color: #606f7b;
    line-height: 1.25;
    font-size: 0.875rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
  }
  .radio {
    margin-right: 0.5rem;
  }
  .select {
    font-size: 0.875rem;
    display: block;
    appearance: none;
    color: #606f7b;
    width: 100%;
    background-color: #fff;
    border-width: 1px;
    border-color: #b8c2cc;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 2rem;
    border-radius: 9999px;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .error {
    border-color: #cc1f1a;
  }
  .error-message {
    color: #cc1f1a;
    display: inline-block;
    margin-top: 0.25rem;
    font-weight: 700;
    font-size: 0.875rem;
  }
  .success {
    border-color: #1f9d55;
  }
  .success-message {
    color: #1f9d55;
    display: inline-block;
    margin-top: 0.25rem;
    font-weight: 700;
    font-size: 0.875rem;
  }
  .button {
    display: block;
    background-color: #E1146B;
    color: #fff;
    font-weight: 700;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 9999px;
    margin-top: 1.5rem;
    border-color: transparent;
    cursor: pointer;
    font-size: 0.875rem;
    width: 100%;
  }
  @media (min-width: 768px) {
    .button {
      width: 33.33333%;
    }
  }
  .button:hover {
    background-color: #E1146BC9;
  }
  .bg-black {
    background-color: #22292f;
  }
  .bg-grey-lighter {
    background-color: #f1f5f8;
  }
  .bg-white {
    background-color: #fff;
  }
  .hover\\:bg-grey-light:hover {
    background-color: #dae1e7;
  }
  .border-grey {
    border-color: #b8c2cc;
  }
  .border-grey-light {
    border-color: #dae1e7;
  }
  .rounded {
    border-radius: 0.25rem;
  }
  .border-solid {
    border-style: solid;
  }
  .border {
    border-width: 1px;
  }
  .border-t-0 {
    border-top-width: 0;
  }
  .border-r-0 {
    border-right-width: 0;
  }
  .border-l-0 {
    border-left-width: 0;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .hidden {
    display: none;
  }
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .items-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .font-light {
    font-weight: 300;
  }
  .h-6 {
    height: 1.5rem;
  }
  .leading-normal {
    line-height: 1.5;
  }
  .m-0 {
    margin: 0;
  }
  .m-8 {
    margin: 2rem;
  }
  .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .max-w-sm {
    max-width: 30rem;
  }
  .opacity-50 {
    opacity: 0.5;
  }
  .p-8 {
    padding: 2rem;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .pointer-events-none {
    pointer-events: none;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .pin-y {
    top: 0;
    bottom: 0;
  }
  .pin-t {
    top: 0;
  }
  .pin-r {
    right: 0;
  }
  .shadow {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .fill-current {
    fill: currentColor;
  }
  .text-grey-darkest {
    color: #3d4852;
  }
  .text-grey-darker {
    color: #606f7b;
  }
  .text-white {
    color: #fff;
  }
  .text-2xl {
    font-size: 1.5rem;
  }
  .no-underline {
    text-decoration: none;
  }
  .w-6 {
    width: 1.5rem;
  }
  @media (min-width: 576px) {
    .sm\\:rounded-large {
      border-radius: 1rem;
    }
    .sm\\:items-center {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .sm\\:my-8 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 768px) {
    .md\\:flex-row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .md\\:mt-0 {
      margin-top: 0;
    }
  }
`;
const LangChange = styled.button`
  text-transform: uppercase;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0px;
  background: transparent;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
  outline: none;
`;

export { SignInStyleWrapper, LangChange };
