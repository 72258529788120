import { Map } from 'immutable';
import { AboutUs, ProfileFile } from 'types/profile.types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

export const PROFILE_ABOUT_US_UPDATE_START = 'PROFILE_ABOUT_US_UPDATE_START';
export const PROFILE_ABOUT_US_UPDATE_DONE = 'PROFILE_ABOUT_US_UPDATE_DONE';
export const PROFILE_ABOUT_US_UPDATE_CANCELLED = 'PROFILE_ABOUT_US_UPDATE_CANCELLED';
export const PROFILE_ABOUT_US_UPDATE_ERROR = 'PROFILE_ABOUT_US_UPDATE_ERROR';

export const PROFILE_ABOUT_US_UPDATE_FIELD = 'PROFILE_ABOUT_US_UPDATE_FIELD';

export const PROFILE_ABOUT_US_ADD_VIDEO = 'PROFILE_ABOUT_US_ADD_VIDEO';
export const PROFILE_ABOUT_US_UPDATE_VIDEO = 'PROFILE_ABOUT_US_UPDATE_VIDEO';
export const PROFILE_ABOUT_US_DELETE_VIDEO = 'PROFILE_ABOUT_US_DELETE_VIDEO';

export const PROFILE_ABOUT_US_ADD_FILE = 'PROFILE_ABOUT_US_ADD_FILE';
export const PROFILE_ABOUT_US_UPDATE_FILE = 'PROFILE_ABOUT_US_UPDATE_FILE';
export const PROFILE_ABOUT_US_DELETE_FILE = 'PROFILE_ABOUT_US_DELETE_FILE';

export interface ProfileAboutUsStateMap extends Map<string, any> {
  aboutUs: AboutUs | null;
  aboutUsSubmitting: boolean;
  aboutUsSubmitError: string | null;
  aboutUsSubmitSuccess: string | null;
}

export interface AboutUsFieldUpdateProps {
  field: string;
  value: any;
}

export interface AboutUsApiRequestDoneAction {
  type: typeof PROFILE_ABOUT_US_UPDATE_DONE;
  payload: { aboutUs: AboutUs; successMessage: string | null };
}

export interface AboutUsApiRequestAction {
  type: typeof PROFILE_ABOUT_US_UPDATE_START;
  payload: AboutUs;
}

export interface AboutUsApiRequestErrorAction {
  type: typeof PROFILE_ABOUT_US_UPDATE_ERROR;
  payload: string;
}

export type ProfileAboutUsState = ImmutableMapInterface<ProfileAboutUsStateMap, string, any>;

export interface AboutUsFileUpdateAction {
  type:
    | typeof PROFILE_ABOUT_US_ADD_FILE
    | typeof PROFILE_ABOUT_US_UPDATE_FILE
    | typeof PROFILE_ABOUT_US_DELETE_FILE;
  payload: ProfileFile;
}

export interface AboutUsVideoAddAction {
  type:
    | typeof PROFILE_ABOUT_US_ADD_VIDEO;
}

export interface VideoDelete {
  index: number;
}

export interface AboutUsVideoDeleteAction {
  type: typeof PROFILE_ABOUT_US_DELETE_VIDEO;
  payload: VideoDelete;
}

export interface AboutUsVideoUpdateAction {
  type:
    | typeof PROFILE_ABOUT_US_UPDATE_VIDEO;
  payload: VideoUpdate;
}

export interface AboutUsFieldUpdateAction {
  type: typeof PROFILE_ABOUT_US_UPDATE_FIELD;
  payload: AboutUsFieldUpdateProps;
}

export interface AboutUsNoPayloadAction {
  type: typeof PROFILE_ABOUT_US_UPDATE_CANCELLED;
}

export interface VideoUpdate {
  index: number;
  link: String;
}
