import { notification } from 'antd';
import axios from 'axios';
import 'blob-polyfill';
import TokenRefresher from 'helpers/tokenRefresher';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import actions from 'redux/auth/actions';
import maintenanceActions from 'redux/maintenance/actions';
import storeObject from 'redux/storeDeclaration';

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (config.progressBar === false) {
      Nprogress.start();
    }
    const newConfig = config;
    if (!config.headers.locale) {
      newConfig.headers.locale = storeObject
        .getStore()
        .getState()
        .LanguageSwitcher.get('language').locale;
    }
    if (!config.headers.Version) {
      newConfig.headers.ClientVersion = process.env.REACT_APP_CLIENT_VERSION;
    }
    return newConfig;
  },
  (error) => {
    Nprogress.done();
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    Nprogress.done();
    // Do something with response data
    return response;
  },
  (error) => {
    log(error);
    log(error.config.url);
    // Do something with response error
    const {
      config,
      response: {
        status,
        data: { message, userMessage },
      },
    } = error;
    const originalRequest = config;
    if (
      status === 403
      && (message === 'This token has expired' || error.response.data instanceof Blob)
    ) {
      if (!TokenRefresher.getRefreshing()) {
        TokenRefresher.refresh();
      }

      const retry = new Promise((resolve, reject) => {
        TokenRefresher.subscribeTokenRefresh((token) => {
          if (token) {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          } else {
            storeObject.getStore().dispatch(
              actions.logout({
                remember: true,
                requestLogin: true,
              }),
            );
            reject();
          }
        });
      });

      return retry;
    }

    if (status === 503) {
      storeObject.getStore().dispatch(maintenanceActions.toggleMaintenance(true));
    }

    if (userMessage) {
      notification.error({
        message: translate('error.userError'),
        description: translate(userMessage),
      });
    }
    Nprogress.done();
    return Promise.reject(error);
  },
);
