const faqActions = {
  FETCH_FAQ_START: 'FETCH_FAQ_START',
  FETCH_FAQ_DONE: 'FETCH_FAQ_DONE',
  FETCH_FAQ_ERROR: 'FETCH_FAQ_ERROR',
  FETCH_FAQ_CANCELLED: 'FETCH_FAQ_CANCELLED',

  fetchFaq: (payload: any) => ({
    type: faqActions.FETCH_FAQ_START,
    payload,
  }),
};

export default faqActions;
