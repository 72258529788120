import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LogSetup } from 'helpers/utility';
import { ConfigProvider } from 'antd';
import Profiler from 'symfony-spa-wdt';
import { store, history } from './redux/store';
import PublicRoutes from './router';
import themes from './config/themes';
import { themeConfig } from './config';
import AtsAppHolder from './AtsAppStyle';
import Boot from './redux/boot';
import 'Ats.less';
import 'containers/Ats/global.css';
import 'nprogress/nprogress.css';
import './helpers/axiosInterceptor';

LogSetup();

class AtsApp extends React.Component {
  componentDidMount() {
    const el = document.getElementById('preloader');
    if (!el.classList.contains('done')) {
      el.classList.add('done');
    }
  }

  render() {
    return (
      <React.Fragment>
        <ConfigProvider autoInsertSpaceInButton={false}>
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AtsAppHolder>
              <Provider store={store}>
                <PublicRoutes history={history} />
              </Provider>
            </AtsAppHolder>
          </ThemeProvider>
        </ConfigProvider>
        {process.env.NODE_ENV === 'development' && <Profiler />}
      </React.Fragment>
    );
  }
}

Boot()
  .then(() => AtsApp())
  .catch(error => console.error(error));

export default AtsApp;
