import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { CompanyTeamMember } from 'types/profile.types';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import createCompanyTeamMemberFromAPIResponse from './helpers';
import {
  companyTeamMemberDelete as companyTeamMemberDeleteRequest,
  companyTeamMemberUpdate as companyTeamMemberUpdateRequest
} from './requests';
import {
  CompanyTeamMemberApiRequestAction,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR,
  PROFILE_COMPANY_TEAM_MEMBER_DELETE_START,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR,
  PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START
} from './types';

const Nprogress = require('nprogress');

// function* companyTeamMemberCreateGenerator(action: CompanyTeamMemberApiRequestAction): any {
//   try {
//     yield call(() => {
//       Nprogress.start();
//     });

//     const storeState = yield select((s) => s.NewProfile);
//     const companyTeamMembers = storeState.get('companyTeamMembers');
//     yield put({ type: PROFILE_COMPANY_TEAM_MEMBER_STATE_ADD_TEAM_MEMBER
// , payload: action.payload });

//     const response = yield call(companyTeamMemberCreateRequest, action.payload);

//     const createdTeamMember = createCompanyTeamMemberFromAPIResponse(response.data);

//     yield put({
//       type: PROFILE_COMPANY_TEAM_MEMBER_CREATE_DONE,
//       payload: {
//         teamMembers: [createdTeamMember, ...companyTeamMembers],
//         successMessage: translate('profile.form.saved'),
//       },
//     });
//     yield put({
//       type: CREATE_PROFILE_SNAPSHOT,
//     });

//     message.success(translate('profile.form.saved'));
//   } catch (e) {
//     yield put({
//       type: PROFILE_COMPANY_TEAM_MEMBER_STATE_DELETE_TEAM_MEMBER,
//       payload: action.payload,
//     });
//     yield put({
//       type: PROFILE_COMPANY_TEAM_MEMBER_CREATE_ERROR,
//       payload: translate('error.tryAgainMessage'),
//     });
//     message.error(translate('error.tryAgainMessage'));
//   } finally {
//     yield call(() => {
//       Nprogress.done();
//     });
//     if (yield cancelled()) {
//       yield put({
//         type: PROFILE_COMPANY_TEAM_MEMBER_CREATE_CANCELLED,
//       });
//     }
//   }
// }

function* companyTeamMemberUpdateGenerator(action: CompanyTeamMemberApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const storeState = yield select((s) => s.NewProfile);
    const companyTeamMembers = storeState.get('companyTeamMembers');
    const response = yield call(companyTeamMemberUpdateRequest, action.payload);
    const teamMemberIndex = companyTeamMembers.findIndex(
      (v: CompanyTeamMember) => v.id === action.payload.id && v.type === action.payload.type
    );
    if (teamMemberIndex < 0) {
      const updatedTeamMember = createCompanyTeamMemberFromAPIResponse(
        response.data.companyProfileTeamMemberMutation
      );
      yield put({
        type: PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE,
        payload: {
          teamMembers: [updatedTeamMember, ...companyTeamMembers],
          successMessage: translate('profile.form.saved'),
        },
      });
      message.success(translate('profile.form.saved'));
    } else {
      const updatedTeamMember = createCompanyTeamMemberFromAPIResponse(
        response.data.companyProfileTeamMemberMutation
      );
      yield put({
        type: PROFILE_COMPANY_TEAM_MEMBER_UPDATE_DONE,
        payload: {
          teamMembers: Object.assign([], companyTeamMembers, {
            [teamMemberIndex]: updatedTeamMember,
          }),
          successMessage: translate('profile.form.saved'),
        },
      });
      message.success(translate('profile.form.saved'));
    }
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
  } catch (e) {
    log(e);
    yield put({
      type: PROFILE_COMPANY_TEAM_MEMBER_UPDATE_ERROR,
      payload: translate('error.tryAgainMessage'),
    });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_COMPANY_TEAM_MEMBER_UPDATE_CANCELLED,
      });
    }
  }
}

function* companyTeamMemberDeleteGenerator(action: CompanyTeamMemberApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    const companyTeamMembers = storeState.get('companyTeamMembers');
    yield call(companyTeamMemberDeleteRequest, action.payload);
    const teamMemberIndex = companyTeamMembers.findIndex(
      (v: CompanyTeamMember) => v.id === action.payload.id && v.type === action.payload.type
    );
    if (teamMemberIndex < 0) {
      yield put({
        type: PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR,
        payload: 'Undefined teamMember index',
      });
      message.error(translate('error.tryAgainMessage'));
    } else {
      yield put({
        type: PROFILE_COMPANY_TEAM_MEMBER_DELETE_DONE,
        payload: {
          teamMember: action.payload,
          teamMembers: companyTeamMembers.filter((v: CompanyTeamMember) => {
            if (v.id === action.payload.id && v.type === action.payload.type) {
              return false;
            }
            return true;
          }),
        },
      });
      message.success(translate('profile.form.saved'));
    }
  } catch (e) {
    log(e);
    yield put({ type: PROFILE_COMPANY_TEAM_MEMBER_DELETE_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: PROFILE_COMPANY_TEAM_MEMBER_DELETE_CANCELLED,
      });
    }
  }
}

function* companyTeamMemberUpdate() {
  yield takeLatest(PROFILE_COMPANY_TEAM_MEMBER_UPDATE_START, companyTeamMemberUpdateGenerator);
}

function* companyTeamMemberDelete() {
  yield takeLatest(PROFILE_COMPANY_TEAM_MEMBER_DELETE_START, companyTeamMemberDeleteGenerator);
}

export default function* rootSaga() {
  yield all([spawn(companyTeamMemberUpdate), spawn(companyTeamMemberDelete)]);
}
