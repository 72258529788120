import styled from 'styled-components';
import { palette } from 'styled-theme';

const AnimatedLoaderStyled = styled.div`
  display: block;
  text-align: center;
  padding: 40px;
  .loader {
    display: inline-block;
  }
  .loader-inner {
    > div {
      background: ${palette('background', 4)};
    }
  }
`;

export default AnimatedLoaderStyled;
