import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  packages: [],
  loading: false,
  denied: false,
});

export default function packagesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_PACKAGES_START:
      return state.set('loading', true).set('denied', false);

    case actions.FETCH_PACKAGES_DONE:
      return state.set('packages', action.payload).set('loading', false);

    case actions.FETCH_PACKAGES_DENIED:
      return state.set('denied', true);

    case actions.FETCH_PACKAGES_CANCELLED:
    case actions.FETCH_PACKAGES_ERROR:
      return state.set('loading', false);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
