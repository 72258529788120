import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { AboutUs, AttachmentPayload, ProfileFile } from 'types/profile.types';

const aboutUsUpdate = (payload: AboutUs) => {
  const variables = { ...payload };
  if (variables.files?.length) {
    variables.attachments = variables.files
      .filter((file) => file.file && file.type === 'new')
      .map(
        (file: any): AttachmentPayload => ({
          attachment: file.file,
          description: file.description || '',
        })
      );

    variables.files = variables.files
      .filter((file) => file.type !== 'new')
      .map((file: any): ProfileFile => omit(file, '__typename', 'type') as any);
  }

  const mutation = gql`
    mutation companyProfileAboutUsMutation(
      $profileId: Int!
      $aboutUsHtml: String
      $attachments: [CompanyProfileAttachmentInput!]
      $files: [CompanyProfileFileInput]
      $videoUrls: [String]
    ) {
      companyProfileAboutUsMutation(
        id: $profileId
        aboutUs: {
          aboutUsHtml: $aboutUsHtml
          attachments: $attachments
          files: $files
          videoUrls: $videoUrls
        }
      ) {
        aboutUsHtml
        videoUrls
        files {
          id
          path
          description
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export default aboutUsUpdate;
