const pricelistActions = {
  TOGGLE_PRICELIST_MODAL: 'TOGGLE_PRICELIST_MODAL',

  CHANGE_DURATION: 'CHANGE_DURATION',

  FETCH_PRICELIST_START: 'FETCH_PRICELIST_START',
  FETCH_PRICELIST_DONE: 'FETCH_PRICELIST_DONE',
  FETCH_PRICELIST_ERROR: 'FETCH_PRICELIST_ERROR',
  FETCH_PRICELIST_CANCELLED: 'FETCH_PRICELIST_CANCELLED',

  MUTATE_CREATE_INCOMPLETE_JOB_START: 'MUTATE_CREATE_INCOMPLETE_JOB_START',
  MUTATE_CREATE_INCOMPLETE_JOB_DONE: 'MUTATE_CREATE_INCOMPLETE_JOB_DONE',
  MUTATE_CREATE_INCOMPLETE_JOB_ERROR: 'MUTATE_CREATE_INCOMPLETE_JOB_ERROR',
  MUTATE_CREATE_INCOMPLETE_JOB_CANCELLED: 'MUTATE_CREATE_INCOMPLETE_JOB_CANCELLED',

  SET_LOADING: 'SET_LOADING',

  FETCH_ADVERT_POSITIONS_START: 'FETCH_ADVERT_POSITIONS_START',
  FETCH_ADVERT_POSITIONS_DONE: 'FETCH_ADVERT_POSITIONS_DONE',
  FETCH_ADVERT_POSITIONS_ERROR: 'FETCH_ADVERT_POSITIONS_ERROR',
  FETCH_ADVERT_POSITIONS_CANCELLED: 'FETCH_ADVERT_POSITIONS_CANCELLED',

  FETCH_PRICE_CALCULATION_START: 'FETCH_PRICE_CALCULATION_START',
  FETCH_PRICE_CALCULATION_DONE: 'FETCH_PRICE_CALCULATION_DONE',
  FETCH_PRICE_CALCULATION_ERROR: 'FETCH_PRICE_CALCULATION_ERROR',
  FETCH_PRICE_CALCULATION_CANCELLED: 'FETCH_PRICE_CALCULATION_CANCELLED',

  fetchAdvertPositions: () => ({
    type: pricelistActions.FETCH_ADVERT_POSITIONS_START,
  }),

  createIncompleteJob: payload => ({
    type: pricelistActions.MUTATE_CREATE_INCOMPLETE_JOB_START,
    payload,
  }),

  fetchPricelist: () => ({
    type: pricelistActions.FETCH_PRICELIST_START,
  }),

  togglePricelistModal: payload => ({
    type: pricelistActions.TOGGLE_PRICELIST_MODAL,
    payload,
  }),

  changeDuration: payload => ({
    type: pricelistActions.CHANGE_DURATION,
    payload,
  }),
};

export default pricelistActions;
