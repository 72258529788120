import { fromJS, Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  jobs: [],
  jobsCount: 0,
  jobsDoneLoading: false,
  currentJob: null,
  selectedApplicationId: new Map({}),
  jobLoading: true, // this shows the loader
  jobFetching: false, // this prevents refetching
  applicationFilters: {},
  selectedFilters: new Map({
    selectionLevel: [],
    rating: [],
    tags: [],
    candidateName: '',
    email: undefined,
  }),
  sort: new Map({
    name: null,
    selectionLevel: null,
    rating: null,
    preselectionPassed: null,
    rejected: null,
    tags: null,
  }),
  accessDenied: false,
  endSelectionJob: null,
  errorLoading: false,
  orderedAdDetails: null
});

export default function jobReducer(state = initState, action) {
  switch (action.type) {
    /**
     * single job fetching
     */
    case actions.JOB_FETCHING:
      return state
        .set('jobLoading', true)
        .set('jobFetching', true)
        .set('errorLoading', false)
        .set('accessDenied', false);
    case actions.JOB_CLEAR:
      return state
        .set('currentJob', null);
    case actions.JOB_FETCHING_DONE:
      return state
        .set('currentJob', fromJS(action.payload))
        .set('jobLoading', false)
        .set('errorLoading', false)
        .set('jobFetching', false);

    case actions.JOB_FETCHING_ERROR:
      return state.set('jobLoading', false).set('jobFetching', false).set('errorLoading', true);

    case actions.JOB_FETCHING_DENIED:
      return state.set('accessDenied', true);
    
    /**
     * implementation below is for multiple sort columns
     */
    // return state.setIn(
    //   ['sort', action.payload.field],
    //   sortDirectionFlow(action.payload.currentDirection),
    // );

    case actions.APPLICATION_FILTER_CLEAR:
      return state
        .set('applicationFilters', {})
        .set('selectedFilters', initState.get('selectedFilters'));


    case actions.END_SELECTION_SELECT_JOB:
      return state.set('endSelectionJob', action.payload || null);

    case actions.END_SELECTION_MUTATION_DONE:
      return state.set(
        'jobs',
        state.get('jobs').map((job) => {
          const newJob = job;
          if (action.payload.job === job) {
            newJob.selectionEnded = true;
          }
          return newJob;
        })
      );

    case actions.FETCH_JOBS_COUNT_DONE:
      return state.set('jobsCount', action.payload);

    /**
     * jobs fetching
     */
    case actions.DONE_FETCHING:
      return state.set('jobs', action.payload.job).set('jobsCount', action.payload.count);
    case actions.JOBS_LOADING_DONE:
      return state.set('jobsDoneLoading', true);
    case actions.FETCH_JOBS:
      return state.set('jobsDoneLoading', false);

    case actions.FETCH_ORDERED_AD_DATA_DONE:
      return state.set('orderedAdDetails', fromJS(action.payload));

    case actions.MUTATE_JOB_DEACTIVATE_START:
      return state.set(
        'jobs',
        state.get('jobs').map((job) => {
          const newJob = job;
          if (action.payload.id === job.id) {
            newJob.status = 'archive';
            newJob.online = false;
          }
          return newJob;
        })
      );

    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
