const tourActions = {
  TOUR_SET_INDEX: 'TOUR_SET_INDEX',
  TOUR_RESET: 'TOUR_RESET',

  TOUR_RUN_SECTION: 'TOUR_RUN_SECTION',
  TOUR_ADD_SECTION: 'TOUR_ADD_SECTION',
  TOUR_REMOVE_SECTION: 'TOUR_REMOVE_SECTION',
  TOUR_SET_SECTION_PARAMS: 'TOUR_SET_SECTION_PARAMS',

  RUN: 'RUN',
  STOP: 'STOP',
  CANCELL: 'CANCELL',
  RUN_CANCEL_TOUR: 'RUN_CANCEL_TOUR',
  RUN_CANCEL_TOUR_CLOSE: 'RUN_CANCEL_TOUR_CLOSE',

  VERIFY_CHECKPOINT_START: 'VERIFY_CHECKPOINT_START',
  VERIFY_CHECKPOINT_DONE: 'VERIFY_CHECKPOINT_DONE',
  VERIFY_CHECKPOINT_ERROR: 'VERIFY_CHECKPOINT_ERROR',
  VERIFY_CHECKPOINT_CANCELLED: 'VERIFY_CHECKPOINT_CANCELLED',

  VERIFY_CHECKPOINT_WITH_NOTIFICATION_START: 'VERIFY_CHECKPOINT_WITH_NOTIFICATION_START',
  VERIFY_CHECKPOINT_WITH_NOTIFICATION_DONE: 'VERIFY_CHECKPOINT_WITH_NOTIFICATION_DONE',
  VERIFY_CHECKPOINT_WITH_NOTIFICATION_ERROR: 'VERIFY_CHECKPOINT_WITH_NOTIFICATION_ERROR',
  VERIFY_CHECKPOINT_WITH_NOTIFICATION_CANCELLED: 'VERIFY_CHECKPOINT_WITH_NOTIFICATION_CANCELLED',

  FINISH_CHECKPOINT: 'FINISH_CHECKPOINT',

  resetTour: () => ({
    type: tourActions.TOUR_RESET,
  }),

  removeSection: (section) => ({
    type: tourActions.TOUR_REMOVE_SECTION,
    payload: section,
  }),

  verifyCheckpoint: (section, contentId) => ({
    type: tourActions.VERIFY_CHECKPOINT_START,
    payload: {
      section,
      contentId,
    },
  }),

  verifyCheckpointWithNotification: (section, contentId) => ({
    type: tourActions.VERIFY_CHECKPOINT_WITH_NOTIFICATION_START,
    payload: {
      section,
      contentId,
    },
  }),

  setIndex: (index) => ({
    type: tourActions.TOUR_SET_INDEX,
    payload: index,
  }),

  addSection: (section) => ({
    type: tourActions.TOUR_ADD_SECTION,
    payload: section,
  }),

  runSection: (section, state) => ({
    type: tourActions.TOUR_RUN_SECTION,
    payload: {
      section,
      state: typeof state !== 'undefined' ? state : tourActions.RUN,
    },
  }),

  setSectionParams: (section, params) => ({
    type: tourActions.TOUR_SET_SECTION_PARAMS,
    payload: {
      section,
      params,
    },
  }),
};

export default tourActions;
