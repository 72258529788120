import { cookie } from 'helpers/utility';
import { all, call, cancelled, put, select, spawn, takeEvery } from 'redux-saga/effects';
import languageActions from 'redux/languageSwitcher/actions';
import actions from './actions';
import fetchBannerRequest from './requests';

const filterAnnouncements = announcements => {
  const filteredAnnouncements = announcements.filter(
    announcement => !cookie.get(`hide_announcement_${announcement.id}`)
  );
  return filteredAnnouncements[0] || null;
};

function* fetchBannerGenerator() {
  try {
    const response = yield call(fetchBannerRequest);
    yield put({
      type: actions.FETCH_BANNER_DONE,
      payload: filterAnnouncements(response.data),
    });
  } catch (e) {
    yield put({ type: actions.FETCH_BANNER_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_BANNER_CANCELLED,
      });
    }
  }
}

function* toggleBannerGenerator(action) {
  try {
    const announcement = yield select(state => state.Banner.get('banner'));
    if (action.payload === false) {
      yield call(() => cookie.set(`hide_announcement_${announcement.id}`, true, 15));
    }
  } catch (e) {
    log(e);
  }
}

export function* fetchBanner() {
  yield takeEvery(
    [actions.FETCH_BANNER_START, languageActions.CHANGE_LANGUAGE],
    fetchBannerGenerator
  );
}

export function* hideBanner() {
  yield takeEvery(actions.TOGGLE_BANNER, toggleBannerGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchBanner), spawn(hideBanner)]);
}
