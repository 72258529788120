import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { AttachmentPayload, ProfileFile, SelectionProcess } from 'types/profile.types';

const selectionProcessUpdate = (selectionProcess: SelectionProcess) => {
  const variables = { ...selectionProcess };

  if (variables.files?.length) {
    variables.attachments = variables.files
      .filter((file) => file.file && file.type === 'new')
      .map(
        (file: any): AttachmentPayload => ({
          attachment: file.file,
          description: file.description || '',
        })
      );

    variables.files = variables.files
      .filter((file) => file.type !== 'new')
      .map((file: any): ProfileFile => omit(file, '__typename', 'type') as any);
  }

  const mutation = gql`
    mutation companyProfileSelectionProcessMutation(
      $profileId: Int!
      $attachments: [CompanyProfileAttachmentInput!]
      $files: [CompanyProfileFileInput]
      $selectionDuration: Int
      $selectionPhases: Int
      $selectionProcessHtml: String
      $selectionType: [String]
      $videoUrls: [String]
    ) {
      companyProfileSelectionProcessMutation(
        id: $profileId
        selectionProcess: {
          attachments: $attachments
          files: $files
          selectionDuration: $selectionDuration
          selectionPhases: $selectionPhases
          selectionProcessHtml: $selectionProcessHtml
          selectionType: $selectionType
          videoUrls: $videoUrls
        }
      ) {
        files {
          id
          path
          description
        }
        selectionDuration
        selectionPhases
        selectionProcessHtml
        selectionType
        videoUrls
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export default selectionProcessUpdate;
