import { Map } from 'immutable';
import { ProfileActions, ProfileState } from '../types';
import {
  CompanyAddress,
  ContactSectionState,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR,
  PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR,
  PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START,
  PROFILE_CONTACT_SECTION_STATE_ADD_COMPANY_ADDRESS,
  PROFILE_CONTACT_SECTION_STATE_REMOVE_COMPANY_ADDRESS,
  PROFILE_CONTACT_SECTION_STATE_UPDATE_COMPANY_ADDRESS,
  PROFILE_CONTACT_SECTION_STATE_UPDATE_CONTACT_INFO_FIELD,
  PROFILE_CONTACT_SECTION_STATE_UPDATE_EMPLOYEE_COUNT_OPTION,
  PROFILE_CONTACT_SECTION_STATE_UPDATE_SOCIAL_NETWORKS_FIELD,
  PROFILE_CONTACT_SECTION_STATE_UPDATE_WEBSITE,
  PROFILE_CONTACT_SECTION_UPDATE_CANCELLED,
  PROFILE_CONTACT_SECTION_UPDATE_DONE,
  PROFILE_CONTACT_SECTION_UPDATE_ERROR,
  PROFILE_CONTACT_SECTION_UPDATE_START
} from './types';

const initState: ContactSectionState = Map<string, any>({
  countries: [],
  employeeCountOptions: [],

  website: '',
  employeeCountOption: null,
  contactInfo: null,
  socialNetworks: null,
  companyAddresses: null,

  contactSectionUpdating: false,
  contactSectionUpdateError: null,
  contactSectionUpdateSuccess: null,

  countriesFetching: false,
  countriesFetchError: null,

  employeeCountOptionsFetching: false,
  employeeCountOptionsFetchError: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    /* Contact section update */
    case PROFILE_CONTACT_SECTION_UPDATE_START:
      return state
        .set('contactSectionUpdating', true)
        .set('contactSectionUpdateError', null)
        .set('contactSectionUpdateSuccess', null);
    case PROFILE_CONTACT_SECTION_UPDATE_ERROR:
      return state
        .set('contactSectionUpdating', false)
        .set('contactSectionUpdateError', action.payload);
    case PROFILE_CONTACT_SECTION_UPDATE_CANCELLED:
      return state.set('contactSectionUpdating', false);
    case PROFILE_CONTACT_SECTION_UPDATE_DONE:
      return state
        .set('contactSectionUpdating', false)
        .set('contactSectionUpdateSuccess', action?.payload?.successMessage);
    /* Fetch countries */
    case PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_START:
      return state.set('countriesFetching', true).set('countriesFetchError', null);
    case PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_ERROR:
      return state.set('countriesFetching', false).set('countriesFetchError', action.payload);
    case PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_CANCELLED:
      return state.set('countriesFetching', false);
    case PROFILE_CONTACT_SECTION_FETCH_COUNTRIES_DONE:
      return state.set('countriesFetching', false).set('countries', action.payload);
    /* Fetch emplyoee count options */
    case PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_START:
      return state
        .set('employeeCountOptionsFetching', true)
        .set('employeeCountOptionsFetchError', null);
    case PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_ERROR:
      return state
        .set('employeeCountOptionsFetching', false)
        .set('employeeCountOptionsFetchError', action.payload);
    case PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_CANCELLED:
      return state.set('employeeCountOptionsFetching', false);
    case PROFILE_CONTACT_SECTION_FETCH_EMPLOYEE_COUNT_OPTIONS_DONE:
      return state
        .set('employeeCountOptionsFetching', false)
        .set('employeeCountOptions', action.payload);
    /* State updates */
    case PROFILE_CONTACT_SECTION_STATE_UPDATE_WEBSITE:
      return state.set('website', action.payload);
    case PROFILE_CONTACT_SECTION_STATE_UPDATE_EMPLOYEE_COUNT_OPTION:
      return state.set('employeeCountOption', action.payload);
    case PROFILE_CONTACT_SECTION_STATE_UPDATE_CONTACT_INFO_FIELD: {
      const contactInfo = state.get('contactInfo');
      return state.set('contactInfo', {
        ...contactInfo,
        [action.payload.field]: action.payload.value,
      });
    }
    case PROFILE_CONTACT_SECTION_STATE_UPDATE_SOCIAL_NETWORKS_FIELD: {
      const socialNetworks = state.get('socialNetworks');
      return state.set('socialNetworks', {
        ...socialNetworks,
        [action.payload.field]: action.payload.value,
      });
    }

    case PROFILE_CONTACT_SECTION_STATE_UPDATE_COMPANY_ADDRESS:
      return state.set(
        'companyAddresses',
        state.get('companyAddresses').map((companyAddress: CompanyAddress, index: number) => {
          if (index === action.payload.index) {
            companyAddress.address = action.payload.value;
          }
          return companyAddress;
        })
      );

    case PROFILE_CONTACT_SECTION_STATE_ADD_COMPANY_ADDRESS:
      return state.set('companyAddresses', [
        ...state.get('companyAddresses'),
        {
          address: '',
        },
      ]);

    case PROFILE_CONTACT_SECTION_STATE_REMOVE_COMPANY_ADDRESS: {
      return state.set(
        'companyAddresses',
        state.get('companyAddresses').filter((companyAddress: CompanyAddress, index: number) => {
          return index !== action.payload.index;
        })
      );
    }
    default:
      return state;
  }
};

export default initState;

export { initState, reducer };
