import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchPackagesGraphql = (payload) => {
  const params = {};
  const { recache, variables } = payload;

  if (recache) {
    params.fetchPolicy = 'network-only';
  }
  const query = gql`
    query packages($includePending: Boolean) {
      companyPackages(includePending: $includePending) {
        id
        activationDate
        expirationDate
        balance
        initialBalance
        jobPackageType
        jobType
        packageDuration
        companyName
        combo
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

export default fetchPackagesGraphql;
