import clone from 'clone';
import { transparentize } from 'polished';
import { palette, primaries, shadowColor, shadowSize } from './palette';
import defaultTheme from './themedefault';

const theme = clone(defaultTheme);

theme.name = 'atsFancy';

theme.input = {
  padding: '8px 8px',
  fontSize: '0.8rem',
};

theme.palette = {
  ...theme.palette,
  ...palette,
};

theme.palette.primary = theme.palette.primary.concat(primaries);

theme.palette.border = [
  theme.palette.primary[32], // 0: Border
  theme.palette.primary[22], // 1: Job content
  theme.palette.primary[39], // 2: acl wrapper
];

theme.palette.background = [
  theme.palette.primary[15], // 0: default text color
  theme.palette.primary[26], // 1: rating star color
  theme.palette.primary[29], // 2: header background
  theme.palette.primary[29], // 3: left menu background
  theme.palette.primary[28], // 4: animated loader color
  theme.palette.primary[42], // 5: comment header background
  theme.palette.primary[29], // 6: flowpaper toolbar background
  theme.palette.primary[14], // 7: flowpaper viewer background
  theme.palette.primary[14], // 8: flowpaper toolbar button background
  theme.palette.primary[14], // 9: single job background
  theme.palette.primary[29], // 10: layout wrapper background
  theme.palette.primary[14], // 11: main content background
  theme.palette.primary[14], // 12: application background
  theme.palette.primary[14], // 13: application attachment list background
  theme.palette.primary[43], // 14: profile list background
  theme.palette.primary[14], // 15: profile list item background
  theme.palette.red[0], // 16: profile list rejected item background
  theme.palette.grey[0], // 17: profile list item hover background
  theme.palette.primary[39], // 18: right sidebar background
  theme.palette.primary[18], // 19: sandwich color
  theme.palette.primary[14], // 20: searchbox icon
  theme.palette.primary[14], // 21: dropdown popover background
  theme.palette.primary[14], // 22: sortwrapper background
  theme.palette.blue[2], // 23: initials circle background
  theme.palette.primary[31], // 24: status menu background
  theme.palette.primary[14], // 25: drag item background
  theme.palette.primary[14], // 26: job sort background
  theme.palette.primary[29], // 27: aside header background
  theme.palette.primary[35], // 28: application list item selected
  theme.palette.primary[14], // 29: initials circle
  theme.palette.primary[37], // 30: left menu item selected
  theme.palette.primary[14], // 31: button background
  theme.palette.primary[14], // 32: checkbox background
  theme.palette.primary[14], // 33: expand handle background
  theme.palette.primary[14], // 34: Dropdown background
  theme.palette.primary[14], // 35: application search bar background
  theme.palette.primary[37], // 36: timeline item background
  theme.palette.primary[29], // 37: main padding background
  theme.palette.primary[63], // 38: job status background
  theme.palette.primary[41], // 39: team acl wrapper background
  theme.palette.primary[14], // 40: modal background
  theme.palette.primary[42], // 41: preselection background
  theme.palette.green[0], // 42: preselection correct answer background
  theme.palette.primary[44], // 43: greybox background
  theme.palette.primary[14], // 44: searchbox input background
  theme.palette.primary[14], // 45: new tag background
  theme.palette.primary[14], // 46: input background
  theme.palette.primary[46], // 47: input disabled background
  theme.palette.primary[14], // 48: profile list item option background
  theme.palette.primary[14], // 49: flowpaper zoom button background
  theme.palette.primary[14], // 50: applications selection header background
  theme.palette.primary[46], // 51: userchip background
  theme.palette.primary[14], // 52: tabs background
  theme.palette.primary[30], // 53: no results background
  theme.palette.primary[35], // 54: renew button background
  theme.palette.primary[53], // 55: job tag background
  theme.palette.primary[54], // 56: table hover background
  theme.palette.primary[53], // 57: input addon background
  theme.palette.primary[29], // 58: profile image placeholder background
  theme.palette.primary[14], // 59: select box background
  theme.palette.primary[35], // 60: banner background
  theme.palette.primary[35], // 61: primary background
  theme.palette.primary[35], // 62: contact button background
  theme.palette.primary[32], // 63: unread background
  transparentize(0.95, theme.palette.primary[17]), // 64: job status ordering background
  theme.palette.primary[22], // 65: job button hover background
  theme.palette.primary[57], // 66: dropdown hover background
  theme.palette.grayscale[4], // 67: select tag background
  theme.palette.primary[14], // 68: select clear button under X
  theme.palette.primary[60], // 69: tag background
  theme.palette.primary[59], // 70: alert error background
  theme.palette.primary[63], // 71: scroll background
  theme.palette.primary[65], // 72: team temp alert info background
  theme.palette.primary[66], // 73: bulk actions background
  theme.palette.primary[14], // 74: message background
  theme.palette.primary[57], // 75: alert info background
  theme.palette.primary[69], // 76: profile promo background
  theme.palette.primary[14], // 77: tooltip background
  theme.palette.primary[14], // 78: notification background
  theme.palette.primary[77], // 79: notification feature background
  theme.palette.primary[42], // 80: cover letter background
  theme.palette.primary[14], // 81: ordered job background
  theme.palette.primary[14], // 82: page selector button
  theme.palette.primary[14], // 83: ad type background
  theme.palette.primary[14], // 84: radio button background
  theme.palette.primary[0], // 85: radio button selected background
  theme.palette.primary[14], // 86: InputNumber background
  theme.palette.grayscale[8], // 87: InputNumber background (active)
  theme.palette.primary[14], // 88: Steps
  theme.palette.grayscale[4], // 89: Online interviews header
  theme.palette.grayscale[4], // 90: email template variable background
  theme.palette.primary[43], // 91: photo background
  theme.palette.primary[82], // 92 CV database highlight background
  theme.palette.primary[17], // 93 standout orange for loyaltyreport
  theme.palette.primary[85], // 94 standout purple for loyaltyreport
  theme.palette.primary[14], // 95 section background color
];

theme.palette.foreground = [
  theme.palette.primary[15], // 0: default text color
  theme.palette.primary[26], // 1: rating star color
  theme.palette.primary[35], // 2: left menu link color
  theme.palette.primary[35], // 3: left menu highlight link color
  theme.palette.primary[35], // 4: left menu selected link color
  theme.palette.primary[35], // 5: left menu link hover color
  theme.palette.primary[15], // 6: initials circle
  theme.palette.primary[15], // 7: divider text
  theme.palette.primary[15], // 8: default button text
  theme.palette.primary[20], // 9: faded text color
  theme.palette.primary[35], // 10: status text color
  theme.palette.primary[23], // 11: profile list item option text color
  theme.palette.primary[45], // 12: dropdown hover color
  theme.palette.primary[48], // 13: disabled input text color
  theme.palette.primary[49], // 14: application list email
  theme.palette.primary[50], // 15: phone number color
  theme.palette.primary[35], // 16: promo divider text color
  theme.palette.primary[14], // 17: renew button text
  theme.palette.primary[35], // 18: hightlight color text
  theme.palette.primary[52], // 19: job tag text color
  theme.palette.primary[15], // 20: select text
  theme.palette.primary[14], // 21: banner text
  theme.palette.primary[35], // 22: primary color text
  theme.palette.primary[32], // 23: unread text
  theme.palette.primary[17], // 24: generic highlights
  theme.palette.primary[20], // 25: comment time
  theme.palette.primary[35], // 26: comment highlight
  theme.palette.primary[35], // 27: dark mode switch color
  theme.palette.primary[72], // 28: new application notification
  theme.palette.primary[74], // 29: notification text
  theme.palette.primary[76], // 30: notification feature icon
  theme.palette.primary[17], // 31: notification new application icon
  theme.palette.primary[74], // 32: notification feature text
  theme.palette.primary[14], // 33: package promo text
  theme.palette.primary[30], // 34: subtle divider
  theme.palette.primary[45], // 35: page selector button
  theme.palette.primary[0], // 36: radio primary color
  theme.palette.primary[29], // 37: radio button selected foreground
  transparentize(0.92, theme.palette.primary[0]), // 38: radio focused shadow color,
  theme.palette.primary[0], // 39: radio border color
  transparentize(0.92, theme.palette.primary[0]), // 40: InputNumber focused shadow color
  theme.palette.primary[45], // 41: generic link color
  theme.palette.primary[81], // 42: antd blue
  theme.palette.primary[35], // 43: job status foreground
  theme.palette.primary[17], // 44: job status ordered foreground
  theme.palette.blue[5], // 45: email template variable foreground
  theme.palette.red[2], // 46: template editor invalid color
  theme.palette.primary[35], // 47 CV database highlight foreground
  theme.palette.primary[83], // 48 custom class for standout-text
  theme.palette.primary[17], // 49 standout orange for loyaltyreport
  theme.palette.primary[85], // 50 standout purple for loyaltyreport
  theme.palette.primary[87], // 51 border color for loyaltyreport
];

theme.palette.fontWeight = [
  '700', // 0: left menu highlight
];

theme.palette.borderRadius = [
  '10px 0px 0px 10px', // 0: application wrapper
  '7px', // 1: button
  '10px', // 2: profile list
  '0px 10px 10px 0px', // 3: right sidebar
  '0px 20px 20px 0px', // 4: left menu selected item
  '0px 20px 20px 0px', // 5: sort wrapper
  '20px 0px 0px 20px', // 6: filter wrapper
  '20px', // 7: last child filter wrapper
  '20px 0px 0px 20px', // 8: application search bar wrapper
  '20px 20px 20px 20px', // 9: application search bar no sort wrapper
  '20px 20px 20px 20px', // 10: filter no sort wrapper
  '10px', // 11: package order form container
];

theme.palette.borderColor = [
  theme.palette.primary[36], // 0: button border
  theme.palette.primary[36], // 1: profile list item button border
  theme.palette.primary[51], // 2: profile header bottom border
  theme.palette.primary[36], // 3: job tag border color
  theme.palette.primary[56], // 4: profile image placeholder border
  theme.palette.primary[36], // 5: select box border
  theme.palette.primary[62], // 6: tab border
  theme.palette.primary[73], // 7: new application notification
  theme.palette.primary[75], // 8: notification
  theme.palette.primary[45], // 9: page selector button
  theme.palette.primary[36], // 10: simple input
];

theme.palette.shadowStyle = [
  `${shadowSize[2]} ${shadowColor[1]}`, // 0: action button shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 1: job shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 2: job hover shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 3: main content shadow
  `${shadowSize[2]} ${shadowColor[0]}`, // 4: application wrapper shadow
  `${shadowSize[4]} ${shadowColor[2]}`, // 5: attachment list shadow
  `${shadowSize[3]} ${shadowColor[1]}`, // 6: attachment list hover shadow
  `${shadowSize[0]} ${shadowColor[1]}`, // 7: attachment list active shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 8: application filter shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 9: application filter hover shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 10: application filter focus shadow
  `${shadowSize[2]} ${shadowColor[0]}`, // 11: profile list shadow
  `${shadowSize[2]} ${shadowColor[0]}`, // 12: right sidebar shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 13: search bar shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 14: search bar hover shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 15: search bar focus shadow
  `${shadowSize[2]} ${shadowColor[0]}`, // 16: dropdown shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 17: sort shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 18: sort hover shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 19: job sort wrapper shadow
  `${shadowSize[5]} ${shadowColor[1]}`, // 20: job sort wrapper hover shadow
  `${shadowSize[5]} ${shadowColor[1]}`, // 21: job sort wrapper focus shadow
  `${shadowSize[0]} ${shadowColor[1]}`, // 22: page selector button shadow
  `${shadowSize[7]} ${shadowColor[4]}`, // 23: header shadow
  `${shadowSize[7]} ${shadowColor[4]}`, // 24: search bar shadow
  `${shadowSize[2]} ${shadowColor[1]}`, // 25: search bar hover shadow
  `${shadowSize[10]} ${shadowColor[3]}`, // 26: input shadow style
  `${shadowSize[12]} ${shadowColor[1]}`, // 27: seller image shadow style
  `${shadowSize[2]} ${shadowColor[8]}`, // 28: pricelist highlight
  `${shadowSize[6]} ${shadowColor[1]}`, // 29: ad type shadow
  `${shadowSize[13]} ${shadowColor[10]}`, // 30: ad type shadow (hover)
  `${shadowSize[13]} ${shadowColor[10]}`, // 31: package order form container
  `${shadowSize[13]} ${shadowColor[10]}`, // 32: banner shadow
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 33: job shadow (mobile)
  `0 10px 15px -3px rgba(125,59,236,.1), 0 4px 6px -2px rgba(125,59,236,.05)`, // 34: breadcrumg
];

theme.palette.borderStyle = [
  '0px', // 0: header border
  `7px solid ${theme.palette.primary[35]}`, // 1: left menu selected item;
  '1px solid #e8e8e8', // 2: divider
  `1px solid ${theme.palette.primary[22]}`, // 3: light divider
  `1px solid ${theme.palette.primary[43]}`, // 4: preselection divider
  '1px solid #e8e8e8', // 5: select tag border
  `1px solid ${theme.palette.primary[36]}`, // 6: default button border
  `1px solid ${theme.palette.primary[68]}`, // 7: alert info border
  `1px solid ${theme.palette.primary[71]}`, // 8: tooltip border
  `1px solid ${theme.palette.primary[79]}`, // 9: menu divider
  `1px solid ${theme.palette.foreground[34]}`, // 10: subtle border
  '0px', // 11: breadcrumb
];

theme.palette.mobile = ['991px', '1440px', '1537px'];

theme.palette.spacing = [
  '20', // 0: layout spacing
  '0', // 1: spacing between application and sidebar
];

theme.palette.size = [
  '45', // 0: header height
  '240', // 1: sidebar width
  '380', // 2: profile list
  '320', // 3: right sidebar width
  '140', // 4: rightsidebar comment input wrapper size
  '40', // 5: application search height
  '380', // 6: application search width
  '0', // 7: actions bar
  '0', // 8: application search actions width
  '280', // 9: profile list collapsed width
  '380', // 10: right floater size
  '40', // 11: banner size
  '10', // 12: scroll size
  '2', // 13: scroll size mobile
  '400', // 14: notification list on home page
  '57', // 15: small sidebar width
];
theme.sizes = {
  bigLetters: '0.9rem',
};

theme.palette.padding = [
  '0 16px 0 16px', // 0: left menu item padding
  '0 16px 0 25px', // 1: left menu item hover padding
  '0 16px 0 9px', // 2: left menu item selected padding
];

theme.palette.focusOutline = [
  '3px solid rgba(24, 144, 255, .06)', // 0: radio button focus style
];

export default theme;
