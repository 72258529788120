import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createMiddleware } from 'redux-beacon';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import analyticsEventMap from './analyticsEventMap';
import reducers from './reducers';
import rootSaga from './sagas';
import storeObject from './storeDeclaration';

const devMiddleWare = [];
const enhancers = [];
if (process.env.NODE_ENV !== 'production' && !window.localStorage.getItem('disable-redux-logger')) {
  devMiddleWare.push(logger);
}

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const analyticsMiddleware = createMiddleware(analyticsEventMap, GoogleTagManager());
const middlewares = [routeMiddleware, analyticsMiddleware, sagaMiddleware, thunk, ...devMiddleWare];

enhancers.push(applyMiddleware(...middlewares));

/* eslint-disable */
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV !== 'production'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
/* eslint-enable */

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  }),
  composeEnhancers(...enhancers)
);

(storeObject as any).store = store;

if (process.env.NODE_ENV !== 'production') {
  if ((module as any).hot) {
    (module as any).hot.accept('./reducers', () => {
      // @ts-ignore
      store.replaceReducer(reducers);
    });
  }
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, history };
