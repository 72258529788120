import {
  all, takeLatest, put, spawn, call, cancelled,
} from 'redux-saga/effects';
import actions from './actions';
import fetchInvoicesGraphql from './graphql';

function* fetchInvoicesGenerator() {
  try {
    const response = yield call(fetchInvoicesGraphql);
    yield put({
      type: actions.FETCH_INVOICES_DONE,
      payload: response.data.invoices,
    });
  } catch (e) {
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.FETCH_INVOICES_DENIED });
    }
    yield put({ type: actions.FETCH_INVOICES_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_INVOICES_CANCELLED,
      });
    }
  }
}

export function* fetchInvoices() {
  yield takeLatest(
    actions.FETCH_INVOICES_START,
    fetchInvoicesGenerator,
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchInvoices),
  ]);
}
