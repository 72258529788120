import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchRecruitRankGraphql = () => {
  const query = gql`
    query recruitRank {
      recruitRank {
        display
        link
      }
    }
  `;

  return client.query({
    query,
  });
};

export default fetchRecruitRankGraphql;
