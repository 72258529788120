const actions = {
  LOGOUT: 'LOGOUT',
  TOGGLE_SETTINGS_FLOATER: 'TOGGLE_SETTINGS_FLOATER',

  TOGGLE_MAXIMIZE: 'TOGGLE_MAXIMIZE',
  TOGGLE_SIDEBAR_SLIDEOUT: 'TOGGLE_SIDEBAR_SLIDEOUT',

  TOGGLE_MINI_SIDEBAR: 'TOGGLE_MINI_SIDEBAR',

  toggleSidebarSlideOut: (payload) => ({
    type: actions.TOGGLE_SIDEBAR_SLIDEOUT,
    payload,
  }),

  toggleMaximize: (payload) => ({
    type: actions.TOGGLE_MAXIMIZE,
    payload,
  }),

  toggleSettingsFloater: (payload) => ({
    type: actions.TOGGLE_SETTINGS_FLOATER,
    payload,
  }),

  toggleMiniSidebar: (payload) => ({
    type: actions.TOGGLE_MINI_SIDEBAR,
    payload,
  }),
};

export default actions;
