import * as Sentry from '@sentry/browser';
import { message } from 'antd';
import { getLanguageByLocale } from 'containers/LanguageSwitcher/config';
import links from 'helpers/links';
import { socketConnect } from 'helpers/socket-connector';
import { isDemo } from 'helpers/utility';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import { all, call, cancelled, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import authActions from 'redux/auth/actions';
import languageActions from 'redux/languageSwitcher/actions';
import actions from './actions';
import {
  fetchCurrentEmployerGraphql,
  fetchEmployerGraphql,
  mutateEmployerBasicGraphql,
  mutateEmployerGraphql,
} from './graphql';
import { uploadPhotoRequest } from './requests';

function* getUserGenerator() {
  try {
    yield put({
      type: actions.LOADING_USER,
    });
    const response = yield call(fetchCurrentEmployerGraphql);
    yield put({
      type: actions.USER_LOADED,
      payload: response.data.user,
    });
    const languageState = yield select((state) => state.LanguageSwitcher);
    if (languageState.get('language').locale !== response.data.user.locale) {
      yield put({
        type: languageActions.CHANGE_LANGUAGE,
        language: getLanguageByLocale(response.data.user.locale),
      });
    }
    yield call(() => {
      if (!window.dataLayer.filter((item) => item.userData).length) {
        window.dataLayer.push({
          userData: {
            uid: response.data.user.uid,
            cid: response.data.user.cid,
          },
          devicePixelRatio: window.getDevicePixelRatio(),
        });
      }
    });
    yield call(() => {
      if (window.olark) {
        const {
          data: {
            user: { name, email, contactPhone: phoneNumber, companyId },
          },
        } = response;
        window.olark('api.visitor.updateFullName', {
          fullName: name,
        });
        window.olark('api.visitor.updateEmailAddress', {
          emailAddress: email,
        });

        window.olark('api.visitor.updatePhoneNumber', {
          phoneNumber,
        });
        window.olark('api.chat.updateVisitorStatus', {
          snippet: ['CRM', links.crm(companyId).url],
        });
      }
      if (window.Tawk_API && !isDemo()) {
        const {
          data: {
            user: { name, email, contactPhone: phoneNumber, companyId, tawkHash },
          },
        } = response;
        try {
          // do it twice because i have no idea if widget is loaded
          window.Tawk_API.setAttributes(
            {
              name,
              email,
              phoneNumber,
              crm: links.crm(companyId).url,
              hash: tawkHash,
            },
            (error) => {
              log(error);
            }
          );
          window.Tawk_API.onLoad = () => {
            window.Tawk_API.setAttributes(
              {
                name,
                email,
                phoneNumber,
                crm: links.crm(companyId).url,
                hash: tawkHash,
              },
              (error) => {
                log(error);
              }
            );
          };
        } catch (e) {
          log(e);
        }
      }
    });
    yield call(() => {
      const {
        data: {
          user: { id, username, email, locale },
        },
      } = response;

      Sentry.configureScope((scope) => {
        scope.setUser({ id, username, email });
      });

      Sentry.configureScope((scope) => {
        scope.setTag('locale', locale);
      });
    });

    yield call(() => {
      const {
        data: {
          user: { id, companyId },
        },
      } = response;
      socketConnect({ userId: id, companyId });
    });
  } catch (e) {
    log(e);
    if (window.navigator.onLine) {
      yield put({ type: actions.ERROR_LOADING_USER });
      yield put({
        type: authActions.LOGOUT,
        payload: {
          redirect: `${window.location.pathname}${window.location.search}`,
          requestLogin: true,
        },
      });
    } else {
      yield put({
        type: 'GO_OFFLINE',
      });
    }
  }
}

function* loadUserDataGenerator(action) {
  try {
    yield getUserGenerator();
    const userState = yield select((state) => state.User);
    const {
      payload: { recache },
    } = action;
    const response = yield call(fetchEmployerGraphql, {
      variables: {
        id: parseInt(userState.get('user').id, 10),
      },
      recache: recache || false,
    });
    yield put({
      type: actions.LOAD_USER_DATA_DONE,
      payload: response.data.employer,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.LOAD_USER_DATA_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.LOAD_USER_DATA_CANCELLED,
      });
    }
  }
}

function* modifyBillingInformationGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(mutateEmployerGraphql, action.payload);
    yield put({
      type: actions.UPDATE_BILLING_DONE,
      payload: response.data.modifyBillingInformation,
    });
    yield call(() => {
      message.success(translate('billing.form.success'));
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.UPDATE_BILLING_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.UPDATE_BILLING_CANCELLED,
      });
    }
  }
}

function* logoutGenerator() {
  try {
    yield call(() => {
      Sentry.configureScope((scope) => scope.setUser(null));
    });
  } catch (e) {
    log(e);
  }
}

function* mutateEmployerGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    let response;
    if (action.payload.photo && action.payload.photo.file) {
      response = yield call(uploadPhotoRequest, action.payload.photo.file);
    }

    const profilePayload = { ...action.payload };

    if (response) {
      profilePayload.photo = response.data.fileUrl;
    }

    yield call(mutateEmployerBasicGraphql, profilePayload);
    yield put({
      type: actions.MUTATE_USER_DONE,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_USER_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_USER_CANCELLED,
      });
    }
  }
}

export function* getUser() {
  yield takeEvery(
    [authActions.LOGIN_SUCCESS, actions.MUTATE_USER_DONE, actions.UPDATE_BILLING_DONE],
    getUserGenerator
  );
}

export function* loadUserData() {
  yield takeEvery(actions.LOAD_USER_DATA_START, loadUserDataGenerator);
}

export function* modifyBillingInformation() {
  yield takeEvery(actions.UPDATE_BILLING_START, modifyBillingInformationGenerator);
}

export function* logout() {
  yield takeEvery('LOGOUT', logoutGenerator);
}

export function* mutateEmployer() {
  yield takeEvery(actions.MUTATE_USER_START, mutateEmployerGenerator);
}

export default function* rootSaga() {
  yield all([
    fork(getUser),
    spawn(loadUserData),
    spawn(modifyBillingInformation),
    spawn(logout),
    spawn(mutateEmployer),
  ]);
}
