import { Map } from 'immutable';
import actions from './actions';

const initState = Map({
  renewModalVisible: false,
  job: Map(),
});

export default function renewReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.TOGGLE_RENEW_MODAL:
      return state.set('renewModalVisible', action.payload.state);

    case actions.SET_JOB:
      return state.set('job', action.payload);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
