import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import actions from 'redux/jobReceipt/actions';
import fetchJobReceiptGraphql from 'redux/jobReceipt/graphql';

function* fetchJobReceiptGenerator(action) {
  try {
    const response = yield call(fetchJobReceiptGraphql, {
      variables: {
        id: action.payload.id,
      },
    });
    yield put({
      type: actions.FETCH_JOB_RECEIPT_DONE,
      payload: response.data.jobReceipt,
    });
  } catch (e) {
    log(e);
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.FETCH_JOB_RECEIPT_DENIED });
    }
    yield put({ type: actions.FETCH_JOB_RECEIPT_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_JOB_RECEIPT_CANCELLED,
      });
    }
  }
}

function* fetchJobReceipt() {
  yield takeLatest(actions.FETCH_JOB_RECEIPT_START, fetchJobReceiptGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchJobReceipt)]);
}
