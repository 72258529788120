import { push } from 'connected-react-router';
import links from 'helpers/links';
import Nprogress from 'nprogress';
import { all, call, cancelled, put, spawn, takeEvery } from 'redux-saga/effects';
import projectActions from 'redux/project/actions';
import tourActions from 'redux/tour/actions';
import actions from './actions';
import createApplicationGraphql from './graphql';
import requestGenerator from './requestGenerator';
import { uploadDocumentRequest } from './requests';

function* uploadDocumentGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const requests = action.payload.map((file) => call(uploadDocumentRequest, file));
    const responses = yield all(requests);

    yield put({
      type: actions.UPLOAD_DOCUMENT_DONE,
      payload: responses,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.UPLOAD_DOCUMENT_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.UPLOAD_DOCUMENT_CANCELLED,
      });
    }
  }
}

function* createApplicationGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const data = action.payload;

    const requestData = requestGenerator(data);
    const response = yield call(createApplicationGraphql, {
      application: requestData,
    });

    yield put({
      type: actions.CREATE_APPLICATION_DONE,
    });

    yield put({
      type: projectActions.FETCH_PROJECT_START,
      payload: {
        variables: {
          id: requestData.projectId,
        },
        recache: true,
      },
    });

    if (response.data.mutateApplication.id) {
      yield put(
        push(links.application(requestData.projectId, response.data.mutateApplication.id).url)
      );
    }
    yield put({
      type: tourActions.VERIFY_CHECKPOINT_START,
      payload: {
        section: 'tags',
      },
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.CREATE_APPLICATION_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.CREATE_APPLICATION_CANCELLED,
      });
    }
  }
}

function* uploadDocument() {
  yield takeEvery(actions.UPLOAD_DOCUMENT_START, uploadDocumentGenerator);
}

function* createApplication() {
  yield takeEvery(actions.CREATE_APPLICATION_START, createApplicationGenerator);
}

export default function* rootSaga() {
  yield all([spawn(uploadDocument), spawn(createApplication)]);
}
