import { Map } from 'immutable';
import {
  APIResponseProfileFile, ProfileFile, ProfilePost
} from 'types/profile.types';
import { VideoUpdate } from '../aboutUs/types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

/* UPDATE POST ACTION TYPES */
export const PROFILE_POST_UPDATE_START = 'PROFILE_POST_UPDATE_START';
export const PROFILE_POST_UPDATE_DONE = 'PROFILE_POST_UPDATE_DONE';
export const PROFILE_POST_UPDATE_CANCELLED = 'PROFILE_POST_UPDATE_CANCELLED';
export const PROFILE_POST_UPDATE_ERROR = 'PROFILE_POST_UPDATE_ERROR';

/* DELETE POST ACTION TYPES */
export const PROFILE_POST_DELETE_START = 'PROFILE_POST_DELETE_START';
export const PROFILE_POST_DELETE_DONE = 'PROFILE_POST_DELETE_DONE';
export const PROFILE_POST_DELETE_CANCELLED = 'PROFILE_POST_DELETE_CANCELLED';
export const PROFILE_POST_DELETE_ERROR = 'PROFILE_POST_DELETE_ERROR';

/* POST STATE ACTION TYPES */
export const PROFILE_POST_STATE_ADD_POST = 'PROFILE_POST_STATE_ADD_POST';
export const PROFILE_POST_STATE_UPDATE_POST = 'PROFILE_POST_STATE_UPDATE_POST';
export const PROFILE_POST_STATE_DELETE_POST = 'PROFILE_POST_STATE_DELETE_POST';

/* ACTIVE POST ACTION TYPES */
export const PROFILE_POST_ACTIVE_POST_SET_ACTIVE_POST = 'PROFILE_POST_ACTIVE_POST_SET_ACTIVE_POST';
export const PROFILE_POST_ACTIVE_POST_UPDATE_FIELD = 'PROFILE_POST_ACTIVE_POST_UPDATE_FIELD';

export const PROFILE_POST_ACTIVE_POST_ADD_VIDEO = 'PROFILE_POST_ACTIVE_POST_ADD_VIDEO';
export const PROFILE_POST_ACTIVE_POST_UPDATE_VIDEO = 'PROFILE_POST_ACTIVE_POST_UPDATE_VIDEO';
export const PROFILE_POST_ACTIVE_POST_DELETE_VIDEO = 'PROFILE_POST_ACTIVE_POST_DELETE_VIDEO';

export const PROFILE_POST_ACTIVE_POST_ADD_FILE = 'PROFILE_POST_ACTIVE_POST_ADD_FILE';
export const PROFILE_POST_ACTIVE_POST_UPDATE_FILE = 'PROFILE_POST_ACTIVE_POST_UPDATE_FILE';
export const PROFILE_POST_ACTIVE_POST_DELETE_FILE = 'PROFILE_POST_ACTIVE_POST_DELETE_FILE';

export const PROFILE_POST_RESET_MESSAGES = 'PROFILE_POST_RESET_MESSAGES';

export interface ProfilePostStateMap extends Map<string, any> {
  profilePosts: ProfilePost[];
  activePost: ProfilePost | null;
  postSubmitting: boolean;
  postSubmitError: string | null;
  postSubmitSuccess: string | null;
  postDeleting: boolean;
  postDeleteError: string | null;
}

export interface ActivePostFieldUpdateProps {
  field: string;
  value: any;
}

export type ProfilePostState = ImmutableMapInterface<ProfilePostStateMap, string, any>;

export interface APIResponseProfilePost {
  id?: number;
  title: string;
  postHtml: string;
  author: string;
  videoUrls?: String[] | string[] | null;
  files: APIResponseProfileFile[];
}

export interface PostApiRequestDoneAction {
  type:
    | typeof PROFILE_POST_UPDATE_DONE
    | typeof PROFILE_POST_DELETE_DONE;
  payload: {
    post: ProfilePost;
    posts: ProfilePost[];
    successMessage: string | null;
  };
}

export interface PostApiRequestAction {
  type:
    | typeof PROFILE_POST_UPDATE_START
    | typeof PROFILE_POST_UPDATE_CANCELLED
    | typeof PROFILE_POST_DELETE_START
    | typeof PROFILE_POST_DELETE_CANCELLED;
  payload: ProfilePost;
}

export interface PostApiRequestErrorAction {
  type:
    | typeof PROFILE_POST_UPDATE_ERROR
    | typeof PROFILE_POST_DELETE_ERROR;
  payload: string;
}

export interface PostStateUpdateAction {
  type:
    | typeof PROFILE_POST_STATE_ADD_POST
    | typeof PROFILE_POST_STATE_UPDATE_POST
    | typeof PROFILE_POST_STATE_DELETE_POST;
  payload: ProfilePost;
}

export interface SetActivePostAction {
  type: typeof PROFILE_POST_ACTIVE_POST_SET_ACTIVE_POST;
  payload: ProfilePost | null;
}

export interface ActivePostFileUpdateAction {
  type:
    | typeof PROFILE_POST_ACTIVE_POST_ADD_FILE
    | typeof PROFILE_POST_ACTIVE_POST_UPDATE_FILE
    | typeof PROFILE_POST_ACTIVE_POST_DELETE_FILE;
  payload: ProfileFile;
}

export interface ActivePostVideoAddAction {
  type: typeof PROFILE_POST_ACTIVE_POST_ADD_VIDEO;
}

export interface VideoDelete {
  index: number;
}

export interface ActivePostVideoDeleteAction {
  type: typeof PROFILE_POST_ACTIVE_POST_DELETE_VIDEO;
  payload: VideoDelete;
}

export interface ActivePostVideoUpdateAction {
  type: typeof PROFILE_POST_ACTIVE_POST_UPDATE_VIDEO;
  payload: VideoUpdate;
}

export interface ActivePostFieldUpdateAction {
  type: typeof PROFILE_POST_ACTIVE_POST_UPDATE_FIELD;
  payload: ActivePostFieldUpdateProps;
}

export interface PostNoPayloadAction {
  type:
    | typeof PROFILE_POST_UPDATE_CANCELLED
    | typeof PROFILE_POST_DELETE_CANCELLED;
}

export interface PostResetMessages {
  type: typeof PROFILE_POST_RESET_MESSAGES;
}

export type ProfilePostActions =
  | PostApiRequestAction
  | PostApiRequestDoneAction
  | PostApiRequestErrorAction
  | PostStateUpdateAction
  | ActivePostFileUpdateAction
  | ActivePostFieldUpdateAction
  | SetActivePostAction
  | ActivePostVideoUpdateAction
  | PostNoPayloadAction
  | PostResetMessages;
