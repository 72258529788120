import gql from 'graphql-tag';
import client from 'graphql/client';

/* job receipt fetch */
const fetchJobReceiptGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query jobReceipt($id: Int!) {
      jobReceipt(id: $id) {
        duration
        type
        socialPosting
        socialPostingType
        socialPostingInstagramType
        jobSocialPosting {
          socialNetwork
          postingSite
          type
        }
        companyName
        title
        city
        expirationDate
        webPositionCheckbox
        webPosition
        applicationsOnEmail
        applicationDeliveryEmail
        forInvalids
        forInvalidsOnly
        forStudents
        forStudentsOnly
        contactName
        phoneNumber
        contactAddress
        postalCodeCity
        pib
        idNumberOther
        idNumber
        email
        applicationPhoneNumber
        displayName
        logoFiles {
          id
          originalFileName
          relativePath
        }
        documentFiles {
          id
          originalFileName
          relativePath
        }
        language
        languageLevel
        computerSkill
        computerSkillLevel
        workExperienceLevel
        educationalLevel
        media
        mediaFiles
        totalPrice
        endSelection
        paymentMethod
        highlighted
        datePosted
        siteVisibility
        packageDetails {
          id
          combo
        }
        invite
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

export default fetchJobReceiptGraphql;
