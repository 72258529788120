import { notification } from 'antd';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import {
  all,
  call,
  cancelled,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import actions from './actions';
import {
  fetchJobGraphql,
  fetchJobsCountGraphql,
  fetchJobsGraphql,
  fetchOrderedAdData,
  mutateDeactivateJob,
  mutateSelectionEndedGraphql,
} from './graphql';

const jobStore = (state) => state.Job;

function* fetchJobsCountGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(fetchJobsCountGraphql, action.payload);
    yield put({
      type: actions.FETCH_JOBS_COUNT_DONE,
      payload: response.data.jobs.count,
    });
    yield call(() => {
      Nprogress.done();
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_JOBS_COUNT_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_JOBS_COUNT_CANCELLED,
      });
    }
  }
}

/**
 * Job list generator function
 * @param {*} action
 */
function* fetchJobsGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(fetchJobsGraphql, action.payload);
    yield put({
      type: actions.DONE_FETCHING,
      payload: response.data.jobs,
    });
    yield put({
      type: actions.JOBS_LOADING_DONE,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.ERROR_FETCHING });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_CANCELLED,
      });
    }
  }
}

/**
 * Single job generator function
 * @param {*} action
 */
function* fetchJobGenerator(action) {
  const jobState = yield select(jobStore);
  try {
    yield call(() => {
      Nprogress.start();
    });
    yield put({
      type: actions.JOB_FETCHING,
    });

    const activeSorts = jobState.get('sort').filter((sortDirection) => sortDirection !== null);

    const sort = {
      sortField: Object.keys(activeSorts.toJS())[0],
      sortDirection: activeSorts.toJS()[Object.keys(activeSorts.toJS())[0]],
    };

    const payload = {
      ...action.payload,
      variables: {
        ...action.payload.variables,
        ...jobState.get('selectedFilters').toJS(),
        ...sort,
      },
    };

    const response = yield call(fetchJobGraphql, payload);
    yield put({
      type: actions.JOB_FETCHING_DONE,
      payload: response.data.job,
    });
    yield put({
      type: actions.JOB_LOADING_DONE,
    });
  } catch (e) {
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.JOB_FETCHING_DENIED });
    } else {
      notification.error({
        message: translate('error.userError'),
        description: translate('error.tryAgain'),
        duration: 0,
      });
    }
    yield put({
      type: actions.JOB_FETCHING_ERROR,
      payload: e.message,
    });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.JOB_FETCH_CANCELLED,
      });
    }
  }
}

function* endSelectionMutationGenerator({ payload }) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    yield call(mutateSelectionEndedGraphql, {
      variables: {
        id: payload.job.id,
        notification: payload.result,
      },
    });
    yield put({
      type: actions.END_SELECTION_MUTATION_DONE,
      payload,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.END_SELECTION_MUTATION_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.END_SELECTION_MUTATION_CANCELLED,
      });
    }
  }
}

/**
 *
 * @param {*} action
 */
function* fetchOrderedAdGenerator(action) {
  try {
    const response = yield call(fetchOrderedAdData, action.payload);
    yield put({
      type: actions.FETCH_ORDERED_AD_DATA_DONE,
      payload: response.data.orderedAdData,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_ORDERED_AD_DATA_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_ORDERED_AD_DATA_CANCELLED,
      });
    }
  }
}

function* deactivateJobGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    yield call(mutateDeactivateJob, {
      jobId: action.payload.id,
    });
    yield put({
      type: actions.MUTATE_JOB_DEACTIVATE_DONE,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.MUTATE_JOB_DEACTIVATE_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.MUTATE_JOB_DEACTIVATE_CANCELLED,
      });
    }
  }
}

export function* fetchJobs() {
  yield takeLatest(actions.FETCH_JOBS, fetchJobsGenerator);
}

export function* fetchJob() {
  yield takeLatest([actions.JOB_FETCH], fetchJobGenerator);
}

function* endSelection() {
  yield takeEvery(actions.END_SELECTION_MUTATION_START, endSelectionMutationGenerator);
}

function* fetchJobsCount() {
  yield takeLatest(actions.FETCH_JOBS_COUNT_START, fetchJobsCountGenerator);
}

function* fetchOrderedAd() {
  yield takeLatest(actions.FETCH_ORDERED_AD_DATA_START, fetchOrderedAdGenerator);
}

function* deactivateJob() {
  yield takeLatest(actions.MUTATE_JOB_DEACTIVATE_START, deactivateJobGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchJobs),
    spawn(fetchJobsCount),
    spawn(fetchJob),
    spawn(endSelection),
    spawn(fetchOrderedAd),
    spawn(deactivateJob),
  ]);
}
