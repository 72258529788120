import styled from 'styled-components';
import { Typography as AntTypography } from 'antd';
import { palette } from 'styled-theme';

const Typography = styled(AntTypography)``;

const TypographyText = styled(AntTypography.Text)`
  color: ${palette('foreground', 0)};
`;

Typography.Text = TypographyText;

export default Typography;
