import { Buffer } from 'buffer/';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sentry';

/** whats needed for safari and ie10 */
// import 'core-js/modules/es.array.find';
// import 'core-js/modules/es.array.find-index';
// import 'core-js/modules/es.string.includes';
// import 'core-js/modules/es.array.includes';
// import 'core-js/modules/es.object.assign';
// import 'core-js/modules/es.string.starts-with';
// import 'core-js/modules/es.array.fill';
// import 'core-js/modules/es.string.repeat';
// import 'core-js/modules/es.string.code-point-at';
// import 'core-js/es/set';
// import 'core-js/es/map';
// import 'core-js/es/promise';

import { isDemo } from 'helpers/utility';
import ReactDOM from 'react-dom';
import AtsApp from './AtsApp';
import registerServiceWorker, { unregister } from './registerServiceWorker';

if (!global.Intl) {
  global.Intl = require('intl'); // eslint-disable-line
}

window.Buffer = Buffer;
ReactDOM.render(<AtsApp />, document.getElementById('root'));

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./AtsApp.js', () => {
    const NextApp = require('./AtsApp').default; // eslint-disable-line
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}

if (!isDemo()) {
  registerServiceWorker();
} else {
  unregister();
}
