import axios from 'helpers/axios';
import { getToken } from '../../helpers/utility';
import config from '../../config';

const forwardApplication = data => axios({
  method: 'post',
  url: `${config.apiUrl}/applications/forward`,
  data,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export default forwardApplication;
