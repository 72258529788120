import config from 'config';
import axios from 'helpers/axios';
import { getToken } from 'helpers/utility';

const fetchApplicationFiltersRequest = (projectId: number) =>
  axios({
    method: 'get',
    url: `${config.apiUrl}/project/${projectId}/filters/`,
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });

export default fetchApplicationFiltersRequest;
