import { all, call, put, spawn, takeLatest } from 'redux-saga/effects';
import actions from 'redux/continueIncompleteJob/actions';
import fetchIncompleteJobsToContinue from './graphql';

function* fetchContinuableJobsGenerator(): any {
  try {
    const response = yield call(fetchIncompleteJobsToContinue);
    yield put({
      type: actions.FETCH_CONTINUABLE_JOBS_DONE,
      payload: response.data.incompleteJobsToContinue,
    });
  } catch (e) {
    log(e);
  }
}

function* fetchContinuableJobs() {
  yield takeLatest(actions.FETCH_CONTINUABLE_JOBS_START, fetchContinuableJobsGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchContinuableJobs)]);
}
