const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CREDENTIALS_CHANGE: 'CREDENTIALS_CHANGE',
  LOGIN_VALID: 'LOGIN_VALID',
  VALIDATE: 'VALIDATE',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: credentials => ({
    type: actions.LOGIN_REQUEST,
    payload: credentials,
  }),
  credentialsChange: credentials => ({
    type: actions.CREDENTIALS_CHANGE,
    payload: credentials,
  }),
  logout: data => (dispatch, getState) => {
    dispatch({
      type: actions.LOGOUT,
      payload: {
        redirect: data && data.remember ? `${getState().router.location.pathname}${getState().router.location.search}` : false,
        requestLogin: (data && data.requestLogin) || false,
      },
    });
  },

  validate: payload => ({
    type: actions.VALIDATE,
    payload,
  }),
};
export default actions;
