const employerMock = {
  id: '1',
  username: 'demo',
  initials: 'DD',
  email: 'demo@infostud.com',
  companyName: 'Demo company',
  typeId: 0,
  typeValue: '',
  PIB: '123456789',
  maticniBroj: '123',
  contactPhone: '063 646 56',
  ime: 'Demo',
  prezime: 'Korisnik',
  locale: 'sr',
  loyaltyStatus: 'not_member',
};

export default employerMock;
