const employerProfileMock = {
  id: 1,
  companyId: 1,
  name: 'Demo d.o.o.',
  logoPath: null,
  websiteUrl: 'https://www.hrlab.rs',
  cityName: 'Subotica',
  address: 'Korzo 1',
  phoneNumber: '',
  email: 'demo@infostud.com',
  descriptionText: '',
  requiringText: '',
  offeringsText: '',
  profileType: 'basic',
  country: {
    id: 434,
    nameSerbian: 'SRBIJA',
    nameEnglish: 'Serbia',
  },
  employeeCountOption: {
    id: 2,
    name: '20-50',
  },
};

export default employerProfileMock;
