const applicationMock = [
  {
    id: 1,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Petar Nešić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 8,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: false,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 13,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'Demo Poslodavac 1',
          initials: 'DP'
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'Demo Poslodavac 2',
          initials: 'DP'
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: [
      {
        id: 62303,
        preselectionQuestion: {
          id: 62303,
          required: false,
          question: 'Koji je vaš nivo obrazovanja?',
          passingPoints: 0,
          genericQuestion: true,
          genericQuestionType: 'edu',
          preselectionQuestionAnswers: [
            {
              id: 445288,
              answer: 'Osnovna škola',
              order: 0,
              points: 0,
            },
            {
              id: 445289,
              answer: 'Srednja škola',
              order: 0,
              points: 0,
            },
            {
              id: 445290,
              answer: 'Visoka škola ili Bachelor / Viša',
              order: 0,
              points: 0,
            },
            {
              id: 445291,
              answer: 'Master / Fakultet',
              order: 0,
              points: 0,
            },
            {
              id: 445292,
              answer: 'Magistratura',
              order: 0,
              points: 0,
            },
            {
              id: 445293,
              answer: 'Phd / Doktorat',
              order: 0,
              points: 0,
            },
          ],
        },
        candidateAnswer: {
          id: 12612729,
          answerId: 445290,
          comment: '',
        },
      },
      {
        id: 62304,
        preselectionQuestion: {
          id: 62304,
          required: false,
          question: 'Koliko godina radnog iskustva imate?',
          passingPoints: 0,
          genericQuestion: true,
          genericQuestionType: 'ri',
          preselectionQuestionAnswers: [
            {
              id: 445294,
              answer: 'bez radnog iskustva',
              order: 0,
              points: 0,
            },
            {
              id: 445295,
              answer: 'manje od jedne godine',
              order: 0,
              points: 0,
            },
            {
              id: 445296,
              answer: '1 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445297,
              answer: '2 godine',
              order: 0,
              points: 0,
            },
            {
              id: 445298,
              answer: '3 godine',
              order: 0,
              points: 0,
            },
            {
              id: 445299,
              answer: '4 godine',
              order: 0,
              points: 0,
            },
            {
              id: 445300,
              answer: '5 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445301,
              answer: '6 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445302,
              answer: '7 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445303,
              answer: '8 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445304,
              answer: '9 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445305,
              answer: '10 godina',
              order: 0,
              points: 0,
            },
            {
              id: 445306,
              answer: 'više od 10 godina',
              order: 0,
              points: 0,
            },
          ],
        },
        candidateAnswer: {
          id: 12612730,
          answerId: 445294,
          comment: '',
        },
      },
    ],
  },
  {
    id: 2,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Ranko  Brkić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 6,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: false,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 12,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 3,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Dobrica Krsmanović',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 6,
    selectionLevel: 'Interview',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 11,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 4,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Zorica Vladić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 6,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 10,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 5,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Anica Pavlović',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 10,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 9,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 6,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Danka Vladimirović',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 2,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 8,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 7,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Vuk Janković',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 4,
    selectionLevel: 'NarrowedRound',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 7,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 8,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Momčilo Aleksić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 8,
    selectionLevel: 'NarrowedRound',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 6,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 9,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Malina Vladić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: true,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 10,
    selectionLevel: 'Qualification',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 5,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 10,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Dejan Pavluković',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 10,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 4,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 11,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Vojislav Zelić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 8,
    selectionLevel: 'Qualification',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 3,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 12,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Ivan Dević',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 8,
    selectionLevel: 'Qualification',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 2,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 13,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Veselin Romić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 8,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 1,
    fromSite: 0,
    job_id: 1,
    projects: [
      {
        id: 1,
        name: "Demo oglas 1"
      }
    ],
    job: {
      id: 1,
      title: 'Demo oglas',
    },
    applications: [
      {
        id: 2,
        name: 'Demo korisnik',
        applicationTime: '2015-02-25T09:35:36+01:00',
        projects: [
          {
            id: 2,
            name: "Demo oglas 2"
          }
        ],
        job: {
          id: 2,
          title: 'Demo oglas 2',
        },
      },
    ],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
  {
    id: 14,
    applicationTime: '2015-03-25T13:05:14+01:00',
    name: 'Velimir Simić',
    email: 'demo@infostud.com',
    phone: '063 646 56',
    rejected: false,
    preselectionPassed: 'DoesMeetRequirements',
    rating: 8,
    selectionLevel: 'Candidate',
    coverLetter: 'Ovde se prikazuje propratno pismo ukoliko ga je kandidat poslao',
    employerView: true,
    consentExpiring: false,
    consentExpiringDate: '2100-02-25T09:35:36+01:00',
    consent: true,
    order: 1,
    fromSite: 0,
    job_id: 2,
    projects: [
      {
        id: 2,
        name: "Demo oglas 2"
      }
    ],
    job: {
      id: 2,
      title: 'Demo oglas 2',
    },
    applications: [],
    events: [
      {
        id: 'application_submitted_12835777',
        eventType: 'application_submitted',
        dateTime: '2015-03-25T13:05:14+01:00',
        eventData: null,
      },
      {
        id: '2',
        eventType: 'rejection_sent',
        dateTime: '2019-02-14T12:25:39+01:00',
        eventData:
          '{"email":"Po\\u0161tovani,\\r\\n\\r\\nObave\\u0161tavamo Vas da je konkurs za poziciju %radno_mesto% zavr\\u0161en. Odlu\\u010dili smo se za drugog kandidata koji je najvi\\u0161e odgovarao zahtevima date pozicije.\\r\\n\\r\\nHvala Vam na interesovanju i ulo\\u017eenom vremenu.\\r\\n\\u017delimo Vam sre\\u0107u u daljoj potrazi za poslom i uspe\\u0161nu karijeru.\\r\\n\\r\\nSrda\\u010dan pozdrav,"}',
      },
    ],
    comments: [
      {
        id: 3,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 1',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Dobar je',
        createdDateTime: '2019-04-04T10:37:00+02:00',
        jobId: 1,
        jobTitle: 'Demo oglas',
        application: {
          id: 1,
        },
      },
      {
        id: 2,
        canEdit: true,
        employer: {
          username: 'Demo Poslodavac 2',
          name: 'demo@infostud.com',
          initials: "DP"
        },
        content: 'Baš i nije',
        createdDateTime: '2019-04-04T10:36:55+02:00',
        jobId: 2,
        jobTitle: 'Demo oglas 2',
        application: { id: 14 },
      },
    ],
    applicationCVSnapshot: {
      id: 1,
    },
    tags: [],
    attachments: [],
    preselectionApplication: null,
  },
];

export default applicationMock;
