const shareActions = {
  TOGGLE_VISIBLE: 'TOGGLE_VISIBLE',

  SET_EDITING_PROJECT: 'SET_EDITING_PROJECT',

  STORE_REF: 'STORE_REF',

  setEditingProject: project => ({
    type: shareActions.SET_EDITING_PROJECT,
    payload: project,
  }),

  toggleSharePopupVisiblity: state => ({
    type: shareActions.TOGGLE_VISIBLE,
    payload: state,
  }),

  storeRef: ref => ({
    type: shareActions.STORE_REF,
    payload: ref,
  }),
};

export default shareActions;
