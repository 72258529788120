import styled from 'styled-components';

const ValidateStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div.title {
    margin-top: 20px;
  }
`;

export default ValidateStyled;
