import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import { ReduxAction } from 'types/redux.types';
import actions from './actions';
import fetchAvailableJobsForUpsale from './graphql';

function* fetchAvailableJobsGenerator(action: ReduxAction): any {
  try {
    const response = yield call(fetchAvailableJobsForUpsale);
    yield put({
      type: actions.FETCH_AVAILABLE_JOBS_DONE,
      payload: response.data,
    });
    // }
  } catch (e) {
    log(e);
    yield put({ type: actions.FETCH_AVAILABLE_JOBS_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_AVAILABLE_JOBS_CANCELLED,
      });
    }
  }
}

function* fetchAvailableJobs() {
  yield takeLatest(actions.FETCH_AVAILABLE_JOBS_START, fetchAvailableJobsGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchAvailableJobs)]);
}
