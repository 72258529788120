import gql from 'graphql-tag';

const profileFragment = gql`
  fragment OldProfileFragment on EmployerProfile {
    id
    companyId
    name
    logoPath
    websiteUrl
    cityName
    address
    phoneNumber
    email
    descriptionText
    requiringText
    offeringsText
    profileType
    country {
      id
      nameSerbian
      nameEnglish
    }
    employeeCountOption {
      id
      name
    }
  }
`;

const fetchProfileGraphqlQuery = gql`
  query profile {
    employerProfile {
      ...OldProfileFragment
    }
  }
  ${profileFragment}
`;

const fetchCountryNamesQuery = gql`
  query countryNames {
    employerProfileCountries {
      id
      nameSerbian
      nameEnglish
    }
  }
`;

const fetchEmployeeCountOptionsQuery = gql`
  query employerProfileEmployeeCountOptions {
    employerProfileEmployeeCountOptions {
      id
      name
    }
  }
`;

const mutateProfileGraphqQuery = gql`
  mutation modifyEmployerProfile($employerProfile: EmployerProfileInput) {
    modifyEmployerProfile(employerProfile: $employerProfile) {
      ...OldProfileFragment
    }
  }
  ${profileFragment}
`;

export {
  fetchProfileGraphqlQuery,
  mutateProfileGraphqQuery,
  fetchCountryNamesQuery,
  fetchEmployeeCountOptionsQuery,
};
