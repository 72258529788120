const profileNavigationActions = {
  CHANGE_SECTION: 'CHANGE_SECTION',

  changeSection: (payload: string) => ({
    type: profileNavigationActions.CHANGE_SECTION,
    payload
  }),
};

export default profileNavigationActions;
