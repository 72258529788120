import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchAvailableJobsForUpsale = () => {
  const query = gql`
    query availableJobsForUpsale {
      availableJobsForUpsale {
        id
        title
      }
      lists {
        cities {
          id
          name
        }
        countries {
          id
          name
        }
      }
    }
  `;

  return client.query({
    query,
    fetchPolicy: 'network-only',
  });
};

const fetchJobDataForUpsale = (jobId: number) => {
  const query = gql`
    query fetchJobDataForUpsale($jobId: Int) {
      job(id: $jobId) {
        id
        title
        jobType
        siteVisibility
        duration
        daysLeftTillExpiration
        highlighted
        jobSocialPosting {
          postingSite
          socialNetwork
        }
      }
    }
  `;

  const variables = {
    jobId,
  };

  return client.query({
    query,
    variables,
    fetchPolicy: 'no-cache',
  });
};

const insertUpsale = (variables: any) => {
  const mutation = gql`
    mutation insertUpsale(
      $jobId: Int!
      $socialPosting: [SocialPostingInput]
      $highlighted: Int
      $paymentMethod: PaymentMethod!
      $billingContactName: String
      $billingCompanyName: String
      $pib: String
      $phone: String
      $zipCode: String
      $address: String
      $idNumber: String
      $cardOwnerName: String
      $countryId: Int
      $cityId: Int
      $cityName: String
      $employerType: Int
    ) {
      insertUpsale(
        jobId: $jobId
        upsale: {
          socialPosting: $socialPosting
          highlighted: $highlighted
          paymentMethod: $paymentMethod
        }
        paymentDetails: {
          contactName: $billingContactName
          companyName: $billingCompanyName
          pib: $pib
          phone: $phone
          zipCode: $zipCode
          address: $address
          idNumber: $idNumber
          cardOwnerName: $cardOwnerName
          countryId: $countryId
          cityId: $cityId
          cityName: $cityName
          employerType: $employerType
        }
      ) {
        id
        paymentMethod
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export default fetchAvailableJobsForUpsale;
export { fetchJobDataForUpsale, insertUpsale };
