import gql from 'graphql-tag';

const accessFragment = gql`
  fragment AccessFragment on Employer {
    id
    initials
    name
    email
    accessType
    accessId
  }
`;

export default accessFragment;
