const companyActions = {
  FETCH_COMPANY_SELLER_START: 'FETCH_COMPANY_SELLER_START',
  FETCH_COMPANY_SELLER_DONE: 'FETCH_COMPANY_SELLER_DONE',
  FETCH_COMPANY_SELLER_ERROR: 'FETCH_COMPANY_SELLER_ERROR',
  FETCH_COMPANY_SELLER_CANCELLED: 'FETCH_COMPANY_SELLER_CANCELLED',

  fetchCompanySeller: () => ({
    type: companyActions.FETCH_COMPANY_SELLER_START,
  }),
};

export default companyActions;
