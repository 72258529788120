const userMock = {
  initials: 'DD',
  name: 'Demo nalog',
  id: 1,
  username: 'Demo',
  email: 'demo@infostud.com',
  ime: 'Demo',
  prezime: 'Demo',
  locale: 'sr',
};

export default userMock;
