import authActions from './auth/actions';
import bannerActions from './banner/actions';
import { store } from './store';

const boot = () =>
  new Promise(() => {
    store.dispatch(authActions.checkAuthorization());
    store.dispatch(bannerActions.fetchBanner());
  });

export default boot;
