import { Map } from 'immutable';
import { CompanyBenefit } from 'types/profile.types';
import { ProfileActions, ProfileState } from '../types';
import {
  CompanyBenefitsState,
  COMPANY_BENEFITS_SYNC_CANCELLED,
  COMPANY_BENEFITS_SYNC_DONE,
  COMPANY_BENEFITS_SYNC_ERROR,
  COMPANY_BENEFITS_SYNC_START,
  COMPANY_BENEFIT_STATE_TOGGLE_BENEFIT,
  COMPANY_BENEFIT_STATE_UPDATE_BENEFIT,
  COMPANY_BENEFIT_STATE_UPDATE_BENEFIT_ORDER,
  COMPANY_BENEFIT_TYPES_FETCH_CANCELLED,
  COMPANY_BENEFIT_TYPES_FETCH_DONE,
  COMPANY_BENEFIT_TYPES_FETCH_ERROR,
  COMPANY_BENEFIT_TYPES_FETCH_START
} from './types';

const initState: CompanyBenefitsState = Map<string, any>({
  companyBenefits: [],
  companyBenefitTypes: [],
  benefitsSyncing: false,
  benefitsSyncingError: null,
  benefitsSyncingSuccess: null,
  benefitTypesFetching: false,
  benefitTypesFetchingError: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    // API Call
    case COMPANY_BENEFITS_SYNC_START:
      return state
        .set('benefitsSyncing', true)
        .set('benefitsSyncingError', null)
        .set('benefitsSyncingSuccess', null);
    case COMPANY_BENEFITS_SYNC_ERROR:
      return state.set('benefitsSyncing', false).set('benefitsSyncingError', action.payload);
    case COMPANY_BENEFITS_SYNC_CANCELLED:
      return state.set('benefitsSyncing', false);
    case COMPANY_BENEFITS_SYNC_DONE:
      return state
        .set('companyBenefits', action.payload.companyBenefits)
        .set('benefitsSyncingSuccess', action.payload.successMessage)
        .set('benefitsSyncing', false);

    case COMPANY_BENEFIT_TYPES_FETCH_START:
      return state.set('benefitTypesFetching', true).set('benefitTypesFetchingError', null);
    case COMPANY_BENEFIT_TYPES_FETCH_ERROR:
      return state
        .set('benefitTypesFetching', false)
        .set('benefitTypesFetchingError', action.payload);
    case COMPANY_BENEFIT_TYPES_FETCH_CANCELLED:
      return state.set('benefitTypesFetching', false);
    case COMPANY_BENEFIT_TYPES_FETCH_DONE:
      return state.set('companyBenefitTypes', action.payload);

    case COMPANY_BENEFIT_STATE_TOGGLE_BENEFIT: {
      const companyBenefits = state.get('companyBenefits');
      const benefitIndex = companyBenefits.findIndex(
        (benefit: CompanyBenefit) => benefit.benefitTypeId === action.payload.id
      );
      if (benefitIndex === -1) {
        // Add benefit because none was found
        return state.set('companyBenefits', [
          ...companyBenefits,
          {
            id: (companyBenefits.length ?? 0) + 1,
            description: '',
            benefitType: action.payload,
            benefitTypeId: action.payload.id,
            hidden: false,
            order: (companyBenefits.length ?? 0) + 1,
          },
        ]);
      }
      // Update benefit with the hidden flag and toggle order
      return state.set(
        'companyBenefits',
        Object.assign([], companyBenefits, {
          [benefitIndex]: {
            ...companyBenefits[benefitIndex],
            hidden: !companyBenefits[benefitIndex].hidden,
          },
        })
      );
    }

    case COMPANY_BENEFIT_STATE_UPDATE_BENEFIT: {
      const companyBenefits = state.get('companyBenefits');
      return state.set(
        'companyBenefits',
        Object.assign([], companyBenefits, {
          [companyBenefits.findIndex(
            (benefit: CompanyBenefit) => benefit.benefitTypeId === action.payload.benefitTypeId
          )]: action.payload,
        })
      );
    }

    case COMPANY_BENEFIT_STATE_UPDATE_BENEFIT_ORDER: {
      return state.set('companyBenefits', action.payload);
    }

    default:
      return state;
  }
};

export { initState, reducer };
