const applicationActions = {
  /** single application fetching */
  APPLICATION_FETCH: 'APPLICATION_FETCH',
  APPLICATION_REFRESH: 'APPLICATION_REFRESH', // doesnt show placeholder loader
  APPLICATION_FETCHING_DONE: 'APPLICATION_FETCHING_DONE',
  APPLICATION_FETCHING_ERROR: 'APPLICATION_FETCHING_ERROR',
  APPLICATION_LOADING_DONE: 'APPLICATION_LOADING_DONE',
  APPLICATION_FETCH_CANCEL: 'APPLICATION_FETCH_CANCEL',
  APPLICATION_FETCH_CANCELLED: 'APPLICATION_FETCH_CANCELLED',
  APPLICATION_LOADING_START: 'APPLICATION_LOADING_START',
  APPLICATION_GDPRED: 'APPLICATION_GDPRED',

  /* application list fetching */
  APPLICATIONS_FETCH: 'APPLICATIONS_FETCH',
  APPLICATIONS_FETCHING_DONE: 'APPLICATIONS_FETCHING_DONE',
  APPLICATIONS_FETCHING_ERROR: 'APPLICATIONS_FETCHING_ERROR',
  APPLICATIONS_LOADING_DONE: 'APPLICATIONS_LOADING_DONE',
  APPLICATIONS_FETCH_CANCEL: 'APPLICATIONS_FETCH_CANCEL',
  APPLICATIONS_FETCH_CANCELLED: 'APPLICATIONS_FETCH_CANCELLED',

  /** single application mutation */
  /** rating */
  APPLICATION_RATING_MUTATION_START: 'APPLICATION_RATING_MUTATION_START',
  APPLICATION_RATING_MUTATION_DONE: 'APPLICATION_RATING_MUTATION_DONE',
  APPLICATION_RATING_MUTATION_ERROR: 'APPLICATION_RATING_MUTATION_ERROR',
  APPLICATION_RATING_MUTATION_CANCELLED: 'APPLICATION_RATING_MUTATION_CANCELLED',

  /** selectionLevel */
  APPLICATION_SELECTION_LEVEL_MUTATION_START: 'APPLICATION_SELECTION_LEVEL_MUTATION_START',
  APPLICATION_SELECTION_LEVEL_MUTATION_DONE: 'APPLICATION_SELECTION_LEVEL_MUTATION_DONE',
  APPLICATION_SELECTION_LEVEL_MUTATION_ERROR: 'APPLICATION_SELECTION_LEVEL_MUTATION_ERROR',
  APPLICATION_SELECTION_LEVEL_MUTATION_CANCELLED: 'APPLICATION_SELECTION_LEVEL_MUTATION_CANCELLED',
  /** END: single application mutation */

  /** comment */
  APPLICATION_COMMENT_ADD_MUTATION_START: 'APPLICATION_COMMENT_ADD_MUTATION_START',
  APPLICATION_COMMENT_ADD_MUTATION_DONE: 'APPLICATION_COMMENT_ADD_MUTATION_DONE',
  APPLICATION_COMMENT_ADD_MUTATION_ERROR: 'APPLICATION_COMMENT_ADD_MUTATION_ERROR',
  APPLICATION_COMMENT_ADD_MUTATION_CANCELLED: 'APPLICATION_COMMENT_ADD_MUTATION_CANCELLED',
  APPLICATION_COMMENT_ADD_MUTATION_LOADING: 'APPLICATION_COMMENT_ADD_MUTATION_LOADING',
  APPLICATION_COMMENT_EDITOR_TOGGLE: 'APPLICATION_COMMENT_EDITOR_TOGGLE',
  APPLICATION_COMMENT_EDITOR_SET: 'APPLICATION_COMMENT_EDITOR_SET',
  APPLICATION_COMMENT_EDITOR_SET_RAW: 'APPLICATION_COMMENT_EDITOR_SET_RAW',
  APPLICATION_COMMENT_EDITOR_SET_COMMENT_ID: 'APPLICATION_COMMENT_EDITOR_SET_COMMENT_ID',
  APPLICATION_COMMENT_EDITOR_INVALID_TOGGLE: 'APPLICATION_COMMENT_EDITOR_INVALID_TOGGLE',
  APPLICATION_COMMENT_EDIT_MUTATION_START: 'APPLICATION_COMMENT_EDIT_MUTATION_START',
  APPLICATION_COMMENT_EDIT_MUTATION_DONE: 'APPLICATION_COMMENT_EDIT_MUTATION_DONE',
  APPLICATION_COMMENT_EDIT_MUTATION_ERROR: 'APPLICATION_COMMENT_EDIT_MUTATION_ERROR',
  APPLICATION_COMMENT_EDIT_MUTATION_CANCELLED: 'APPLICATION_COMMENT_EDIT_MUTATION_CANCELLED',
  APPLICATION_COMMENT_REMOVE_MUTATION_START: 'APPLICATION_COMMENT_REMOVE_MUTATION_START',
  APPLICATION_COMMENT_REMOVE_MUTATION_DONE: 'APPLICATION_COMMENT_REMOVE_MUTATION_DONE',
  APPLICATION_COMMENT_REMOVE_MUTATION_ERROR: 'APPLICATION_COMMENT_REMOVE_MUTATION_ERROR',
  APPLICATION_COMMENT_REMOVE_MUTATION_CANCELLED: 'APPLICATION_COMMENT_REMOVE_MUTATION_CANCELLED',

  APPLICATION_COMMENTS_FETCH_START: 'APPLICATION_COMMENTS_FETCH_START',
  APPLICATION_COMMENTS_FETCH_DONE: 'APPLICATION_COMMENTS_FETCH_DONE',
  APPLICATION_COMMENTS_FETCH_ERROR: 'APPLICATION_COMMENTS_FETCH_ERROR',
  APPLICATION_COMMENTS_FETCH_CANCELLED: 'APPLICATION_COMMENTS_FETCH_CANCELLED',

  /**rating */
  APPLICATION_RATING_FETCH_START: 'APPLICATION_RATING_FETCH_START',
  APPLICATION_RATING_FETCH_DONE: 'APPLICATION_RATING_FETCH_DONE',
  APPLICATION_RATING_FETCH_ERROR: 'APPLICATION_RATING_FETCH_ERROR',
  APPLICATION_RATING_FETCH_CANCELLED: 'APPLICATION_RATING_FETCH_CANCELLED',

  /** tags */
  APPLICATION_TAG_MUTATION_ADD_START: 'APPLICATION_TAG_MUTATION_ADD_START',
  APPLICATION_TAG_MUTATION_ADD_DONE: 'APPLICATION_TAG_MUTATION_ADD_DONE',
  APPLICATION_TAG_MUTATION_ADD_ERROR: 'APPLICATION_TAG_MUTATION_ADD_ERROR',
  APPLICATION_TAG_MUTATION_ADD_CANCELLED: 'APPLICATION_TAG_MUTATION_ADD_CANCELLED',
  APPLICATION_TAG_MUTATION_REMOVE_START: 'APPLICATION_TAG_MUTATION_REMOVE_START',
  APPLICATION_TAG_MUTATION_REMOVE_DONE: 'APPLICATION_TAG_MUTATION_REMOVE_DONE',
  APPLICATION_TAG_MUTATION_REMOVE_ERROR: 'APPLICATION_TAG_MUTATION_REMOVE_ERROR',
  APPLICATION_TAG_MUTATION_REMOVE_CANCELLED: 'APPLICATION_TAG_MUTATION_REMOVE_CANCELLED',

  APPLICATION_TAGS_FETCH_START: 'APPLICATION_TAGS_FETCH_START',
  APPLICATION_TAGS_FETCH_DONE: 'APPLICATION_TAGS_FETCH_DONE',
  APPLICATION_TAGS_FETCH_ERROR: 'APPLICATION_TAGS_FETCH_ERROR',
  APPLICATION_TAGS_FETCH_CANCELLED: 'APPLICATION_TAGS_FETCH_CANCELLED',

  /** events */
  APPLICATION_EVENTS_FETCH_START: 'APPLICATION_EVENTS_FETCH_START',
  APPLICATION_EVENTS_FETCH_DONE: 'APPLICATION_EVENTS_FETCH_DONE',
  APPLICATION_EVENTS_FETCH_ERROR: 'APPLICATION_EVENTS_FETCH_ERROR',
  APPLICATION_EVENTS_FETCH_CANCELLED: 'APPLICATION_EVENTS_FETCH_CANCELLED',

  /** rejections */
  APPLICATION_REJECTION_DONE: 'APPLICATION_REJECTION_DONE',

  /** comments sidebar */
  SHOW_COMMENTS: 'SHOW_COMMENTS',
  HIDE_COMMENTS: 'HIDE_COMMENTS',

  /** delete application */
  APPLICATION_DELETE_START: 'APPLICATION_DELETE_START',
  APPLICATION_DELETE_DONE: 'APPLICATION_DELETE_DONE',
  APPLICATION_DELETE_ERROR: 'APPLICATION_DELETE_ERROR',
  APPLICATION_DELETE_CANCELLED: 'APPLICATION_DELETE_CANCELLED',

  APPLICATION_MARK_AS_UNREAD_START: 'APPLICATION_MARK_AS_UNREAD_START',
  APPLICATION_MARK_AS_UNREAD_DONE: 'APPLICATION_MARK_AS_UNREAD_DONE',
  APPLICATION_MARK_AS_UNREAD_ERROR: 'APPLICATION_MARK_AS_UNREAD_ERROR',
  APPLICATION_MARK_AS_UNREAD_CANCELLED: 'APPLICATION_MARK_AS_UNREAD_CANCELLED',

  APPLICATION_HIDE_START: 'APPLICATION_HIDE_START',
  APPLICATION_HIDE_DONE: 'APPLICATION_HIDE_DONE',
  APPLICATION_HIDE_ERROR: 'APPLICATION_HIDE_ERROR',
  APPLICATION_HIDE_CANCELLED: 'APPLICATION_HIDE_CANCELLED',

  deleteApplication: (payload) => ({
    type: applicationActions.APPLICATION_DELETE_START,
    payload,
  }),

  markAsUnread: (payload) => ({
    type: applicationActions.APPLICATION_MARK_AS_UNREAD_START,
    payload,
  }),

  showPreloader: () => ({
    type: applicationActions.APPLICATION_LOADING_START,
  }),

  fetchApplication: (variables, recache) => ({
    type: applicationActions.APPLICATION_FETCH,
    payload: {
      variables,
      recache,
    },
  }),
  fetchApplications: (variables, recache) => ({
    type: applicationActions.APPLICATIONS_FETCH,
    payload: {
      variables,
      recache,
    },
  }),

  showCommentsAction: () => ({
    type: applicationActions.SHOW_COMMENTS,
  }),

  hideCommentsAction: () => ({
    type: applicationActions.HIDE_COMMENTS,
  }),

  updateApplicationRating: (payload) => ({
    type: applicationActions.APPLICATION_RATING_MUTATION_START,
    payload,
  }),

  updateSelectionLevel: (payload) => ({
    type: applicationActions.APPLICATION_SELECTION_LEVEL_MUTATION_START,
    payload,
  }),

  addTag: (payload) => ({
    type: applicationActions.APPLICATION_TAG_MUTATION_ADD_START,
    payload,
  }),

  removeTag: (payload) => ({
    type: applicationActions.APPLICATION_TAG_MUTATION_REMOVE_START,
    payload,
  }),

  refreshTags: () => ({
    type: applicationActions.APPLICATION_TAGS_FETCH_START,
  }),

  refreshEvents: () => ({
    type: applicationActions.APPLICATION_EVENTS_FETCH_START,
  }),

  toggleCommentEditor: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_EDITOR_TOGGLE,
    payload,
  }),

  toggleCommentEditorInvalid: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_EDITOR_INVALID_TOGGLE,
    payload,
  }),

  setCommentInEditor: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_EDITOR_SET,
    payload,
  }),

  setRawCommentInEditor: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_EDITOR_SET_RAW,
    payload,
  }),

  addComment: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_ADD_MUTATION_START,
    payload,
  }),

  editComment: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_EDIT_MUTATION_START,
    payload,
  }),

  removeComment: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_REMOVE_MUTATION_START,
    payload,
  }),

  refreshComments: () => ({
    type: applicationActions.APPLICATION_COMMENTS_FETCH_START,
  }),

  refreshRating: () => ({
    type: applicationActions.APPLICATION_RATING_FETCH_START,
  }),

  setCommentEditorCommentId: (payload) => ({
    type: applicationActions.APPLICATION_COMMENT_EDITOR_SET_COMMENT_ID,
    payload,
  }),

  applicationRefresh: (payload) => ({
    type: applicationActions.APPLICATION_REFRESH,
    payload,
  }),

  applicationHide: (payload) => ({
    type: applicationActions.APPLICATION_HIDE_START,
    payload,
  }),
};

export default applicationActions;
