const actions = {
  FETCH_JOB_RECEIPT_START: 'FETCH_JOB_RECEIPT_START',
  FETCH_JOB_RECEIPT_DONE: 'FETCH_JOB_RECEIPT_DONE',
  FETCH_JOB_RECEIPT_ERROR: 'FETCH_JOB_RECEIPT_ERROR',
  FETCH_JOB_RECEIPT_CANCELLED: 'FETCH_JOB_RECEIPT_CANCELLED',
  FETCH_JOB_RECEIPT_DENIED: 'FETCH_JOB_RECEIPT_DENIED',

  fetchJobReceipt: payload => ({
    type: actions.FETCH_JOB_RECEIPT_START,
    payload,
  }),
};

export default actions;
