import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  user: {
    initials: null,
  },
  loadedUser: null,
  submitting: false,
  submittingProfileChange: false,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.USER_LOADED:
      return state.set('user', action.payload);

    case actions.LOAD_USER_DATA_DONE:
      return state.set('loadedUser', action.payload);

    case actions.UPDATE_BILLING_START:
      return state.set('submitting', true);

    case actions.UPDATE_BILLING_ERROR:
      return state.set('submitting', false);
    case actions.UPDATE_BILLING_DONE:
      return state.set('loadedUser', action.payload).set('submitting', false);

    case actions.MUTATE_USER_START:
      return state.set('submittingProfileChange', true);
    case actions.MUTATE_USER_DONE:
      return state.set('submittingProfileChange', false);

    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
