const actions = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  EMAIL_TEMPLATE_FETCH: 'EMAIL_TEMPLATE_FETCH',
  EMAIL_TEMPLATE_FETCHING_ERROR: 'EMAIL_TEMPLATE_FETCHING_ERROR',
  EMAIL_TEMPLATE_FETCHING_DONE: 'EMAIL_TEMPLATE_FETCHING_DONE',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_TEMPLATE_GROUP: 'SET_TEMPLATE_GROUP',
  SET_APPLICATIONS: 'SET_APPLICATIONS',
  LOAD_TEMPLATE_DATA: 'LOAD_TEMPLATE_DATA',
  SET_SENDER_NAME: 'SET_SENDER_NAME',
  SET_SENDER_EMAIL: 'SET_SENDER_EMAIL',
  SET_SUBJECT: 'SET_SUBJECT',
  SET_BODY: 'SET_BODY',
  EMAIL_SEND_START: 'EMAIL_SEND_START',
  EMAIL_SEND_DONE: 'EMAIL_SEND_DONE',
  EMAIL_SEND_ERROR: 'EMAIL_SEND_ERROR',
  EMAIL_SEND_CANCELLED: 'EMAIL_SEND_CANCELLED',
  COPY_TEMPLATES: 'COPY_TEMPLATES',
  UPDATE_EDITING_BODY: 'UPDATE_EDITING_BODY',
  UPDATE_EDITING_SUBJECT: 'UPDATE_EDITING_SUBJECT',
  SET_CATEGORY: 'SET_CATEGORY',

  LOGO_DATA_FETCH_START: 'LOGO_DATA_FETCH_START',
  LOGO_DATA_FETCH_DONE: 'LOGO_DATA_FETCH_DONE',
  LOGO_DATA_FETCH_ERROR: 'LOGO_DATA_FETCH_ERROR',
  LOGO_DATA_FETCH_CANCELLED: 'LOGO_DATA_FETCH_CANCELLED',

  LOGO_UPLOAD_START: 'LOGO_UPLOAD_START',
  LOGO_UPLOAD_DONE: 'LOGO_UPLOAD_DONE',
  LOGO_UPLOAD_ERROR: 'LOGO_UPLOAD_ERROR',
  LOGO_UPLOAD_CANCELLED: 'LOGO_UPLOAD_CANCELLED',

  REFETCH_LOGO_DATA: 'REFETCH_LOGO_DATA',

  CONNECT_IMAGE_TO_JOB_START: 'CONNECT_IMAGE_TO_JOB_START',
  CONNECT_IMAGE_TO_JOB_DONE: 'CONNECT_IMAGE_TO_JOB_DONE',
  CONNECT_IMAGE_TO_JOB_ERROR: 'CONNECT_IMAGE_TO_JOB_ERROR',
  CONNECT_IMAGE_TO_JOB_CANCELLED: 'CONNECT_IMAGE_TO_JOB_CANCELLED',

  SET_SELECTED_LOGO: 'SET_SELECTED_LOGO',

  DELETE_LOGO_START: 'DELETE_LOGO_START',
  DELETE_LOGO_DONE: 'DELETE_LOGO_DONE',
  DELETE_LOGO_ERROR: 'DELETE_LOGO_ERROR',
  DELETE_LOGO_CANCELLED: 'DELETE_LOGO_CANCELLED',

  RESET_EDITOR: 'RESET_EDITOR',

  SELECT_FIRST_LOGO: 'SELECT_FIRST_LOGO',

  SET_PREVIOUS_DATA: 'SET_PREVIOUS_DATA',
  FETCH_PREVIOUS_DATA: 'FETCH_PREVIOUS_DATA',

  resetEditor: () => ({
    type: actions.RESET_EDITOR,
  }),

  deleteLogo: file => ({
    type: actions.DELETE_LOGO_START,
    payload: file,
  }),

  setSelectedLogo: file => ({
    type: actions.SET_SELECTED_LOGO,
    payload: file,
  }),

  setFirstLogo: () => ({
    type: actions.SELECT_FIRST_LOGO,
  }),

  uploadLogo: payload => ({
    type: actions.LOGO_UPLOAD_START,
    payload,
  }),

  fetchTemplates: payload => ({
    type: actions.EMAIL_TEMPLATE_FETCH,
    payload,
  }),

  fetchLogo: () => ({
    type: actions.LOGO_DATA_FETCH_START,
  }),

  setCategory: payload => ({
    type: actions.SET_CATEGORY,
    payload,
  }),

  updateEditingBody: payload => ({
    type: actions.UPDATE_EDITING_BODY,
    payload,
  }),

  updateEditingSubject: payload => ({
    type: actions.UPDATE_EDITING_SUBJECT,
    payload,
  }),

  copyTemplates: () => ({
    type: actions.COPY_TEMPLATES,
  }),

  toggleModal: state => ({
    type: actions.TOGGLE_MODAL,
    payload: state,
  }),

  setTemplateGroup: action => ({
    type: actions.SET_TEMPLATE_GROUP,
    payload: action,
  }),

  setTemplate: action => ({
    type: actions.SET_TEMPLATE,
    payload: action,
  }),

  setRecepients: applications => ({
    type: actions.SET_APPLICATIONS,
    payload: applications,
  }),

  loadTemplateData: body => ({
    type: actions.LOAD_TEMPLATE_DATA,
    payload: body,
  }),

  setSenderName: name => ({
    type: actions.SET_SENDER_NAME,
    payload: name,
  }),

  setSenderEmail: email => ({
    type: actions.SET_SENDER_EMAIL,
    payload: email,
  }),

  setSubject: subject => ({
    type: actions.SET_SUBJECT,
    payload: subject,
  }),

  setBody: body => ({
    type: actions.SET_BODY,
    payload: body,
  }),

  sendEmail: email => ({
    type: actions.EMAIL_SEND_START,
    payload: email,
  }),
};

export default actions;
