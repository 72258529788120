import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from 'redux/auth/actions';
import queryString from 'query-string';
import Loader from 'components/animatedLoader';
import IntlMessages from 'components/utility/IntlMessages';
import ValidateStyled from './validate.style';

class Validate extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    validate: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object,
  };

  componentDidMount() {
    const {
      location: { search },
      validate,
    } = this.props;

    const { code } = queryString.parse(search);
    validate(code);
  }

  render() {
    return (
      <ValidateStyled>
        <Loader type="cube-transition" scale="2" />
        <div className='title' >
          <IntlMessages id="login.loading" />
        </div>
      </ValidateStyled>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.Auth.get('loggedIn'),
});

const mapDispatchToProps = {
  validate: actions.validate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Validate);
