const adDiscountListActions = {
  FETCH_AD_DISCOUNT_LIST_START: 'FETCH_AD_DISCOUNT_LIST_START',
  FETCH_AD_DISCOUNT_LIST_DONE: 'FETCH_AD_DISCOUNT_LIST_DONE',
  FETCH_AD_DISCOUNT_LIST_ERROR: 'FETCH_AD_DISCOUNT_LIST_ERROR',
  FETCH_AD_DISCOUNT_LIST_CANCELLED: 'FETCH_AD_DISCOUNT_LIST_CANCELLED',
  FETCH_AD_DISCOUNT_LIST_DENIED: 'FETCH_AD_DISCOUNT_LIST_DENIED',

  fetchAdDiscountList: () => ({
    type: adDiscountListActions.FETCH_AD_DISCOUNT_LIST_START,
  }),
};

export default adDiscountListActions;
