const actions = {
  SEND_FEEDBACK_START: 'SEND_FEEDBACK_START',
  SEND_FEEDBACK_DONE: 'SEND_FEEDBACK_DONE',
  SEND_FEEDBACK_ERROR: 'SEND_FEEDBACK_ERROR',
  SEND_FEEDBACK_CANCELLED: 'SEND_FEEDBACK_CANCELLED',

  CHECK_SHOW_FEEDBACK: 'CHECK_SHOW_FEEDBACK',
  CHECK_SHOW_FEEDBACK_ALLOWED: 'CHECK_SHOW_FEEDBACK_ALLOWED',
  CHECK_SHOW_FEEDBACK_DENIED: 'CHECK_SHOW_FEEDBACK_DENIED',

  checkShowFeedback: () => ({
    type: actions.CHECK_SHOW_FEEDBACK,
  }),

  sendFeedback: payload => ({
    type: actions.SEND_FEEDBACK_START,
    payload,
  }),
};

export default actions;
