import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { MyCompanySectionSubmitProps } from './types';

const updateMyCompanySection = (payload: MyCompanySectionSubmitProps) => {
  const variables = { ...payload };

  if (payload.backgroundImage?.file) {
    variables.backgroundImageAttachment = payload.backgroundImage.file;
    delete variables.backgroundImage;
  }

  if (payload.companyLogo?.file) {
    variables.companyLogoAttachment = payload.companyLogo.file;
    delete variables.companyLogo;
  }

  if (variables.companyLogo) {
    variables.companyLogo = omit(variables.companyLogo, '__typename');
  }

  if (variables.backgroundImage) {
    variables.backgroundImage = omit(variables.backgroundImage, '__typename');
  }

  const mutation = gql`
    mutation companyProfileMyCompanySectionMutation(
      $id: Int!
      $slogan: String
      $companyName: String
      $companyLogo: CompanyProfileFileInput
      $companyLogoAttachment: CompanyProfileUpload
      $backgroundImage: CompanyProfileFileInput
      $backgroundImageAttachment: CompanyProfileUpload
    ) {
      companyProfileMyCompanySectionMutation(
        id: $id
        myCompany: {
          slogan: $slogan
          companyName: $companyName
          language: SR
          companyLogo: $companyLogo
          companyLogoAttachment: $companyLogoAttachment
          backgroundImage: $backgroundImage
          backgroundImageAttachment: $backgroundImageAttachment
        }
      ) {
        id
        backgroundImage {
          description
          id
          name
          path
        }
        companyLogo {
          description
          id
          name
          path
        }
        companyName
        language
        slogan
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export default updateMyCompanySection;
