import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchIncompleteJobsToContinue = () => {
  const query = gql`
    query incompleteJobsToContinue {
      incompleteJobsToContinue {
        uid
        dateUpdated
        completionPercentage
        data {
          location
          position
          siteVisibility
        }
      }
    }
  `;

  return client.query({
    query,
    fetchPolicy: 'network-only',
  });
};

export default fetchIncompleteJobsToContinue;