import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  zipModalVisible: false,
  recepients: null,
  submitting: false,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_ZIP_MODAL_VISIBLE:
      return state.set('zipModalVisible', action.payload);
    case actions.SET_ZIP_RECEPIENTS:
      return state.set('recepients', action.payload);
    case actions.ZIP_APPLICATIONS_START:
      return state.set('submitting', true);
    case actions.ZIP_APPLICATIONS_ERROR:
    case actions.ZIP_APPLICATIONS_DONE:
      return state
        .set('submitting', false)
        .set('zipModalVisible', false)
        .set('recepients', []);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
