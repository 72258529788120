import gql from 'graphql-tag';

const fetchCompanyBenefitTypesQuery = gql`
  query countryNames {
    companyProfileCompanyBenefitTypes {
      id
      titleSr
      titleEn
      inputExampleSr
      inputExampleEn
      iconClass
    }
  }
`;

export default fetchCompanyBenefitTypesQuery;
