import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { CompanyBrand } from 'types/profile.types';

const companyBrandUpdate = (brand: CompanyBrand) => {
  let variables = { ...brand };
  if (brand.logo) {
    variables.logo = brand.logo.file as any;
  }
  variables = omit(variables, '__typename');
  const mutation = gql`
    mutation companyProfileCompanyBrandMutation(
      $profileId: Int!
      $description: String
      $id: Int
      $logo: CompanyProfileUpload
      $title: String
    ) {
      companyProfileCompanyBrandMutation(
        id: $profileId
        brand: { description: $description, id: $id, logo: $logo, title: $title }
      ) {
        description
        id
        logo {
          id
          path
        }
        title
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

const companyBrandDelete = (variables: CompanyBrand) => {
  const mutation = gql`
    mutation companyProfileDeleteCompanyBrand($profileId: Int!, $id: Int!) {
      companyProfileDeleteCompanyBrand(id: $profileId, brandId: $id) {
        description
        id
        logo {
          id
          description
          path
        }
        title
      }
    }
  `;

  return client.mutate({
    mutation,
    variables
  });
};

export { companyBrandUpdate, companyBrandDelete };
