import { Map } from 'immutable';
import { ProfileFile } from 'types/profile.types';
import { TOGGLE_PREVIEW_MODAL, TOGGLE_SUBSECTION_EDITING_IN_PROGRESS } from '../general/types';
import ImmutableMapInterface from '../ImmutableMapInterface.type';

export const PROFILE_MY_COMPANY_SECTION_UPDATE_START = 'PROFILE_MY_COMPANY_SECTION_UPDATE_START';
export const PROFILE_MY_COMPANY_SECTION_UPDATE_DONE = 'PROFILE_MY_COMPANY_SECTION_UPDATE_DONE';
export const PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED =
  'PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED';
export const PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR = 'PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR';

export const PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_LOGO =
  'PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_LOGO';
export const PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_BACKGROUND_IMAGE =
  'PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_BACKGROUND_IMAGE';
export const PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_FIELD =
  'PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_FIELD';

export interface MyCompanySectionSubmitProps {
  id: number | null;
  companyName: string;
  slogan: string;
  language: 'sr' | 'en';
  companyLogo?: ProfileFile | null;
  backgroundImage?: ProfileFile | null;
  successMessage: null | string;
  backgroundImageAttachment?: File;
  companyLogoAttachment?: File;
}

/* STATE INTERFACES */
export interface MyCompanySectionStateMap extends Map<string, any> {
  companyName: string;
  slogan: string;
  companyLogo: ProfileFile | null;
  backgroundImage: ProfileFile | null;
  myCompanySectionUpdating: boolean;
  myCompanySectionUpdateError: string | null;
  myCompanySectionUpdateSuccess: string | null;
}
export type MyCompanySectionState = ImmutableMapInterface<MyCompanySectionStateMap, string, any>;

/* STATE INTERFACES */

export interface UpdateStateFieldProps {
  field: 'companyName' | 'slogan';
  value: string;
}
export interface MyCompanySectionUpdateStateFieldAction {
  type: typeof PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_FIELD;
  payload: UpdateStateFieldProps;
}

export interface MyCompanySectionUpdateFileAction {
  type:
    | typeof PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_LOGO
    | typeof PROFILE_MY_COMPANY_SECTION_UPDATE_STATE_BACKGROUND_IMAGE;
  payload: ProfileFile | null;
}

export interface MyCompanySectionNoPayloadAction {
  type:
    | typeof PROFILE_MY_COMPANY_SECTION_UPDATE_START
    | typeof PROFILE_MY_COMPANY_SECTION_UPDATE_CANCELLED;
}

export interface MyCompanySectionApiErrorAction {
  type: typeof PROFILE_MY_COMPANY_SECTION_UPDATE_ERROR;
  payload: string;
}

export interface MyCompanySectionApiRequestDoneAction {
  type: typeof PROFILE_MY_COMPANY_SECTION_UPDATE_DONE;
  payload: MyCompanySectionSubmitProps;
}

export interface ProfileTogglePreviewAction {
  type: typeof TOGGLE_PREVIEW_MODAL;
  payload: boolean;
}

export interface ProfileToggleSubsectionEditing {
  type: typeof TOGGLE_SUBSECTION_EDITING_IN_PROGRESS;
  payload: boolean;
}
