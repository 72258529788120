import { message } from 'antd';
import { replace } from 'connected-react-router';
import links from 'helpers/links';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { fetchCountryNamesGraphql, fetchEmployeeCountOptionsGraphql, fetchProfileGraphql, mutateProfileGraphql } from './graphql';
import uploadProfileLogoRequest from './requests';

/* fetch profile generator */
function* fetchProfileGenerator() {
  try {
    const response = yield call(fetchProfileGraphql);
    const {
      data: {
        employerProfile: { profileType },
      },
    } = response;

    /**
     * store this in history and use it as BACK button on designed profiles
     */
    if (['designed_profile', 'hr_branding_campaign'].indexOf(profileType) !== -1) {
      yield put(replace(links.jobList.url));
      yield call(() => {
        window.open(links.profile.editor);
        // window.location.href = links.profile.editor;
      });
    }

    yield put({
      type: actions.PROFILE_FETCH_DONE,
      payload: response.data.employerProfile,
    });
  } catch (e) {
    log(e);
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.PROFILE_FETCH_DENIED });
    }
    yield put({ type: actions.PROFILE_FETCH_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.PROFILE_FETCH_CANCELLED,
      });
    }
  }
}

function* fetchCountryNamesGenerator() {
  try {
    const response = yield call(fetchCountryNamesGraphql);
    yield put({
      type: actions.PROFILE_COUNTRY_NAMES_FETCH_DONE,
      payload: response.data.employerProfileCountries,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.PROFILE_COUNTRY_NAMES_FETCH_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.PROFILE_COUNTRY_NAMES_FETCH_CANCELELD,
      });
    }
  }
}

function* fetchEmployeeCountOptionsGenerator() {
  try {
    const response = yield call(fetchEmployeeCountOptionsGraphql);
    yield put({
      type: actions.PROFILE_EMPLOYEE_COUNT_OPTIONS_DONE,
      payload: response.data.employerProfileEmployeeCountOptions,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.PROFILE_EMPLOYEE_COUNT_OPTIONS_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.PROFILE_EMPLOYEE_COUNT_OPTIONS_CANCELLED,
      });
    }
  }
}

function* mutateEmployerProfileGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const response = yield call(mutateProfileGraphql, action.payload);
    yield put({
      type: actions.PROFILE_UPDATE_DONE,
      payload: response.data.modifyEmployerProfile,
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    log(e);
    yield put({ type: actions.PROFILE_UPDATE_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.PROFILE_UPDATE_CANCELLED,
      });
    }
  }
}

function* uploadProfileLogoGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const requests = action.payload.map(data => call(uploadProfileLogoRequest, data));
    const responses = yield all(requests);
    yield put({
      type: actions.PROFILE_LOGO_UPLOAD_DONE,
      payload: responses,
    });
  } catch (e) {
    let errorMessage = null;
    if (e.response) {
      if (e.response.data.message === 'Image is not valid') {
        const {
          response: {
            data: { errors },
          },
        } = e;
        errorMessage = errors
          .map((item) => {
            let key = null;
            if (item.indexOf('This file is not a valid image') !== -1) {
              key = 'error.not-image';
            } else if (item.indexOf('is too small') !== -1) {
              key = 'error.image-small';
            } else if (item.indexOf('The file is too large') !== -1) {
              key = 'error.filesize-large';
            } else {
              key = 'error.tryAgainMessage';
            }
            return translate(key);
          })
          .join('\n');
      }
    }
    yield put({ type: actions.PROFILE_LOGO_UPLOAD_ERROR });
    message.error(errorMessage || translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

export function* fetchProfile() {
  yield takeLatest(actions.PROFILE_FETCH_START, fetchProfileGenerator);
}

export function* fetchCountryNames() {
  yield takeLatest(
    [actions.PROFILE_FETCH_START, actions.PROFILE_COUNTRY_NAMES_FETCH_START],
    fetchCountryNamesGenerator,
  );
}

export function* fetchEmployeeCountOptions() {
  yield takeLatest(
    [actions.PROFILE_FETCH_START, actions.PROFILE_EMPLOYEE_COUNT_OPTIONS_START],
    fetchEmployeeCountOptionsGenerator,
  );
}

export function* mutateEmployerProfile() {
  yield takeLatest(actions.PROFILE_UPDATE_START, mutateEmployerProfileGenerator);
}

function* uploadProfileLogo() {
  yield takeLatest(actions.PROFILE_LOGO_UPLOAD_START, uploadProfileLogoGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchProfile),
    spawn(fetchCountryNames),
    spawn(fetchEmployeeCountOptions),
    spawn(mutateEmployerProfile),
    spawn(uploadProfileLogo),
  ]);
}
