const emailTemplatesMock = [
  {
    id: 1,
    subject: 'Odbijenica 1',
    body:
      'Poštovani,\r\n\r\nObaveštavamo Vas da je konkurs za poziciju %radno_mesto% završen. Odlučili smo se za drugog kandidata koji je najviše odgovarao zahtevima date pozicije.\r\n\r\nHvala Vam na interesovanju i uloženom vremenu.\r\nŽelimo Vam sreću u daljoj potrazi za poslom i uspešnu karijeru.\r\n\r\nSrdačan pozdrav,',
    language: 'sr',
    type: 'default',
    created_datetime: '2015-08-21T11:46:13+0200',
    updated_datetime: '2015-08-21T11:46:13+0200',
    category: 'rejection',
  },
  {
    id: 2,
    subject: 'Odbijenica 2',
    body:
      'Poštovani,\n\nZahvaljujemo Vam se na interesovanju za rad na poziciji %radno_mesto%. Nakon pregleda Vaše biografije, na žalost, odlučili smo se za drugog kandidata koji je najviše odgovarao zahtevima navedene pozicije.\n\nVašu biografiju ćemo sačuvati i bićemo slobodni da Vas kontaktiramo kada budemo raspisivali konkurs za poziciju za koju smatramo da bi Vam više odgovarala. Isto tako, Vas ohrabrujemo da i dalje pratite naše konkurse.\n\nSrdačan pozdrav, ',
    language: 'sr',
    type: 'default',
    created_datetime: '2015-08-21T11:46:13+0200',
    updated_datetime: '2016-04-11T14:52:24+0200',
    category: 'rejection',
  },
  {
    id: 3,
    subject: 'Poziv na intervju 1',
    body:
      'Poštovani,\r\n\r\nNakon što smo pregledali Vašu biografiju, zainteresovani smo da Vas malo bolje upoznamo, te bi nam bilo drago da dođete na intervju dana ___________ u ____________ časova. Naša adresa je ______________.\r\n\r\nMolimo vas za potvrdu putem email-a ili telefona. Moj kontakt je ______________. Budite slobodni da me kontaktirate i za sva dodatna pitanja.\r\n\r\nSrdačan pozdrav,\t\r\n',
    language: 'sr',
    type: 'default',
    created_datetime: '2015-08-21T11:46:13+0200',
    updated_datetime: '2015-08-21T11:46:13+0200',
    category: 'call_to_interview',
  },
  {
    id: 4,
    subject: 'Poziv na intervju 2 ',
    body:
      'Poštovani,\r\n\r\nVoleli bismo da Vas pozovemo na intervju koji će se održati dana ____________ u ________ časova u našim kancelarijama na adresi _______________.\r\n\r\nMoj kontakt je _____________ pa Vas molim da potvrdite svoj dolazak putem email-a ili telefona.\r\n\r\nSrdačan pozdrav,\t\t\r\n',
    language: 'sr',
    type: 'default',
    created_datetime: '2015-08-21T11:46:13+0200',
    updated_datetime: '2015-08-21T11:46:13+0200',
    category: 'call_to_interview',
  },
  {
    id: 7,
    subject: 'Zahvalnica',
    body:
      'Poštovani,\n\nZahvaljujemo Vam se na interesovanju za poziciju %radno_mesto%. Po isteku roka konkursa, pristupićemo daljem procesu selekcije kada ćemo Vas i obavestiti o narednim koracima. \n\nHvala na strpljenju.\nSrdačan pozdrav,',
    language: 'sr',
    type: 'default',
    created_datetime: '2015-12-03T00:00:00+0100',
    updated_datetime: '2018-03-05T23:05:38+0100',
    category: 'thank_you',
  },
];

export default emailTemplatesMock;
