import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  submitting: false,
  showFeedback: false,
});

export default function feedbackReducer(state = initState, action) {
  switch (action.type) {
    case actions.SEND_FEEDBACK_START:
      return state.set('submitting', true);

    case actions.CHECK_SHOW_FEEDBACK_ALLOWED:
      return state.set('showFeedback', true);

    case actions.SEND_FEEDBACK_ERROR:
    case actions.SEND_FEEDBACK_CANCELLED:
      return state.set('submitting', false);

    case actions.SEND_FEEDBACK_DONE:
      return state.set('submitting', false).set('showFeedback', false);
    default:
      return state;
  }
}
