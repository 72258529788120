import styled from 'styled-components';
import { palette } from 'styled-theme';

const Form = component => styled(component)`
  
`;

const Item = component => styled(component)`
  &.ant-form-item, 
  &.ant-form-item .ant-form-item-label label {
    color: ${palette('foreground', 0)};
  }
  .ant-input-group-addon {
    background: ${palette('background', 57)};
  }
`;

export default Form;
export { Item };
