import { fromJS, List, Map } from 'immutable';
import { ReduxAction } from 'types/redux.types';
import faqActions from './actions';

const initState = Map({
  posts: List(),
});

export default function faqReducer(state = initState, action: ReduxAction) {
  switch (action.type) {
    case faqActions.FETCH_FAQ_DONE:
      return state.set('posts', fromJS(action.payload));
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
