import { SmileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { Button } from 'components/uielements';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import analyticsActions from 'redux/analytics/actions';
import storeObject from 'redux/storeDeclaration';
import actions from './actions';
import verifyCheckpointRequest, { finishOnboardingRequest } from './requests';

function* verifyCheckpointGenerator(action) {
  try {
    const response = yield call(verifyCheckpointRequest, action.payload);
    if (response.data.show) {
      yield put({
        type: actions.VERIFY_CHECKPOINT_DONE,
        payload: {
          section: action.payload.section,
          state: response.data.show,
        },
      });
    }
  } catch (e) {
    yield put({ type: actions.VERIFY_CHECKPOINT_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.VERIFY_CHECKPOINT_CANCELLED,
      });
    }
  }
}

function* verifyCheckpointWithNotificationGenerator(action) {
  try {
    const response = yield call(verifyCheckpointRequest, action.payload);
    const store = storeObject.getStore();
    yield call(() => {
      if (response.data.show) {
        store.dispatch({
          type: analyticsActions.REDUX_TRACK_EVENT,
          payload: {
            category: 'onboarding',
            action: 'onboarding-request-notification',
          },
        });
        notification.open({
          message: translate('tour.tools.notification.title'),
          description: translate('tour.tools.notification.description'),
          duration: 0,
          key: 'tour notification',
          icon: <SmileOutlined style={{ color: '#108ee9' }} />,
          onClose: () => {
            store.dispatch({
              type: actions.TOUR_RUN_SECTION,
              payload: {
                section: action.payload.section,
                state: actions.CANCELL,
              },
            });
            store.dispatch({
              type: analyticsActions.REDUX_TRACK_EVENT,
              payload: {
                category: 'onboarding',
                action: 'close_X',
                label: 'notification',
              },
            });
          },
          btn: (
            <>
              <Button
                onClick={() => {
                  notification.close('tour notification');
                  store.dispatch({
                    type: actions.TOUR_RUN_SECTION,
                    payload: {
                      section: action.payload.section,
                      state: actions.CANCELL,
                    },
                  });
                  store.dispatch({
                    type: analyticsActions.REDUX_TRACK_EVENT,
                    payload: {
                      category: 'onboarding',
                      action: 'close_no',
                      label: 'notification',
                    },
                  });
                }}
              >
                {translate('tour.notification.cancel')}
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: '10px',
                }}
                onClick={() => {
                  store.dispatch({
                    type: actions.VERIFY_CHECKPOINT_DONE,
                    payload: {
                      section: action.payload.section,
                      state: response.data.show,
                    },
                  });
                  notification.close('tour notification');
                  store.dispatch({
                    type: analyticsActions.REDUX_TRACK_EVENT,
                    payload: {
                      category: 'onboarding',
                      action: 'run',
                      label: 'notification',
                    },
                  });
                }}
              >
                {translate('tour.notification.run')}
              </Button>
            </>
          ),
        });
      }
    });
  } catch (e) {
    yield put({ type: actions.VERIFY_CHECKPOINT_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.VERIFY_CHECKPOINT_CANCELLED,
      });
    }
  }
}

function* finishOnboardingGenerator(action) {
  try {
    if ([actions.CANCELL, actions.STOP].indexOf(action.payload.state) !== -1) {
      if (action.payload.section) {
        yield call(finishOnboardingRequest, {
          section: action.payload.section,
        });
      }
      if (actions.CANCELL === action.payload.state) {
        yield put({
          type: actions.RUN_CANCEL_TOUR,
          payload: {
            state: actions.RUN,
          },
        });
      }
    }
  } catch (e) {
    log(e);
  }
}

export function* markAsFinishedGenerator(action) {
  if (action.payload.section) {
    yield call(finishOnboardingRequest, {
      section: action.payload.section,
    });
  }
}

export function* verifyCheckpoint() {
  yield takeEvery(actions.VERIFY_CHECKPOINT_START, verifyCheckpointGenerator);
}

export function* verifyCheckpointWithNotification() {
  yield takeLatest(
    actions.VERIFY_CHECKPOINT_WITH_NOTIFICATION_START,
    verifyCheckpointWithNotificationGenerator
  );
}

export function* finishOnboarding() {
  yield takeLatest(actions.TOUR_RUN_SECTION, finishOnboardingGenerator);
}

export function* markAsFinished() {
  yield takeEvery(actions.FINISH_CHECKPOINT, markAsFinishedGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(verifyCheckpoint),
    spawn(finishOnboarding),
    spawn(verifyCheckpointWithNotification),
    spawn(markAsFinished),
  ]);
}
