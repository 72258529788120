import commentListeners from './listeners/comments';
import eventsListeners from './listeners/events';
import ratingListeners from './listeners/rating';
import tagListeners from './listeners/tags';
import listenersStore, { ListenerList } from './listenersStore';

const listenersList: ListenerList = {
  commentListeners,
  tagListeners,
  ratingListeners,
  eventsListeners,
};

export default listenersStore(listenersList);
