import { all, call, cancelled, put, spawn, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import fetchPackagesGraphql from './graphql';

function* fetchPackagesGenerator(action) {
  try {
    const response = yield call(fetchPackagesGraphql, action.payload);
    yield put({
      type: actions.FETCH_PACKAGES_DONE,
      payload: response.data.companyPackages,
    });
  } catch (e) {
    if (e.message.indexOf('access.denied') !== -1) {
      yield put({ type: actions.FETCH_PACKAGES_DENIED });
    }
    yield put({ type: actions.FETCH_PACKAGES_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.FETCH_PACKAGES_CANCELLED,
      });
    }
  }
}

export function* fetchPackages() {
  yield takeLatest(
    actions.FETCH_PACKAGES_START,
    fetchPackagesGenerator,
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchPackages),
  ]);
}
