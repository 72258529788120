import Nprogress from 'nprogress';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { fetchAllApplicationsGraphql, fetchAllTagsGraphql, fetchProjectsGraphql } from './graphql';
// import fetchAllApplicationRequest from './requests';

/* fetchAllApplicationsGenerator */
function* fetchAllApplicationsGenerator() {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const AllApplications = (state) => state.AllApplications;
    const state = yield select(AllApplications);
    const response = yield call(fetchAllApplicationsGraphql, {
      variables: state.get('filters').toJS(),
      recache: true,
    });
    log(response);
    yield put({
      type: actions.ALL_APPLICATIONS_FETCH_DONE,
      payload: {
        applications: response.data.allApplicationsInProjects.applications,
        count: parseInt(response.data.allApplicationsInProjects.count, 10),
      },
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.ALL_APPLICATIONS_FETCH_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: actions.ALL_APPLICATIONS_FETCH_CANCELLED,
      });
    }
  }
}

function* fetchFiltersGenerator() {
  try {
    const responses = yield all([call(fetchProjectsGraphql), call(fetchAllTagsGraphql)]);
    yield put({
      type: actions.ALL_APPLICATIONS_FILTERS_FETCH_DONE,
      payload: responses,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.ALL_APPLICATIONS_FILTERS_FETCH_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.ALL_APPLICATIONS_FILTERS_FETCH_CANCELLED,
      });
    }
  }
}

/* fetch all applications  sada */
export function* fetchAllApplications() {
  yield takeLatest(
    [
      actions.ALL_APPLICATIONS_FETCH_START,
      actions.ALL_APPLICATIONS_SET_FILTER,
      actions.ALL_APPLICATIONS_RESET_FILTER,
    ],
    fetchAllApplicationsGenerator
  );
}

export function* fetchFilters() {
  yield takeLatest(actions.ALL_APPLICATIONS_FILTERS_FETCH_START, fetchFiltersGenerator);
}

export default function* rootSaga() {
  yield all([spawn(fetchAllApplications), spawn(fetchFilters)]);
}
