import { Listener } from '../listenersStore';

const tagListeners: Listener = {
  'refresh/tags': (dispatch: any, action: any) => {
    dispatch({
      type: 'APPLICATION_TAGS_FETCH_START',
      payload: action.payload,
    });
  },
};

export default tagListeners;
