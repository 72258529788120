import { all, spawn } from 'redux-saga/effects';
import profilePostSaga from './profilePost/saga';
import aboutUsSaga from './aboutUs/saga';
import companyBrandSaga from './companyBrand/saga';
import companyBenefitsSaga from './companyBenefits/saga';
import contactSectionSaga from './contactSection/saga';
import companyTeamMembersSaga from './companyTeamMembers/saga';
import selectionProcessSaga from './selectionProcess/saga';
import myCompanySectionSaga from './myCompanySection/saga';
import generalSaga from './general/saga';

export default function* rootSaga() {
  yield all([
    spawn(generalSaga),
    spawn(profilePostSaga),
    spawn(aboutUsSaga),
    spawn(companyBrandSaga),
    spawn(companyBenefitsSaga),
    spawn(contactSectionSaga),
    spawn(companyTeamMembersSaga),
    spawn(selectionProcessSaga),
    spawn(myCompanySectionSaga),
  ]);
}
