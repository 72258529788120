import axios from 'helpers/axios';
import config from '../../config';
import { getToken } from '../../helpers/utility';

const zipApplicationsRequest = (data) => {
  const { applicationIds, projectId } = data;
  return axios({
    method: 'post',
    url: `${config.apiUrl}/applications/zip`,
    data: {
      applicationIds,
      projectId,
    },
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

export default zipApplicationsRequest;
