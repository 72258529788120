const teamMock = {
  invitedMembers: [],
  teamMembers: [
    {
      id: '392d94dddc059732dfcc2234d73acf22',
      access: [
        {
          id: 'a1833d1315010349d88d378059ba97aa',
          allowed: true,
          contentType: 'admin',
        },
        {
          id: '0a35d2fd656836d205c9df82d0937cbc',
          allowed: true,
          contentType: 'job',
        },
        {
          id: '8738b7515cd21e33539cf893b8ca6b43',
          allowed: false,
          contentType: 'profile',
        },
        {
          id: 'ae46b323fc321d0f176ec3a60b3e1bd4',
          allowed: true,
          contentType: 'invoice',
        },
      ],
      employer: {
        id: '1',
        email: 'demo@infostud.com',
        name: 'Demo Poslodavac',
        initials: 'DD',
        currentUser: true,
      },
    },
    {
      id: 'd4eb8ce25e21b18ae39aa33142bd8a1e',
      access: [
        {
          id: '802f6e1cd9262ee33b1ed5a7f614d599',
          allowed: true,
          contentType: 'admin',
        },
        {
          id: 'b69d12938bf47d1bf2833ccbb05c32f5',
          allowed: true,
          contentType: 'job',
        },
        {
          id: '7e582e98349fd5193871c644cf0a92eb',
          allowed: false,
          contentType: 'profile',
        },
        {
          id: '7ee70e3d1a81b5e4cb354322a7404eee',
          allowed: true,
          contentType: 'invoice',
        },
      ],
      employer: {
        id: '36267',
        email: 'demo2@infostud.com',
        name: 'Drugi korisnik iste kompanije',
        initials: 'DK',
        currentUser: false,
      },
    },
  ],
};

export default teamMock;
