import { Map } from 'immutable';
import { ProfileFile } from 'types/profile.types';
import { ProfileActions, ProfileState } from '../types';
import {
  ProfileAboutUsState,
  PROFILE_ABOUT_US_ADD_FILE,
  PROFILE_ABOUT_US_ADD_VIDEO,
  PROFILE_ABOUT_US_DELETE_FILE,
  PROFILE_ABOUT_US_DELETE_VIDEO,
  PROFILE_ABOUT_US_UPDATE_CANCELLED,
  PROFILE_ABOUT_US_UPDATE_DONE,
  PROFILE_ABOUT_US_UPDATE_ERROR,
  PROFILE_ABOUT_US_UPDATE_FIELD,
  PROFILE_ABOUT_US_UPDATE_FILE,
  PROFILE_ABOUT_US_UPDATE_START,
  PROFILE_ABOUT_US_UPDATE_VIDEO
} from './types';

const initState: ProfileAboutUsState = Map<string, any>({
  aboutUs: null,
  aboutUsSubmitting: false,
  aboutUsSubmitError: null,
  aboutUsSubmitSuccess: null,
});

const reducer = (state: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    // API Call
    case PROFILE_ABOUT_US_UPDATE_START:
      return state
        .set('aboutUsSubmitting', true)
        .set('aboutUsSubmitError', null)
        .set('aboutUsSubmitSuccess', null);
    case PROFILE_ABOUT_US_UPDATE_ERROR:
      return state.set('aboutUsSubmitting', false).set('aboutUsSubmitError', action.payload);
    case PROFILE_ABOUT_US_UPDATE_CANCELLED:
      return state.set('aboutUsSubmitting', false);
    case PROFILE_ABOUT_US_UPDATE_DONE:
      return state
        .set('aboutUsSubmitting', false)
        .set('aboutUs', action.payload.aboutUs)
        .set('aboutUsSubmitSuccess', action.payload.successMessage);

    case PROFILE_ABOUT_US_UPDATE_FIELD:
      return state.set('aboutUs', {
        ...state.get('aboutUs'),
        [action.payload.field]: action.payload.value,
      });

    case PROFILE_ABOUT_US_ADD_VIDEO: {
      const aboutUs = state.get('aboutUs');
      return state.set('aboutUs', {
        ...aboutUs,
        videoUrls: [null, ...aboutUs.videoUrls],
      });
    }

    case PROFILE_ABOUT_US_UPDATE_VIDEO: {
      const aboutUs = state.get('aboutUs');
      const videoIndex = aboutUs.videoUrls.findIndex(
        (v: any, index: number) => index === action.payload.index
      );
      if (videoIndex < 0) {
        return state;
      }
      return state.set('aboutUs', {
        ...aboutUs,
        videoUrls: Object.assign([], aboutUs.videoUrls, {
          [videoIndex]: action.payload.link,
        }),
      });
    }

    case PROFILE_ABOUT_US_DELETE_VIDEO: {
      const aboutUs = state.get('aboutUs');
      return state.set('aboutUs', {
        ...aboutUs,
        videoUrls: aboutUs.videoUrls.filter(
          (v: any, index: number) => index !== action.payload.index
        ),
      });
    }

    case PROFILE_ABOUT_US_ADD_FILE: {
      const aboutUs = state.get('aboutUs');
      return state.set('aboutUs', {
        ...aboutUs,
        files: [action.payload, ...aboutUs.files],
      });
    }

    case PROFILE_ABOUT_US_UPDATE_FILE: {
      const aboutUs = state.get('aboutUs');
      const fileIndex = aboutUs.files.findIndex(
        (v: ProfileFile) => v.type === action.payload.type && v.id === action.payload.id
      );
      if (fileIndex < 0) {
        return state;
      }
      return state.set('aboutUs', {
        ...aboutUs,
        files: Object.assign([], aboutUs.files, {
          [fileIndex]: action.payload,
        }),
      });
    }

    case PROFILE_ABOUT_US_DELETE_FILE: {
      const aboutUs = state.get('aboutUs');
      return state.set('aboutUs', {
        ...aboutUs,
        files: aboutUs.files.filter(
          (v: ProfileFile) => v.type !== action.payload.type || v.id !== action.payload.id
        ),
      });
    }

    default:
      return state;
  }
};

export { initState, reducer };
