import { themeConfig } from 'config';
import { theme } from 'helpers/utility';
import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  isActivated: false,
  selectedTheme: theme.get() || themeConfig.theme,
});

const themeSwitcherReducer = function (state = initState, action) {
  switch (action.type) {
    case actions.CHANGE_THEME:
      theme.set(action.theme);
      return state.set('selectedTheme', action.theme);
    default:
      return state;
  }
};

export default themeSwitcherReducer;
