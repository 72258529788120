import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  modalVisible: false,
  creatingTemplate: false,
  createdTemplateData: null,
  updating: false,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    /* single application reducer */
    case actions.TOGGLE_TEMPLATE_EDITOR_MODAL:
      return state.set('modalVisible', action.payload);

    case actions.EMAIL_TEMPLATE_CREATE_START:
      return state.set('creatingTemplate', true);
    case actions.EMAIL_TEMPLATE_CREATE_ERROR:
    case actions.EMAIL_TEMPLATE_CREATE_DONE:
      return state.set('creatingTemplate', false).set('createdTemplateData', action.payload);

    case actions.EMAIL_TEMPLATE_UPDATE_START:
      return state.set('updating', true);

    case actions.EMAIL_TEMPLATE_UPDATE_DONE:
    case actions.EMAIL_TEMPLATE_UPDATE_ERROR:
      return state.set('updating', false);

    default:
      return state;
  }
}
