import { Select } from 'antd';
import { AntSelect, StyledSelect } from './styles/select.style';

const WDSelect = AntSelect(Select);
const { Option, OptGroup } = Select;
const PlainSelect = StyledSelect(Select);

const Arrow = () => (
  <span className="ant-select-arrow custom-arrow" unselectable="on" style={{ userSelect: 'none' }}>
    <i className="anticon anticon-down ant-select-arrow-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className=""
        viewBox="0 0 1024 1024"
        data-icon="down"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
      </svg>
    </i>
  </span>
);

WDSelect.Arrow = Arrow;

export default WDSelect;
export { Option, OptGroup, PlainSelect };
