import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { ContactSectionSubmitProps } from './types';

const updateContactSection = (payload: ContactSectionSubmitProps) => {
  const variables = { ...payload };

  variables.employeeCountOption = payload.employeeCountOption?.id as any;
  variables.socialNetworks = omit(variables.socialNetworks, '__typename');
  variables.contactInfo = omit(variables.contactInfo, '__typename');
  variables.companyAddresses = variables.companyAddresses.map((companyAddress) =>
    omit(companyAddress, '__typename')
  );

  const mutation = gql`
    mutation companyProfileContactSection(
      $profileId: Int!
      $website: String
      $employeeCountOption: Int
      $socialNetworks: CompanyProfileSocialNetworksInput
      $contactInfo: CompanyProfileContactInfoInput
      $companyAddresses: [CompanyProfileAddressInput!]
    ) {
      companyProfileContactSectionMutation(
        id: $profileId
        contact: {
          companyAddresses: $companyAddresses
          website: $website
          employeeCountOption: $employeeCountOption
          socialNetworks: $socialNetworks
          companyContactInfo: $contactInfo
        }
      ) {
        website
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });
};

export default updateContactSection;
