import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import gql from 'graphql-tag';
import { all, takeLatest, put, spawn, call } from 'redux-saga/effects';
import client from 'graphql/client';

type SliceState = {
  flags: string[] | null;
};

const initialState: SliceState = {
  flags: null,
};

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    fetchFlagsStart: () => {},
    fetchFlagsFinished: (state, action: PayloadAction<string[]>) => {
      state.flags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('LOGOUT', (state) => {
      state = initialState;
    });
  },
});

// saga

const fetchFlagsGraphql = () => {
  const query = gql`
    query featureFlags {
      featureFlags
    }
  `;

  return client.query({
    query,
  });
};

function* fetchFlagsGenerator() {
  // @ts-ignore
  const response = yield call(fetchFlagsGraphql);
  yield put(actions.fetchFlagsFinished(response.data.featureFlags));
}

export function* fetchFlags() {
  yield takeLatest(actions.fetchFlagsStart.type, fetchFlagsGenerator);
}

export function* rootSaga() {
  yield all([spawn(fetchFlags)]);
}

// exports

const { actions, reducer } = featureFlagsSlice;
export const { fetchFlagsStart, fetchFlagsFinished } = actions;
export default reducer;
