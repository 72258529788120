import axios from 'helpers/axios';
import { getToken } from '../../helpers/utility';
import config from '../../config';

const createEmailTemplateRequest = (data) => {
  const { groupName, locale } = data;

  return axios({
    method: 'post',
    url: `${config.apiUrl}/emailTemplates`,
    data: {
      subject: '',
      body: '',
      category: groupName,
      language: locale,
    },
    headers: {
      Authorization: `Bearer ${getToken().get('idToken')}`,
    },
  });
};

const deleteEmailTemplateRequest = ({ id }) => axios({
  method: 'delete',
  url: `${config.apiUrl}/emailTemplates/${id}`,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

const updateEmailTemplateRequest = data => axios({
  method: 'patch',
  url: `${config.apiUrl}/emailTemplates/${data.id}`,
  data,
  headers: {
    Authorization: `Bearer ${getToken().get('idToken')}`,
  },
});

export { createEmailTemplateRequest, updateEmailTemplateRequest, deleteEmailTemplateRequest };
