import { message } from 'antd';
import translate from 'languageProvider/inline';
import { all, call, cancelled, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { CompanyBrand } from 'types/profile.types';
import { CREATE_PROFILE_SNAPSHOT } from '../general/types';
import createCompanyBrandFromAPIResponse from './helpers';
import {
  companyBrandDelete as companyBrandDeleteRequest,
  companyBrandUpdate as companyBrandUpdateRequest
} from './requests';
import {
  CompanyBrandApiRequestAction,
  COMPANY_BRAND_DELETE_CANCELLED,
  COMPANY_BRAND_DELETE_DONE,
  COMPANY_BRAND_DELETE_ERROR,
  COMPANY_BRAND_DELETE_START,
  COMPANY_BRAND_UPDATE_CANCELLED,
  COMPANY_BRAND_UPDATE_DONE,
  COMPANY_BRAND_UPDATE_ERROR,
  COMPANY_BRAND_UPDATE_START
} from './types';

const Nprogress = require('nprogress');

function* companyBrandUpdateGenerator(action: CompanyBrandApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    const companyBrands = storeState.get('companyBrands');
    const response = yield call(companyBrandUpdateRequest, action.payload);
    const brandIndex = companyBrands.findIndex(
      (v: CompanyBrand) => v.id === action.payload.id && v.type === action.payload.type
    );
    const updatedPost = createCompanyBrandFromAPIResponse(
      response.data.companyProfileCompanyBrandMutation
    );
    if (brandIndex < 0) {
      yield put({
        type: COMPANY_BRAND_UPDATE_DONE,
        payload: {
          brands: [updatedPost, ...companyBrands],
          successMessage: translate('profile.form.saved'),
        },
      });
    } else {
      yield put({
        type: COMPANY_BRAND_UPDATE_DONE,
        payload: {
          brands: Object.assign([], companyBrands, {
            [brandIndex]: updatedPost,
          }),
          successMessage: translate('profile.form.saved'),
        },
      });
      message.success(translate('profile.form.saved'));
    }
    yield put({
      type: CREATE_PROFILE_SNAPSHOT,
    });
  } catch (e) {
    log(e);
    yield put({ type: COMPANY_BRAND_UPDATE_ERROR, payload: translate('error.tryAgainMessage') });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: COMPANY_BRAND_UPDATE_CANCELLED,
      });
    }
  }
}

function* companyBrandDeleteGenerator(action: CompanyBrandApiRequestAction): any {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const storeState = yield select((s) => s.NewProfile);
    const companyBrands = storeState.get('companyBrands');
    yield call(companyBrandDeleteRequest, action.payload);

    yield put({
      type: COMPANY_BRAND_DELETE_DONE,
      payload: {
        brand: action.payload,
        brands: companyBrands.filter(
          (v: CompanyBrand) => v.id !== action.payload.id || v.type !== action.payload.type
        ),
      },
    });
    message.success(translate('profile.form.saved'));
  } catch (e) {
    log(e);
    yield put({ type: COMPANY_BRAND_DELETE_ERROR });
    message.error(translate('error.tryAgainMessage'));
  } finally {
    yield call(() => {
      Nprogress.done();
    });
    if (yield cancelled()) {
      yield put({
        type: COMPANY_BRAND_DELETE_CANCELLED,
      });
    }
  }
}

function* companyBrandUpdate() {
  yield takeLatest(COMPANY_BRAND_UPDATE_START, companyBrandUpdateGenerator);
}

function* companyBrandDelete() {
  yield takeLatest(COMPANY_BRAND_DELETE_START, companyBrandDeleteGenerator);
}

export default function* rootSaga() {
  yield all([spawn(companyBrandUpdate), spawn(companyBrandDelete)]);
}
