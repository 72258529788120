import gql from 'graphql-tag';
import client from 'graphql/client';
import { omit } from 'lodash';
import { CompanyBenefit } from 'types/profile.types';
import { CompanyProfileBenefitsMutation } from './types';

const apiSyncCompanyBenefits = (profileId: number | null, payload: CompanyBenefit[]) => {
  let variables: CompanyProfileBenefitsMutation = {
    profileId,
    companyBenefits: payload.map((benefit: CompanyBenefit) => {
      return omit(benefit, ['id', 'benefitType', '__typename']);
    }),
  };

  const mutation = gql`
    mutation companyProfileBenefitsMutation(
      $profileId: Int!
      $companyBenefits: [CompanyProfileCompanyBenefitInput]
    ) {
      companyProfileBenefitsMutation(id: $profileId, companyBenefits: $companyBenefits) {
        benefitType {
          id
          titleSr
          titleEn
          inputExampleSr
          inputExampleEn
          iconClass
        }
        description
        hidden
        id
        order
      }
    }
  `;

  return client.mutate({
    mutation,
    variables,
  });

  //   return axios({
  //     method: 'post',
  //     url: `${config.apiUrl}/company-profile/company-benefits/edit`,
  //     data: {
  //       companyBenefits: companyBenefits.map((benefit) => ({
  //         description: benefit.description,
  //         benefitTypeId: benefit.benefitTypeId,
  //         hidden: benefit.hidden || false,
  //       })),
  //     },
  //     headers: {
  //       Authorization: `Bearer ${getToken().get('idToken')}`,
  //     },
  //   });
};

export default apiSyncCompanyBenefits;
