const pricelistMock = {
  RSD: {
    data: {
      adPriceList: [
        {
          adType: 'MINI',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '195000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '234000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'MINI',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '225000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '270000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'ECONOMY',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '490000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '588000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'ECONOMY',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '630000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '756000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'CLASSIC',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '1190000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '1428000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'CLASSIC',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '1590000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '1908000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'DESIGNED',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '2300000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '2760000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'DESIGNED',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '3190000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '3828000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'HOT',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '4200000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '5040000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'HOT',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '6200000',
              currency: 'RSD',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '7440000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
      ],
    },
  },
  EUR: {
    data: {
      adPriceList: [
        {
          adType: 'MINI',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '1800',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '1800',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'MINI',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '2100',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '2100',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'ECONOMY',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '4500',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '4500',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'ECONOMY',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '6000',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '6000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'CLASSIC',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '11000',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '11000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'CLASSIC',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '14500',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '14500',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'DESIGNED',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '21000',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '21000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'DESIGNED',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '29000',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '29000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'HOT',
          lengthInDays: 15,
          quote: {
            price: {
              amount: '38000',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '38000',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
        {
          adType: 'HOT',
          lengthInDays: 30,
          quote: {
            price: {
              amount: '56500',
              currency: 'EUR',
              __typename: 'Money',
            },
            priceWithTax: {
              amount: '56500',
              __typename: 'Money',
            },
            __typename: 'AdPriceQuote',
          },
          __typename: 'AdPriceQuoteWithAdInformation',
        },
      ],
    },
  },
};

export default pricelistMock;
