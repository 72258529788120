import client from 'graphql/client';
import { fetchEmployeeCountOptionsQuery, fetchCountriesQuery } from './queries';

const fetchCompanyEmployeeCountOptionsGraphql = () =>
  client.query({
    query: fetchEmployeeCountOptionsQuery,
  });
const fetchCompanyCountriesGraphql = () =>
  client.query({
    query: fetchCountriesQuery,
  });

export { fetchCompanyEmployeeCountOptionsGraphql, fetchCompanyCountriesGraphql };
