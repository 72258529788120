import { message, notification } from 'antd';
import Nprogress from 'nprogress';
import { all, call, cancelled, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import projectActions from 'redux/project/actions';
import tourActions from 'redux/tour/actions';
import translate from '../../languageProvider/inline';
import applicationActions from '../application/actions';
import cacheActions from '../cacheControl/actions';
import emailTemplatesEditorActions from '../emailTemplateEditor/actions.js';
import actions from './actions';
import { connectImageToProjectRequest, deleteLogoRequest, fetchEmailTemplatesRequest, fetchLogoRequest, sendEmailsRequest, uploadLogoRequest } from './requests';

/* fetch templates generator */
function* fetchTemplatesGenerator(action) {
  try {
    if (
      (action.type === actions.TOGGLE_MODAL && action.payload) ||
      action.type !== actions.TOGGLE_MODAL
    ) {
      yield call(() => {
        Nprogress.start();
      });
      const response = yield call(fetchEmailTemplatesRequest);
      yield put({
        type: actions.EMAIL_TEMPLATE_FETCHING_DONE,
        payload: response.data,
      });
    }
  } catch (e) {
    yield put({ type: actions.EMAIL_TEMPLATE_FETCHING_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

/* fetch logo generator */
function* fetchLogoGenerator(action) {
  try {
    if (
      (action.type === actions.TOGGLE_MODAL && action.payload) ||
      action.type !== actions.TOGGLE_MODAL
    ) {
      yield call(() => {
        Nprogress.start();
      });

      const projectState = yield select((state) => state.Project);

      const response = yield call(fetchLogoRequest, {
        projectId: projectState.get('currentProject').get('id'),
      });
      yield put({
        type: actions.LOGO_DATA_FETCH_DONE,
        payload: response.data,
      });
      yield put({
        type: actions.SELECT_FIRST_LOGO,
      });
    }
  } catch (e) {
    yield put({ type: actions.LOGO_DATA_FETCH_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

/* email sending generator */
function* sendEmailGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });
    const response = yield call(sendEmailsRequest, action.payload);
    yield put({
      type: actions.EMAIL_SEND_DONE,
      payload: response.data,
    });

    const { projectId } = action.payload;

    if (projectId) {
      yield put({
        type: projectActions.APPLICATION_FILTER_FETCH,
        payload: projectId,
      });
    }

    // refresh application if its in currrent view
    const applicationsStore = (state) => state.Application;
    const applicationsState = yield select(applicationsStore);
    if (
      action.payload.recepients.filter(
        (item) => item.id === applicationsState.get('application').id
      ).length
    ) {
      yield put({
        type: applicationActions.APPLICATION_REFRESH,
        payload: {
          variables: {
            applicationId: applicationsState.get('application').id,
            projectId
          },
          recache: true,
        },
      });
    }
    // update job store
    if (action.payload.category === 'rejection') {
      yield put({
        type: projectActions.UPDATE_REJECTED_APPLICATIONS,
        payload: {
          id: action.payload.recepients.map((item) => item.id),
        },
      });
    }
    // mark applications as invalid in graphql store
    yield put({
      type: cacheActions.INVALIDATE_APPLICATIONS,
      payload: action.payload.recepients.map((item) => item.id),
    });

    const {
      data: { rejected },
    } = response;

    yield call(() => {
      if (!rejected || rejected.length < action.payload.recepients.length) {
        notification.success({
          message: translate(response.data.userMessage),
        });
      }
    });

    yield call(() => {
      if (rejected && rejected.length) {
        const notificationMessage = {
          message: translate('emails.rejection.notice'),
        };
        notificationMessage.description = `${translate(
          'emails.rejection.rejected'
        )} ${rejected.join(', ')}`;
        notificationMessage.duration = 0;
        notification.info(notificationMessage);
      }
    });
    if (action.payload.recepients.length === 1) {
      yield put({
        type: tourActions.VERIFY_CHECKPOINT_START,
        payload: {
          section: 'bulk',
        },
      });
    } else {
      yield put({
        type: tourActions.FINISH_CHECKPOINT,
        payload: {
          section: 'bulk',
        },
      });
    }
  } catch (e) {
    log(e);
    yield put({ type: actions.EMAIL_SEND_ERROR });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.EMAIL_SEND_CANCELLED,
      });
    }
    yield call(() => {
      Nprogress.done();
    });
  }
}

function* uploadLogoGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const requests = action.payload.map((file) => call(uploadLogoRequest, file));
    const responses = yield all(requests);
    yield put({
      type: actions.LOGO_UPLOAD_DONE,
      payload: responses,
    });
  } catch (e) {
    const {
      response: { status },
    } = e;
    if (status === 400) {
      message.warning(translate('templates.fileupload.error.400'));
    }
    yield put({ type: actions.LOGO_UPLOAD_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

/* connect image to project */
function* connectImageToProjectGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    const projectState = yield select((state) => state.Project);

    const requests = action.payload.map(file =>
      call(connectImageToProjectRequest, {
        projectId: projectState.get('currentProject').get('id'),
        fileUrl: file.data.fileUrl,
      })
    );

    yield all(requests);
    yield put({
      type: actions.REFETCH_LOGO_DATA,
    });
  } catch (e) {
    log(e);
    yield put({ type: actions.CONNECT_IMAGE_TO_JOB_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

/* delete logo generator */
function* deleteLogoGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    yield call(deleteLogoRequest, action.payload);
    yield put({
      type: actions.DELETE_LOGO_DONE,
    });
  } catch (e) {
    yield put({ type: actions.DELETE_LOGO_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

function* setPreviousDataGenerator(action) {
  try {
    const previousData = {
      companyName: action.payload.senderName,
      email: action.payload.senderEmail,
      subject: action.payload.subject,
    };
    yield call(() => {
      window.sessionStorage.setItem('previous.companyName', previousData.companyName);
      window.sessionStorage.setItem('previous.email', previousData.email);
      window.sessionStorage.setItem('previous.subject', previousData.subject);
    });

    yield put({
      type: actions.FETCH_PREVIOUS_DATA,
      payload: true,
    });
  } catch (e) {
    log(e);
  }
}

function* fetchPreviousDataGenerator(action) {
  try {
    yield call(() => {
      Nprogress.start();
    });

    if (action.payload) {
      const previousData = {
        companyName: window.sessionStorage.getItem('previous.companyName'),
        email: window.sessionStorage.getItem('previous.email'),
        subject: '',
      };

      yield put({
        type: actions.SET_PREVIOUS_DATA,
        payload: previousData,
      });
    }
  } catch (e) {
    log(e);
  } finally {
    yield call(() => {
      Nprogress.done();
    });
  }
}

/* email templates saga */
function* fetchTemplates() {
  yield takeLatest(
    [
      emailTemplatesEditorActions.EMAIL_TEMPLATE_CREATE_DONE,
      emailTemplatesEditorActions.EMAIL_TEMPLATE_UPDATE_DONE,
      emailTemplatesEditorActions.EMAIL_TEMPLATE_DELETE_DONE,
      actions.TOGGLE_MODAL,
      actions.EMAIL_TEMPLATE_FETCH,
    ],
    fetchTemplatesGenerator
  );
}

function* connectImageToProject() {
  yield takeEvery([actions.LOGO_UPLOAD_DONE], connectImageToProjectGenerator);
}

function* fetchLogo() {
  yield takeLatest(
    [actions.TOGGLE_MODAL, actions.REFETCH_LOGO_DATA, actions.DELETE_LOGO_DONE],
    fetchLogoGenerator
  );
}

function* fetchPreviousData() {
  yield takeLatest([actions.TOGGLE_MODAL, actions.FETCH_PREVIOUS_DATA], fetchPreviousDataGenerator);
}

function* setPreviousData() {
  yield takeEvery([actions.EMAIL_SEND_START], setPreviousDataGenerator);
}

/* email sending saga */
function* sendEmails() {
  yield takeEvery([actions.EMAIL_SEND_START], sendEmailGenerator);
}

/* upload logo */
function* uploadLogo() {
  yield takeEvery([actions.LOGO_UPLOAD_START], uploadLogoGenerator);
}

/* delete logo */
function* deleteLogo() {
  yield takeEvery([actions.DELETE_LOGO_START], deleteLogoGenerator);
}

export default function* rootSaga() {
  yield all([
    spawn(fetchTemplates),
    spawn(sendEmails),
    spawn(fetchLogo),
    spawn(uploadLogo),
    spawn(connectImageToProject),
    spawn(deleteLogo),
    spawn(fetchPreviousData),
    spawn(setPreviousData),
  ]);
}
