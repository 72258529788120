import apiConfig from 'config';
import FormData from 'form-data';
import axios from 'helpers/axios';
import { cookie, getToken } from 'helpers/utility';
import actions from 'redux/auth/actions';
import storeObject from 'redux/storeDeclaration';

class TokenRefresher {
  constructor() {
    const { apiUrl } = apiConfig;
    this.apiUrl = apiUrl;
  }

  subscribers = [];

  refreshing = false;

  scheduled = null;

  getRefreshing = () => this.refreshing;

  setRefreshing = (state) => {
    this.refreshing = state;
    return this;
  };

  onRefreshed = (token, error) => {
    this.subscribers.map(cb => cb(token, error));
  };

  subscribeTokenRefresh = (cb) => {
    this.subscribers.push(cb);
  };

  schedule = (timeout) => {
    if (this.scheduled) {
      clearTimeout(this.scheduled);
    }
    this.scheduled = setTimeout(() => {
      this.refresh();
    }, timeout);
  }

  refresh = () => {
    this.setRefreshing(true);
    const tokenStorage = getToken();
    const formData = new FormData();
    formData.append('access_token', tokenStorage.get('idToken'));
    formData.append('refresh_token', tokenStorage.get('refreshToken'));
    axios
      .post(`${this.apiUrl}/refresh`, formData)
      .then((refreshResponse) => {
        if (
          refreshResponse.data
          && refreshResponse.data.access_token
          && refreshResponse.data.refresh_token
        ) {
          cookie
            .set('token', refreshResponse.data.access_token)
            .set('refresh_token', refreshResponse.data.refresh_token);
          this.setRefreshing(false);
          this.onRefreshed(refreshResponse.data.access_token);
          this.subscribers = [];
          this.schedule(3000000);
        } else {
          storeObject.getStore().dispatch(actions.logout({
            remember: true,
            requestLogin: true,
          }));
        }
      })
      .catch((error) => {
        this.setRefreshing(false);
        this.onRefreshed(false, error);
        this.subscribers = [];
      });
  };
}

export default new TokenRefresher();
