import { fromJS, Map } from 'immutable';
import emailTemplateEditorActions from '../emailTemplateEditor/actions';
import actions from './actions';

const initState = new Map({
  modalVisible: false,
  templates: [],
  selectedTemplate: null,
  selectedTemplateGroup: null,
  selectedApplications: [],
  loadingTemplates: false,
  loadedTemplate: undefined,
  senderName: '',
  senderEmail: '',
  subject: '',
  body: undefined,
  templatesCopyForEditing: [],
  currentCategory: null,
  logo: [],
  selectedLogo: null,
  logoUploading: false,
  logoLoading: false,
  previousData: new Map({
    companyName: null,
    email: null,
    subject: null,
  }),
  sending: false,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_MODAL:
      return state.set('modalVisible', action.payload).set('logoLoading', true);

    case actions.EMAIL_TEMPLATE_FETCH:
      return state.set('loadingTemplates', true);
    case actions.EMAIL_TEMPLATE_FETCHING_ERROR:
      return state.set('loadingTemplates', false);
    case actions.EMAIL_TEMPLATE_FETCHING_DONE:
      return state
        .set('templates', action.payload)
        .set('templatesCopyForEditing', fromJS(action.payload))
        .set('loadingTemplates', false);
    case actions.SET_TEMPLATE:
      return state.set('selectedTemplate', action.payload);
    case actions.SET_TEMPLATE_GROUP:
      return state.set('selectedTemplateGroup', action.payload);
    case actions.SET_APPLICATIONS:
      return state.set('selectedApplications', action.payload);
    case actions.LOAD_TEMPLATE_DATA:
      return state.set('loadedTemplate', action.payload);
    case actions.SET_SENDER_NAME:
      return state.set('senderName', action.payload);
    case actions.SET_SENDER_EMAIL:
      return state.set('senderEmail', action.payload);
    case actions.SET_SUBJECT:
      return state.set('subject', action.payload);
    case actions.SET_BODY:
      return state.set('body', action.payload);
    case actions.SET_SELECTED_LOGO:
      return state.set('selectedLogo', action.payload);

    case actions.DELETE_LOGO_START:
      return state.set(
        'logo',
        state.get('logo').filter((file) => file.id !== action.payload.id)
      );

    case actions.COPY_TEMPLATES:
      return state.set('templatesCopyForEditing', fromJS(state.get('templates')));

    case actions.EMAIL_SEND_START:
      return state.set('sending', true);

    case actions.EMAIL_SEND_ERROR:
    case actions.EMAIL_SEND_CANCELLED:
      return state.set('sending', false);

    case actions.EMAIL_SEND_DONE:
      return state.set('modalVisible', false).set('sending', false);

    case actions.REFETCH_LOGO_DATA:
      return state.set('logoLoading', true);
    case actions.LOGO_DATA_FETCH_DONE:
      return state.set('logo', action.payload).set('logoLoading', false);

    case actions.SELECT_FIRST_LOGO: {
      const logo = state.get('selectedLogo') || state.get('logo')[0] || null;
      return state.set('selectedLogo', logo);
    }

    case actions.UPDATE_EDITING_SUBJECT:
      return state.setIn(
        [
          'templatesCopyForEditing',
          state
            .get('templatesCopyForEditing')
            .toJS()
            .findIndex((el) => el.id === action.payload.id),
        ],
        fromJS(action.payload)
      );

    case actions.UPDATE_EDITING_BODY:
      return state.setIn(
        [
          'templatesCopyForEditing',
          state
            .get('templatesCopyForEditing')
            .toJS()
            .findIndex((el) => el.id === action.payload.id),
        ],
        fromJS(action.payload)
      );

    case actions.RESET_EDITOR:
      return state
        .set('selectedTemplate', undefined)
        .set('selectedTemplateGroup', undefined)
        .set('selectedApplications', [])
        .set('loadedTemplate', undefined)
        .set('currentCategory', null)
        .set('senderName', '')
        .set('senderEmail', '')
        .set('subject', '')
        .set('body', undefined)
        .set('logo', [])
        .set('selectedLogo', null);

    case emailTemplateEditorActions.EMAIL_TEMPLATE_DELETE_START: {
      /** update UI before saga stuff */
      const templates = fromJS(state.get('templates'));
      return state.set(
        'templates',
        templates.delete(templates.findIndex((item) => item.id === action.payload.id)).toJS()
      );
    }

    case actions.LOGO_UPLOAD_START:
      return state.set('logoUploading', true);
    case actions.LOGO_UPLOAD_DONE:
    case actions.LOGO_UPLOAD_ERROR:
      return state.set('logoUploading', false);

    case actions.SET_PREVIOUS_DATA:
      return state.set('previousData', fromJS(action.payload));

    case actions.SET_CATEGORY:
      return state.set('currentCategory', action.payload);
    default:
      return state;
  }
}
