import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  application: {
    name: '',
    email: '',
    attachments: [],
    comments: [],
  },
  applicationGdpred: false,
  applications: [],
  applicationLoading: true,
  applications_loading: true,
  applicationRatingMutating: false,
  showComments: false,
  applicationCommentEditorVisible: false,
  applicationCommentMutating: false,
  applicationCommentEditorContent: '',
  applicationCommentEditorContentRaw: '',
  applicationCommentEditorInvalid: false,
  applicationCommentEditorCommentId: null,
  showGdprStuff: true,
});

export default function applicationReducer(state = initState, action) {
  switch (action.type) {
    /* single application reducer */
    case actions.APPLICATION_FETCHING_DONE:
      return state.set('application', action.payload);
    case actions.APPLICATION_LOADING_DONE:
      return state.set('applicationLoading', false);
    // return state;
    case actions.APPLICATION_FETCH: // has saga side effect
      return state.set('applicationLoading', true).set('applicationGdpred', false);
    case actions.APPLICATION_LOADING_START: // no saga side effect
      return state.set('applicationLoading', true);

    /* single application rating mutation */
    case actions.APPLICATION_RATING_MUTATION_START:
      if (action.payload.id.includes(state.get('application').id)) {
        return state.set('applicationRatingMutating', true).set('application', {
          ...state.get('application'),
          rating: action.payload.rating,
        });
      }
      return state.set('applicationRatingMutating', true);

    case actions.APPLICATION_RATING_MUTATION_ERROR:
      return state.set('applicationRatingMutating', false);
    case actions.APPLICATION_RATING_MUTATION_DONE:
      return state
        .set('applicationRatingMutating', false)
        .set('application', { ...state.get('application'), rating: action.payload.rating });

    /* comments */
    case actions.APPLICATION_COMMENT_ADD_MUTATION_LOADING:
      return state.set('applicationCommentMutating', true);
    case actions.APPLICATION_COMMENT_EDIT_MUTATION_DONE:
    case actions.APPLICATION_COMMENT_ADD_MUTATION_DONE:
      return state
        .set('application', {
          ...state.get('application'),
          comments: action.payload.comments,
        })
        .set('applicationCommentMutating', false);
    case actions.APPLICATION_COMMENT_EDITOR_TOGGLE:
      return state.set('applicationCommentEditorVisible', action.payload);
    case actions.APPLICATION_COMMENT_EDITOR_SET:
      return state.set('applicationCommentEditorContent', action.payload);
    case actions.APPLICATION_COMMENT_EDITOR_SET_RAW:
      return state.set('applicationCommentEditorContentRaw', action.payload);
    case actions.APPLICATION_COMMENT_EDITOR_INVALID_TOGGLE:
      return state.set('applicationCommentEditorInvalid', action.payload);
    case actions.APPLICATION_COMMENT_EDITOR_SET_COMMENT_ID:
      return state.set('applicationCommentEditorCommentId', action.payload);

    /* single application selectionLevel mutation */
    case actions.APPLICATION_SELECTION_LEVEL_MUTATION_START: {
      if (action.payload.id.indexOf(state.get('application').id) !== -1) {
        return state.set('application', {
          ...state.get('application'),
          selectionLevel: action.payload.selectionLevel,
        });
      }
      return state;
    }

    /* application list reducer */
    case actions.APPLICATIONS_FETCHING_DONE:
      return state.set('applications', action.payload);
    case actions.APPLICATIONS_LOADING_DONE:
      return state.set('applications_loading', false);
    case actions.APPLICATIONS_FETCH:
      return state.set('applications_loading', true);

    /* comments sidebar */
    case actions.SHOW_COMMENTS:
      return state.set('showComments', true);
    case actions.HIDE_COMMENTS:
      return state.set('showComments', false);

    /* tags mutation */
    case actions.APPLICATION_TAG_MUTATION_REMOVE_START: {
      /** remove now, saga will populate with new tag list anyway
       * but this looks better for the user
       */
      const { tagId } = action.payload;
      return state.set('application', {
        ...state.get('application'),
        tags: state.get('application').tags.filter((tag) => tag.id !== tagId),
      });
    }

    case actions.APPLICATION_TAG_MUTATION_ADD_START: {
      /** add now, saga will populate with new tag list anyway
       * but this looks better for the user
       */
      const tag = action.payload;
      const { tagId } = tag;
      const { tags, ...application } = state.get('application');
      /* filter to check if already on the list */
      if (!state.get('application').tags.filter((item) => item.id === tagId).length) {
        tags.push({
          id: tag.tagId,
          name: tag.name,
          special_type: '',
          __typename: 'Tag',
        });
        return state.set('application', {
          tags,
          ...application,
        });
      }
      return state;
    }

    case actions.APPLICATION_TAGS_FETCH_DONE:
      if (state.get('application').id === action.payload.id) {
        return state.set('application', {
          ...state.get('application'),
          tags: action.payload.tags,
        });
      }
      return state;

    case actions.APPLICATION_EVENTS_FETCH_DONE:
      if (state.get('application').id === action.payload.id) {
        return state.set('application', {
          ...state.get('application'),
          events: action.payload.events,
        });
      }
      return state;

    case actions.APPLICATION_COMMENTS_FETCH_DONE:
      if (state.get('application').id === action.payload.id) {
        return state.set('application', {
          ...state.get('application'),
          comments: action.payload.comments,
        });
      }
      return state;

    case actions.APPLICATION_RATING_FETCH_DONE:
      if (state.get('application').id === action.payload.id) {
        return state.set('application', {
          ...state.get('application'),
          rating: action.payload.rating,
        });
      }
      return state;

    case actions.APPLICATION_HIDE_START:
      return state.set('application', {
        ...state.get('application'),
        hidden: action.payload.state,
      });

    case actions.APPLICATION_GDPRED:
      return state.set('applicationGdpred', true);

    case actions.APPLICATION_TAG_MUTATION_REMOVE_DONE:
    case actions.APPLICATION_TAG_MUTATION_ADD_DONE:
      return state.set('application', { ...state.get('application'), tags: action.payload.tags });

    case '@@router/LOCATION_CHANGE':
      return state.set('applicationCommentEditorVisible', false);

    /* default logout handler */
    case 'LOGOUT':
      return initState;
    default:
      return state;
  }
}
