const maintenanceActions = {
  TOGGLE_MAINTENANCE: 'TOGGLE_MAINTENANCE',

  toggleMaintenance: (payload: boolean): any => ({
    type: maintenanceActions.TOGGLE_MAINTENANCE,
    payload,
  }),
};

export default maintenanceActions;
