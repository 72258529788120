import ADTYPE from 'components/jobSubmission/adTypes';
import gql from 'graphql-tag';
import client from 'graphql/client';

const fetchAdvertPositionsGraphql = () => {
  const query = gql`
    query lists {
      lists {
        advertPositions {
          id
          name
        }
      }
    }
  `;
  return client.query({
    query,
    fetchPolicy: 'network-only',
  });
};

/* pricelist fetch */
const fetchPricelistGraphql = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const query = gql`
    query adPriceList($currency: Currency!) {
      adPriceList(currency: $currency) {
        adType
        lengthInDays
        quote {
          price {
            amount
            currency
          }
          priceWithTax {
            amount
          }
        }
      }
    }
  `;

  return client.query({
    query,
    variables,
    ...params,
  });
};

const mutateCreateIncompleteJob = (payload) => {
  const params = {};
  const { variables } = payload;

  if (payload.recache) {
    params.fetchPolicy = 'network-only';
  }

  const mutation = gql`
    mutation createIncompleteJob($durationInDays: Int, $adType: AdType, $siteVisibility: Int) {
      createIncompleteJob(
        durationInDays: $durationInDays
        adType: $adType
        siteVisibility: $siteVisibility
      )
    }
  `;

  return client.mutate({
    mutation,
    variables,
    ...params,
  });
};

const fetchPriceCalculationGraphQl = (payload) => {
  const query = gql`
    query adPriceCalculation(
      $currency: Currency!
      $adType: AdType
      $lengthInDays: Int
      $addOns: [AddOnTypes]
      $adPlacementType: AdPlacementType
    ) {
      adPriceCalculation(
        currency: $currency
        adPriceRequests: [
          {
            adType: $adType
            lengthInDays: $lengthInDays
            adCount: 1
            addOns: $addOns
            adPlacementType: $adPlacementType
          }
        ]
      ) {
        price {
          amount
          currency
        }
        tax {
          amount
          currency
        }
        priceWithTax {
          amount
          currency
        }
        addOns {
          name
          price {
            amount
            currency
          }
          tax {
            amount
            currency
          }
          priceWithTax {
            amount
            currency
          }
        }
      }
    }
  `;

  return client.query({
    query,
    variables: {
      currency: payload.currency,
      adType: ADTYPE.GRAPHQL[payload.adType],
      lengthInDays: payload.lengthInDays,
      addOns: payload.addOns,
      adPlacementType: payload.adPlacementType,
    },
  });
};

export default fetchPricelistGraphql;
export { mutateCreateIncompleteJob, fetchAdvertPositionsGraphql, fetchPriceCalculationGraphQl };
