import { message } from 'antd';
import { push, replace } from 'connected-react-router';
import FormData from 'form-data';
import client from 'graphql/client';
import axios from 'helpers/axios';
import links from 'helpers/links';
import TokenRefresher from 'helpers/tokenRefresher';
import { clearToken, cookie, getToken, isDemo } from 'helpers/utility';
import jwtDecode from 'jwt-decode';
import translate from 'languageProvider/inline';
import Nprogress from 'nprogress';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import config from '../../config';
import actions from './actions';

const login = (formData) => axios.post(`${config.apiUrl}/login`, formData);

function* loginRequestGenerator(action) {
  let hide;
  try {
    yield call(() => {
      Nprogress.start();
      hide = message.loading(translate('signin.wait'), 0);
    });
    const formData = new FormData();
    formData.append('username', action.payload.username);
    formData.append('password', action.payload.password);
    const response = yield call(login, formData);
    yield put({
      type: actions.LOGIN_SUCCESS,
      token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    });
    // yield put(push(`${config.baseUrl}/ats-prijave`));
  } catch (e) {
    message.error(translate('signin.error'));
    yield put({ type: actions.LOGIN_ERROR });
  } finally {
    yield call(() => {
      Nprogress.done();
      hide();
    });
  }
}

function* loginSuccessGenerator(payload) {
  yield cookie.set('token', payload.token).set('refresh_token', payload.refresh_token);

  if (!isDemo()) {
    // set schedule to refresh expiring tokens
    let refreshIn = 3000000;
    try {
      const { exp } = jwtDecode(payload.token);
      const now = Math.round(new Date().getTime() / 1000);
      const expiresIn = exp - now;

      if (expiresIn > 0) {
        // remove one minute and schedule refresh
        refreshIn = (expiresIn - 60) * 1000;
      }
    } catch (e) {
      log(e);
    }

    TokenRefresher.schedule(refreshIn);
  }
}

let loggingOut = false;

function* logoutGenerator(action) {
  if (isDemo()) {
    client.resetStore();
    clearToken();
    yield put(push(`${config.baseUrl}/prijava-poslodavca`));
  } else {
    clearToken();
    yield call(() => {
      if (action.payload.requestLogin) {
        if (!loggingOut) {
          loggingOut = true;
          window.location = links.oauth().requestLogin;
        }
      } else {
        window.location = links.oauth().logout;
      }
    });
  }
}

function* checkAuthorizationGenerator() {
  const token = getToken().get('idToken');
  const refreshToken = getToken().get('refreshToken');
  if (token) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      token,
      refresh_token: refreshToken,
      profile: 'Profile',
    });
  }
}

function* validateGenerator(action) {
  try {
    const { origin, pathname } = window.location;
    const formData = new FormData();
    formData.append('code', action.payload);
    formData.append('redirect_uri', `${origin}${pathname}`);
    const response = yield call(
      (params) => axios.post(`${config.apiUrl}/login/authorization_code`, params),
      formData
    );
    const { access_token: token, refresh_token: refreshToken } = response.data;
    yield put({
      type: actions.LOGIN_SUCCESS,
      token,
      refresh_token: refreshToken,
      profile: 'Profile',
    });
    const redirectTo = window.sessionStorage.getItem('redirectTo');
    yield put(replace(redirectTo || `${config.baseUrl}/ats-prijave`));
    window.sessionStorage.removeItem('redirectTo');
  } catch (e) {
    log(e);

    const Maintenance = (state) => state.Maintenance;
    const state = yield select(Maintenance);
    const maintenance = state.get('maintenance');
    if (!maintenance) {
      window.location = links.oauth().login;
    }
  }
}

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', loginRequestGenerator);
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, loginSuccessGenerator);
}

// export function* loginError() {
//   yield takeEvery(actions.LOGIN_ERROR, function* () { });
// }

export function* logout() {
  yield takeEvery(actions.LOGOUT, logoutGenerator);
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorizationGenerator);
}

export function* validate() {
  yield takeEvery(actions.VALIDATE, validateGenerator);
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    // fork(loginError),
    fork(logout),
    fork(validate),
  ]);
}
