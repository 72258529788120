const projectActions = {
  FETCH_PROJECT_START: 'FETCH_PROJECT_START',
  FETCH_PROJECT_DONE: 'FETCH_PROJECT_DONE',
  FETCH_PROJECT_ERROR: 'FETCH_PROJECT_ERROR',
  FETCH_PROJECT_CANCELLED: 'FETCH_PROJECT_CANCELLED',
  FETCH_PROJECT_DENIED: 'FETCH_PROJECT_DENIED',

  /** application filters  */
  APPLICATION_FILTER_FETCH: 'APPLICATION_FILTER_FETCH',
  APPLICATION_FILTER_FETCHING_DONE: 'APPLICATION_FILTER_FETCHING_DONE',
  APPLICATION_FILTER_FETCHING_ERROR: 'APPLICATION_FILTER_FETCHING_ERROR',
  APPLICATION_FILTER_FETCHING_CANCELLED: 'APPLICATION_FILTER_FETCHING_CANCELLED',
  APPLICATION_FILTER_CLEAR: 'APPLICATION_FILTER_CLEAR',

  SET_SORT: 'SET_SORT',
  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',

  EXPAND_APPLICATIONS_LIST: 'EXPAND_APPLICATIONS_LIST',
  COLLAPSE_APPLICATIONS_LIST: 'COLLAPSE_APPLICATIONS_LIST',
  HIDE_APPLICATIONS_LIST: 'HIDE_APPLICATIONS_LIST',
  SHOW_APPLICATIONS_LIST: 'SHOW_APPLICATIONS_LIST',

  RESET_APPLICATION_SELECTION: 'RESET_APPLICATION_SELECTION',

  TOGGLE_BULK_ACTIONS: 'TOGGLE_BULK_ACTIONS',

  MODIFY_APPLICATION_SELECTION: 'MODIFY_APPLICATION_SELECTION',
  SELECT_ALL_APPLICATIONS: 'SELECT_ALL_APPLICATIONS',

  /** rejection */
  UPDATE_REJECTED_APPLICATIONS: 'UPDATE_REJECTED_APPLICATIONS',

  TOGGLE_SHOW_HIDDEN: 'TOGGLE_SHOW_HIDDEN',

  TOGGLE_SELECTION_LABEL_EDITOR: 'TOGGLE_SELECTION_LABEL_EDITOR',
  CHANGE_SELECTION_LABEL_TEXT: 'CHANGE_SELECTION_LABEL_TEXT',
  CHANGE_SELECTION_LABEL_VISIBLITY: 'CHANGE_SELECTION_LABEL_VISIBLITY',

  SAVE_CUSTOM_SELECTION_LABELS_START: 'SAVE_CUSTOM_SELECTION_LABELS_START',
  SAVE_CUSTOM_SELECTION_LABELS_DONE: 'SAVE_CUSTOM_SELECTION_LABELS_DONE',
  SAVE_CUSTOM_SELECTION_LABELS_ERROR: 'SAVE_CUSTOM_SELECTION_LABELS_ERROR',
  SAVE_CUSTOM_SELECTION_LABELS_CANCELLED: 'SAVE_CUSTOM_SELECTION_LABELS_CANCELLED',

  fetchProject: (variables: any, recache: Boolean) => ({
    type: projectActions.FETCH_PROJECT_START,
    payload: {
      variables,
      recache,
    },
  }),

  clearApplicationFilters: () => ({
    type: projectActions.APPLICATION_FILTER_CLEAR,
  }),

  fetchApplicationFilters: (projectId: number) => ({
    type: projectActions.APPLICATION_FILTER_FETCH,
    payload: projectId,
  }),

  setFilter: (payload: any) => ({
    type: projectActions.SET_FILTER,
    payload,
  }),

  resetFilter: (payload: any) => ({
    type: projectActions.RESET_FILTER,
    payload,
  }),

  expandApplicationList: () => ({
    type: projectActions.EXPAND_APPLICATIONS_LIST,
  }),

  collapseApplicationList: () => ({
    type: projectActions.COLLAPSE_APPLICATIONS_LIST,
  }),

  hideApplicationsList: () => ({
    type: projectActions.HIDE_APPLICATIONS_LIST,
  }),

  showApplicationList: () => ({
    type: projectActions.SHOW_APPLICATIONS_LIST,
  }),

  resetApplicationToSelection: () => ({
    type: projectActions.RESET_APPLICATION_SELECTION,
  }),

  /** there saga connected to this action */
  setSort: (payload: any) => ({
    type: projectActions.SET_SORT,
    payload,
  }),

  toggleBulkActions: (state: Boolean) => ({
    type: projectActions.TOGGLE_BULK_ACTIONS,
    payload: {
      state,
    },
  }),

  setApplicationToSelection: (payload: any) => ({
    type: projectActions.MODIFY_APPLICATION_SELECTION,
    payload,
  }),

  selectAllApplications: () => ({
    type: projectActions.SELECT_ALL_APPLICATIONS,
  }),

  toggleShowHidden: (payload: any) => ({
    type: projectActions.TOGGLE_SHOW_HIDDEN,
    payload,
  }),

  
  saveCustomSelectionLabels: (payload: any) => ({
    type: projectActions.SAVE_CUSTOM_SELECTION_LABELS_START,
    payload,
  }),

  changeSelectionLabelVisibility: (payload: any) => ({
    type: projectActions.CHANGE_SELECTION_LABEL_VISIBLITY,
    payload,
  }),

  changeSelectionLabelText: (payload: any) => ({
    type: projectActions.CHANGE_SELECTION_LABEL_TEXT,
    payload,
  }),

  toggleSelectionLabelEditor: (state: Boolean) => ({
    type: projectActions.TOGGLE_SELECTION_LABEL_EDITOR,
    payload: state,
  }),
};

export default projectActions;
