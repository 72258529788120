import ats from './ats';
import atsDark from './atsDark';
import atsFancy from './atsFancy';
import atsOldFancy from './atsOldFancy';

const themes = {
  ats,
  atsFancy,
  atsDark,
  atsOldFancy
};
export default themes;
