import {
  initState as profilePostInitState,
  reducer as profilePostReducer
} from './profilePost/reducer';

import { initState as aboutUsInitState, reducer as aboutUsReducer } from './aboutUs/reducer';

import {
  initState as companyBrandInitState,
  reducer as companyBrandReducer
} from './companyBrand/reducer';

import {
  initState as companyBenefitsInitState,
  reducer as companyBenefitsReducer
} from './companyBenefits/reducer';

import {
  initState as contactSectionInitState,
  reducer as contactSectionReducer
} from './contactSection/reducer';

import {
  initState as companyTeamMembersInitState,
  reducer as companyTeamMembersReducer
} from './companyTeamMembers/reducer';

import {
  initState as selectionProcessInitState,
  reducer as selectionProcessReducer
} from './selectionProcess/reducer';

import {
  initState as myCompanySectionInitState,
  reducer as myCompanySectionReducer
} from './myCompanySection/reducer';

import { initState as generalInitState, reducer as generalReducer } from './general/reducer';
import { ProfileActions } from './types';

const initState = generalInitState
  .merge(profilePostInitState)
  .merge(aboutUsInitState)
  .merge(companyBrandInitState)
  .merge(companyBenefitsInitState)
  .merge(contactSectionInitState)
  .merge(companyTeamMembersInitState)
  .merge(selectionProcessInitState)
  .merge(myCompanySectionInitState);

const rootReducer = (state = initState, action: ProfileActions) => {
  let changedState = generalReducer(state, action);
  changedState = profilePostReducer(changedState, action);
  changedState = aboutUsReducer(changedState, action);
  changedState = companyBrandReducer(changedState, action);
  changedState = companyBenefitsReducer(changedState, action);
  changedState = contactSectionReducer(changedState, action);
  changedState = companyTeamMembersReducer(changedState, action);
  changedState = selectionProcessReducer(changedState, action);
  changedState = myCompanySectionReducer(changedState, action);
  return changedState;
};

export default rootReducer;
